import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {SupplierRepository} from "./supplier.repository";
import {SupplierModel} from "./supplier.model";
import {ROUTING} from "../../utilities/routing";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum SupplierDtoTypes {
  SupplierDTO = 'SupplierDTO',
  SlimSupplierDTO = 'SlimSupplierDTO'
}

@Injectable({
  providedIn: 'root'
})
export class SupplierService extends CrudService<SupplierModel, SupplierRepository<SupplierModel>> {


  constructor(repo: SupplierRepository<SupplierModel>) {
    super(ROUTING.SUPPLIER, repo, SupplierModel, PageMetaDataConf.SUPPLIER);
  }
}

