import {Moment} from "moment";
import {StockConfig, StockProduct, WarehouseModel} from "../warehouse/warehouse.model";
import {PriceModel} from "../../models/price.model";
import {ProductHistoryModel, SupplierProduct} from "../product/product.model";
import {BaseModel} from "../../utilities/abstract/base.model";
import {ConvertFrom, ConvertProcess, IgnoreProperty, ParseProcess, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../processor.service";
import {AttachmentModel, EntityAttachment} from "../attachment/attachment.model";
import {Currencies} from "../../utilities/types";

export class Note {
  message = '';

  constructor(message?: string) {
    this.message = message ?? '';
  }
}

export enum SupplierOrderStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  ORDERED = 'ORDERED',
  ARRIVED = 'ARRIVED',
  PROCESSED = 'PROCESSED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED'

}

export enum SlimSupplierOrderStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  PROCESSED = 'PROCESSED'

}


export class SupplierOrderElement extends BaseModel {
  count = 0;
  note?: Note;
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseMoment'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'convertMoment'})
  estimatedArrival?: Moment;

  @ParseTo(PriceModel)
  @ConvertFrom
  prices: PriceModel[] = [];
  @ParseTo(SupplierProduct)
  supplierProduct!: SupplierProduct;

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getSupplierProductIdIfExist'}, "model")
  supplierProductId = 0;


  @IgnoreProperty
  countArrived = 0;
  @IgnoreProperty
  product?: ProductHistoryModel;
  @IgnoreProperty
  config!: StockConfig;
  message = '';
  @IgnoreProperty
  productName = '';
  @IgnoreProperty
  net = '';

  public static initSupplierOrderElementByStockProduct(stockProduct: StockProduct) {
    const supplierOrderElement = new SupplierOrderElement();
    supplierOrderElement.supplierProduct = stockProduct.product.supplierProduct;
    supplierOrderElement.prices = stockProduct.product.supplierProduct.prices;
    supplierOrderElement.count = stockProduct.orderCount;
    return supplierOrderElement;
  }
}

export class SupplierOrder extends BaseModel {
  orderNumber = '';

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'addNote'}, "model")
  note?: Note;
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT


  message = '';


  @ParseTo(EntityAttachment)
  @ConvertFrom
  attachments: EntityAttachment[] = []

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseMoment'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'convertMoment'})
  estimatedArrival?: Moment;

  warehouse!: WarehouseModel

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getWarehouseIdIfExist'}, "model")
  warehouseId = 0;

  @ParseTo(SupplierOrderElement)
  @ConvertFrom
  elements: SupplierOrderElement[] = [];

  getPdfs(): AttachmentModel[] {
    return this.attachments.map(ac => ac.attachment);
  }

  get sumOrderable(): number {
    let count = 0;
    this.elements.forEach(product => {
      count += product.count;

    });
    return count;
  }

  get sumOrderablePrice(): number {
    let price = 0;
    this.elements.forEach(element => {
      price += element.count * element.supplierProduct.basePrice.gross();
    });
    return price;
  }

  get supplierName(): string {
    if (this.elements) {
      return this.elements[0].supplierProduct.companyName;
    }
    return '';
  }

  get currency(): Currencies {
    if (this.elements[0] && this.elements[0].supplierProduct.prices[0]) {
      return this.elements[0].supplierProduct.prices[0].currency
    }
    return Currencies.huf;
  }
}
