import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {SupplierOrder, SupplierOrderElement} from "./supplier-order.model";
import {SupplierOrderRepository} from "./supplier-order.repository";
import {ValidatorService} from "../../utilities/service/validator.service";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";
import {SupplierOrderInvoiceRepository} from "./supplier-order-invoice.repository";
import {SupplierOrderInvoice} from "./supplier-order-invoice.model";
import {PostPayload} from "../../models/post-payload.model";
import {PutPayload} from "../../models/put-payload.model";

export enum SupplierOrderDtoType {
  SupplierOrderDTO = 'SupplierOrderDTO',
  SlimSupplierOrderDTO = 'SlimSupplierOrderDTO',
  SupplierOrderInvoiceDTO = 'SupplierOrderInvoiceDTO',
  SlimSupplierOrderInvoiceDTO = 'SlimSupplierOrderInvoiceDTO',
}

@Injectable({
  providedIn: 'root'
})
export class SupplierOrderService extends CrudService<SupplierOrder, SupplierOrderRepository> {

  constructor(repo: SupplierOrderRepository, private supplierOrderInvoiceRepo: SupplierOrderInvoiceRepository) {
    super(ROUTING.SUPPLIER_ORDER, repo, SupplierOrder, PageMetaDataConf.SUPPLIER_ORDER);
  }

  override validate(model: SupplierOrder) {
    // ValidatorService.required(model.estimatedArrival, 'error.supplierOrder.estimatedArrival')
    ValidatorService.required(model.warehouse, 'error.supplierOrder.warehouse')
    if (model.elements.length < 1) {
      throw new Error('error.supplierOrder.elements');
    }

    model.elements.forEach(element => {
      ValidatorService.required(element.supplierProduct, 'error.supplierOrder.elementProduct')
      ValidatorService.required(element.count, 'error.supplierOrder.count')
      if (element.count < 1) {
        throw new Error('error.supplierOrder.count');
      }
    })

  }

  updateSupplierInvoice(id: number, supplierInvoice: SupplierOrderInvoice) {
    return this.supplierOrderInvoiceRepo.update(id, new PutPayload<SupplierOrderInvoice>(supplierInvoice), SupplierOrderInvoice);
  }

  getSupplierOrderInvoice(id: number) {
    const filter = this.filterService.filter(SupplierOrderDtoType.SupplierOrderInvoiceDTO)
      .equal('supplierOrder_id', id)
      .and()
      .equal('deleted', false)
      .create();
    return this.supplierOrderInvoiceRepo.getAllByFilter(filter.stringify(), SupplierOrderInvoice);
  }

  createInvoiceData(id: number, currency: string) {
    const payload: PostPayload<any> = new PostPayload({
      supplierOrderId: id, currency: currency
    }, SupplierOrderDtoType.SupplierOrderInvoiceDTO);
    return this.supplierOrderInvoiceRepo.save(payload, SupplierOrderInvoice);
  }

  setOrdered(id: number,) {
    return this.repo.setOrdered(id);
  }

  process(row: SupplierOrder) {
    return this.repo.process(row.id, row);
  }

  approve(row: SupplierOrder) {
    return this.repo.approve(row.id);
  }

  addElement(supplierOrderId: number, supplierOrder: SupplierOrder) {
    return this.repo.addElement(supplierOrderId, supplierOrder);
  }

  updateElement(supplierOrderId: number, supplierOrder: SupplierOrder) {
    return this.repo.updateElement(supplierOrderId, supplierOrder);
  }

  removeElement(supplierOrderId: number, tempElement: SupplierOrderElement) {
    return this.repo.removeElement(supplierOrderId, tempElement.id);
  }

  approveElement(supplierOrderId: number, element: SupplierOrderElement) {
    return this.repo.approveElement(supplierOrderId, element.id);
  }

  approveElementOld(supplierOrderId: number, element: SupplierOrderElement, count: number) {
    return this.repo.approveElementOld(supplierOrderId, element.id, count);
  }

  uploadPdfs(model: SupplierOrder) {
    return this.repo.uploadPdfs(model.id, model);
  }
}

