import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

export class AddAttachmentModalInput {
  model: any;
  multiple = false;
  maxElement = 1;

  constructor(model: any, multiple = false, maxElement = 1) {
    this.model = model;
    this.multiple = multiple;
    this.maxElement = maxElement;
  }
}

@Component({
  selector: 'app-add-image-to-model',
  templateUrl: './add-image-to-model.component.html',
  styleUrls: ['./add-image-to-model.component.scss']
})
export class AddImageToModelComponent implements OnInit {

  model: any;
  itemAttachments: any[] = [];
  selectedAttachment: any[] = [];

  constructor(@Inject(NX_MODAL_DATA) public componentConfig: AddAttachmentModalInput,) {
    this.model = this.componentConfig.model;

  }

  async ngOnInit(): Promise<void> {
    if (this.model.attachments) {
      this.itemAttachments = [...this.model.attachments];
    }
  }


}
