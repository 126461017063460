import {CrudService} from "../service/crud.service";
import {PermissionCheck} from "./permission-check";
import {lastValueFrom, Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {TranslateService} from "@ngx-translate/core";
import {BaseModel} from "./base.model";
import {ServiceLocator} from "../service/service.locator";
import {TableAction, TableCol} from "@hellp/table";

export abstract class ListPage<MODEL extends BaseModel, SERVICE extends CrudService<MODEL, any>> extends PermissionCheck {
  colDef!: TableCol[];
  actionDef!: TableAction[];
  componentIsReady = false;
  protected viewportService: NxViewportService;
  protected translateService: TranslateService;
  protected readonly _destroyed = new Subject<void>();

  public models?: MODEL[];
  public mobile = false;

  public eventWhereMobileIsTrue?: () => void;

  protected constructor(public service: SERVICE) {
    super();
    this.viewportService = ServiceLocator.injector.get(NxViewportService);
    this.translateService = ServiceLocator.injector.get(TranslateService);
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
        if (this.eventWhereMobileIsTrue) {
          this.eventWhereMobileIsTrue();
        }
      });
    this.initPermission();
  }

  abstract loadData(): void;

  initTable(): void {
  }

  public newConsumer(): () => void {
    return () => this.newProcess();
  }

  public showConsumer(): (row: any) => void {
    return (model: any) => this.showProcess(model);
  }

  public editConsumer(): (row: any) => void {
    return (model: any) => this.editProcess(model);
  }

  protected deleteConsumer(): (row: any) => void {
    return (model: any) => this.deleteProcess(model);

  }

  protected newProcess(): void {
    this.service.navigateToNew();
  }

  protected showProcess(model: any): void {
    this.service.navigateToShow(model.id);
  }

  protected editProcess(model: any): void {
    this.service.navigateToEdit(model.id);
  }

  protected deleteProcess(row: any): void {
    this.service.notificationService.confirm(
      this.translateService.instant('general.warning'),
      this.translateService.instant('general.areUSure'),
      'general.ok',
      'general.cancel').then(async result => {
      if (result.value) {
        await lastValueFrom(this.service.delete(row.id));
        this.loadData();
      }
    });
  }

  protected async getTranslate(key: string): Promise<string> {
    return (await lastValueFrom(this.translateService.get(key)));
  }

  protected onDestroy() {
    this._destroyed.complete();
    this._destroyed.unsubscribe();
  }
}
