import {Component, Inject} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {PlantCultureIssue} from "../../../services/technologies/plant-culture-issue.model";

@Component({
  selector: 'app-add-plant-culture-issue-dialog',
  templateUrl: './add-plant-culture-issue-dialog.component.html',
  styleUrls: ['./add-plant-culture-issue-dialog.component.css']
})
export class AddPlantCultureIssueDialogComponent {
  constructor(@Inject(NX_MODAL_DATA) public plantCultureIssue: PlantCultureIssue) {
  }
}
