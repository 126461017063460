import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CrudService} from '../../../utilities/service/crud.service';

@Component({
  selector: 'hellp-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.css'],
})
export class ListContainerComponent {
  @Input() hasNewConsumer = true;
  @Input() service!: CrudService<any, any>;
  @Input() componentIsReady = false;
  @Output() componentIsReadyChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public newConsumer(): () => void {
    return () => this.service.navigateToNew();
  }
}
