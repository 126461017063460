import {Component, Input, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {CartElement, ExtraCartElement} from "../../../services/order/order";
import {CartService, ExtraKeyIdMap} from "../../../services/order/cart.service";
import {ProductModel} from "../../../services/product/product.model";
import {HellparserService} from "@hellp/parser";

@Component({
  selector: 'display-product-cart-card',
  templateUrl: './product-cart-card-display.component.html',
  styleUrls: ['./product-cart-card-display.component.scss']
})
export class ProductCartCardDisplayComponent implements OnInit {
  @Input() cartElement?: CartElement;
  @Input() extraElement?: ExtraCartElement;
  @Input() index!: number;
  @Input() isLeft = true;
  @Input() cartService!: CartService

  private readonly _destroyed = new Subject<void>();
  mobile = true;

  constructor(public viewportService: NxViewportService, private parser: HellparserService,
  ) {
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
    if (this.cartElement) {
      this.cartElement.product.published = this.parser.parseApiDataToModel(this.cartElement.product.published, ProductModel);
      this.cartElement.productId = this.cartElement.product.product.id;
    }
    if (this.extraElement) {

      this.extraElement.productId = ExtraKeyIdMap[this.extraElement.key];

    }
  }


  increment() {
    if (this.cartElement) {
      this.cartService.increment(this.cartElement.productId);
    }
  }

  decrement() {
    if (this.cartElement) {
      this.cartService.decrement(this.cartElement.productId)
    }
  }

  remove() {
    if (this.cartElement) {
      this.cartService.remove(this.index, this.cartElement.productId)
    }
  }
}
