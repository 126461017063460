import { Component, OnDestroy, OnInit } from '@angular/core';
import { Page } from '@hellp/routing';
import { ROUTING } from '../../../utilities/routing';
import { ShowPage } from '../../../utilities/abstract/show-page';
import { GoodsLanding, GoodsLandingElement } from '../../../services/goods-landing/goods-landing.model';
import { GoodsLandingDtoType, GoodsLandingService } from '../../../services/goods-landing/goods-landing.service';
import { ActivatedRoute } from '@angular/router';
import { HellparserService } from '@hellp/parser';
import { ServiceLocator } from '../../../utilities/service/service.locator';
import { switchMap, takeUntil } from 'rxjs';
import { SupplierOrderStatus } from '../../../services/supplier-order/supplier-order.model';

@Component({
  selector: 'app-good-landing-show',
  templateUrl: './good-landing-show.component.html',
  styleUrls: ['./good-landing-show.component.css'],
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.GOODS_LANDING,
})
export class GoodLandingShowComponent extends ShowPage<GoodsLanding, GoodsLandingService> implements OnInit, OnDestroy {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  isAllProcessed: boolean = false;

  constructor(service: GoodsLandingService, route: ActivatedRoute) {
    super(service, route);
  }

  ngOnInit(): void {
    this.onInit(GoodsLandingDtoType.GoodsLandingDTO);
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  protected override initProcess() {
    this.model.elements.forEach((element) => (element.count = element.supplierOrderElement.count));
    this.isAllProcessed = this.model.elements.every((element) => element.status == SupplierOrderStatus.APPROVED);
  }

  approveElement(goodsLandingElement: GoodsLandingElement) {
    this.service.notificationService
      .confirm('inventory.approveElementConfirm', 'goodsLanding.approveElementConfirmDesc', 'general.yes', 'general.no')
      .then((res) => {
        if (res.isConfirmed) {
          const update$ = this.service.updateElements(this.model);
          const approve$ = this.service.approveElement(this.model, goodsLandingElement);
          update$
            .pipe(
              takeUntil(this._destroyed),
              switchMap(() => approve$),
            )
            .subscribe((result) => {
              this.service.notificationService.showSuccess('general.success', 'supplierOrder.successApprove');
              this.onInit(GoodsLandingDtoType.GoodsLandingDTO);
            });
        }
      });
  }

  processAll() {
    const approve$ = this.service.approve(this.model);
    const process$ = this.service.process(this.model);

    approve$
      .pipe(
        takeUntil(this._destroyed),
        switchMap(() => process$),
      )
      .subscribe((result) => {
        this.service.notificationService.showSuccess('general.success', 'goodsLanding.successProcess');
        this.service.navigateToBase();
      });
  }
}
