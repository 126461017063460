import {Component, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ListPage} from "../../../utilities/abstract/list-page";
import {ProductMatrixModel, ProductMatrixRelationType} from "../../../services/product-matrix/product-matrix.model";
import {
  ProductMatrixRelationDtoTypes,
  ProductMatrixService
} from "../../../services/product-matrix/product-matrix.service";
import {lastValueFrom} from "rxjs";
import {ProductService} from "../../../services/product/product.service";
import {ProductHistoryModel} from "../../../services/product/product.model";

@Component({
  selector: 'app-product-matrix-list',
  templateUrl: './product-matrix-list.component.html',
  styleUrls: ['./product-matrix-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.PRODUCT_MATRIX
})
export class ProductMatrixListComponent extends ListPage<ProductMatrixModel, ProductMatrixService> implements OnInit {

  productMatrix: ((ProductMatrixModel | null | undefined)[])[] = [];
  productList: ProductHistoryModel[] = [];
  size = 0;

  constructor(service: ProductMatrixService, private productService: ProductService) {
    super(service);
  }

  ngOnInit() {
    this.loadData()
  }

  async loadData(): Promise<void> {
    this.models = await lastValueFrom(this.service.getAllByDeleted(ProductMatrixRelationDtoTypes.ProductMatrixRelationDTO));
    this.productList = await lastValueFrom(this.productService.getAllForProductMatrix());
    this.productList.sort((a, b) => a.id - b.id);

    if (this.models) {
      this.generateMatrix();
      this.componentIsReady = true;
    }
  }


  generateMatrix() {
    this.size = this.productList.length;
    const matrix: ((ProductMatrixModel | null | undefined)[])[] = [];
    for (let i = 0; i < this.size; i++) {
      const row: (ProductMatrixModel | null | undefined)[] = [];
      for (let j = 0; j < this.size; j++) {
        if (i === j) {
          row.push(null);
        } else {
          const a = this.models?.find(pm => {
            return pm.productHistoryRow.id == this.productList[i].id && pm.productHistoryCol.id == this.productList[j].id
          });
          row.push(a);
        }
      }
      matrix.push(row);
    }
    this.productMatrix = matrix;
  }

  isDiagonal(i: number, j: number): boolean {
    return this.productList[i] == this.productList[j];
  }

  protected readonly ProductMatrixRelationType = ProductMatrixRelationType;
  protected readonly Object = Object;

  saveProductRelation($event: any, i: number, j: number) {
    const col = this.productList[j];
    const row = this.productList[i];
    const model = ProductMatrixModel.getInstance(col, row, $event);
    this.service.save(model, ProductMatrixRelationDtoTypes.ProductMatrixRelationDTO).subscribe(result => {
      this.loadData();
    });
  }

  updateTypeOfRelation(cell: ProductMatrixModel) {
    this.service.update(cell, ProductMatrixRelationDtoTypes.ProductMatrixRelationDTO).subscribe(result => {
      this.loadData();
    });
  }
}
