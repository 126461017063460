<ng-container *ngIf="model.deliveryConfig">
  <h6 nxHeadline="subsection-small">{{'category.deliveryData.mode' |translate}}</h6>
  <ul class="list--custom-color" nxList="ordered-circle">
    <ng-container *ngFor="let deliverMode of model.deliveryConfig.availableModes">
      <li class="nx-margin-bottom-0">{{'category.deliveryData.' + deliverMode | translate}}</li>
    </ng-container>
  </ul>
  <br>
</ng-container>

<ng-container *ngIf="model.financialConfig" class="nx-margin-bottom-2xs">
  <h6 nxHeadline="subsection-small">{{'category.financialData.mode' |translate}}</h6>
  <ul class="list--custom-color" nxList="ordered-circle">
    <ng-container *ngFor="let financial of model.financialConfig.availableModes">
      <li class="nx-margin-bottom-0">{{'category.financialData.' + financial | translate}}</li>
    </ng-container>
  </ul>
  <br>
  <ng-container *ngIf="!notForCategory">
    <h6 nxHeadline="subsection-small">
      {{'category.financialData.tax' |translate}}</h6>
    <h5 nxHeadline="subsection-large">{{model.financialConfig.tax }}%</h5>
    <br>
  </ng-container>
</ng-container>

<ng-container *ngIf="model.packagingConfig" class="nx-margin-bottom-2xs">
  <h6 nxHeadline="subsection-small">{{'category.packagingData.icePackRanges' |translate}}</h6>
  <ul class="list--custom-color" nxList="ordered-circle">
    <ng-container *ngFor="let icePackRange of model.packagingConfig.icePackRanges">
      <li class="nx-margin-bottom-0">
        {{icePackRange.start}} {{'general.count' | translate}}
        - {{icePackRange.end}} {{'general.count' | translate}}: {{icePackRange.count}} {{'general.count' | translate}}
      </li>
    </ng-container>
  </ul>
  <br>
</ng-container>
