import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyPath'
})
export class OnlyPathPipe implements PipeTransform {

  transform(value: string, ): string {
    const splitted = value.split("/");
    splitted.splice((splitted.length-1),1);
    return splitted.join('/');
  }

}
