<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div nxRow="">
    <div nxCol="12" class="nx-margin-y-2m">
      <hellp-stepper-container [steps]="steps"></hellp-stepper-container>
    </div>
    <div nxCol="12" class="text-end">
      <button
        nxButton="primary small"
        (click)="onSave()"
        class="nx-margin-bottom-s"
        type="button"
      >
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</hellp-form-container>

<ng-template #baseData>
  <div nxRow="">
    <div nxCol="12,12,12,6">
      <div class="card p-3 mb-4 box-shadow">
        <nx-formfield nxLabel="{{'article.title' | translate}}" appearance="auto">
          <input appValidatedInput required nxInput name="title" [(ngModel)]="formModel.title"/>
          <nx-error class="nx-margin-bottom-m" nxFormfieldError
                    *ngIf="validationError.title"> {{ validationError.title | translate }}
          </nx-error>
          <span nxFormfieldHint>255/{{ formModel.title.length }}</span>
        </nx-formfield>
        <ng-container *ngIf="formModel.status == MagazineStatus.DRAFT">
          <div nxRow nxRowAlignItems="start">
            <div nxCol="12,8">
              <nx-formfield nxLabel="{{'article.publishedAt' | translate}}" appearance="auto">
                <input nxDatefield
                       #formatDatefield="nxDatefield"
                       [nxParseFormat]="['MM/DD/YYYY', 'YYYY.MM.DD']"
                       nxDisplayFormat="YYYY.MM.DD"
                       nxInput
                       [nxDatepicker]="myDatepicker"
                       [(ngModel)]="formModel.publishedAt"/>
                <span nxFormfieldHint> YYYY.MM.DD </span>
                <nx-datepicker-toggle
                  [for]="myDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker></nx-datepicker>

              </nx-formfield>
            </div>
            <div nxCol="12,4">
              <nx-timefield
                #timefield
                [(ngModel)]="formModel.publishedAtTime"
                name="publishedAtTime"
                label="{{'article.publishedAtTime' | translate}}"
              ></nx-timefield>
              <div *ngIf="timefield.errorState">
                <nx-error> Invalid time input.</nx-error>
              </div>
            </div>
          </div>
        </ng-container>

        <nx-formfield nxLabel="{{'article.shortDescription' | translate}}" appearance="auto">
                <textarea appValidatedInput required nxInput name="shortDescription"
                          [(ngModel)]="formModel.shortDescription"
                          style="min-height: 200px;"></textarea>
          <nx-error nxFormfieldError
                    *ngIf="validationError.shortDescription"> {{ validationError.shortDescription | translate }}
          </nx-error>
          <span nxFormfieldHint>255/{{ formModel.shortDescription.length }}</span>
        </nx-formfield>
      </div>
    </div>
    <div nxCol="12,12,12,6">
      <forms-tag-form [tags]="formModel.tagList" (tagsChange)="tagsChange($event)"></forms-tag-form>
    </div>
  </div>
  <div nxRow="" class="nx-margin-top-2m">
    <div nxCol="12,12,12,6">
      <div class="card p-3 mb-4 box-shadow">
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-m">
          {{ 'article.coverPhoto' | translate }}
        </h3>
        <app-image-upload [(selectedAttachment)]="formModel.coverPhoto.attachment"
                          [secondPanelExpanded]="true"></app-image-upload>
        <nx-error class="nx-margin-top-m" nxFormfieldError
                  *ngIf="validationError.coverPhoto"> {{ validationError.coverPhoto | translate }}
        </nx-error>
      </div>
    </div>
    <div nxCol="12,12,12,6">

      <div class="card p-3 mb-4 box-shadow">
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-m">
          {{ 'article.author' | translate }}
        </h3>
        <nx-formfield nxLabel="{{'article.existingAuthors' | translate}}" appearance="auto">
          <nx-dropdown appValidatedInput required [(ngModel)]="formModel.authorId" (selectionChange)="changeAuthor()">
            <nx-dropdown-item
              *ngFor="let option of authors"
              [nxValue]="option.id"
            >{{ option.name }}
            </nx-dropdown-item>
          </nx-dropdown>
          <nx-error nxFormfieldError *ngIf="validationError.authorId"> {{ validationError.authorId | translate }}
          </nx-error>
        </nx-formfield>
      </div>
    </div>
  </div>

</ng-template>
<ng-template #contentData>
  <div class="card p-3 mb-4 box-shadow">
    <div nxRow="">
      <div nxCol="12">
        <quill-editor [(ngModel)]="formModel.content" f></quill-editor>
        <!--          <div class="NgxEditor__Wrapper" style="min-height: 500px">-->
        <!--            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>-->
        <!--            <ngx-editor style="height: 500px" [editor]="editor" name="description" [(ngModel)]="formModel.content"-->
        <!--                        [disabled]="false"-->
        <!--                        placeholder="{{'article.contant' | translate}}"></ngx-editor>-->
        <!--          </div>-->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #summaryData>
  <div class="card p-3 mb-4 box-shadow">
    <display-magazine [magazine]="formModel"></display-magazine>

  </div>
</ng-template>

