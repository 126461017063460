import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {PlantCultureIssueType} from "./plant-culture-issue-type.model";
import {PlantCultureIssueTypeRepository} from "./plant-culture-issue-type.repository";
import {ValidatorService} from "../../utilities/service/validator.service";

export enum PlantCultureIssueTypeDtoType {
  PlantCultureIssueTypeDTO = 'PlantCultureIssueTypeDTO',
  SlimPlantCultureIssueTypeDTO = 'SlimPlantCultureIssueTypeDTO',
}

@Injectable({
  providedIn: 'root'
})
export class PlantCultureIssueTypeService extends CrudService<PlantCultureIssueType, PlantCultureIssueTypeRepository> {

  constructor(repo: PlantCultureIssueTypeRepository) {
    super(ROUTING.PLANT_CULTURE_ISSUE_TYPE, repo, PlantCultureIssueType);
  }

  override validate(model: PlantCultureIssueType) {
    ValidatorService.allRequired(model, ['name', 'monthStart', 'partOfMonthStart', 'monthEnd', 'partOfMonthEnd'], PlantCultureIssueType.name);
  }
}

