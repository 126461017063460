<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div class="row">
    <div class="col-md-10 mx-auto mt-4">
      <app-pdf-upload [(selectedAttachment)]="formModel.attachment" [firstPanelExpanded]="true"></app-pdf-upload>
    </div>
  </div>
</hellp-form-container>
