import {Pipe, PipeTransform} from '@angular/core';
import {PriceModel} from "../models/price.model";

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  currencyMap: { [key: string]: string } = {
    'HUF': "Ft",
    'EUR': "Eur"
  }

  transform(value: PriceModel, withVat?: boolean): string {
    if (withVat) {
      return `${value.net} ${this.currencyMap[value.currency]} +${value.vat}% ÁFA `;
    }
    return `${value.net} ${this.currencyMap[value.currency]}`;
  }

}
