<div nxLayout="grid nogutters">
  <div nxRow="">
    <div nxCol="12,12,12,6" class="nx-margin-top-2xs">

      <h6 nxHeadline="subsection-small">{{'product.dimensions' | translate}}
        <nx-icon
          #clickTriggerIcon
          aria-label="trigger by click"
          name="info-circle-o"
          size="s"
          [nxPopoverTriggerFor]="popoverClick"
          nxPopoverTrigger="click"
          tabindex="0"
          role="button"
          style="font-size: 12px"
        >
        </nx-icon>
      </h6>
      <h5 nxHeadline="subsection-large">
        {{dimensions.width}} {{dimensions.unit}} * {{dimensions.height}} {{dimensions.unit}}
        * {{dimensions.length}} {{dimensions.unit}}
      </h5>

      <nx-popover #popoverClick>
        <span class="nx-popover__text p-3">{{'product.dimensionInfo2' | translate}}</span>
      </nx-popover>
    </div>
    <div nxCol="12,12,12,6" class="nx-margin-top-2xs">
      <h6 nxHeadline="subsection-small">{{'product.weight' | translate}}</h6>
      <h5 nxHeadline="subsection-large">
        {{weightKg}} Kg
      </h5>
    </div>
  </div>
</div>