<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div nxRow="">
    <div nxCol="12" class="nx-margin-y-2m">
      <hellp-stepper-container #stepper [steps]="steps"
                               (changeStepEvent)="changeStep($event)"></hellp-stepper-container>
    </div>
    <div nxCol="6" class="text-start">
      <button
        *ngIf="productHistory"
        nxButton="cta small"
        (click)="openPublished()"
        class="nx-margin-bottom-s"
        type="button"
      >
        {{ 'product.openPublisehd' | translate }}
      </button>
    </div>
    <div nxCol="6" class="text-end">
      <button
        nxButton="primary small"
        (click)="onSave()"
        class="nx-margin-bottom-s"
        type="button"
      >
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</hellp-form-container>

<ng-template #baseData>
  <forms-product-base-form [uploadType]="service.$uploadType.value" [(model)]="formModel"
                           [validationError]="validationError"></forms-product-base-form>
  <div nxRow="">
    <div nxCol="12,12,12,6">
      <forms-price-form *ngIf="service.$uploadType.value == UploadType.SALES" [(priceModels)]="formModel.prices"
                        [validationError]="validationError"></forms-price-form>
    </div>
    <div nxCol="12,12,12,{{service.$uploadType.value == UploadType.MARKETING?'12':'6'}}">
      <forms-product-pack-form *ngIf="service.$uploadType.value==UploadType.MARKETING"
                               [(model)]="formModel"></forms-product-pack-form>
    </div>
  </div>
</ng-template>

<ng-template #supplierProduct>
  <forms-supplier-product-form [(model)]="formModel.history.supplierProduct"></forms-supplier-product-form>
</ng-template>

<ng-template #tagsAndOrderConfig>
  <forms-order-config-form [(orderConfig)]="formModel.history.orderConfig"
                           [notForCategory]="true"
                           [validationError]="validationError"></forms-order-config-form>
  <div nxRow="">
    <div nxCol="6">
      <hellp-card-container>
        <nx-switcher [(ngModel)]="formModel.history.elementOfProductMatrix" labelSize="small">
          {{ 'product.elementOfProductMatrix' |translate }}
        </nx-switcher>
      </hellp-card-container>
    </div>
    <div nxCol="6">
      <forms-tag-form [tags]="formModel.history.tagList" (tagsChange)="tagsChange($event)"></forms-tag-form>
    </div>
  </div>
</ng-template>

<ng-template #attachmentData>
  <hellp-card-container>
    <app-image-upload [selectedAttachment]="formModel.history.mainImage.attachment"
                      (selectedAttachmentChange)="selectedAttachmentChanges($event)"
                      [secondPanelExpanded]="true"></app-image-upload>

    <nx-error class="nx-margin-top-m" nxFormfieldError
              *ngIf="validationError.attachment"> {{ validationError.attachment | translate }}
    </nx-error>
  </hellp-card-container>
</ng-template>

<ng-template #galleryData>
  <hellp-card-container>
    <app-images-upload [selectedAttachments]="formModel.getImagesAsAttachment()"
                       (selectedAttachmentsChange)="selectedAttachmentsChanges($event)" [secondPanelExpanded]="true"
                       [maxElement]="100"
                       [isMultiple]="true"></app-images-upload>
  </hellp-card-container>
</ng-template>

<ng-template #relatedProduct>
  <hellp-card-container title="{{'product.dynamicAttribute' | translate}}">
    <forms-dynamic-attribute-form
      [(dynamicAttribute)]="formModel.history.dynamicAttributes"></forms-dynamic-attribute-form>
  </hellp-card-container>
  <hellp-card-container title="{{'product.relatedProduct' | translate}}">
    <form-related-product [actualProduct]="formModel.history"
                          [(relatedProducts)]="formModel.history.relatedProducts"></form-related-product>
  </hellp-card-container>
</ng-template>

<ng-template #summaryData>
  <div class="card p-3 mb-4 box-shadow">
    <display-product-show [model]="formModel"></display-product-show>
  </div>
</ng-template>
