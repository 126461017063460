import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AttachmentModel} from "../../../services/attachment/attachment.model";
import {GalleryItem, ImageItem} from "ng-gallery";

@Component({
  selector: 'app-gallery-panel',
  templateUrl: './gallery-panel.component.html',
  styleUrls: ['./gallery-panel.component.css']
})
export class GalleryPanelComponent implements OnInit {
  @Input() attachments!: AttachmentModel[];
  images!: GalleryItem[];

  ngOnInit(): void {
    this.refresh(this.attachments);
  }

  refresh(attachments: AttachmentModel[]) {
    this.attachments = attachments;
    this.images = [];
    this.attachments.forEach(attachment => {
      if (attachment) {
        this.images.push(
          new ImageItem({src: attachment.getSrcHeight(800), thumb: attachment.src})
        );
      }
    });
  }

  protected readonly length = length;
  defaultGallery: GalleryItem[] = [new ImageItem({src: 'assets/img/default.jpg', thumb: 'assets/img/default.jpg'})];
}
