import {BaseModel} from "../../utilities/abstract/base.model";
import {StockProduct, WarehouseModel} from "../warehouse/warehouse.model";
import {ConvertFrom, ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../processor.service";
import {Note, SupplierOrderStatus} from "../supplier-order/supplier-order.model";

export class InventoryElement extends BaseModel {
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT
  count = 0;
  @ParseTo(StockProduct)
  stockProduct!: StockProduct;

  note: Note = new Note();
  message = '';

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getStockProductId'}, "model")
  stockProductId = 0;
}

export class Inventory extends BaseModel {
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  createdAt: Date = new Date();
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  processedAt?: Date;

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'getMessageFromNote'})
  note?: Note;
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT

  @ParseTo(InventoryElement)
  @ConvertFrom
  elements: InventoryElement[] = [];

  @ParseTo(WarehouseModel)
  warehouse!: WarehouseModel

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getWarehouseIdIfExist'}, "model")
  warehouseId = 0;
}