import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {
  MinimumStock,
  Month,
  StockConfig,
  StockProduct,
  WarehouseModel
} from "../../../services/warehouse/warehouse.model";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";

@Component({
  selector: 'app-stock-config-dialog',
  templateUrl: './stock-config-dialog.component.html',
  styleUrls: ['./stock-config-dialog.component.css']
})
export class StockConfigDialogComponent implements OnInit {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  product: ProductHistoryModel;
  model?: StockProduct;
  warehouses: WarehouseModel[];
  currentIndex = 0;
  stockProducts: StockProduct[];
  selectedWarehouse?: WarehouseModel;

  constructor(@Inject(NX_MODAL_DATA) readonly data: { product: ProductHistoryModel, warehouses: WarehouseModel[] }) {
    this.product = this.parser.parseApiDataToModel(data.product, ProductHistoryModel);
    this.warehouses = this.parser.parseApiDataListToModelList(data.warehouses, WarehouseModel);

    this.stockProducts = this.product.stockProducts;
  }

  ngOnInit(): void {
   this.selectedWarehouse = this.warehouses.find(wh => wh.mainHQ);
    if(this.selectedWarehouse){
     this.changeWarehouse(this.selectedWarehouse);
   }
  }

  changeWarehouse(warehouse: WarehouseModel) {
    this.model = this.stockProducts.find(s => s.warehouseId === warehouse.id);
    if (!this.model) {
      this.model = new StockProduct();
      this.model.product = this.product;
      this.model.warehouse = warehouse;
      this.model.config = new StockConfig();
      this.model.config.minimumStocks.push(new MinimumStock());
    } else {
      this.model.product = new ProductHistoryModel();
      this.model.product.id = this.product.id;
      this.model.warehouse = warehouse;
      if (this.model.config && this.model.config.minimumStocks.length < 1) {
        this.model.config.minimumStocks.push(new MinimumStock());
      }
    }
  }

  addNewYear() {
    if (this.model) {
      this.model.config.minimumStocks = this.model.config.minimumStocks.sort((a, b) => a.year - b.year);
      const lastYear = this.model.config.minimumStocks[this.model.config.minimumStocks.length - 1].year;
      const newMinStock = new MinimumStock();
      newMinStock.year = parseInt(lastYear.toString()) + 1;
      this.model.config.minimumStocks.push(newMinStock);
      this.currentIndex = this.model.config.minimumStocks.length - 1;
    }
  }

  protected readonly Month = Month;
}
