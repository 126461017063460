<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div class="row mt-3">
    <div class="col-md-5">

      <h3 nxHeadline="subsection-medium">{{ 'order.cartSet' | translate }}</h3>
      <div class="row align-items-center justify-content-start mt-3">
        <div class="col-lg-10 col-md-7 ms-auto">
          <nx-formfield nxLabel="{{'order.availableProducts' | translate}}"
                        appearance="auto"
                        nxFloatLabel="always">
            <input
              nxInput
              type="text"
              [nxAutocomplete]="auto2"
              [nxAutocompleteItems]="dynamicBackendOptions"
              [(ngModel)]="selectedName"
            />
            <nx-autocomplete #auto2="nxAutocomplete"></nx-autocomplete>
          </nx-formfield>
        </div>
        <div class="col-lg-2 col-md-3 me-auto">
          <button nxPlainButton="" (click)="add()">{{ 'general.add' | translate }}</button>
        </div>
        <ng-container *ngIf="cartModel.cartElements.length>0 || cartModel.extraCartElements.length>0">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <p><b>{{ 'order.sumCount' | translate }}: </b>{{ cartModel.countElements }} db</p>
            <p><b>{{ 'order.sumPrice' | translate }}: </b>{{ cartModel.sumPrice | number : '1.2-2' }} Ft</p>
            <p><b>{{ 'order.sumWeight' | translate }}: </b>{{ cartModel.sumWeight | number : '1.2-2' }} kg</p>
          </div>
        </ng-container>
        <div class="col-12">
          <div style="height: 400px; overflow-y: auto; padding-bottom: 50px;">
            <ng-container *ngIf="cartModel.cartElements">
              <ng-container *ngFor="let element of cartModel.cartElements; let i = index">
                <display-product-cart-card [cartElement]="element" [index]="i"
                                           [cartService]="cartService"></display-product-cart-card>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="cartModel.extraCartElements">
              <ng-container *ngFor="let element of cartModel.extraCartElements; let i = index">
                <display-product-cart-card [extraElement]="element" [index]="i"
                                           [cartService]="cartService"></display-product-cart-card>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="row">
        <div class="col-md-6">
          <nx-formfield nxLabel="{{'cart.paymentType' |translate}}" nxFloatLabel="always" appearance="auto">
            <nx-dropdown [(ngModel)]="formModel.paymentType"
                         (ngModelChange)="cartService.changePaymentType(formModel.paymentType)">
              <nx-dropdown-item
                *ngFor="let paymentType of PaymentType | keyvalue"
                [nxValue]="paymentType.key"
              >
                {{ 'cart.option.' + paymentType.key + '.label' | translate }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div class="col-md-6">
          <nx-formfield nxLabel="{{'cart.deliveryType' |translate}}" nxFloatLabel="always" appearance="auto">
            <nx-dropdown [(ngModel)]="formModel.deliveryType"
                         (ngModelChange)="cartService.changeDeliveryType(formModel.deliveryType)">
              <nx-dropdown-item
                *ngFor="let paymentType of DeliveryType | keyvalue"
                [nxValue]="paymentType.key"
              >
                {{ 'cart.option.' + paymentType.key + '.label' | translate }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div class="col-12">
          <nx-switcher class="my-2" [(ngModel)]="withExistUser" labelSize="small"
                       name="withExistUser" (ngModelChange)="onChangeWithUser()">
            {{ 'order.withExistUser' | translate }}
          </nx-switcher>

        </div>
        <ng-container *ngIf="withExistUser">
          <div class="col-12">
            <nx-formfield nxLabel="{{'order.existUser' |translate}}" nxFloatLabel="always" appearance="auto">
              <nx-dropdown [(ngModel)]="selectedUser" (ngModelChange)="onUserSelect()">
                <nx-dropdown-item
                  *ngFor="let user of registeredUsers"
                  [nxValue]="user"
                >
                  {{ user.name }} ({{ user.email }})
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <ng-container *ngIf="selectedUser">
            <div class="col-12">
              <button nxPlainButton class="my-2" (click)="onAddBillingData(BillingDataComponentMode.BillingData)">
                {{ 'order.newBillingData' | translate }}
              </button>
              <h4 nxHeadline="subsection-medium">{{ 'order.billingData' | translate }}</h4>
              <nx-radio-group [(ngModel)]="formModel.billingData" name="billingData">
                <ng-container *ngFor="let billingData of selectedUser.billingData">
                  <nx-radio labelSize="small" [nxValue]="billingData"
                            class="nx-margin-bottom-m">
                    {{ billingData.firstName }} {{ billingData.lastName }}, {{ billingData.email }}<br>
                    {{ billingData.address.toString() }}
                  </nx-radio>
                </ng-container>
              </nx-radio-group>

              <ng-container *ngIf="formModel.deliveryType == DeliveryType.DELIVERY">
                <hr class="hr-dark">
                <nx-switcher class="my-2" [(ngModel)]="formModel.deliveryDataSameAsBillingData" labelSize="small"
                             (checkedChange)="onDeliveryAddressAdd()"
                             name="isCompany">
                  {{ 'profile.sameAsBillingData' | translate }}
                </nx-switcher>
                <br>
                <button nxPlainButton class="my-2" (click)="onAddBillingData(BillingDataComponentMode.DeliveryData)">
                  {{ 'order.newDeliveryData' | translate }}
                </button>
                <h4 nxHeadline="subsection-medium">{{ 'order.deliveryData' | translate }}</h4>
                <nx-radio-group [(ngModel)]="formModel.deliveryData" name="deliveryData">
                  <ng-container *ngFor="let billingData of selectedUser.deliveryData">
                    <nx-radio labelSize="small" [nxValue]="billingData"
                              class="nx-margin-bottom-m">
                      {{ billingData.firstName }} {{ billingData.lastName }}, {{ billingData.email }}<br>
                      {{ billingData.address.toString() }}
                    </nx-radio>
                  </ng-container>
                </nx-radio-group>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!withExistUser ">
          <div class="col-12">
            <h3 nxHeadline="subsection-medium" class="mb-3">{{ 'order.billingData' | translate }}</h3>
            <button nxPlainButton class="my-2" (click)="onAddBillingData(BillingDataComponentMode.BillingData)">
              {{ 'order.newBillingDataNoUser' | translate }}
            </button>
            <ng-container *ngIf="formModel.billingData">
              <p>
                {{ formModel.billingData.firstName }} {{ formModel.billingData.lastName }}
                , {{ formModel.billingData.email }}<br>
                {{ formModel.billingData.address.toString() }}</p>
            </ng-container>
          </div>

          <ng-container *ngIf="formModel.deliveryType == DeliveryType.DELIVERY">
            <div class="col-12 mt-3">
              <h3 nxHeadline="subsection-medium" class="mb-3">{{ 'order.deliveryData' | translate }}</h3>
              <nx-switcher class="my-2" [(ngModel)]="formModel.deliveryDataSameAsBillingData" labelSize="small"
                           (checkedChange)="onDeliveryAddressAdd()"
                           name="isCompany">
                {{ 'profile.sameAsBillingData' | translate }}
              </nx-switcher>
              <ng-container *ngIf="!formModel.deliveryDataSameAsBillingData">
                <button nxPlainButton class="my-2" (click)="onAddBillingData(BillingDataComponentMode.DeliveryData)">
                  {{ 'order.newDeliveryDataNoUser' | translate }}
                </button>
              </ng-container>

              <ng-container *ngIf="formModel.deliveryData">
                <p>
                  {{ formModel.deliveryData.firstName }} {{ formModel.deliveryData.lastName }}
                  , {{ formModel.deliveryData.email }}<br>
                  {{ formModel.deliveryData.address.toString() }}
                </p>
              </ng-container>
              <ng-container *ngIf="formModel.deliveryDataSameAsBillingData && formModel.billingData">
                <p>
                  {{ formModel.billingData.firstName }} {{ formModel.billingData.lastName }}
                  , {{ formModel.billingData.email }}<br>
                  {{ formModel.billingData.address.toString() }}
                </p>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </div>
  </div>
</hellp-form-container>
