import {BaseModel, Slugable} from "../../utilities/abstract/base.model";
import {Month} from "../warehouse/warehouse.model";
import {PartOfMonth} from "../../utilities/types";
import {PlantCultureType} from "./plant-culture.model";
import {CultureIssuePairingModel} from "./culture-issue-pairing.model";
import {ConvertProcess} from "@hellp/parser";
import {ProcessorService} from "../processor.service";

export class PlantCultureIssueType extends BaseModel implements Slugable {
  name = '';

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'slugGenerator'}, "model")
  slug = '';

  monthStart: Month = Month.JANUARY;
  partOfMonthStart: PartOfMonth = PartOfMonth.START;

  monthEnd: Month = Month.JANUARY;
  partOfMonthEnd = PartOfMonth.END;

  type: PlantCultureType = PlantCultureType.VEGETABLE;

  cultureIssuePairings: CultureIssuePairingModel[] = []

  get slugField(): string {
    return this.name;
  }

  get startOfMonth(): string[] {
    return [this.monthStart, this.partOfMonthStart];
  }

  get endOfMonth(): string[] {
    return [this.monthEnd, this.partOfMonthEnd];
  }

}