import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {lastValueFrom, map, Observable} from "rxjs";
import {NxDataDisplayOrientation} from "@aposin/ng-aquila/data-display";
import {ShowPage} from "../../../utilities/abstract/show-page";
import {SupplierModel} from "../../../services/supplier/supplier.model";
import {SupplierDtoTypes, SupplierService} from "../../../services/supplier/supplier.service";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ActivatedRoute} from "@angular/router";
import {ProductHistoryDtoTypes, ProductService} from "../../../services/product/product.service";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {HistoryStatus} from 'src/app/utilities/types';

@Component({
  selector: 'app-supplier-show',
  templateUrl: './supplier-show.component.html',
  styleUrls: ['./supplier-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.SUPPLIER,
})
export class SupplierShowComponent extends ShowPage<SupplierModel, SupplierService> implements OnInit, OnDestroy {

  readonly mobileViewport$ = this.viewportService.max(
    NxBreakpoints.BREAKPOINT_MEDIUM,
    100,
  );
  readonly orientation$: Observable<NxDataDisplayOrientation> =
    this.mobileViewport$.pipe(
      map(mobile => (mobile ? 'vertical' : 'horizontal-columns')),
    );

  currentIndex = 0;
  products?: ProductHistoryModel[];

  supplierOrder?: any[];

  @ViewChild('priceTpl') priceTpl!: TemplateRef<any>
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>
  colDef!: TableCol[];
  actionDef!: TableAction[];

  constructor(service: SupplierService, route: ActivatedRoute, private productService: ProductService) {
    super(service, route);
  }

  ngOnInit(): void {
    this.onInit(SupplierDtoTypes.SupplierDTO);

  }

  protected override async initProcess() {
    super.initProcess();
    await this.initTable();
    this.products = await lastValueFrom(this.productService.getAllForSupplier(ProductHistoryDtoTypes.ProductHistoryDTO, this.model.id));
  }

  async initTable(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showProductConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.editProductConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('status', await this.getTranslate('product.status')).filterable(
        TableFilter.newSelect(Object.keys(HistoryStatus).map(this.generateStatusFilterOption()))).template(
        () => this.statusTpl),
      TableCol.newString('product.itemNr', await this.getTranslate('product.itemNr')),
      TableCol.newString('product.name', await this.getTranslate('product.name')),
      TableCol.newString('product.sku', await this.getTranslate('product.SKU')),
      TableCol.newString('category.name', await this.getTranslate('product.category')),
      TableCol.newString('product.prices', await this.getTranslate('product.price')).template(
        () => this.priceTpl),
    ];
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  public showConsumer(): (row: any) => void {
    return (model: any) => this.service.navigateToShow(model.id);
  }

  public showProductConsumer(): (row: ProductHistoryModel) => void {
    return (model: ProductHistoryModel) => this.productService.navigateToShow(model.product.id);
  }

  public editProductConsumer(): (row: ProductHistoryModel) => void {
    return (model: ProductHistoryModel) => {
      if (model.editInProgress) {
        this.productService.navigateToEdit(model.draft.id);
      } else {
        this.productService.navigateToEdit(model.product.id);
      }
    }
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  protected readonly HistoryStatus = HistoryStatus;
}
