import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AttachmentModel} from "../../../services/attachment/attachment.model";

@Component({
    selector: 'app-single-attachment-upload',
    templateUrl: './single-attachment-upload.component.html',
    styleUrls: ['./single-attachment-upload.component.scss']
})
export class SingleAttachmentUploadComponent {

    @Input() acceptString = "image/*,application/pdf,application/octet-stream,plain/text";
    @Input() isMultiple = false;
    @Input() file!: File;
    @Output() fileChange: EventEmitter<File> = new EventEmitter<File>();

    @Input() attachment!: AttachmentModel;
    @Output() attachmentChange: EventEmitter<AttachmentModel> = new EventEmitter<AttachmentModel>();
    public fileString?: string;

    addFile(file: File) {
        if (!this.attachmentChange.name || this.attachment.name.length < 1) {
            this.attachment.name = file.name;
        }
        this.getBase64(file)
        this.file = file;
        this.fileChange.emit(this.file);
        this.attachmentChange.emit(this.attachment);
    }

    getBase64(file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result == "string") {
                this.fileString = reader.result;

            }
        };
    }
}
