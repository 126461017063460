import {HellpNotificationService} from '@hellp/service';
import {Router} from '@angular/router';
import {BehaviorSubject, EmptyError, map, Observable, Subject} from 'rxjs';
import {FormlyService} from '../../services/formly.service';
import {HellpFiltererService} from '@hellp/filter';
import {CrudRepository} from './crud.repository';
import {BaseModel} from '../abstract/base.model';
import {ServiceLocator} from './service.locator';
import {PostPayload} from '../../models/post-payload.model';
import {PutPayload} from '../../models/put-payload.model';
import {Type} from '@angular/core';
import {HellparserService} from '@hellp/parser';
import {PageMetaData, PageMetaDataConf} from '../page-meta-data.conf';
import {ValidationError} from "../../models/validation.error.model";

export abstract class CrudService<
  MODEL extends BaseModel,
  REPO extends CrudRepository<MODEL>,
> {
  filterService: HellpFiltererService;
  public notificationService: HellpNotificationService;
  public formlyService: FormlyService;
  public router: Router;
  private parser: HellparserService;
  protected _destroyed = new Subject<void>();
  public $validationError: BehaviorSubject<ValidationError[]> = new BehaviorSubject<ValidationError[]>([]);

  protected constructor(
    public path: string,
    public repo: REPO,
    private clazz: Type<MODEL>,
    public pageMetadata: PageMetaData = PageMetaDataConf.PRODUCT,
  ) {
    this.filterService = ServiceLocator.injector.get(HellpFiltererService);
    this.notificationService = ServiceLocator.injector.get(
      HellpNotificationService,
    );
    this.formlyService = ServiceLocator.injector.get(FormlyService);
    this.router = ServiceLocator.injector.get(Router);
    this.parser = ServiceLocator.injector.get(HellparserService);
  }

  getById(id: number, dto: string): Observable<MODEL | undefined> {
    const filter = this.filterService.filter(dto).equal('id', id).create();
    return this.repo.getAllByFilter(filter.stringify(), this.clazz).pipe(
      map((result) => {
        if (result.length > 0) {
          return result[0];
        } else {
          return undefined;
        }
      }),
    );
  }

  getAllByDeleted(dto: string, deleted = false): Observable<MODEL[]> {
    const filter = this.filterService
      .filter(dto)
      .equal('deleted', deleted)
      .create();
    return this.repo.getAllByFilter(filter.stringify(), this.clazz);
  }

  save(model: MODEL, responseDTO?: string): Observable<any> {
    this.validate(model);

    return this.repo.save(new PostPayload(model, responseDTO), this.clazz);
  }

  saveFile(file: File, model: MODEL) {
    this.validate(model);
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append(
      'attachment',
      JSON.stringify(this.parser.convertModelToApi(model)),
    );
    return this.repo.saveForm(formData, this.clazz);
  }

  update(model: MODEL, responseDTO?: string): Observable<any> {
    this.validate(model);
    return this.repo.update(
      model.id,
      new PutPayload(model, responseDTO),
      this.clazz,
    );
  }

  delete(id: number): Observable<any> {
    return this.repo.deleteById(id);
  }

  public getRoutingPath(additionalPath?: string): string {
    let tail = '';
    if (additionalPath) {
      tail += additionalPath.startsWith('/')
        ? additionalPath
        : '/' + additionalPath;
    }
    return this.path + tail;
  }

  public navigateToBase(): void {
    this.router.navigate([this.getRoutingPath()]);
  }

  public navigateToNew(prefix = '', postfix = '') {
    this.router.navigate([this.getRoutingPath(prefix + 'new' + postfix)]);
  }

  public navigateToShow(id: number | string): void {
    this.router.navigate([this.getRoutingPath('show'), id]);
  }

  public navigateToEdit(id: number, secondPart?: any): void {
    if (secondPart) {
      this.router.navigate([this.getRoutingPath('edit'), id, secondPart]);
    } else {
      this.router.navigate([this.getRoutingPath('edit'), id]);
    }
  }

  /**
   *
   * @param model
   * @throws {Error}
   */
  public validate(model: MODEL): void | never {
    console.debug("Not Implement");
  }

  public validationErrorHandler(e: any) {
    const b = !(e instanceof EmptyError);
    if (b) {
      this.notificationService.showWarning(
        'general.warning',
        e.message,
      );
      console.log(e);
    }
  }
}
