<div class="d-flex mt-n2">
  <div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
    <nx-icon [name]="iconName"></nx-icon>
  </div>
  <div class="ms-3 my-auto" *ngIf="model">
    <h6 class="mb-0"
        *ngIf=" model.id">{{ model[titleProperty] }} {{ 'general.editEntity.' + modelType | translate }} {{ 'general.edit' | translate }}</h6>
    <h6 class="mb-0" *ngIf="!model.id">{{ 'general.newEntity.' + modelType | translate }}</h6>
  </div>

  <div class="ms-3 my-auto" *ngIf="!model">
    <h6 class="mb-0">{{ title | translate }}</h6>
  </div>
  <div class="ms-auto text-end">

    <ng-container *ngIf="withActions">
      <ng-container *ngIf="!mobile">
        <ng-container *ngFor="let option of modalOptions">
          <button
            nxIconButton="cta small"
            type="button"
            class="nx-margin-right-2xs"
            (click)="option.openModalSupplier()"
          >
            <nx-icon name="{{option.iconName}}"></nx-icon>
          </button>
        </ng-container>

        <button *ngIf="saveSupplier" nxButton="primary small" type="button" (click)="saveSupplier()"
                [disabled]="disableSave">
          <nx-icon name="save-o" class="nx-margin-right-xs"></nx-icon>
          {{ 'general.save' | translate }}
        </button>
        <button *ngIf="!saveSupplier" nxButton="primary small" type="button" (click)="save.emit()"
                [disabled]="disableSave">
          <nx-icon name="save-o" class="nx-margin-right-xs"></nx-icon>
          {{ 'general.save' | translate }}
        </button>
        <button *ngIf="cancelSupplier"
                nxButton="cta small"
                class="nx-margin-left-2xs"
                type="button"
                aria-label="a useful label"
                (click)="cancelSupplier()"
        >
          <nx-icon name="arrow-right-from-bracket" class="nx-margin-right-xs"></nx-icon>

          {{ 'general.cancel' | translate }}
        </button>
        <button *ngIf="!cancelSupplier"
                nxButton="cta small"
                class="nx-margin-left-2xs"
                type="button"
                aria-label="a useful label"
                [disabled]="disabledCancel"
                (click)="cancel.emit()"
        >
          <nx-icon name="arrow-right-from-bracket" class="nx-margin-right-xs"></nx-icon>
          {{ 'general.cancel' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="mobile">
        <button
          nxIconButton="cta small-medium"
          [nxContextMenuTriggerFor]="menu"
          aria-label="Open menu"
          type="button"
        >
          <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
        </button>

        <nx-context-menu #menu="nxContextMenu">
          <ng-container *ngFor="let option of modalOptions">
            <button
              nxContextMenuItem
              aria-label="a useful label"
              type="button"
              class="nx-margin-right-2xs nx-margin-bottom-0"
              (click)="option.openModalSupplier()"
            >
              <nx-icon name="{{option.iconName}}"></nx-icon>
              {{ option.name | translate }}
            </button>
          </ng-container>
          <button nxContextMenuItem type="button" (click)="saveSupplier()" [disabled]="disableSave">
            <nx-icon name="save-o" class="nx-margin-right-2xs"></nx-icon>
            {{ 'general.save' | translate }}

          </button>
          <button
            nxContextMenuItem=""
            type="button"
            aria-label="a useful label"
            (click)="cancelSupplier()"
          >
            <nx-icon name="arrow-left" class="nx-margin-right-2xs"></nx-icon>
            <ng-container> {{ 'general.cancel' | translate }}</ng-container>
          </button>
        </nx-context-menu>
      </ng-container>
    </ng-container>
  </div>
</div>
