import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {ActivatedRoute} from "@angular/router";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {CategoryDtoTypes, CategoryService} from "../../../services/category/category.service";
import {CategoryModel} from "../../../services/category/category.model";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {StepElement} from "../../../models/step-element.model";
import {SKIP_VALIDATION} from "../../../utilities/types";
import {ValidatedInputDirective} from "../../../directives/validated-input.directive";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.css']
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.CATEGORY,
})
export class CategoryFormComponent extends FormPage<CategoryModel, CategoryService> implements OnInit, OnDestroy {
  @ViewChild('baseData') baseData!: TemplateRef<any>;
  @ViewChild('dynamicData') dynamicData!: TemplateRef<any>;
  @ViewChild('attachmentData') attachmentData!: TemplateRef<any>;
  @ViewChild('orderConfigData') orderConfigData!: TemplateRef<any>;
  @ViewChild('summaryData') summaryData!: TemplateRef<any>;

  @ViewChildren(ValidatedInputDirective) override inputs!: QueryList<ValidatedInputDirective>;

  steps!: StepElement[]
  saveIsEnable = false;
  parents: CategoryModel[] = [];

  constructor(service: CategoryService, route: ActivatedRoute,
              public dialogService: NxDialogService, private cdr: ChangeDetectorRef) {
    super(route, service, CategoryModel, []);
  }

  ngOnInit(): void {
    this.onInit(CategoryDtoTypes.ProductCategoryDTO);
    this.initSteps();
    this.cdr.detectChanges();
  }

  protected override afterModelIsSetProcess() {
    this.service.getAllMayHaveChildren().pipe(takeUntil(this._destroyed)).subscribe(result => {
      this.parents = result.filter(category => category.id != this.formModel.id);

      this.componentIsReady = true
    });
    this.initErrorHandlingOnInput();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }


  private initSteps() {
    this.steps = [
      new StepElement(1,'category.baseInformation', () => this.baseData, this.validateStep(1)),
      new StepElement(2,'category.attachment', () => this.attachmentData, this.validateStep(2)),
      new StepElement(3,'category.orderConfig', () => this.orderConfigData, this.validateStep(3)),
      new StepElement(4,'category.summary', () => this.summaryData, SKIP_VALIDATION),
    ]
  }

  validateStep(stepCount: number) {
    return () => {
      try {
        this.service.validateByStep(this.formModel, stepCount);
        return true;
      } catch (e) {
        console.warn(e);
        return false
      }
    }
  }
}
