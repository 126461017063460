<ng-container *ngIf="model && componentIsReady">
  <div nxLayout="grid">
    <div nxRow>
      <div nxCol="12,12,12,6,6">

        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="truck-ramp-box"
            [title]="model.companyName"
            [backSupplier]="back()"
            [editSupplier]="edit()"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-s">

            <div nxCol="12">
              <nx-data-display class="nx-margin-bottom-s" [orientation]="orientation$ | async">
                <nx-data-display-label>
                  <nx-icon name="signature" class="nx-margin-right-s"></nx-icon>
                  {{ 'supplier.companyName' | translate }}
                </nx-data-display-label>
                {{ model.companyName }}
              </nx-data-display>
              <nx-data-display class="nx-margin-bottom-s" [orientation]="orientation$ | async">
                <nx-data-display-label>
                  <nx-icon name="location-dot" class="nx-margin-right-s"></nx-icon>
                  {{ 'supplier.hq' | translate }}
                </nx-data-display-label>
                {{ model.address.toString() }}
              </nx-data-display>
              <nx-data-display class="nx-margin-bottom-s" [orientation]="orientation$ | async">
                <nx-data-display-label>
                  <nx-icon name="scale-balanced" class="nx-margin-right-s"></nx-icon>
                  {{ 'supplier.taxNumber' | translate }}
                </nx-data-display-label>
                {{ model.taxNumber }}
              </nx-data-display>
              <nx-data-display class="nx-margin-bottom-s" [orientation]="orientation$ | async">
                <nx-data-display-label>
                  <nx-icon name="money-check" class="nx-margin-right-s"></nx-icon>
                  {{ 'supplier.bankAccountNumber' | translate }}
                </nx-data-display-label>
                {{ model.bankAccountNumber }}
              </nx-data-display>

              <nx-data-display class="nx-margin-bottom-s" [orientation]="orientation$ | async">
                <nx-data-display-label>
                  <nx-icon name="user-o" class="nx-margin-right-s"></nx-icon>
                  {{ 'supplier.contactName' | translate }}
                </nx-data-display-label>
                {{ model.contactName }}
              </nx-data-display>
              <nx-data-display class="nx-margin-bottom-s" [orientation]="orientation$ | async">
                <nx-data-display-label>
                  <nx-icon name="phone" class="nx-margin-right-s"></nx-icon>
                  {{ 'supplier.contactPhone' | translate }}
                </nx-data-display-label>
                <a class="underline" href="tel:{{model.contactPhone}}"> {{ model.contactPhone }}</a>
              </nx-data-display>
              <nx-data-display class="nx-margin-bottom-s" [orientation]="orientation$ | async">
                <nx-data-display-label>
                  <nx-icon name="envelope" class="nx-margin-right-s"></nx-icon>
                  {{ 'supplier.contactEmail' | translate }}
                </nx-data-display-label>
                <a class="underline" href="mailto:{{model.contactEmail}}"> {{ model.contactEmail }}</a>
              </nx-data-display>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12,12,12,6,6">
        <!--        <nx-card class="viewer">-->
        <!--          <page-show-tool-bar-->
        <!--            iconName="chart-line"-->
        <!--            title="{{'supplier.orderStatistic' | translate}}"-->
        <!--          ></page-show-tool-bar>-->
        <!--          <div nxRow="" class="nx-margin-s">-->
        <!--            <div nxCol="12">-->
        <!--              <app-line-chart></app-line-chart>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </nx-card>-->
      </div>
    </div>

    <nx-card class="viewer" *ngIf="products &&products.length>0">
      <page-show-tool-bar
        iconName="circle-nodes"
        title="{{'supplier.connectionInfo' | translate}}"
      ></page-show-tool-bar>
      <div nxRow>
        <div nxCol="12">
          <nx-tab-group [(selectedIndex)]="currentIndex">
            <nx-tab label="{{'supplier.product'| translate}}" class="nx-margin-top-2xs"
                    *ngIf="(products && products.length>0)">
              <ng-template nxTabContent>
                <div nxRow="" class="nx-margin-top-2xs">
                  <div nxCol="12">
                    <hellp-table id="sup-prod-table" [rows]="products" [colDefinitions]="colDef"
                                 [actionConfig]="actionDef"
                                 [showAsList]="mobile"></hellp-table>
                  </div>
                </div>
              </ng-template>
            </nx-tab>

            <!--            <nx-tab label="{{'supplier.supplierOrder'| translate}}" *ngIf="(supplierOrder && supplierOrder.length>0)">-->
            <!--              <ng-template nxTabContent>-->
            <!--                <p>Fill in your content!</p>-->
            <!--              </ng-template>-->
            <!--            </nx-tab>-->
          </nx-tab-group>

        </div>
      </div>
    </nx-card>
  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>


<ng-template #priceTpl let-value="value">
  <ng-container *ngIf="value.length>0">
    {{ value[0].net }} + {{ value[0].vat }}%
  </ng-container>
</ng-template>

<ng-template #statusTpl let-value="value">
  <span
    class="badge-sm {{value == HistoryStatus.DRAFT?'badge-draft':value == HistoryStatus.PUBLISHED?'badge-published':'badge-in-progress'}}">
  {{ 'enum.' + value | translate }}
    </span>
</ng-template>