<nx-card class="viewer">
  <page-list-view-header
    iconName="cart-shopping"
    [title]="'userOrder.headline'"
    [customActions]="customActions"
    [newConsumer]="newConsumer() "
  ></page-list-view-header>
</nx-card>
<div nxRow="" class="nx-margin-bottom-m" nxRowJustify="center">
  <div nxCol="12,12,6">
    <nx-card class="viewer h-100">
      <h4 class="nx-margin-bottom-m" nxHeadline="subsection-small">{{ 'userOrder.datePrefilter' | translate }}</h4>
      <ng-container *ngFor="let filter of dataFilters;trackBy:dataFilterTrackFn">
        <button nxButton="{{filter.active?'cta':'tertiary'}} small" (click)="filter.action(filter)"
                class="nx-margin-right-m">
          <nx-icon *ngIf="filter.iconName" [name]="filter.iconName" class="nx-margin-right-s"></nx-icon>
          {{ 'userOrder.' + filter.key | translate }}
        </button>
      </ng-container>
    </nx-card>
  </div>
  <div nxCol="12,12,6">
    <nx-card class="viewer h-100">
      <h4 class="nx-margin-bottom-m" nxHeadline="subsection-small">{{ 'userOrder.productPreFilter' | translate }}</h4>
      <nx-formfield nxLabel="{{'userOrder.product' | translate}}" appearance="outline" nxFloatLabel="always">
        <input nxInput [(ngModel)]="productNameFilter" name="productNameFilter"/>
      </nx-formfield>
      <div class="text-end">
        <button nxButton="cte small" (click)="productFilter()"
                class="nx-margin-right-m">
          {{ 'general.filter' | translate }}
        </button>
        <button nxButton="tertiary small" (click)="clearProductFilter()"
                class="nx-margin-right-m">
          {{ 'general.clearFilter' | translate }}
        </button>
      </div>
    </nx-card>
  </div>
</div>
<nx-card class="viewer">

  <ng-container *ngIf="componentIsReady">
    <hellp-table id="user-order-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                 [showAsList]="mobile" #hellpTable></hellp-table>
  </ng-container>

  <ng-container *ngIf="!componentIsReady">
    <div class="d-flex justify-content-center nx-margin-y-2m">
      <app-spinner></app-spinner>
    </div>
  </ng-container>
</nx-card>

<ng-template #statusTpl let-value="value">
  <span
    class="badge-sm {{value+'-badge'}}">
  {{ 'enum.' + value | translate }}
    </span>
</ng-template>

<ng-template #paymentTpl let-value="value">
  <span class="badge-sm {{value+'-badge'}}">
    <nx-icon *ngIf="value == PaymentType.BARION" name="credit-card"></nx-icon>
    <nx-icon *ngIf="value == PaymentType.CASH" name="coins"></nx-icon>
  </span>
  {{ 'enum.' + value | translate }}
</ng-template>

<ng-template #deliveryTpl let-value="value">
  <span class="badge-sm {{value+'-badge'}} ">
    <nx-icon *ngIf="value == DeliveryType.DELIVERY" name="truck"></nx-icon>
    <nx-icon *ngIf="value == DeliveryType.ONSITE" name="building"></nx-icon>
  </span>
  {{ 'enum.' + value | translate }}
</ng-template>
<ng-template #priceTpl let-value="value">
  {{ value }} Ft
</ng-template>


