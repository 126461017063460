import {Injectable} from '@angular/core';
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {Tag} from "../product/product.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/tags')
export class TagRepository extends CrudRepository<Tag> {
  constructor() {
    super();
  }


}
