import {BaseModel} from "@hellp/repository";
import {UserRole, UserType} from "../../utilities/types";

export class SlimUserModel extends BaseModel {
  type: UserType = UserType.simple
  email = '';
  firstName = '';
  lastName = '';
  realmRoles: string[] = [UserRole.CUSTOMER];

  public get name() {
    return `${this.firstName} ${this.lastName}`;
  }
}