import { Component, Input, OnInit } from '@angular/core';
import { ProductModel } from '../../../services/product/product.model';

@Component({
  selector: 'display-product-show',
  templateUrl: './product-show-display.component.html',
  styleUrls: ['./product-show-display.component.css'],
})
export class ProductShowDisplayComponent implements OnInit {
  @Input() model!: ProductModel;

  ngOnInit(): void {}
}
