import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductModel} from "../../../services/product/product.model";
import {Utilities} from "../../../utilities/utilities";
import {CategoryModel} from "../../../services/category/category.model";
import {CategoryDtoTypes, CategoryService} from "../../../services/category/category.service";
import {takeUntil} from "rxjs";
import {Editor, Toolbar} from "ngx-editor";
import {ProductService, UploadType} from "../../../services/product/product.service";
import {FormComponent} from "../../../utilities/abstract/form-component";
import {NGX_TOOLBAR} from "../../../utilities/types";

@Component({
  selector: 'forms-product-base-form',
  templateUrl: './product-base-form.component.html',
  styleUrls: ['./product-base-form.component.css']
})
export class ProductBaseFormComponent extends FormComponent implements OnInit {
  private focusedSKU = false;
  @Input() uploadType: UploadType = UploadType.SALES;
  @Input() model!: ProductModel;
  @Output() modelChange: EventEmitter<ProductModel> = new EventEmitter();

  categories!: CategoryModel[];
  editor: Editor;

  toolbar: Toolbar = NGX_TOOLBAR;


  constructor(private categoryService: CategoryService, productService: ProductService) {
    super(productService);
    this.editor = new Editor();
  }

  ngOnInit(): void {
    if (this.model.history && this.model.history.category) {
      this.model.history.categoryId = this.model.history.category.id;
    } else {
      delete (this.model.history as any).categoryId;
    }
    this.categoryService.getAllByDeleted(CategoryDtoTypes.ParentOnlyProductCategoryDTO)
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => {
        this.categories = result;
      });
    this.initErrorHandlingOnInput();
  }

  refreshCategory() {
    const category = this.categories.find(c => c.id == this.model.history.categoryId);
    if (category) {
      this.model.history.category = category;
      if (category && category.orderConfig && category.orderConfig.financialConfig) {
        for (const p of this.model.prices) {
          p.vat = category.orderConfig.financialConfig?.tax;
        }
      }
    } else {
      console.warn('Something went wrong with category');
    }
  }

  changeSKU() {
    if (!this.focusedSKU && this.uploadType == UploadType.SALES) {
      this.model.sku = Utilities.toSKU(this.model.name);
    }
  }

  focusSKU() {
    this.focusedSKU = true;
  }

  protected readonly UploadType = UploadType;
}
