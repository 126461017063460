import {BaseModel} from "../../utilities/abstract/base.model";
import {ProductHistoryModel} from "../product/product.model";
import {ConvertProcess, IgnoreProperty, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../processor.service";
import moment, {Moment} from "moment";
import {SupplierOrderElement} from "../supplier-order/supplier-order.model";
import {InventoryElement} from "../inventory/inventory.model";

export enum WarehouseType {
  HQ = "HQ",
  WEB_SHOP = "WEB_SHOP"
}

export enum Month {
  'JANUARY' = 'JANUARY',
  'FEBRUARY' = 'FEBRUARY',
  'MARCH' = 'MARCH',
  'APRIL' = 'APRIL',
  'MAY' = 'MAY',
  'JUNE' = 'JUNE',
  'JULY' = 'JULY',
  'AUGUST' = 'AUGUST',
  'SEPTEMBER' = 'SEPTEMBER',
  'OCTOBER' = 'OCTOBER',
  'NOVEMBER' = 'NOVEMBER',
  'DECEMBER' = 'DECEMBER'
}

export const MonthByIndex: { [key: string]: Month } = {
  1: Month.JANUARY,
  2: Month.FEBRUARY,
  3: Month.MARCH,
  4: Month.APRIL,
  5: Month.MAY,
  6: Month.JUNE,
  7: Month.JULY,
  8: Month.AUGUST,
  9: Month.SEPTEMBER,
  10: Month.OCTOBER,
  11: Month.NOVEMBER,
  12: Month.DECEMBER,
}


export class MinimumStock extends BaseModel {
  year: number = (new Date()).getFullYear();
  monthlyMinimum: { [key: string]: number } = {
    'JANUARY': 0,
    'FEBRUARY': 0,
    'MARCH': 0,
    'APRIL': 0,
    'MAY': 0,
    'JUNE': 0,
    'JULY': 0,
    'AUGUST': 0,
    'SEPTEMBER': 0,
    'OCTOBER': 0,
    'NOVEMBER': 0,
    'DECEMBER': 0,
  };

}

export class StockConfig {
  minimumStocks: MinimumStock[] = [];

  get actualMinimumStock(): MinimumStock {

    const tmp = this.minimumStocks.find(s => s.year == (new Date()).getFullYear());

    return tmp ?? new MinimumStock();
  }
}

export class StockProduct extends BaseModel {
  count = 0;

  product!: ProductHistoryModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getProductIdIfExist'}, "model")
  productId = 0;

  warehouse!: WarehouseModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getWarehouseIdIfExist'}, "model")
  warehouseId = 0;
  warehouseName = '';


  @ParseTo(StockConfig)
  config: StockConfig = new StockConfig();


  @IgnoreProperty
  orderCount = 0;
  @IgnoreProperty
  addToOrder = false;
  @IgnoreProperty
  orderNote?: string;
  @IgnoreProperty
  estimatedDate?: Moment;
  @IgnoreProperty
  tempElement?: SupplierOrderElement | InventoryElement;

  static newStockProductByStockProduct(sourceStock: StockProduct) {
    const sp = new StockProduct();
    sp.product = sourceStock.product;
    sp.config = new StockConfig();
    return sp;
  }
}

export class WarehouseModel extends BaseModel {

  mainHQ = false;
  name = '';
  description = '';
  type: WarehouseType = WarehouseType.HQ;
  @ParseTo(StockProduct)
  products: StockProduct[] = []

  get sumOrderable(): number {
    let count = 0;
    this.products.forEach(product => {
      if (product.addToOrder) {
        count += product.orderCount;
      }
    });
    return count;
  }

  get sumOrderablePrice(): number {
    let price = 0;
    this.products.forEach(product => {
      if (product.addToOrder) {
        console.log(product.product.supplierProduct);
        price += product.orderCount * product.product.supplierProduct.basePrice.gross();
      }
    });
    return price;
  }


}



