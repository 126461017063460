import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NX_MODAL_DATA, NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {HellpNotificationService} from "@hellp/service";
import {BillingDataFormComponent} from "../../form/billing-data-form/billing-data-form.component";
import {UserType} from "../../../utilities/types";
import {HellparserService} from "@hellp/parser";
import {lastValueFrom} from "rxjs";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {UserService} from "../../../services/user/user.service";
import {BillingDataService} from "../../../services/order/billing-data.service";
import {BILLING_DATA_MODAL_ID} from "../edit-billing-data-dialog/edit-billing-data-dialog.component";
import {BillingData} from "../../../services/order/order";
import {UserModel} from "../../../services/user/user.model";
import {AddressModel} from "../../../models/address.model";


export enum BillingDataComponentMode {
  BillingData = "BillingData",
  DeliveryData = "DeliveryData"
}

@Component({
  selector: 'dialog-billing-data',
  templateUrl: './billing-data-dialog.component.html',
  styleUrls: ['./billing-data-dialog.component.scss']
})
export class BillingDataDialogComponent implements OnInit {
  private readonly notificationService: HellpNotificationService = ServiceLocator.injector.get(HellpNotificationService);
  @ViewChild('billingDataForm') billingDataForm!: BillingDataFormComponent;

  mode: BillingDataComponentMode;
  billingData: BillingData = new BillingData();
  ref?: NxModalRef<any>;

  constructor(@Inject(NX_MODAL_DATA) readonly injectData: any,
              private readonly dialogService: NxDialogService,
              private readonly userService: UserService,
              private readonly parserService: HellparserService,
              private readonly billingDataService: BillingDataService) {
    this.billingData.user = injectData.user ?? UserModel.getDummy();
    this.billingData.firstName = this.billingData.user.firstName;
    this.billingData.lastName = this.billingData.user.lastName;
    this.billingData.email = this.billingData.user.email;
    this.billingData.address = new AddressModel();
    this.mode = injectData.mode;
  }


  ngOnInit(): void {
    this.ref = this.dialogService.getModalById(BILLING_DATA_MODAL_ID);
  }

  async save() {
    if (this.billingDataForm && this.billingDataForm.billingDataForm && this.billingDataForm.billingDataForm.valid && this.ref) {
      const ref = this.ref;
      if (this.billingData.user.type == UserType.dummy) {
        const user = await lastValueFrom(this.userService.findByEmail(this.billingData.email));
        if (user == null) {
          this.billingData.user.email = this.billingData.email;
          this.billingData.user.firstName = this.billingData.firstName;
          this.billingData.user.lastName = this.billingData.lastName;
          this.userService.registration(this.billingData.user as UserModel).subscribe(result => {
            this.billingData.user = result;
            this.billingDataSave(ref, result);
          });
        } else {
          this.billingData.user = user;
          this.billingDataSave(ref, user);
        }

      } else {
        this.billingDataSave(ref, this.billingData.user as UserModel);
      }
    } else {
      this.notificationService.showError("general.error", "error.billingDataFormNotValid");
    }
  }

  private billingDataSave(ref: NxModalRef<any>, user: UserModel) {
    if (this.mode == BillingDataComponentMode.BillingData) {
      this.billingDataService.createBillingDataForUser(this.billingData).subscribe(result => {
        this.notificationService.showSuccess('general.success', 'profile.successBillingDataSave');
        result.user = UserModel.getSlimUserFrom(user);
        ref.close(this.parserService.parseApiDataToModel(result, BillingData));
      });
    } else {
      this.billingDataService.createDeliveryDataForUser(this.billingData).subscribe(result => {
        this.notificationService.showSuccess('general.success', 'profile.successBillingDataSave');
        result.user = UserModel.getSlimUserFrom(user);
        ref.close(this.parserService.parseApiDataToModel(result, BillingData));
      })
    }
  }
}
