<app-attachment-handler
  [attachedItems]="itemAttachments"
  [availableItems]="availableAttachments"
  [attachedTitle]="'general.attachedPdf'"
  [availableTitle]="'general.availablePdf'"
  [attachedViewComponent]="imgViewComponent"
  [availableViewComponent]="imgViewComponent"
  [acceptString]="'*'"
  [isMultiple]="isMultiple"
  [maxElement]="maxElement"
  [secondPanelExpanded]="secondPanelExpanded"
  (refreshEvent)="attachmentRefresh()"
  (attachmentSaveEvent)="attachmentSave($event)"
>
</app-attachment-handler>
