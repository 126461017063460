import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {Author} from "../../../services/article/magazine.model";
import {AuthorDtoTypes, AuthorService} from "../../../services/article/author.service";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ActivatedRoute} from "@angular/router";
import {ValidatedInputDirective} from "../../../directives/validated-input.directive";

@Component({
  selector: 'app-author-form',
  templateUrl: './author-form.component.html',
  styleUrls: ['./author-form.component.css']
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.AUTHOR,
})
export class AuthorFormComponent extends FormPage<Author, AuthorService> implements OnInit, OnDestroy {
  @ViewChildren(ValidatedInputDirective) override inputs!: QueryList<ValidatedInputDirective>;

  constructor(route: ActivatedRoute, service: AuthorService) {
    super(route, service, Author, []);
  }

  ngOnInit(): void {
    this.onInit(AuthorDtoTypes.AuthorDTO);
    this.initErrorHandlingOnInput();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  protected override afterModelIsSetProcess() {
    this.componentIsReady = true
  }
}
