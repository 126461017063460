import {ExtraPriceModel} from "../category/category.model";
import {PriceModel} from "../../models/price.model";
import {Currencies} from "../../utilities/types";
import {BaseModel} from "../../utilities/abstract/base.model";
import {AttachmentModel} from "../attachment/attachment.model";
import {ConvertFrom, ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {A} from "@angular/cdk/keycodes";
import {ProcessorService} from "../processor.service";
import {ExtendedProcessorService} from "../extended-processor.service";


export enum ExtraPriceType {
  DELIVERY_PRICE = "DELIVERY_PRICE",
  ICE_PACK_PRICE = "ICE_PACK_PRICE",
  CASH_PRICE = "CASH_PRICE",
}

export enum DeliveryPartnerType {
  GLS = 'GLS',
  SPRINTER = 'SPRINTER',
  MPL = 'MPL',
}

export class DeliveryPartner extends BaseModel {
  name = '';
  @ParseTo(AttachmentModel)
  logo!: AttachmentModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getLogoId'}, "model")
  logoId = 0;
  shortDescription = '';
  type!: DeliveryPartnerType;

  constructor(name?: string, logo?: string, shortDescription?: string) {
    super();
    this.name = name ?? '';
    this.shortDescription = shortDescription ?? '';
  }
}

export class KeyExtraPrice {
  key = '';
  @ParseTo(ExtraPriceModel)
  extraPrice!: ExtraPriceModel
}

export class PurchaseInfoModel extends BaseModel {
  @ParseTo(ExtraPriceModel)
  icePackPrice: ExtraPriceModel = new ExtraPriceModel(ExtraPriceType.ICE_PACK_PRICE);
  @ParseTo(KeyExtraPrice)
  cashPrices: KeyExtraPrice[] = []
  @ParseTo(KeyExtraPrice)
  deliveryPrices: KeyExtraPrice[] = []
  @ParseTo(DeliveryPartner)
  deliveryPartners: DeliveryPartner[] = []
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'addDeliveryPartnerId'}, "model")
  deliveryPartnerIds: number[] = [];
}


