import { Component } from '@angular/core';

@Component({
  selector: 'app-product-stock-panel',
  templateUrl: './product-stock-panel.component.html',
  styleUrls: ['./product-stock-panel.component.css']
})
export class ProductStockPanelComponent {

}
