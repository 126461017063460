import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {PlantCulture} from "./plant-culture.model";
import {PlantCultureRepository} from "./plant-culture.repository";
import {PlantCultureIssueType} from "./plant-culture-issue-type.model";
import {ValidatorService} from "../../utilities/service/validator.service";

export enum PlantCultureDtoType {
  PlantCultureDTO = 'PlantCultureDTO',
  SlimPlantCultureDTO = 'SlimPlantCultureDTO',
}

@Injectable({
  providedIn: 'root'
})
export class PlantCultureService extends CrudService<PlantCulture, PlantCultureRepository> {

  constructor(repo: PlantCultureRepository) {
    super(ROUTING.PLANT_CULTURE, repo, PlantCulture);
  }

  override validate(model: PlantCulture) {
    ValidatorService.allRequired(model, ['name'], PlantCulture.name);
  }

}

