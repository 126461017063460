import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {KeycloakProfile} from "keycloak-js";
import {BehaviorSubject, Subject, Subscription, takeUntil} from "rxjs";
import {KeycloakService} from "keycloak-angular";
import {ExtendedStorageService, HellpNotificationService, HellpNotificationServiceType} from "@hellp/service";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {AUTH_USER_KEY} from "../../app.config";
import {RxStompService} from "../../utilities/service/rx-stomp.service";
import {Message} from "@stomp/stompjs";
import {HellparserService} from "@hellp/parser";
import {WebsocketMessageModel} from "../../models/websocket-message.model";
import {MagazineModel} from "../../services/article/magazine.model";
import {NotificationModel, TypeIcon} from "../../models/notification.model";

@Component({
  selector: 'layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.css']
})
export class LayoutHeaderComponent implements OnInit {
  private topicSubscription!: Subscription;
  public authUser!: KeycloakProfile;
  public roleCards!: any[];
  @Input() withNavigation = true;
  private readonly _destroyed = new Subject<void>();
  mobil = true;
  notifications: any[] = [];
  unreadNotification$: BehaviorSubject<number> = new BehaviorSubject<number>(0);


  receivedMessages: string[] = [];

  constructor(private keycloakService: KeycloakService,
              private myStorageService: ExtendedStorageService,
              public viewportService: NxViewportService,
              private parserService: HellparserService,
              private rxStompService: RxStompService,
              private notificationService: HellpNotificationService,
              private _cdr: ChangeDetectorRef,) {
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobil = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
    this.authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, {});

    this.topicSubscription = this.rxStompService
      .watch('/output/magazine-publish')
      .subscribe((message: Message) => {
        const websocketMessage: WebsocketMessageModel<MagazineModel> = this.parserService.parseApiDataToModel(JSON.parse(message.body), WebsocketMessageModel<MagazineModel>);
        websocketMessage.attribute = this.parserService.parseApiDataToModel(websocketMessage.attribute, MagazineModel);
        const notification = new NotificationModel(
          TypeIcon[websocketMessage.type],
          websocketMessage.attribute.title,
          websocketMessage.attribute.publishedAt.toDate(),
          "",
          false
        );
        this.notifications.push(notification);
        this.notificationService.showInfo(`general.${websocketMessage.type}`, notification.title, HellpNotificationServiceType.Toast)

        //TODO storage tárolás és vissza olvasás
        this.calcUnread();
      });

  }

  private calcUnread() {
    this.unreadNotification$.next((this.notifications.filter(notification => !notification.read)).length)
  }

  async logout() {
    this.myStorageService.deleteFromCookies(AUTH_USER_KEY);
    await this.keycloakService.logout();
  }

  read(index: number) {
    this.notifications[index].read = true;
    this.calcUnread();
  }

  deleteNotification(index: number) {
    this.notifications.splice(index, 1);
    this.calcUnread();
  }

  readAll() {
    this.notifications.forEach(notification => notification.read = true);
    this.calcUnread();
  }

  notificationTrackBy(index: number, item: any): number {
    return item.id;
  }
}
