<nx-card class="viewer">
  <page-form-header iconName="file" [model]="formModel" [titleProperty]="'title'" [saveSupplier]="save()"
                    [cancelSupplier]="cancel()"
                    [modalOptions]="toolbarAddOptions"></page-form-header>
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12">
        <ng-container *ngIf="componentIsReady">
          <app-single-attachment-upload [(file)]="file" [(attachment)]="formModel"></app-single-attachment-upload>
        </ng-container>
        <ng-container *ngIf="!componentIsReady">
          <div class="d-flex justify-content-center nx-margin-y-2m">
            <app-spinner></app-spinner>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</nx-card>
