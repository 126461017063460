<div class="d-flex mt-n2">
  <div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
    <nx-icon [name]="iconName"></nx-icon>
  </div>
  <div class="ms-3 my-auto">
    <h6 class="mb-0">{{ title | translate }}</h6>
  </div>
  <div class="ms-auto">
    <ng-container *ngIf="backSupplier || editSupplier || (customToolbarActions&&customToolbarActions.length>0)">
      <button
        nxIconButton="cta small"
        [nxContextMenuTriggerFor]="menu"
        aria-label="Open menu"
        type="button"
      >
        <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
      </button>

      <nx-context-menu #menu="nxContextMenu">
        <ng-container *ngIf="customToolbarActions">
          <ng-container *ngFor="let toolbarAction of customToolbarActions">
            <button [disabled]="toolbarAction.disabled"
                    *ngIf="toolbarAction.supplier"
                    nxContextMenuItem
                    aria-label="a useful label"
                    type="button"
                    (click)="toolbarAction.supplier()"
            >
              <nx-icon [name]="toolbarAction.iconName"></nx-icon>
              {{ toolbarAction.title | translate }}
            </button>
          </ng-container>
        </ng-container>
        <button
          *ngIf="editSupplier"
          nxContextMenuItem
          aria-label="a useful label"
          type="button"
          (click)="editSupplier()"
        >
          <nx-icon name="pencil"></nx-icon>
          {{ 'general.edit' | translate }}
        </button>
        <button
          *ngIf="backSupplier"
          nxContextMenuItem
          type="button"
          aria-label="a useful label"
          (click)="backSupplier()"
        >
          <nx-icon name="arrow-left" class="nx-margin-right-2xs"></nx-icon
          >
          {{ 'general.back' | translate }}
        </button>
      </nx-context-menu>
    </ng-container>
  </div>
</div>

