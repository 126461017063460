<ng-container *ngIf="model && componentIsReady">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12,12,12,3">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="boxes-stacked"
            title="{{'warehouse.info' | translate}}"
            [backSupplier]="back()"
            [editSupplier]="edit()"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <nx-data-display label="{{'warehouse.name' | translate}}" class="nx-margin-top-2s">
                <h4 class="p-2" nxHeadline="subsection-medium"> {{ model.name }}</h4>
              </nx-data-display>
              <nx-data-display label="{{'warehouse.description' | translate}}" class="nx-margin-top-2s">
                <h4 class="p-2" nxHeadline="subsection-medium">{{ model.description }}</h4>
              </nx-data-display>
              <nx-data-display label="{{'warehouse.typeLabel' | translate}}" class="nx-margin-top-2s">
                <h4 nxHeadline="subsection-medium"
                    class="p-2">{{ 'warehouse.type.' + model.type | translate }}</h4>
              </nx-data-display>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12,12,12,9">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="boxes-stacked"
            title="{{'warehouse.productsInWarehouse' | translate}}"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <hellp-table [colDefinitions]="colDef" [rows]="products" [actionConfig]="actionDef"></hellp-table>
            </div>
          </div>
        </nx-card>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>