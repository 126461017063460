export enum ROUTING {
  SUPPLIER = 'supplier',
  CATEGORY = 'category',
  TAG = 'tag',
  ATTACHMENT = 'attachment',
  MAGAZINE = 'magazine',
  ARTICLE = 'article',
  ABOUT_US = 'about-us',
  ORDER = 'user-order',
  ORDER_INFO = 'order-info',
  CONTACT = 'contact',
  PRODUCT_MATRIX = 'product-matrix',
  AUTHOR = 'author',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_AND_CONDITION = 'terms-and-condition',
  BIO_CERT = 'bio-certificate',
  MARKETING_PRODUCT = 'marketing-product',
  SALES_PRODUCT = 'sales-product',
  PRODUCT = 'product',
  WAREHOUSE = 'warehouse',
  BANNER = 'banner',
  SUPPLIER_ORDER = 'supplier-order',
  INVENTORY = 'inventory',
  SCRAPPING = 'scrapping',
  USER = 'user',
  STOCK_TRANSFER = 'stock-transfer',
  GOODS_LANDING = 'goods-landing',
  TECHNOLOGIES = 'technologies',
  PLANT_CULTURE = 'plant-culture',
  PLANT_CULTURE_ISSUE = 'plant-culture-issue',
  PLANT_CULTURE_ISSUE_TYPE = 'plant-culture-issue-type',
}
