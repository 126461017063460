import {Injectable, Type} from "@angular/core";
import {Delete, Get, Patch, PathVariable, Post, Put, QueryParam, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {BillingData, OrderModel} from "./order";
import {OrderStatus} from "../../utilities/types";
import {Observable} from "rxjs";
import {PostPayload} from "../../models/post-payload.model";

@Injectable({
  providedIn: 'root'
})
export class BillingDataRepository extends CrudRepository<BillingData> {

  @Get('billing-data/filter')
  override getAllByFilter(@QueryParam() filter: string, clazz: Type<BillingData>): Observable<BillingData[]> {
    return this.createRequest().withModel(clazz).send();
  }

  @Get('delivery-data/filter')
  getAllDeliveryDataByFilter(@QueryParam() filter: string, clazz: Type<BillingData>): Observable<BillingData[]> {
    return this.createRequest().withModel(clazz).send();
  }

  @Post('billing-data')
  create(@RequestBody() payload: PostPayload<BillingData>) {
    return this.createRequest().withModel(BillingData).send();
  }

  @Post('delivery-data')
  createDeliveryData(@RequestBody() payload: PostPayload<BillingData>) {
    return this.createRequest().withModel(BillingData).send();
  }

  @Put('billing-data/{id}')
  updateBillingData(@PathVariable() id: number, @RequestBody() payload: PostPayload<BillingData>) {
    return this.createRequest().withModel(BillingData).send();
  }

  @Put('delivery-data/{id}')
  updateDeliveryData(@PathVariable() id: number, @RequestBody() payload: PostPayload<BillingData>) {
    return this.createRequest().withModel(BillingData).send();
  }

}