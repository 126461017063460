import {Injectable} from '@angular/core';
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {PlantCulture} from "./plant-culture.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/plant-cultures')
export class PlantCultureRepository extends CrudRepository<PlantCulture> {
  constructor() {
    super();
  }

}
