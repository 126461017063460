<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady" [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div nxRow="" class="nx-margin-top-m">
    <div nxCol="12,12,12,4">
      <nx-formfield nxLabel="{{'banner.href' |translate}}" appearance="auto">
        <input type="text" nxInput name="name" required [(ngModel)]="formModel.href"/>
      </nx-formfield>
    </div>
    <div nxCol="12,12,6,4">
      <nx-formfield nxLabel="{{'banner.activeFrom' | translate}}" appearance="auto">
        <input nxDatefield
               #formatFrom="nxDatefield"
               [nxParseFormat]="['MM/DD/YYYY', 'YYYY.MM.DD']"
               nxDisplayFormat="YYYY.MM.DD"
               nxInput
               (dateChange)="updateEndDatepicker()"
               [nxDatepicker]="fromDatepicker"
               [(ngModel)]="formModel.activeFrom"/>
        <span nxFormfieldHint> YYYY.MM.DD </span>
        <nx-datepicker-toggle
          [for]="fromDatepicker"
          nxFormfieldSuffix
        ></nx-datepicker-toggle>
        <nx-datepicker #fromDatepicker></nx-datepicker>

      </nx-formfield>
    </div>
    <div nxCol="12,12,6,4">
      <nx-formfield nxLabel="{{'banner.activeTo' | translate}}" appearance="auto">
        <input nxDatefield
               #formatTo="nxDatefield"
               [nxParseFormat]="['MM/DD/YYYY', 'YYYY.MM.DD']"
               nxDisplayFormat="YYYY.MM.DD"
               nxInput
               [nxDatepicker]="toDatepicker"
               [nxMin]="formModel.activeFrom"
               [(ngModel)]="formModel.activeTo"/>
        <span nxFormfieldHint> YYYY.MM.DD </span>
        <nx-datepicker-toggle
          [for]="toDatepicker"
          nxFormfieldSuffix
        ></nx-datepicker-toggle>
        <nx-datepicker #toDatepicker></nx-datepicker>

      </nx-formfield>
    </div>
    <div nxCol="12,12,12,4">
      <h5 nxHeadline="subsection-small" class="nx-margin-bottom-m">{{ 'banner.casual' | translate }}</h5>
      <app-image-upload [(selectedAttachment)]="formModel.normal" [firstPanelExpanded]="!formModel.id"
                        [secondPanelExpanded]="formModel.id>0"></app-image-upload>
    </div>
    <div nxCol="12,12,12,4">
      <h5 nxHeadline="subsection-small" class="nx-margin-bottom-m">{{ 'banner.medium' | translate }}</h5>
      <app-image-upload [(selectedAttachment)]="formModel.medium" [firstPanelExpanded]="!formModel.id"
                        [secondPanelExpanded]=" formModel.id>0">
      </app-image-upload>
    </div>
    <div nxCol="12,12,12,4">
      <h5 nxHeadline="subsection-small" class="nx-margin-bottom-m">{{ 'banner.small' | translate }}</h5>
      <app-image-upload [(selectedAttachment)]="formModel.small" [firstPanelExpanded]="!formModel.id"
                        [secondPanelExpanded]=" formModel.id>0">
      </app-image-upload>
    </div>
  </div>
</hellp-form-container>

