import {Component, Input, OnInit} from '@angular/core';
import {ProductHistoryModel} from "../../../services/product/product.model";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";

@Component({
  selector: 'display-product-card',
  templateUrl: './product-card-display.component.html',
  styleUrls: ['./product-card-display.component.scss']
})
export class ProductCardDisplayComponent implements OnInit {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  @Input() product!: ProductHistoryModel;

  ngOnInit(): void {
    this.product = this.parser.parseApiDataToModel(this.product, ProductHistoryModel);
  }
}
