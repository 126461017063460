<div class="d-flex mt-n2">
  <div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
    <nx-icon [name]="iconName"></nx-icon>
  </div>
  <div class="ms-3 my-auto">
    <h6 class="mb-0">{{ title | translate }}</h6>
  </div>
  <div class="ms-auto">
    <ng-container *ngIf="!mobile">
      <ng-container *ngIf="customActions">
        <ng-container *ngFor="let action of customActions">
          <button
            (click)="action.supplier()"
            nxButton="cta small"
            nxTooltip="{{action.title | translate}}"
            aria-label="add"
            type="button"
            class="nx-margin-right-s"
          >
            <nx-icon name="{{action.iconName}}" class="nx-margin-right-s"></nx-icon>
            {{ action.title | translate }}
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="hasPermission && newConsumer">
        <button
          (click)="newConsumer()"
          nxButton="cta small"
          nxTooltip=" {{ 'general.new' | translate }} {{ 'general.editEntity.' + modelType | translate }}"
          aria-label="add"
          type="button"
        >
          <nx-icon name="{{newIcon}}" class="nx-margin-right-xs"></nx-icon>
          {{ 'general.new' | translate }} {{ 'general.editEntity.' + modelType | translate }}
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="mobile">
      <button
        nxIconButton="cta small"
        [nxContextMenuTriggerFor]="menu"
        aria-label="Open menu"
        type="button"
      >
        <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
      </button>

      <nx-context-menu #menu="nxContextMenu">
        <!--<ng-container *ngIf="viewChangers != undefined">
          <button
            *ngFor="let viewChangerEntry of viewChangers | keyvalue"
            nxContextMenuItem
            [attr.aria-label]="viewChangerEntry.value.viewName"
            type="button"
            class="nx-margin-right-s nx-margin-bottom-0"
            (click)="changeView(viewChangerEntry.key)"
          >
            <nx-icon name="{{viewChangerEntry.value.icon}}"></nx-icon>
            <span
              [ngClass]="{'active-view':viewChangerEntry.value.show}">{{'general.' + viewChangerEntry.value.viewName | translate}}</span>
          </button>
        </ng-container>-->
        <ng-container *ngIf="customActions">
          <ng-container *ngFor="let action of customActions">
            <button
              (click)="action.supplier()"
              nxContextMenuItem
              aria-label="add"
              type="button"
              class="nx-margin-right-s"
            >
              <nx-icon name="{{action.iconName}}" class="nx-margin-right-2xs"></nx-icon>
              {{ action.title | translate }}
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="newConsumer && hasPermission">
          <button (click)="newConsumer()"
                  nxContextMenuItem
                  aria-label="a useful label"
                  type="button">
            <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
            {{ 'general.new' | translate }} {{ 'general.editEntity.' + modelType | translate }}
          </button>
        </ng-container>
      </nx-context-menu>
    </ng-container>

  </div>
</div>
