<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">

  <div class="nx-margin-top-2m">
    <page-formly-form #formly [saveSupplier]="save()"
                      [cancelSupplier]="cancel()"
                      [formModel]="formModel"
                      [fields]="fields"></page-formly-form>
  </div>
</hellp-form-container>


