import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShowPage} from "../../../utilities/abstract/show-page";
import {CultureIssuePairingModel} from "../../../services/technologies/culture-issue-pairing.model";
import {
  CultureIssuePairingDtoType,
  CultureIssuePairingService
} from "../../../services/technologies/culture-issue-pairing.service";
import {ActivatedRoute} from "@angular/router";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";

@Component({
  selector: 'app-culture-issue-pairing-show',
  templateUrl: './culture-issue-pairing-show.component.html',
  styleUrls: ['./culture-issue-pairing-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.TECHNOLOGIES,
})
export class CultureIssuePairingShowComponent extends ShowPage<CultureIssuePairingModel, CultureIssuePairingService> implements OnInit, OnDestroy {

  constructor(service: CultureIssuePairingService, route: ActivatedRoute) {
    super(service, route);
  }

  async ngOnInit(): Promise<void> {
    this.onInit(CultureIssuePairingDtoType.CultureIssuePairingDTO);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }
}
