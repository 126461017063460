import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {SupplierDtoTypes, SupplierService} from "../../../services/supplier/supplier.service";
import {SupplierModel} from "../../../services/supplier/supplier.model";
import {SupplierProduct} from "../../../services/product/product.model";
import {FormComponent} from "../../../utilities/abstract/form-component";

@Component({
  selector: 'forms-supplier-product-form',
  templateUrl: './supplier-product-form.component.html',
  styleUrls: ['./supplier-product-form.component.css']
})
export class SupplierProductFormComponent extends FormComponent implements OnInit, OnDestroy {

  @Input() model!: SupplierProduct;
  @Output() modelChange: EventEmitter<SupplierProduct> = new EventEmitter();

  private supplierSubscription!: Subscription;

  suppliers!: SupplierModel[];
  supplierId = 0;

  constructor(private supplierService: SupplierService) {
    super(supplierService);
  }

  ngOnInit(): void {
    this.supplierSubscription = this.supplierService.getAllByDeleted(SupplierDtoTypes.SlimSupplierDTO).subscribe(result => {
      this.suppliers = result;
    });
    if (this.model && this.model.supplier) {
      this.supplierId = this.model.supplier.id;
    } else {
      this.supplierId = 0;
    }
    this.initErrorHandlingOnInput();
  }


  ngOnDestroy(): void {
    this.supplierSubscription.unsubscribe();
  }

  refreshSupplier() {
    if (!this.model) {
      this.model = new SupplierProduct();
    }
    const supplier = this.suppliers.find(s => s.id == this.supplierId);
    if (supplier) {
      this.model.supplier = supplier;
    } else {
      console.warn('Something went wrong with supplier');
    }
    this.modelChange.emit(this.model);
  }
}
