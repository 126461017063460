import {Component, Input} from '@angular/core';
import {CultureIssuePairingModel} from "../../../services/technologies/culture-issue-pairing.model";

@Component({
  selector: 'display-plant-culture-issue-pairing',
  templateUrl: './plant-culture-issue-pairing-display.component.html',
  styleUrls: ['./plant-culture-issue-pairing-display.component.css']
})
export class PlantCultureIssuePairingDisplayComponent {
  @Input() model!: CultureIssuePairingModel;
}
