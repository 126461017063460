<hellp-list-container [service]="service" [(componentIsReady)]="componentIsReady">
  <hellp-table id="magazine-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
               [showAsList]="mobile"></hellp-table>
</hellp-list-container>

<ng-template #statusTpl let-value="value">
  <span
    class="badge-sm {{value == MagazineStatus.DRAFT?'badge-draft':value == MagazineStatus.PUBLISHED?'badge-published':value == MagazineStatus.INACTIVE?'badge-inactive':'badge-in-progress'}}">
  {{ 'magazine.' + value | translate }}
    </span>
</ng-template>

