import moment, {Moment} from "moment/moment";
import {Toolbar} from "ngx-editor";

export const NGX_TOOLBAR: Toolbar = [
  ['bold', 'italic'],
  ['underline', 'strike'],
  ['code', 'blockquote'],
  ['ordered_list', 'bullet_list'],
  ['link', 'image'],
  ['text_color', 'background_color'],
  ['align_left', 'align_center', 'align_right', 'align_justify'],
  ['format_clear'],
];
export const SKIP_VALIDATION: () => boolean = () => true;
export type StringStringMap = { [key: string]: string };

export enum StorageKey {
  ORDER_FILTER_KEY = "OrderFilterKey"
}

export enum UserType {
  dummy = 'dummy',
  simple = 'simple'
}

export class DataFilterModel {
  iconName?: string;
  active: boolean;
  from: Moment;
  to: Moment;
  key: string;
  action: (filter: any) => void;

  constructor(active: boolean, from: moment.Moment, to: moment.Moment, key: string, action: (filter: any) => void, iconName?: string) {
    this.active = active;
    this.from = from;
    this.to = to;
    this.key = key;
    this.action = action;
    this.iconName = iconName;
  }
}

export enum Currencies {
  huf = "HUF",
  eur = "EUR",
}

export const BASE_CURRENCY = Currencies.huf

export enum DeliveryType {
  DELIVERY = "DELIVERY",
  ONSITE = "ONSITE"
}

export enum SubscriptionType {
  Newsletter = 'NEWSLETTER'
}

export enum HistoryStatus {
  DEPRECATED = "DEPRECATED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum UserRole {
  CUSTOMER = 'CUSTOMER',
  OPERATOR = 'OPERATOR',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUPERADMIN = 'SUPERADMIN',
}

export enum OrderStatus {
  ORDERED = 'ORDERED',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  BEING_PREPARED = 'BEING_PREPARED',
  PREPARED = 'PREPARED',
  PACKAGE_BEING_CHECKED = 'PACKAGE_BEING_CHECKED',
  PACKAGE_CHECKED = 'PACKAGE_CHECKED',
  AWAITING_ONSITE_PICKUP = 'AWAITING_ONSITE_PICKUP',
  AWAITING_COURIER_PICKUP = 'AWAITING_COURIER_PICKUP',
  IN_TRANSIT = 'IN_TRANSIT',
  COMPLETED = 'COMPLETED',
  CANCELLED_BY_USER = 'CANCELLED_BY_USER',
  CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
}

export const CANCELLED_ORDER_STATUSES: OrderStatus[] = [
  OrderStatus.CANCELLED_BY_ADMIN,
  OrderStatus.CANCELLED_BY_USER,
  OrderStatus.FAILED_PAYMENT,
];


export enum PaymentType {
  CASH = "CASH",
  BARION = "BARION"
}

export enum SpecialPriceType {
  packaging = "packaging",
  extra = "extra"
}

export class MultiSelectElement {
  label: string;
  value: any;
  element: any;

  constructor(label: string, value: any, element: any) {
    this.label = label;
    this.value = value;
    this.element = element;
  }

}

export enum PaymentStatus {
  Prepared = "Prepared",
  Started = "Started",
  InProgress = "InProgress",
  Waiting = "Waiting",
  Reserved = "Reserved",
  Authorized = "Authorized",
  Canceled = "Canceled",
  CANCELLED = "Canceled",
  Succeeded = "Succeeded",
  Failed = "Failed",
  PartiallySucceeded = "PartiallySucceeded",
  Expired = "Expired",
}

export enum InvoiceDocumentType {
  ADVANCE = "advance",
  CANCELLATION = "cancellation",
  CERT_OF_COMPLETION = "cert_of_completion",
  D_CERT_OF_COMPLETION = "d_cert_of_completion",
  DOSSIER = "dossier",
  DRAFT = "draft",
  DRAFT_OFFER = "draft_offer",
  DRAFT_ORDER_FORM = "draft_order_form",
  DRAFT_WAYBILL = "draft_waybill",
  INVOICE = "invoice",
  MODIFICATION = "modification",
  OFFER = "offer",
  ORDER_FORM = "order_form",
  PROFORMA = "proforma",
  RECEIPT = "receipt",
  RECEIPT_CANCELLATION = "receipt_cancellation",
  WAYBILL = "waybill"
}


export enum PartOfMonth {
  START = 'START',
  MIDDLE = 'MIDDLE',
  END = 'END',
}

export const PartOfMonthByIndex: { [key: string]: PartOfMonth } = {
  1: PartOfMonth.START,
  2: PartOfMonth.MIDDLE,
  3: PartOfMonth.END
}

export const DELIVERY_MULTI_SELECT_ARRAY = Object.keys(DeliveryType).map(deliveryType => new MultiSelectElement(`general.${deliveryType}`, deliveryType, deliveryType));
export const PRICING_MULTI_SELECT_ARRAY = Object.keys(PaymentType).map(pricingType => new MultiSelectElement(`general.${pricingType}`, pricingType, pricingType));


