<div class="row pt-3 g-0">
  <div class="col-10 mx-auto bg-gradient-light box-shadow border-radius-lg">
    <ng-container *ngIf="cartElement">
      <div class="row py-3">
        <div class="col-3 col-xs-2 px-3 position-relative {{isLeft?'order-first':'order-last'}}">
          <img loading="lazy"
               class="img border-radius-lg max-width-300 max-height-180 w-100 position-relative z-2  {{!mobile?'ml-n4':''}}"
               [src]="cartElement.product.mainImage.attachment.src" alt="{{cartElement.product.product.name}}"
               (error)="cartElement.product.mainImage.attachment.src = 'assets/img/default.jpg'">
        </div>
        <div class="col-9 col-xs-10 z-index-2 position-relative  px-3 my-md-auto mt-4">
          <div class="row  cart-actions">
            <div class="col-12 text-end align-items-end pe-5">
              <h4 nxHeadline="subsection-medium" class="text-end " *ngIf="cartElement.product">
                <span
                  class="small-text me-2">{{ 'order.inStock' | translate }} {{ cartElement.product.stockCountString }}</span>{{ cartElement.product.product.name }}
              </h4>
            </div>
            <div class="col-12 text-end align-items-end pe-5">
              <div class="d-flex justify-content-end">
                <div class="d-flex flex-column justify-content-end align-items-end">
                  <p class="mb-0 text-xs">

                    <button nxPlainButton [disabled]="cartElement.product.stockCount<=cartElement.count"
                            (click)="increment()">
                      <nx-icon name="plus"></nx-icon>
                    </button>
                    <span class="mx-3">{{ cartElement.count }} </span>
                    <button nxPlainButton *ngIf="cartElement.count>1" (click)="decrement()">
                      <nx-icon name="minus"></nx-icon>
                    </button>
                    <button nxPlainButton *ngIf="cartElement.count == 1" (click)="remove()">
                      <nx-icon name="trash"></nx-icon>
                    </button>
                  </p>
                  <p class="text-xs text-secondary mb-0">
                    {{ cartElement.price }} Ft
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="extraElement">
      <div class="row py-3 align-items-center">
        <div class="col-2 col-xs-2 px-3 ps-5 position-relative {{isLeft?'order-first':'order-last'}}">
          <img [src]="'assets/img/'+ extraElement.key+'.svg'" class="avatar avatar-sm me-3 " loading="lazy">
        </div>
        <div class="col-6 col-xs-7 z-index-2 position-relative  px-3 my-md-auto mt-4">
          <h4 nxHeadline="subsection-medium" class="">
            {{ 'cart.extraPrice.' + extraElement.key + '.name' | translate }}
          </h4>
        </div>

        <div class="col-4 col-xs-3 z-index-2 position-relative  px-5 text-end">
          <p class="mb-0 text-xs ">
            <a href="javascript:void(0)"
               class="mx-3">{{ extraElement.count }} {{ 'general.amount' | translate }}</a>
          </p>
          <p class="text-xs text-secondary mb-0">
            {{ extraElement.grossPrice }} Ft
          </p>
        </div>
      </div>
    </ng-container>

  </div>
</div>