import {Injectable, Type} from "@angular/core";
import {Delete, Get, Patch, PathVariable, Post, Put, QueryParam, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {BillingData, EmailData, OrderModel} from "./order";
import {OrderStatus} from "../../utilities/types";
import {Observable} from "rxjs";
import {PostPayload} from "../../models/post-payload.model";

@Injectable({
  providedIn: 'root'
})
export class EmailRepository extends CrudRepository<BillingData> {

  @Post('/emails/general/dynamic-message')
  public sendOrderEmails(@RequestBody() email: EmailData) {
    return this.createRequest().send();
  }

}
