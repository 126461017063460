<ng-container *ngIf="model && componentIsReady">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12,12,12,4,4">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="boxes-stacked"
            title="{{'category.baseInformation' | translate}}"
            [backSupplier]="back()"
            [editSupplier]="edit()"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <app-category-base-panel [model]="model"></app-category-base-panel>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12,12,12,4,4" *ngIf="showDynamicData">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="tag"
            title="{{'category.dynamicAttributes' | translate}}"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <app-dynamic-attribute-panel
                [dynamicAttribute]="model.dynamicAttributes"></app-dynamic-attribute-panel>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12,12,12,4,4" *ngIf="showOrderConfig && model.orderConfig">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="cart-shopping"
            title="{{'category.orderConfig' | translate}}"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <app-category-order-config-panel [model]="model.orderConfig"></app-category-order-config-panel>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12" *ngIf="(products && products.length>0) || model.subcategories.length>0">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="circle-nodes"
            title="{{'category.relations' | translate}}"
          ></page-show-tool-bar>
          <div nxRow class="nx-margin-top-2xs">
            <div nxCol="12">
              <nx-tab-group>
                <nx-tab label="{{'category.subCategory'| translate}}" *ngIf="model.subcategories.length>0">
                  <ng-template nxTabContent>
                    <div nxRow="" class="nx-margin-top-2m">
                      <ng-container *ngFor="let subCategory of model.subcategories">
                        <div nxCol="12,12,6,4" class="nx-margin-bottom-2m">
                          <app-category-element [category]="subCategory" [parent]="model" [recursive]="false"
                                                [actionDef]="[showAction]"></app-category-element>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </nx-tab>

                <nx-tab label="{{'category.product'| translate}}" class="nx-margin-top-2xs"
                        *ngIf="(products && products.length>0)">
                  <ng-template nxTabContent>
                    <div nxRow="" class="nx-margin-top-2xs">
                      <div nxCol="12">
                        <hellp-table id="cat-product-table" [rows]="products" [colDefinitions]="colDef" [actionConfig]="actionDef"
                                     [showAsList]="mobile"></hellp-table>
                      </div>
                    </div>
                  </ng-template>
                </nx-tab>
              </nx-tab-group>

            </div>
          </div>
        </nx-card>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>

<ng-template #priceTpl let-value="value">
  <ng-container *ngIf="value.length>0">
    {{value[0].net}} + {{value[0].vat}}%
  </ng-container>
</ng-template>

<ng-template #statusTpl let-value="value">
  <span
    class="badge-sm {{value == HistoryStatus.DRAFT?'badge-draft':value == HistoryStatus.PUBLISHED?'badge-published':'badge-in-progress'}}">
  {{'enum.' + value | translate}}
    </span>
</ng-template>
