<div nxRow>
  <div nxCol="12,12,12,4">
    <div nxRow="" class="nx-margin-s">

      <div nxCol="12" class="nx-margin-top-s">
        <div class="card border-radius-lg">
          <div [ngClass]="{'bg-gradient-dark':!printMode, 'text-white':!printMode}"
               class="card-header   px-4 py-3">
            <p class="m-0 p-0 d-flex justify-content-between">
              <span>{{ 'order.baseData' | translate }}</span>
            </p>
          </div>
          <div class="card-body px-4 py-3">
            <p>
              <span class="badge-sm {{model.status+'-badge'}}">
                {{ 'enum.' + model.status | translate }}
              </span>
              <span class="ms-3 badge-sm {{model.paymentType+'-badge'}}">
                  <nx-icon *ngIf="model.paymentType == PaymentType.BARION" name="credit-card"></nx-icon>
                  <nx-icon *ngIf="model.paymentType == PaymentType.CASH" name="coins"></nx-icon>
                </span>
              {{ 'enum.' + model.paymentType | translate }}
              <ng-container *ngIf="typeIsChangeable ">
                <button nxPlainButton (click)="openChangePaymentDialog()">
                  <nx-icon name="pencil"></nx-icon>
                </button>
              </ng-container>
              <span class="ms-3 badge-sm {{model.deliveryType+'-badge'}} ">
                  <nx-icon *ngIf="model.deliveryType == DeliveryType.DELIVERY" name="truck"></nx-icon>
                  <nx-icon *ngIf="model.deliveryType == DeliveryType.ONSITE" name="building"></nx-icon>
                </span>
              {{ 'enum.' + model.deliveryType | translate }}
              <ng-container *ngIf="typeIsChangeable">
                <button nxPlainButton (click)="openChangeDeliveryTypeDialog()">
                  <nx-icon name="pencil"></nx-icon>
                </button>
              </ng-container>
            </p>
            <p class="text-small mt-2">
              <nx-icon name="calendar" class="me-2"></nx-icon>
              <span class="nx-font-weight-semibold">{{ 'order.createdAt' | translate }}: </span>
              {{ model.createdAt | date:'yyyy.MM.dd HH:mm' }}
            </p>
            <p class="text-small mt-2">
              <nx-icon name="comment" class="me-2"></nx-icon>
              <span class="nx-font-weight-semibold">{{ 'order.messageOfOrder' | translate }}: </span>
              {{ model.message }}
            </p>
            <ng-container *ngIf="model.invoices && model.invoices.length>0">
              <p class="text-small mt-2">
                <nx-icon name="file-invoice" class="me-2"></nx-icon>
                <span class="nx-font-weight-semibold">{{ 'order.invoicesTitle' | translate }}</span>
              </p>
              <ul nxList>
                <ng-container *ngFor="let invoice of model.invoices | arraySort:'createdAt'">
                  <li>
                    <a [href]="invoice.billingoInvoice.publicUrl" target="_blank"
                       style="{{invoice.billingoInvoice.correctionType === 'CANCELED'?'text-decoration: line-through':''}}">
                      {{ invoice.billingoInvoice.documentNumber }}
                      {{ 'invoice.type.' + invoice.billingoInvoice.documentType | translate }}</a>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="model.paymentType == PaymentType.BARION && model.payment && model.payment.barionPayments.length>0">
        <div nxCol="12" class="nx-margin-top-s">
          <div class="card border-radius-lg">
            <div [ngClass]="{'bg-gradient-dark':!printMode, 'text-white':!printMode}"
                 class="card-header px-4 py-3">
              <p class="m-0 p-0 d-flex justify-content-between">
                <span>{{ 'order.paymentInfo' | translate }}</span>
              </p>
            </div>
            <div class="card-body px-4 py-3">
              <ng-container *ngFor="let barionPayment of model.payment.barionPayments">
                <p class="text-small mt-2">
                  <nx-icon name="cash-register" class="me-2"></nx-icon>
                  <span class="nx-font-weight-semibold">{{ 'order.paymentId' | translate }}: </span>
                  {{ barionPayment.paymentId }}
                  <br>
                  <nx-icon name="chart-simple" class="me-2"></nx-icon>
                  <span class="nx-font-weight-semibold">{{ 'order.paymentState' | translate }}: </span>
                  {{ 'order.paymentStatus.' + barionPayment.status | translate }}
                  <br>
                  <nx-icon name="clock" class="me-2"></nx-icon>
                  <span
                    class="nx-font-weight-semibold">{{ barionPayment.showDateLabel | translate }}
                    : {{ barionPayment.showDate | date:'yyyy.MM.dd HH:mm:ss' }}</span>
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="model.paymentType == PaymentType.CASH && model.payment && model.payment.cashPayments.length>0">
        <div nxCol="12" class="nx-margin-top-s">
          <div class="card border-radius-lg">
            <div [ngClass]="{'bg-gradient-dark':!printMode, 'text-white':!printMode}"
                 class="card-header px-4 py-3">
              <p class="m-0 p-0 d-flex justify-content-between">
                <span>{{ 'order.paymentInfo' | translate }}</span>
              </p>
            </div>
            <div class="card-body px-4 py-3">
              <ng-container *ngFor="let cashPayment of model.payment.cashPayments">
                <p class="text-small mt-2">
                  <nx-icon name="cash-register" class="me-2"></nx-icon>
                  <span class="nx-font-weight-semibold">{{ 'order.paymentId' | translate }}: </span>
                  {{ cashPayment.paymentId }}
                  <br>
                  <nx-icon name="chart-simple" class="me-2"></nx-icon>
                  <span class="nx-font-weight-semibold">{{ 'order.paymentState' | translate }}: </span>
                  {{ 'order.paymentStatus.' + cashPayment.status | translate }}
                  <br>
                  <nx-icon name="clock" class="me-2"></nx-icon>
                  <span
                    class="nx-font-weight-semibold">{{ 'order.payment.completedAt' | translate }}
                    : {{ cashPayment.completedAt | date:'yyyy.MM.dd HH:mm:ss' }}</span>
                  <br>
                  <nx-icon name="ban" class="me-2"></nx-icon>
                  <span
                    class="nx-font-weight-semibold">{{ 'order.payment.canceledAt' | translate }}
                    : {{ cashPayment.canceledAt | date:'yyyy.MM.dd HH:mm:ss' }}</span>
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="model.deliveryType == DeliveryType.DELIVERY && model.shipment">
        <div nxCol="12" class="nx-margin-top-s">
          <div class="card border-radius-lg">
            <div [ngClass]="{'bg-gradient-dark':!printMode, 'text-white':!printMode}"
                 class="card-header px-4 py-3">
              <p class="m-0 p-0 d-flex justify-content-between">
                <span>{{ 'order.shipmentInfo' | translate }}</span>
                <ng-container *ngIf="model.shipment?.deliveryPartner?.type != DeliveryPartnerType.SPRINTER">
                  <button nxPlainButton (click)="refreshDeliveryStatus()">
                    <nx-icon class="text-white" name="refresh"></nx-icon>
                  </button>
                </ng-container>
              </p>
            </div>
            <div class="card-body px-4 py-3">
              <div class="row">
                <div class="col-md-8">
                  <ng-container *ngIf="model.shipment">
                    <p class="text-small mt-2">
                      <nx-icon name="cash-register" class="me-2"></nx-icon>
                      <span class="nx-font-weight-semibold">{{ 'order.shipmentId' | translate }}
                        : </span>{{ model.shipment.activeShipmentId }}
                      <br>
                      <nx-icon name="chart-simple" class="me-2"></nx-icon>
                      <span class="nx-font-weight-semibold">{{ 'order.shipmentState' | translate }}: </span>
                      {{ model.shipment.lastKnownStatus === 'ORDERED' ? ('enum.ORDERED' | translate) : (model.shipment.lastKnownStatus | translate) }}
                      <br>
                      <nx-icon name="clock" class="me-2"></nx-icon>
                      <span
                        class="nx-font-weight-semibold">{{ model.shipment.lastKnownStatusDateTime | date:'yyyy.MM.dd HH.mm.ss' }}</span>
                      <br><br>
                      <button nxPlainButton
                              [downloadFile]="{url:shipmentLabelUrl, fileName:model.shipment.activeShipmentId}">
                        {{ 'order.shipmentLabelPrint' | translate }}
                      </button>
                    </p>
                  </ng-container>
                </div>
                <div class="col-md-4">
                  <img src="{{model.shipment.deliveryPartner.logo.srcWidth}}" style="width: 80px; height: auto;"
                       alt="{{model.shipment.deliveryPartner.name}}">
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
  <div nxCol="12,12,12,8">
    <div nxRow="" class="nx-margin-s">
      <div nxCol="12,12,12,6" class="nx-margin-top-s">
        <display-order-billing-data [(billingData)]="model.billingData"
                                    title="{{'cart.summary.billing' | translate}}"
                                    [isEditable]="billingDataEditable"
                                    [printMode]="printMode"
        ></display-order-billing-data>
      </div>
      <div nxCol="12,12,12,6" class="nx-margin-top-s">
        <display-order-billing-data [billingData]="model.deliveryInfo"
                                    [printMode]="printMode"
                                    (billingDataChange)="refreshDeliveryData($event)"
                                    [isEditable]="deliveryDataEditable && model.deliveryType == DeliveryType.DELIVERY"
                                    title="{{'cart.summary.delivery' | translate}}"
                                    [mode]="model.deliveryType==DeliveryType.ONSITE?'onsite':'delivery'"
        ></display-order-billing-data>
      </div>
      <div nxCol="12" class="nx-margin-top-s">
        <display-cart-table [order]="model" [isEditable]="cartIsEditable"
                            [purchaseInfo]="purchaseInfo"></display-cart-table>
      </div>
    </div>
  </div>
</div>

<ng-template #paymentChangeTpl>
  <div class="centered-content">
    <h3 nxHeadline="subsection-medium" class="nx-modal-margin-bottom">
      {{ 'order.changePaymentType' | translate }}
    </h3>
    <p nxCopytext="small" class="nx-modal-margin-bottom">
      <ng-container *ngIf="model.paymentType == PaymentType.BARION">
    <p class="text-warning bg-darker p-3" *ngIf="model.payment?.isPending">
      {{ 'order.isInPendingWindow'| translate }}
    </p>
    <button nxPlainButton
            (click)="toCash()">{{ 'order.toCash' | translate }}
    </button>
    </ng-container>
    <ng-container *ngIf="model.paymentType == PaymentType.CASH">

      <button nxPlainButton (click)="toBarion()">
        {{ 'order.toBarion' | translate }}
      </button>
    </ng-container>
    </p>
    <div class="two-button-container">
      <button
        nxPlainButton
        (click)="closeTemplateDialog()"
        class="nx-margin-right-s"
      >
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #deliveryTypeChangeTpl>
  <div class="centered-content">
    <h3 nxHeadline="subsection-medium" class="nx-modal-margin-bottom">
      {{ 'order.changeDeliveryType' | translate }}
    </h3>
    <p nxCopytext="small" class="nx-modal-margin-bottom">
      <button nxPlainButton *ngIf="model.deliveryType == DeliveryType.ONSITE"
              (click)="toDelivery()">{{ 'order.toDelivery' | translate }}
      </button>
      <button nxPlainButton *ngIf="model.deliveryType == DeliveryType.DELIVERY"
              (click)="toOnsite()">{{ 'order.toOnsite' | translate }}
      </button>
    </p>
    <div class="two-button-container">
      <button
        nxPlainButton
        (click)="closeTemplateDialog()"
        class="nx-margin-right-s"
      >
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>
