import {Component, Input, OnInit} from '@angular/core';
import {Utilities} from "../../../utilities/utilities";

@Component({
  selector: 'panel-youtube-video',
  templateUrl: './youtube-video-panel.component.html',
  styleUrls: ['./youtube-video-panel.component.css']
})
export class YoutubeVideoPanelComponent implements OnInit {
  @Input() videoUrl = '';
  @Input() width = 560;
  @Input() height = 315;
  embeddedUrl?: string;


  ngOnInit(): void {
    this.embeddedUrl = this.getEmbedUrl();
  }

  getEmbedUrl(): string {
    const videoId = Utilities.extractVideoId(this.videoUrl);
    return `https://www.youtube.com/embed/${videoId}`;
  }

}
