import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {finalize, Observable} from "rxjs";
import {LoaderService} from "./layouts/loader/loader.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AppInterceptor {
  public static readonly SILENT_REQUEST_HEADER_KEY: string = 'x-hellp-silent-request';

  constructor(public loaderService: LoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone(
      this.requestHeaderUpdate(req)
    );
    return next.handle(clonedRequest).pipe(
      finalize(() => this.loaderService.hide())
    );
  }

  private requestHeaderUpdate(req: HttpRequest<any>) {
    let tmpHeaders = req.headers;
    if (!req.headers.has(AppInterceptor.SILENT_REQUEST_HEADER_KEY)) {
      this.loaderService.show();
    } else {
      tmpHeaders = req.headers.delete(AppInterceptor.SILENT_REQUEST_HEADER_KEY);
    }
    if (req.url.includes('ngrok')) {
      return {
        headers: tmpHeaders
          .set('Accept-Language', 'hu-HU')
          .set('ngrok-skip-browser-warning', 'true')
      };
    }
    return {
      headers: tmpHeaders
        .set('Accept-Language', 'hu-HU')
    };
  }
}
