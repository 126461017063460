
<div nxLayout="grid">

    <div nxRow="" nxRowAlignItems="center">
        <div nxCol="5">
            <nx-formfield [nxLabel]="'dynamicAttribute.key' | translate" appearance="auto" >
                <input nxInput type="text" [(ngModel)]="key" />
            </nx-formfield>
        </div>
        <div nxCol="6">
            <nx-formfield [nxLabel]="'dynamicAttribute.value' | translate" appearance="auto">
                <input nxInput type="text" [(ngModel)]="value" />

            </nx-formfield>
        </div>
        <div nxCol="1">
            <button nxFormfieldAppendix  nxIconButton="primary small" type="button" (click)="add()">
                <nx-icon name="plus"></nx-icon>
            </button>
        </div>
    </div>

    <ng-container *ngFor="let dynamicEntry of dynamicAttribute | keyvalue">
        <div nxRow="" nxRowAlignItems="center">
            <div nxCol="5">
                <nx-formfield [nxFloatLabel]="'dynamicAttribute.key' | translate" appearance="auto">
                    <input nxInput type="text" [(ngModel)]="dynamicEntry.key" disabled/>
                </nx-formfield>
            </div>
            <div nxCol="6">
                <nx-formfield [nxFloatLabel]="'dynamicAttribute.value' | translate" appearance="auto">
                    <input nxInput type="text" [(ngModel)]="dynamicEntry.value" disabled/>

                </nx-formfield>
            </div>
            <div nxCol="1">
                <button  nxIconButton="primary danger small" type="button" (click)="remove(dynamicEntry.key)">
                    <nx-icon name="minus"></nx-icon>
                </button>
            </div>
        </div>
    </ng-container>
</div>
