import {Component, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ShowPage} from "../../../utilities/abstract/show-page";
import {
  SupplierOrder,
  SupplierOrderElement,
  SupplierOrderStatus
} from "../../../services/supplier-order/supplier-order.model";
import {SupplierOrderDtoType, SupplierOrderService} from "../../../services/supplier-order/supplier-order.service";
import {ActivatedRoute} from "@angular/router";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {AddAttachmentModalInput} from "../../../components/dialogs/add-image-to-model/add-image-to-model.component";
import {Utilities} from "../../../utilities/utilities";
import {AttachmentModel, EntityAttachment} from "../../../services/attachment/attachment.model";
import {take, takeUntil} from "rxjs";
import {SupplierOrderInvoice} from "../../../services/supplier-order/supplier-order-invoice.model";
import {
  SupplierOrderInvoiceDialogComponent
} from "../../../components/dialogs/supplier-order-invoice-dialog/supplier-order-invoice-dialog.component";
import {AddPdfToModelComponent} from "../../../components/dialogs/add-pdf-to-model/add-pdf-to-model.component";

@Component({
  selector: 'app-supplier-order-show',
  templateUrl: './supplier-order-show.component.html',
  styleUrls: ['./supplier-order-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.SUPPLIER_ORDER,
})
export class SupplierOrderShowComponent extends ShowPage<SupplierOrder, SupplierOrderService> implements OnInit {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  showImgUploader = false;
  showFiles = false;
  supplierOrderInvoices?: SupplierOrderInvoice[];
  loadInvoice: boolean = true;

  constructor(service: SupplierOrderService, route: ActivatedRoute, private readonly dialogService: NxDialogService) {
    super(service, route);
  }

  ngOnInit(): void {
    this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
  }

  protected override initProcess() {
    this.model.warehouse.products.forEach(product => {
      const a: SupplierOrderElement | undefined = this.model.elements.find(e => e.supplierProduct.id == product.product.supplierProduct.id);
      if (a) {
        a.product = this.parser.parseApiDataToModel(product.product, ProductHistoryModel);
        if (a.status == SupplierOrderStatus.DRAFT) {
          a.countArrived = a.count;
        }
      }
    });
    this.service.getSupplierOrderInvoice(this.model.id).pipe(
      takeUntil(this._destroyed)
    ).subscribe(result => {
      this.supplierOrderInvoices = result;
      this.loadInvoice = false;
    });
  }

  approveElement(element: SupplierOrderElement) {
    this.service.approveElementOld(this.model.id, element, element.countArrived).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'supplierOrder.successApprove');
      this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
    })
  }

  openPdfUpload() {
    this.dialogService.open(
      AddPdfToModelComponent,
      Utilities.getDefaultModalConfig(new AddAttachmentModalInput(this.model, true, this.model.elements.length)),
    ).afterClosed().subscribe(result => {
      if (Array.isArray(result)) {
        this.selectedAttachmentsChanges(result);
      }
    });
  }

  selectedAttachmentsChanges(attachments: AttachmentModel[]) {
    this.model.attachments = [];
    attachments.forEach(attachment => {
      const historyAttachment = new EntityAttachment();
      historyAttachment.attachment = attachment;
      this.model.attachments.push(historyAttachment)
    });
    this.uploadPdfs();
  }

  uploadPdfs() {
    const tpm = new SupplierOrder();
    tpm.attachments = this.model.attachments;
    tpm.id = this.model.id;
    this.service.uploadPdfs(tpm).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'supplierOrder.successUpload');
      this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
    })
  }

  onOpenInvoiceDialog() {
    if (this.supplierOrderInvoices == undefined || this.supplierOrderInvoices.length < 1) {
      this.service.createInvoiceData(this.model.id, this.model.currency.toString()).pipe(
        take(1)
      ).subscribe(result => {
        this.supplierOrderInvoices = [];
        this.openInvoiceDialog(result);
      });
    } else {
      this.openInvoiceDialog(this.supplierOrderInvoices[0]);
    }
  }

  private openInvoiceDialog(supplierOrderInvoice: SupplierOrderInvoice) {
    this.dialogService.open(
      SupplierOrderInvoiceDialogComponent,
      Utilities.getDefaultModalConfig(supplierOrderInvoice),
    ).afterClosed().subscribe(result => {
      if (result instanceof SupplierOrderInvoice) {
        this.service.updateSupplierInvoice(result.id, result).pipe(
          takeUntil(this._destroyed)
        ).subscribe(updateRes => {
          if (this.supplierOrderInvoices) {
            this.supplierOrderInvoices[0] = result;
            this.service.notificationService.showSuccess('general.success', 'supplierInvoice.successUpdate');
          } else {

            this.service.notificationService.showWarning('general.warning', 'supplierInvoice.refreshPage');
          }
        });
      }
    });
  }
}
