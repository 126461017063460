import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormPage } from '../../../utilities/abstract/form-page';
import { ActivatedRoute } from '@angular/router';
import { ContactModel } from '../../../services/contact/contact.model';
import { ContactDtoTypes, ContactService } from '../../../services/contact/contact.service';
import { Page } from '@hellp/routing';
import { ROUTING } from '../../../utilities/routing';
import { filter, takeUntil } from 'rxjs';

enum EditType {
  Base = 'Base',
  BaseInfo = 'BaseInfo',
  OpeningHours = 'OpeningHours',
  DeliveryHours = 'DeliveryHours',
}

interface ContactCardDescriber {
  type: EditType;
  title: string;
  editable: boolean;
  template: () => TemplateRef<any>;
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
@Page({
  path: [''],
  pathPrefix: ROUTING.CONTACT,
})
export class ContactFormComponent extends FormPage<ContactModel, ContactService> implements OnInit, OnDestroy {
  protected readonly EditType = EditType;
  @ViewChild('base') baseTpl!: TemplateRef<any>;
  @ViewChild('openingHour') openingHourTpl!: TemplateRef<any>;
  @ViewChild('hqDeliveryHours') hqDeliveryHoursTpl!: TemplateRef<any>;
  @ViewChild('info') infoTpl!: TemplateRef<any>;

  hqFields: any = this.service.formlyService.contactInfoFormConfig;

  contactCard: ContactCardDescriber[] = [
    {
      type: EditType.Base,
      title: 'contact.contact',
      editable: false,
      template: () => this.baseTpl,
    },
    {
      type: EditType.BaseInfo,
      title: 'contact.info',
      editable: false,
      template: () => this.infoTpl,
    },
    {
      type: EditType.OpeningHours,
      title: 'contact.openingHours',
      editable: false,
      template: () => this.openingHourTpl,
    },
    {
      type: EditType.DeliveryHours,
      title: 'contact.hqDeliveryHours',
      editable: false,
      template: () => this.hqDeliveryHoursTpl,
    },
  ];

  constructor(service: ContactService, route: ActivatedRoute) {
    super(route, service, ContactModel, service.formlyService.contactFormConfig);
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected override afterModelIsSetProcess() {
    this.componentIsReady = true;
  }

  protected override savePostProcess() {
    this.contactCard.forEach((card) => (card.editable = false));
  }

  loadData() {
    this.service
      .getAllByDeleted(ContactDtoTypes.ContactDTO)
      .pipe(
        takeUntil(this._destroyed),
        filter((result) => !!result && result.length > 0),
      )
      .subscribe((result) => {
        this.formModel = result[0];
        this.afterModelIsSetProcess();
      });
  }

  editToggle(type: EditType) {
    const find = this.contactCard.find((card) => card.type == type);
    if (find) {
      find.editable = !find.editable;
    }
  }
}
