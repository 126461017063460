import {Post, RepoPath, RequestBody} from "@hellp/repository";
import {Injectable} from "@angular/core";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {PostPayload} from "../../models/post-payload.model";
import {UserModel} from "./user.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/users')
export class UserRepository<MODEL> extends CrudRepository<MODEL> {
  @Post('/')
  public registration(@RequestBody() payload: PostPayload<UserModel>): Observable<UserModel> {
    return this.createRequest().withModel(UserModel).send();
  }

}
