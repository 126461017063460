import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Cart, OrderModel} from "../../../services/order/order";
import {environment} from "../../../../environments/environment";
import {Utilities} from "../../../utilities/utilities";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {EditOrderDialogComponent} from "../../dialogs/edit-order-dialog/edit-order-dialog.component";
import {PurchaseInfoModel} from "../../../services/purchase-info/purchase-info.model";
import {OrderService} from "../../../services/order/order.service";

@Component({
  selector: 'display-cart-table',
  templateUrl: './cart-table-display.component.html',
  styleUrls: ['./cart-table-display.component.scss']
})
export class CartTableDisplayComponent implements OnInit {
  protected readonly environment = environment;
  @Input() order!: OrderModel;
  @Input() isEditable = false;
  @Input() purchaseInfo?: PurchaseInfoModel;
  @Output() loadData: EventEmitter<string> = new EventEmitter();

  constructor(private dialogService: NxDialogService, private orderService: OrderService) {
  }

  ngOnInit(): void {
  }

  edit() {
    this.dialogService.open(
      EditOrderDialogComponent,
      Utilities.getDefaultModalConfig({order: this.order, purchaseInfo: this.purchaseInfo})
    ).afterClosed().subscribe(result => {
      if (result instanceof Cart) {
        this.orderService.reCreateCart(this.order.id, result).subscribe(response => {
          this.orderService.$cartRefresh.next(true)
        })
      }
    });
  }
}
