import {HTTP_INTERCEPTORS, HttpBackend, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {NxIconRegistry} from '@aposin/ng-aquila/icon';

import {AppComponent} from './app.component';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {
  AppDateIntl,
  AppDropdownIntl,
  DATE_PICKER_FORMAT,
  HandleError,
  httpTranslateLoader,
  ImplMissingTranslationHandler,
  NOTIFICATION_CONFIG,
  RxStompServiceFactory,
  scrollStrategyFactory
} from "./app.config";
import {HellproutingService} from "@hellp/routing";
import {KeycloakAngularModule} from "keycloak-angular";
import {AdminLayout} from './layouts/admin-layout/admin.layout';
import {NxSidepanelModule} from "@aposin/ng-aquila/sidepanel";
import {ComponentsModule} from "./components/components.module";
import {NxDocumentationIconModule} from '@aposin/ng-aquila/documentation-icons';
import {HellpositoryModule} from "@hellp/repository";
import {PagesModule} from "./pages/pages.module";
import {HellpTableModule} from "@hellp/table";
import {NxContextMenuModule} from "@aposin/ng-aquila/context-menu";
import {NxExpertModule} from "@aposin/ng-aquila/config";
import {FormlyModule} from '@ngx-formly/core';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {environment} from "../environments/environment";
import {LoaderComponent} from "./layouts/loader/loader.component";
import {LoaderService} from "./layouts/loader/loader.service";
import {AppInterceptor} from "./app.interceptor";
import {ServiceLocator} from './utilities/service/service.locator';
import {ProcessorService} from "./services/processor.service";
import {NX_MODAL_DEFAULT_OPTIONS} from "@aposin/ng-aquila/modal";
import {NX_OVERLAY_SCROLL_STRATEGY} from "@aposin/ng-aquila/overlay";
import {Overlay, OverlayModule} from "@angular/cdk/overlay";
import {GALLERY_CONFIG, GalleryConfig} from "ng-gallery";
import {NX_DATE_FORMATS, NX_DATE_LOCALE, NxDatepickerIntl} from '@aposin/ng-aquila/datefield';
import {NxMomentDateModule} from "@aposin/ng-aquila/moment-date-adapter";
import {QuillModule} from 'ngx-quill'
import {RxStompService} from "./utilities/service/rx-stomp.service";
import {HellparserModule} from "@hellp/parser";
import {ErrorHandlerService, HellpserviceModule} from "@hellp/service";
import {ExtendedProcessorService} from "./services/extended-processor.service";
import {NxDropdownIntl} from "@aposin/ng-aquila/dropdown";
import {registerLocaleData} from '@angular/common';
import localeHu from '@angular/common/locales/hu';

registerLocaleData(localeHu, 'hu-HU');
const routes = HellproutingService.getInstance().getRoutes();

@NgModule({
  declarations: [AppComponent, AdminLayout, LoaderComponent],
  imports: [
    OverlayModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'hu',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpBackend],
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: ImplMissingTranslationHandler}
    }),
    HellpositoryModule.forRoot({
      baseApiUrl: environment.serviceUrl,
      errorHandler: {useFactory: HandleError, deps: [ErrorHandlerService]},
    }),
    HellparserModule.forRoot({
      processorServiceProvider: [ProcessorService, ExtendedProcessorService]
    }),
    HellpserviceModule.forRoot({
      notificationConfig: NOTIFICATION_CONFIG,
      storageConfig: {cookieValidityHours: environment.cookiesValidityHours}
    }),
    HellpTableModule.forRoot(),
    NxContextMenuModule,
    KeycloakAngularModule,
    NxSidepanelModule,
    ComponentsModule,
    NxDocumentationIconModule,
    PagesModule,
    NxExpertModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    NxMomentDateModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
          [{'header': 1}, {'header': 2}],               // custom button values
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
          [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
          [{'direction': 'rtl'}],                         // text direction
          [{'header': [3, 4, 5, 6, false]}],
          [{'color': []}, {'background': []}],          // dropdown with defaults from theme
          [{'font': []}],
          [{'align': []}],
          ['clean'],                                         // remove formatting button
          ['link', 'image']                         // link and image, video
        ]
      }
    }),
  ],
  providers: [
    Title, LoaderService,
    {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true},
    {provide: RxStompService, useFactory: RxStompServiceFactory,},
    // {provide: APP_INITIALIZER, useFactory: keycloakInit, deps: [KeycloakService], multi: true},
    {provide: NX_MODAL_DEFAULT_OPTIONS, useValue: {width: '1180px'}},
    {provide: NX_OVERLAY_SCROLL_STRATEGY, useFactory: scrollStrategyFactory, deps: [Overlay]},
    {provide: NX_DATE_FORMATS, useValue: DATE_PICKER_FORMAT},
    {provide: NX_DATE_LOCALE, useValue: 'hu-HU'},
    {provide: GALLERY_CONFIG, useValue: {autoHeight: true, imageSize: 'cover'} as GalleryConfig},
    {provide: NxDropdownIntl, useClass: AppDropdownIntl},
    {provide: NxDatepickerIntl, useClass: AppDateIntl},
    {provide: LOCALE_ID, useValue: 'hu-HU'}
  ],
  bootstrap:
    [AppComponent],
})

export class AppModule {
  constructor(iconRegistry: NxIconRegistry, private injector: Injector) {
    iconRegistry.setDefaultFont('fas');

    iconRegistry.addFontIcon('delivery', 'truck', 'fas');
    iconRegistry.addFontIcon('onSite', 'building', 'fas');
    ServiceLocator.injector = this.injector;
  }
}
