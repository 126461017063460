import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {ListPage} from "../../../utilities/abstract/list-page";
import {TableAction, TableCol} from "@hellp/table";
import {SupplierModel} from "../../../services/supplier/supplier.model";
import {SupplierDtoTypes, SupplierService} from "../../../services/supplier/supplier.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.css']
})
@Page({
  path: 'supplier/',
  data: {title: 'title.suppliers'},
  authGuard: AdminGuard
})
export class SupplierListComponent extends ListPage<SupplierModel, SupplierService> implements OnInit {
  @ViewChild('test') hdrTpl?: TemplateRef<any>;


  constructor(service: SupplierService,) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('companyName', await this.getTranslate('supplier.companyName')),
      TableCol.newString('contactName', await this.getTranslate('supplier.contactName')),
      TableCol.newMailto('contactEmail', await this.getTranslate('supplier.contactEmail')),
      TableCol.newString('contactPhone', await this.getTranslate('supplier.contactPhone')),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(SupplierDtoTypes.SupplierDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }


}
