import {ProductHistoryModel} from "../product/product.model";
import {ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {ExtendedProcessorService} from "../extended-processor.service";
import {BaseModel} from "../../utilities/abstract/base.model";

export enum ProductMatrixRelationType {
  ADVANTAGEOUS = 'ADVANTAGEOUS',
  NEUTRAL = 'NEUTRAL',
  DISADVANTAGEOUS = 'DISADVANTAGEOUS'
}

export class ProductMatrixModel extends BaseModel {
  @ParseTo(ProductHistoryModel)
  productHistoryCol!: ProductHistoryModel;
  @ConvertProcess({processorClass: ExtendedProcessorService, processorFunctionName: 'getHistoryColId'}, "model")
  productHistoryColId = 0;

  @ParseTo(ProductHistoryModel)
  productHistoryRow!: ProductHistoryModel;
  @ConvertProcess({processorClass: ExtendedProcessorService, processorFunctionName: 'getHistoryRowId'}, "model")
  productHistoryRowId = 0;

  type!: ProductMatrixRelationType | null;

  static getInstance(col: ProductHistoryModel, row: ProductHistoryModel, type: ProductMatrixRelationType): ProductMatrixModel {
    const instance = new ProductMatrixModel();
    instance.productHistoryRow = row;
    instance.productHistoryCol = col;
    instance.productHistoryColId = col.id;
    instance.productHistoryRowId = row.id;
    instance.type = type
    return instance;
  }
}