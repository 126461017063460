import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {BillingData} from "../../../services/order/order";
import {NX_MODAL_DATA, NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {HellparserService} from "@hellp/parser";
import {BillingDataFormComponent} from "../../form/billing-data-form/billing-data-form.component";
import {BillingDataService} from "../../../services/order/billing-data.service";
import {lastValueFrom} from "rxjs";
import {UserModel} from "../../../services/user/user.model";
import {UserService} from "../../../services/user/user.service";

export const BILLING_DATA_MODAL_ID = 'addBillingData';

export type Mode = 'billing' | 'delivery' | 'onsite';

@Component({
  selector: 'app-edit-billing-data-dialog',
  templateUrl: './edit-billing-data-dialog.component.html',
  styleUrls: ['./edit-billing-data-dialog.component.css']
})
export class EditBillingDataDialogComponent implements OnInit {
  @ViewChild('billingDataForm') billingDataForm!: BillingDataFormComponent;
  model: BillingData;
  mode: Mode = "billing";
  ref?: NxModalRef<any>;

  constructor(@Inject(NX_MODAL_DATA) public data: { mode: string, model: any },
              private parser: HellparserService,
              private dialogService: NxDialogService,
              private userService: UserService,
              private billingDataService: BillingDataService) {
    this.mode = data.mode as Mode;
    this.model = this.parser.parseApiDataToModel(window.structuredClone(data.model), BillingData);
  }

  ngOnInit(): void {
    this.ref = this.dialogService.getModalById(BILLING_DATA_MODAL_ID);
  }

  async save(): Promise<void> {
    if (this.billingDataForm && this.billingDataForm.billingDataForm && this.billingDataForm.billingDataForm.valid && this.ref) {
      const ref = this.ref;
      if (this.model.id > 0) {
        if (this.mode == 'billing') {
          this.billingDataService.updateBillingData(this.model).subscribe(result => {
            this.billingDataService.notificationService.showSuccess('general.success', 'profile.successBillingDataSave');
            ref.close(this.parser.parseApiDataToModel(result, BillingData));
          });
        }
        if (this.mode == 'delivery') {
          this.billingDataService.updateDeliveryData(this.model).subscribe(result => {
            this.billingDataService.notificationService.showSuccess('general.success', 'profile.successBillingDataSave');
            ref.close(this.parser.parseApiDataToModel(result, BillingData));
          });
        }
      } else {
        if (this.mode == 'billing') {
          this.billingDataService.createBillingDataForUser(this.model).subscribe(result => {
            this.billingDataService.notificationService.showSuccess('general.success', 'profile.successBillingDataSave');
            ref.close(this.parser.parseApiDataToModel(result, BillingData));
          });
        }
        if (this.mode == 'delivery') {
          this.billingDataService.createDeliveryDataForUser(this.model).subscribe(result => {
            this.billingDataService.notificationService.showSuccess('general.success', 'profile.successBillingDataSave');
            ref.close(this.parser.parseApiDataToModel(result, BillingData));
          });
        }
      }
    }
  }
}
