import {BaseModel} from "../../utilities/abstract/base.model";
import {PlantCulture} from "./plant-culture.model";
import {ConvertFrom, ConvertProcess, ParseTo} from "@hellp/parser";
import {PlantCultureIssue} from "./plant-culture-issue.model";
import {PartOfMonth} from "../../utilities/types";
import {EntityTag, ProductHistoryModel, Tag} from "../product/product.model";
import {ProcessorService} from "../processor.service";
import {ExtendedProcessorService} from "../extended-processor.service";
import {Month} from "../warehouse/warehouse.model";
import {PlantCultureIssueType} from "./plant-culture-issue-type.model";


export class SuggestedProduct {
  @ParseTo(ProductHistoryModel)
  productHistory!: ProductHistoryModel;
  @ConvertProcess({processorClass: ExtendedProcessorService, processorFunctionName: 'getHistoryId'}, "model")
  productHistoryId = 0;

  cultureIssuePairing!: CultureIssuePairingModel;
  dosage = '';
  orderNumber = 1;
}


export class CultureIssuePairingModel extends BaseModel {
  @ParseTo(PlantCulture)
  plantCulture!: PlantCulture
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'plantCultureId'}, "model")
  plantCultureId?: number = 0;

  @ParseTo(PlantCultureIssue)
  plantCultureIssue!: PlantCultureIssue;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'plantCultureIssueId'}, "model")
  plantCultureIssueId?: number = 0;

  @ParseTo(PlantCultureIssueType)
  plantCultureIssueType!: PlantCultureIssueType;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'plantCultureIssueTypeId'}, "model")
  plantCultureIssueTypeId?: number = 0;

  description = '';

  monthStart: Month = Month.JANUARY;
  partOfMonthStart: PartOfMonth = PartOfMonth.START;
  monthEnd: Month = Month.JANUARY;
  partOfMonthEnd = PartOfMonth.END;

  youtubeLinks: string[] = [];

  @ParseTo(EntityTag)
  @ConvertFrom
  tags: EntityTag[] = [];

  @ParseTo(SuggestedProduct)
  @ConvertFrom
  suggestedProducts: SuggestedProduct[] = [];


  get tagList(): Tag[] {
    return this.tags.map(t => t.tag);
  }

  get startOfMonth(): string[] {
    return [this.monthStart, this.partOfMonthStart];
  }

  get endOfMonth(): string[] {
    return [this.monthEnd, this.partOfMonthEnd];
  }

  get sortedSuggestedProducts(): SuggestedProduct[] {
    return this.suggestedProducts.sort((a, b) => a.orderNumber > b.orderNumber ? 1 : -2)
  }
}
