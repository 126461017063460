import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {FormToolBarOption} from "../../../components/form-header/form-tool-bar-option.model";
import {ActivatedRoute} from "@angular/router";
import {TagDtoTypes, TagService} from "../../../services/tag/tag.service";
import {Tag} from "../../../services/product/product.model";

@Component({
  selector: 'app-edit-tag',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss']
})
@Page({
  path: [''],
  pathPrefix: ROUTING.TAG,
})
export class TagFormComponent extends FormPage<Tag, TagService> implements OnInit, OnDestroy {

  toolbarAddOptions: FormToolBarOption[];
  tags: Tag[] = [];

  constructor(service: TagService, route: ActivatedRoute,) {
    super(route, service, Tag, []);
    this.toolbarAddOptions = [];
  }

  ngOnInit(): void {
    this.service.getAllByDeleted(TagDtoTypes.TagDTO).subscribe(result => {
      this.tags = result;
      setTimeout(() => {
        this.componentIsReady = true
      }, 600);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }
}
