import {BaseModel, Slugable} from "../../utilities/abstract/base.model";
import {AttachmentModel, EntityAttachment} from "../attachment/attachment.model";
import {PriceModel} from "../../models/price.model";
import {CategoryModel, OrderConfigModel} from "../category/category.model";
import {SupplierModel} from "../supplier/supplier.model";
import {ConvertFrom, ConvertProcess, IgnoreProperty, ParseProcess, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../processor.service";
import {BASE_CURRENCY, HistoryStatus, StringStringMap} from "../../utilities/types";
import {Utilities} from "../../utilities/utilities";
import {EntityRelatedProduct} from "src/app/models/entity-relation-product";
import {StockProduct} from "../warehouse/warehouse.model";


export type TagType = 'primary' | 'secondary' | 'base';

export class Tag extends BaseModel {
  slug: string;

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'tagToObject'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'tagToString'})
  html: TagHtml
  name: string;
  isKeyword = false;

  constructor(html?: TagHtml, name?: string, isKeyword?: boolean) {
    super();
    this.html = html ?? {type: "primary", nxTaglistLabel: 'unknown'};
    this.name = name ?? '';
    this.slug = Utilities.generateSlug(this.html.nxTaglistLabel, Date.now().toString());
    this.isKeyword = isKeyword ?? false;
  }
}

export interface TagHtml {
  type: TagType;
  nxTaglistLabel: string;
}

export class EntityTag {

  constructor(tag?: Tag) {
    this.tag = tag ?? new Tag();
  }

  @ParseTo(Tag)
  tag!: Tag;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getTagId'}, "model")
  tagId = 0;
}


export class SupplierProduct extends BaseModel {

  @ParseTo(SupplierModel)
  @IgnoreProperty
  supplier!: SupplierModel;

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'supplierId'}, "model")
  supplierId = 0;

  name = ''
  sku = '';
  itemNr = '';

  @ParseTo(PriceModel)
  @ConvertFrom
  prices: PriceModel[] = [];

  @IgnoreProperty
  companyName: string = '';

  get basePrice(): PriceModel {
    const find = this.prices.find(p => p.currency == BASE_CURRENCY);
    return find ?? this.prices[0];
  }
}

export class ProductHistoryModel extends BaseModel {

  elementOfProductMatrix = false;

  published?: ProductModel;

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'convertProductToApi'})
  draft!: ProductModel;

  @ParseTo(SupplierProduct)
  @ConvertFrom
  supplierProduct!: SupplierProduct;

  @ParseTo(CategoryModel)
  @IgnoreProperty
  category!: CategoryModel;
  categoryId = -1;

  @ParseTo(EntityAttachment)
  mainImage: EntityAttachment = new EntityAttachment();

  @ParseTo(EntityAttachment)
  images: EntityAttachment[] = []

  @ParseTo(EntityTag)
  @ConvertFrom
  tags: EntityTag[] = [];

  @ConvertFrom
  relatedProducts: EntityRelatedProduct[] = [];

  dynamicAttributes: StringStringMap = {}

  @ParseTo(StockProduct)
  stockProducts: StockProduct[] = [];

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'ifEmptySetNull'})
  orderConfig: OrderConfigModel = new OrderConfigModel();


  // @ConvertProcess({processorClass: ExtendedProcessorService, processorFunctionName: 'getProductCartExclusive'}, "model")
  cartExclusive = false;
  hasPublishedVersion = false;

  get product(): ProductModel {
    if (this.published) {
      this.published.status = HistoryStatus.PUBLISHED;
      return this.published;
    }
    this.draft.status = HistoryStatus.DRAFT;
    return this.draft;
  }

  get sku(): string {
    return `${this.category.sku}/${this.product.sku}`;
  }

  get editInProgress(): boolean {
    return this.published != undefined && this.draft != undefined;
  }

  get status(): string {
    if (this.editInProgress) {
      return 'IN_PROGRESS';
    }
    return this.product.status;
  }

  get tagList(): Tag[] {
    return this.tags.map(t => t.tag);
  }

  get packagingConfig() {
    if (this.orderConfig && this.orderConfig.packagingConfig && this.orderConfig.packagingConfig.icePackRanges) {
      return this.orderConfig.packagingConfig;
    }
    if (this.category.orderConfig && this.category.orderConfig.packagingConfig && this.category.orderConfig.packagingConfig.icePackRanges) {
      return this.category.orderConfig.packagingConfig
    }
    return undefined;
  }

  get stockCountString(): string {
    this.stockProducts = this.stockProducts.sort((a, b) => a.warehouseName > b.warehouseName ? 1 : -1);
    return this.stockProducts.map((stock: StockProduct) => `${stock.warehouseName}: ${stock.count} db`).join(', ');
  }

  get stockCount(): number {
    let count = 0;
    this.stockProducts.forEach((stock: StockProduct) => {
      count += stock.count;
    });
    return count;
  }

  get hasPublished(): boolean {
    return this.published != undefined || this.hasPublishedVersion;
  }


  public static createNewInstanceFromSelf(self: ProductHistoryModel, draft: ProductModel): ProductHistoryModel {
    const that = new ProductHistoryModel();
    that.images = self.images;
    that.category = self.category;
    that.categoryId = self.categoryId;
    that.mainImage = self.mainImage;
    if (draft.status == HistoryStatus.PUBLISHED) {
      that.published = draft;
    } else {
      that.draft = draft;
    }
    that.tags = self.tags;
    that.orderConfig = self.orderConfig;
    that.relatedProducts = self.relatedProducts;
    that.dynamicAttributes = self.dynamicAttributes;
    that.stockProducts = self.stockProducts;
    return that;
  }

  public static createNewForCreateDraft(self: ProductHistoryModel, draft: any): ProductHistoryModel {
    delete draft.id;
    delete draft.dimensions.id;
    if (draft.prices) {
      draft.prices.forEach((price: any) => delete price.id);
    }
    const that = new ProductHistoryModel();
    that.draft = draft;
    that.id = self.id;
    return that;
  }

}

export class ProductModel extends BaseModel implements Slugable {

  @ParseTo(ProductHistoryModel)
  @IgnoreProperty
  history: ProductHistoryModel = new ProductHistoryModel();
  historyId!: number;
  @IgnoreProperty
  status: HistoryStatus = HistoryStatus.DRAFT

  name = '';
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'slugGenerator'}, "model")
  slug = '';
  shortDescription = 'Kitöltésre vár';
  description = 'Kitöltésre vár';
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'sku'})
  sku = '';
  itemNr = '';
  cartExclusive = false;
  weightKg = 12;
  dimensions: Dimensions = new Dimensions();

  @ParseTo(PriceModel)
  prices: PriceModel[] = [];

  get basePrice(): PriceModel {
    const find = this.prices.find(p => p.currency == BASE_CURRENCY);
    return find ?? new PriceModel();
  }

  get gallery(): AttachmentModel[] {
    return [this.history.mainImage.attachment].concat(this.getImagesAsAttachment());
  }

  getImagesAsAttachment(): AttachmentModel[] {
    return this.history.images.map(ac => ac.attachment);
  }

  getHistoryId(): number {
    if (this.historyId) {
      return this.historyId;
    }
    if (this.history && this.history.id) {
      return this.history.id;
    }
    return 0;
  }

  get slugField(): string {
    return this.name;
  }

  get short(): string {
    return Utilities.removeHtmlTags(this.shortDescription);
  }

  get hasPublished(): boolean {
    return this.history.hasPublished;
  }

}

export class Dimensions {
  unit = 'cm';
  width = 0;
  height = 0;
  length = 0;
}
