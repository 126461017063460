import {Injectable} from '@angular/core';
import {Patch, PathVariable, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {GoodsLanding} from "./goods-landing.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/goods-landings')
export class GoodsLandingRepository extends CrudRepository<GoodsLanding> {
  constructor() {
    super();
  }

  @Patch('/{id}/elements/{elementId}/approve')
  approveElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/{elementId}/process')
  processElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/process')
  process(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/approve')
  approve(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/update')
  updateElements(@PathVariable() id: number, @RequestBody() goodsLanding: GoodsLanding) {
    return this.createRequest().send();
  }
}
