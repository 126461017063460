<div nxModalContent>
  <nx-card class="w-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'order.sendEmail' | translate }}
    </h3>
    <form #ngForm=ngForm></form>
    <div nxRow>
      <div nxCol="12">
        <nx-formfield nxLabel="{{'order.emailSubject' | translate}}" appearance="auto" nxFloatLabel="auto">
          <input nxInput type="text" [(ngModel)]="model.subject" required/>
        </nx-formfield>

        <h6>{{ 'order.emailText' | translate }}</h6>
        <div class="NgxEditor__Wrapper" style="min-height: 500px">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
          <ngx-editor style="height: 500px" [editor]="editor" name="description" [(ngModel)]="model.message" required
                      [disabled]="false"
                      placeholder="{{'product.description' | translate}}"></ngx-editor>
        </div>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [disabled]="!ngForm.valid"
    [nxModalClose]="model"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.send' | translate }}
  </button>
</div>
