<div nxLayout="grid">
  <div nxRow="">
    <div nxCol="12,12,6">
      <nx-formfield nxLabel="{{'category.name' |translate}}" appearance="auto">
        <input appValidatedInput type="text" nxInput name="name" required [(ngModel)]="model.name"/>
        <nx-error class="nx-margin-bottom-m" nxFormfieldError
                  *ngIf="validationError.name"> {{ validationError.name | translate }}
        </nx-error>
      </nx-formfield>
    </div>

    <div nxCol="12,12,6">
      <nx-formfield nxLabel="{{'category.shortName' |translate}}" appearance="auto">
        <input appValidatedInput type="text" nxInput name="name" required [(ngModel)]="model.shortName"
               (ngModelChange)="model.shortName = model.shortName.toUpperCase()"/>
        <nx-error class="nx-margin-bottom-m" nxFormfieldError
                  *ngIf="validationError.shortName"> {{ validationError.shortName | translate }}
        </nx-error>
      </nx-formfield>
    </div>

    <div nxCol="12,12,6">
      <nx-formfield nxLabel="{{'category.description' |translate}}" appearance="auto">
        <textarea appValidatedInput nxInput name="description" required [(ngModel)]="model.description"></textarea>
        <span nxFormfieldHint>{{ model.description.length }}/500</span>
        <nx-error class="nx-margin-bottom-m" nxFormfieldError
                  *ngIf="validationError.description"> {{ validationError.description | translate }}
        </nx-error>
      </nx-formfield>
    </div>
    <div nxCol="12,12,6">
      <nx-formfield nxLabel="{{'category.parent' |translate}}" appearance="auto">
        <nx-dropdown [(ngModel)]="parentId" (selectionChange)="refreshParent()">
          <nx-dropdown-item
            *ngFor="let parent of parents"
            [nxValue]="parent.id"
          > {{ parent.name }}
          </nx-dropdown-item>
        </nx-dropdown>
      </nx-formfield>
    </div>
  </div>
</div>
