import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {StockProduct, WarehouseModel} from "./warehouse.model";
import {WarehouseRepository} from "./warehouse.repository";
import {StockProductRepository} from "./stockProduct.repository";
import {map, Observable} from "rxjs";
import {PostPayload} from "../../models/post-payload.model";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum WarehouseDtoTypes {
  WarehouseDTO = 'WarehouseDTO',
  SlimWarehouseDTO = 'SlimWarehouseDTO',
  SupplierOrderWarehouseDTO = 'SupplierOrderWarehouseDTO',
  ScrappingWarehouseDTO = 'ScrappingWarehouseDTO',
  InventoryWarehouseDTO = 'InventoryWarehouseDTO',
  StockTransferWarehouseDTO = 'StockTransferWarehouseDTO',
  GoodsLandingWarehouseDTO = 'GoodsLandingWarehouseDTO'
}

export enum StockProductDtoTypes {
  StockProductDTO = 'StockProductDTO',
  StockProductWithProductDTO = 'StockProductWithProductDTO',
}

@Injectable({
  providedIn: 'root'
})
export class WarehouseService extends CrudService<WarehouseModel, WarehouseRepository<WarehouseModel>> {

  constructor(repo: WarehouseRepository<WarehouseModel>, private stockRepository: StockProductRepository<StockProduct>) {
    super(ROUTING.WAREHOUSE, repo, WarehouseModel, PageMetaDataConf.WAREHOUSE);
  }

  getMainHQ(dto: string): Observable<WarehouseModel | undefined> {
    const filter = this.filterService.filter(dto)
      .equal('mainHQ', true)
      .create();
    return this.repo.getAllByFilter(filter.stringify(), WarehouseModel).pipe(map((result: WarehouseModel[]) => {
      if (result.length > 0) {
        return result[0]
      }
      return undefined;
    }));
  }

  getStockProductsForMainHQ(supplierId: number) {
    const filter = this.filterService.filter(StockProductDtoTypes.StockProductWithProductDTO)
      .equal('product_supplierProduct_supplier_id', supplierId)
      .and()
      .equal('warehouse_mainHQ', true)
      .create();
    return this.stockRepository.getAllByFilter(filter.stringify(), StockProduct);
  }

  saveStockProduct(stockProduct: StockProduct, dto: string = StockProductDtoTypes.StockProductDTO): Observable<StockProduct> {
    const payload = new PostPayload(stockProduct, dto);
    return this.stockRepository.save(payload, StockProduct);
  }

  updateStockConfig(result: StockProduct) {
    return this.stockRepository.updateStockConfig(result.id, result);
  }
}

