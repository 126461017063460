import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {ValidatorService} from "../../utilities/service/validator.service";
import {BioCertModel} from "./bio-cert.model";
import {BioCertRepository} from "./bio-cert.repository";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum BioCertDtoTypes {
  BioCertificateDTO = 'BioCertificateDTO'
}

@Injectable({
  providedIn: 'root'
})
export class BioCertService extends CrudService<BioCertModel, BioCertRepository> {

  constructor(repo: BioCertRepository) {
    super(ROUTING.BIO_CERT, repo, BioCertModel,PageMetaDataConf.BIO_CERT);
  }

  override validate(model: BioCertModel) {
    ValidatorService.required(model.attachment, "error.terms.attachmentRequired");
  }

}
