import {ConvertProcess, JsonKeyName, ParseProcess, ParseTo} from "@hellp/parser";
import {AttachmentUrlService} from "../../utilities/service/attachment-url.service";
import {environment} from "../../../environments/environment";
import {ProcessorService} from "../processor.service";
import {BaseModel} from "../../utilities/abstract/base.model";

export class AttachmentModel implements BaseModel {
  id = 0;
  @JsonKeyName('title')
  name!: string;
  mimeType = '';
  filePath = '';
  fileName = '';
  slug = '';
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: "dateProcess"})
  createdAt: Date = new Date();
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: "dateProcess"})
  updatedAt: Date = new Date();
  private _src = '';

  public get isImage(): boolean {
    return this.mimeType.includes('image') && !this.mimeType.includes('svg')
  }

  get icon(): string {
    if (this.mimeType.includes('image')) {
      return 'file-image';
    }
    if (this.mimeType.includes('octet')) {
      return 'file-zipper';
    }
    if (this.mimeType.includes('pdf')) {
      return 'file-pdf'
    }
    if (this.mimeType.includes('video')) {
      return 'file-video';
    }
    return 'file-circle-question';
  }

  get url(): string {
    return AttachmentUrlService.getDownloadUrl(this.slug);

  }

  get src(): string {
    if (this._src.length < 1) {
      return AttachmentUrlService.getScaleHeightImageUrl(this.slug, environment.defaultResizeHeight);
    }
    return this._src
  }

  set src(src: string) {
    this._src = src;
  }

  get srcWidth(): string {
    return AttachmentUrlService.getScaleWidthImageUrl(this.slug, environment.defaultResizeWidth);
  }

  getSrcWidth(size: number) {

    return AttachmentUrlService.getScaleWidthImageUrl(this.slug, size);
  }

  getSrcHeight(size: number) {
    return AttachmentUrlService.getScaleHeightImageUrl(this.slug, size);
  }

  get downloadLink(): string {
    return AttachmentUrlService.getDownloadUrl(this.slug);
  }

}

export class EntityAttachment {
  constructor() {
  }

  @ParseTo(AttachmentModel)
  attachment!: AttachmentModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getAttachmentId'}, "model")
  attachmentId = 0;
}

