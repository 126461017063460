import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
  CANCELLED_ORDER_STATUSES,
  DeliveryType,
  OrderStatus,
  PaymentStatus,
  PaymentType
} from "../../../utilities/types";
import {BillingData, ExtraCartElement, OrderModel} from "../../../services/order/order";
import {DeliveryPartnerType, PurchaseInfoModel} from "../../../services/purchase-info/purchase-info.model";
import {environment} from "../../../../environments/environment";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {OrderDtoTypes, OrderService} from "../../../services/order/order.service";
import {CartService, CASH_PRICE_ID, DELIVERY_PRICE_ID} from "../../../services/order/cart.service";
import {
  BILLING_DATA_MODAL_ID,
  EditBillingDataDialogComponent
} from "../../dialogs/edit-billing-data-dialog/edit-billing-data-dialog.component";
import {Utilities} from "../../../utilities/utilities";
import {UserModel} from "../../../services/user/user.model";

@Component({
  selector: 'display-order-details',
  templateUrl: './order-details-display.component.html',
  styleUrls: ['./order-details-display.component.css']
})
export class OrderDetailsDisplayComponent implements OnInit {
  private readonly CANCELLED_STATUSES: OrderStatus[] = CANCELLED_ORDER_STATUSES;

  // private readonly EDITABLE_STATUSES: OrderStatus[] = [OrderStatus.ORDERED, OrderStatus.BEING_PREPARED];
  private readonly NOT_TYPE_CHANGE_STATUSES: OrderStatus[] = [OrderStatus.COMPLETED, OrderStatus.CANCELLED_BY_ADMIN, OrderStatus.IN_TRANSIT, OrderStatus.AWAITING_COURIER_PICKUP, OrderStatus.AWAITING_ONSITE_PICKUP];
  @Input() model!: OrderModel;
  @Input() printMode = false;
  @Input() purchaseInfo?: PurchaseInfoModel;
  @Output() loadData: EventEmitter<string> = new EventEmitter();
  private cartService?: CartService

  @ViewChild('paymentChangeTpl') paymentChangeTpl!: TemplateRef<any>;
  @ViewChild('deliveryTypeChangeTpl') deliveryTypeChangeTpl!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;

  protected readonly DeliveryType = DeliveryType;
  protected readonly PaymentType = PaymentType;
  protected readonly PaymentStatus = PaymentStatus;
  protected readonly DeliveryPartnerType = DeliveryPartnerType;
  billingDataEditable = true;
  deliveryDataEditable = false;
  cartIsEditable = true;
  typeIsChangeable = true;

  constructor(private readonly dialogService: NxDialogService, private orderService: OrderService,) {
  }

  public get isPayed(): boolean {
    return this.model.paymentType == PaymentType.BARION && this.model.payment && this.model.payment.isCompleted
  }

  ngOnInit(): void {
    this.updateChangeability();

    if (this.purchaseInfo) {
      this.cartService = new CartService(this.model.cart, this.model.paymentType, this.model.deliveryType, this.purchaseInfo);
    }
  }

  private reloadData() {
    this.loadData.emit(OrderDtoTypes.OrderDTO);
    this.updateChangeability();
  }

  public updateChangeability() {
    this.deliveryDataEditable = this.model
      && !this.model.paid
      && !this.CANCELLED_STATUSES.includes(this.model.status)
      && this.model.shipment == undefined;
    this.billingDataEditable = this.model
      && !this.model.paid
      && !this.CANCELLED_STATUSES.includes(this.model.status)
      && this.model.invoices.length < 1;
    this.cartIsEditable = this.model
      && !this.model.paid
      && !this.CANCELLED_STATUSES.includes(this.model.status)
      && (this.model.paymentType == PaymentType.CASH || !this.isPayed);
    this.typeIsChangeable = this.model
      && !this.model.paid
      && !this.CANCELLED_STATUSES.includes(this.model.status);
  }

  get shipmentLabelUrl(): string {
    return `${environment.serviceUrl}/orders/${this.model.id}/shipment/${this.model.shipment?.deliveryPartner.type}/label/${this.model.shipment?.activeShipmentId}`
  }

  openChangePaymentDialog() {
    this.templateDialogRef = this.dialogService.open(this.paymentChangeTpl, {
      ariaLabel: 'A simple dialog',
    });
  }

  openChangeDeliveryTypeDialog() {
    if (this.model.deliveryType == DeliveryType.ONSITE && !this.model.deliveryData) {
      this.dialogService.open(
        EditBillingDataDialogComponent,
        Utilities.getDefaultModalConfig({
          mode: 'delivery',
          model: BillingData.newEmptyWithUser(this.model.user as UserModel)
        }, BILLING_DATA_MODAL_ID)
      ).afterClosed().subscribe(result => {
        if (result instanceof BillingData) {
          this.orderService.addDeliveryData(this.model.id, result).subscribe(result => {
            this.templateDialogRef = this.dialogService.open(this.deliveryTypeChangeTpl, {
              ariaLabel: 'A simple dialog',
            });
          })
        }
      });
    } else {
      this.templateDialogRef = this.dialogService.open(this.deliveryTypeChangeTpl, {
        ariaLabel: 'A simple dialog',
      });
    }

  }

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  toCash() {
    if (!this.cartService) {
      this.orderService.notificationService.showWarning('general.warning', 'order.warningPaymentChange');
      return;
    }
    const cashPrice = this.cartService.calcCashPrice();

    if (!cashPrice) {
      this.orderService.notificationService.showWarning('general.warning', 'order.warningPaymentChange');
      return;
    }
    const extra = new ExtraCartElement(CASH_PRICE_ID, cashPrice.key, cashPrice.price)
    this.orderService.changePaymentFromBarionToCash(this.model.id, extra).subscribe(result => {
      this.orderService.notificationService.showSuccess('general.success', 'order.successPaymentChange');
      this.reloadData();
      this.templateDialogRef?.close();
    });
  }

  toBarion() {
    const extraCartElement = this.model.cart.getCashPriceElement();
    if (!extraCartElement) {
      this.orderService.notificationService.showWarning('general.warning', 'order.warningPaymentChange');
      return;
    }
    this.orderService.changePaymentFromCashToBarion(this.model.id, extraCartElement.id).subscribe(result => {
      this.orderService.notificationService.showSuccess('general.success', 'order.successPaymentChange');
      this.reloadData();
      this.templateDialogRef?.close();
    });
  }

  toDelivery() {
    if (!this.cartService) {
      this.orderService.notificationService.showWarning('general.warning', 'order.warningPaymentChange');
      return;
    }
    const deliveryPrice = this.cartService.calcDeliveryPrice();
    if (!deliveryPrice) {
      this.orderService.notificationService.showWarning('general.warning', 'order.warningPaymentChange');
      return;
    }
    const extra = new ExtraCartElement(DELIVERY_PRICE_ID, deliveryPrice.key, deliveryPrice.price)
    this.orderService.changeShipmentFromOnsiteToDelivery(this.model.id, extra).subscribe(result => {
      this.orderService.notificationService.showSuccess('general.success', 'order.successPaymentChange');
      this.reloadData();
      this.templateDialogRef?.close();
    });
  }

  toOnsite() {
    const extraCartElement = this.model.cart.getDeliveryPriceElement();
    if (!extraCartElement) {
      this.orderService.notificationService.showWarning('general.warning', 'order.warningPaymentChange');
      return;
    }
    this.orderService.changeShipmentFromDeliveryToOnsite(this.model.id, extraCartElement.id).subscribe(result => {
      this.orderService.notificationService.showSuccess('general.success', 'order.successPaymentChange');
      this.reloadData();
      this.templateDialogRef?.close();
    });
  }

  refreshDeliveryData(billingData: BillingData) {
    if (this.model.deliveryData) {
      this.model.refreshDeliveryData(billingData);
    } else {
      this.orderService.addDeliveryData(this.model.id, billingData).subscribe(result => {
        // this.reloadData();
        this.model.shipment = result.shipment;
      })
    }
  }

  refreshDeliveryStatus() {
    if (this.model.deliveryType == DeliveryType.DELIVERY && this.model.shipment) {
      this.orderService.refreshDeliveryStatus(this.model.id).subscribe(result => {
        this.reloadData();
      });
    }
  }

}
