import {Component, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {ListPage} from "../../../utilities/abstract/list-page";
import {WarehouseModel, WarehouseType} from "../../../services/warehouse/warehouse.model";
import {WarehouseDtoTypes, WarehouseService} from "../../../services/warehouse/warehouse.service";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.WAREHOUSE,
  authGuard: AdminGuard
})
export class WarehouseListComponent extends ListPage<WarehouseModel, WarehouseService> implements OnInit {

  constructor(service: WarehouseService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')),
      TableCol.newString('name', await this.getTranslate('warehouse.name')),
      TableCol.newString('type', await this.getTranslate('warehouse.typeLabel')).filterable(TableFilter.newSelect(
        Object.keys(WarehouseType).map(this.generateTypeSelectOption())
      )),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(WarehouseDtoTypes.SlimWarehouseDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  private generateTypeSelectOption() {
    return (type: string) => {
      const label = this.translateService.instant(`warehouse.type.${type}`);
      return {label: label, value: type};
    }
  }
}
