<form #formElement="ngForm">
  <div nxRow>
    <div nxCol="12,12,6">
      <nx-formfield nxLabel="{{'deliveryPartner.name' | translate}}" appearance="auto" nxFloatLabel="always">
        <input nxInput [(ngModel)]="model.name" name="name" required/>
      </nx-formfield>
    </div>
    <div nxCol="12,12,6">
      <nx-formfield nxLabel="{{'deliveryPartner.shortDesc' | translate}}" appearance="auto" nxFloatLabel="always">
        <textarea nxInput name="shortDesc" [(ngModel)]="model.shortDescription" required></textarea>
        <span nxFormfieldHint> {{ model.shortDescription.length }}/255 </span>
      </nx-formfield>
    </div>
    <div nxCol="12" class="nx-margin-top-2m">
      <app-image-upload [(selectedAttachment)]="model.logo"></app-image-upload>
    </div>
  </div>
</form>