import {ChangeDetectorRef, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {Author, MagazineModel, MagazineStatus} from "../../../services/article/magazine.model";
import {MagazineDtoTypes, MagazineService} from "../../../services/article/magazine.service";
import {ActivatedRoute} from "@angular/router";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {Editor} from "ngx-editor";
import {EntityTag, Tag} from "../../../services/product/product.model";
import {Utilities} from "../../../utilities/utilities";
import moment from "moment";
import {takeUntil} from "rxjs";
import {StepElement} from "../../../models/step-element.model";
import {SKIP_VALIDATION} from "../../../utilities/types";
import {ValidatedInputDirective} from "../../../directives/validated-input.directive";

@Component({
  selector: 'app-article-form',
  templateUrl: './magazine-form.component.html',
  styleUrls: ['./magazine-form.component.css']
})

@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.MAGAZINE,
})
export class MagazineFormComponent extends FormPage<MagazineModel, MagazineService> implements OnInit {
  saveIsEnable = false;
  @ViewChild('baseData') baseData!: TemplateRef<any>;
  @ViewChild('contentData') contentData!: TemplateRef<any>;
  @ViewChild('summaryData') summaryData!: TemplateRef<any>;
  @ViewChildren(ValidatedInputDirective) override inputs!: QueryList<ValidatedInputDirective>;

  editor: Editor;
  toolbar: any = []
  authors!: Author[];
  hours: string[] = [];
  steps!: StepElement[];

  constructor(service: MagazineService, route: ActivatedRoute,
              public dialogService: NxDialogService, private cdr: ChangeDetectorRef) {
    super(route, service, MagazineModel, []);

    this.editor = new Editor();
    this.hours = Utilities.getHours(0, 23);
  }

  ngOnInit(): void {
    this.onInit(MagazineDtoTypes.MagazineDTO);
    this.initSteps();
    this.cdr.detectChanges();
  }

  protected override afterModelIsSetProcess() {
    this.service.getAuthors().pipe(takeUntil(this._destroyed)).subscribe(result => {
      if (result.length > 0) {
        this.authors = result;
        if (this.formModel.author.id < 1) {
          this.formModel.author = this.authors[0];
        }
        this.formModel.authorId = this.formModel.author.id;
      }
    });
    if (!this.formModel.publishedAt) {
      this.formModel.publishedAt = moment();
    }

    this.formModel.publishedAtTime = this.formModel.publishedAt.format('HH:mm');
    this.saveIsEnable = this.formModel.id > 1;
    this.initErrorHandlingOnInput();
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);


  }

  tagsChange(tags: Tag[]) {
    this.formModel.tags = tags.map(t => new EntityTag(t));
  }

  initSteps() {
    this.steps = [
      new StepElement(1,'article.baseInformation', () => this.baseData, this.validateStep(1)),
      new StepElement(2,'article.content', () => this.contentData, this.validateStep(2)),
      new StepElement(3,'article.summary', () => this.summaryData, SKIP_VALIDATION),
    ]
  }

  validateStep(stepCount: number) {
    return () => {
      try {
        this.service.validateByStep(this.formModel, stepCount);
        return true;
      } catch (e) {
        console.warn(e);
        return false
      }
    }
  }

  changeAuthor() {
    const author = this.authors.find(a => a.id == this.formModel.authorId);
    if (author) {
      this.formModel.author = author;
    }
  }

  protected override savePreProcess() {
    super.savePreProcess();
    this.initTimeForPublishedAt();
  }

  private initTimeForPublishedAt() {
    const partOfTime = this.formModel.publishedAtTime.split(':');
    this.formModel.publishedAt.hour(parseInt(partOfTime[0]));
    this.formModel.publishedAt.minute(parseInt(partOfTime[1]));
    this.formModel.publishedAt.second(0);
  }

  protected readonly MagazineStatus = MagazineStatus;
}
