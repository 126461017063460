<ng-template #invoiceInfo let-invoice="invoice">
  <nx-data-display class="mt-2" label="{{ 'supplierInvoice.invoiceNumber' | translate}}"
                   orientation="horizontal-columns">
    {{ invoice.invoiceNumber }}
  </nx-data-display>
  <nx-data-display class="mt-2" label="{{ 'supplierInvoice.paymentMode' | translate}}" orientation="horizontal-columns">
    {{ 'enum.' + invoice.paymentMethod  | translate }}
  </nx-data-display>
  <nx-data-display class="mt-2" label="{{ 'supplierInvoice.elementCount' | translate}}"
                   orientation="horizontal-columns">
    {{ invoice.elements.length }} db
  </nx-data-display>
</ng-template>

<ng-container *ngIf="model && componentIsReady">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="info"
            title="{{'supplierOrder.showHeadlineInfo' | translate}}"
            [backSupplier]="back()"
          ></page-show-tool-bar>

          <div class="row nx-margin-top-2xs">
            <div class="col-md-4">
              <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.warehouse' | translate }}</p>
              <h3 nxHeadline="subsection-medium">{{ model.warehouse.name }}</h3>
            </div>
            <ng-container *ngIf="model.estimatedArrival">
              <div class="col-md-4">
                <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.estimatedArrival' | translate }}</p>
                <h3 nxHeadline="subsection-medium">{{ model.estimatedArrival.toDate() | date:'yyyy.MM.dd' }}</h3>
              </div>
            </ng-container>
            <div class="col-md-4">
              <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.supplier' | translate }}</p>
              <h3 nxHeadline="subsection-medium">{{ model.supplierName }}</h3>
            </div>
            <div class="col-md-4">
              <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.sumCount' | translate }}</p>
              <h3 nxHeadline="subsection-medium">{{ model.sumOrderable }} db</h3>
            </div>
            <div class="col-md-4">
              <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.sumPrice' | translate }}</p>
              <h3 nxHeadline="subsection-medium">{{ model.sumOrderablePrice }} {{ model.currency }}</h3>
            </div>
            <div class="col-md-4">
              <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.currency' | translate }}</p>
              <h3 nxHeadline="subsection-medium">{{ model.currency }}</h3>
            </div>
          </div>
        </nx-card>
      </div>
      <div class="col-md-6">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="file"
            title="{{'supplierOrder.attachmentHandling' | translate}}"
          ></page-show-tool-bar>
          <div class="row nx-margin-top-2xs">
            <div class="col-12 my-3">
              <button nxButton="cta small" class="me-3" (click)="openPdfUpload()">
                <nx-icon name="file-pdf" class="nx-margin-right-2xs"></nx-icon>
                {{ 'supplierOrder.showImgUploader' | translate }}
              </button>
              <button nxButton="cta small" (click)="onOpenInvoiceDialog()">
                <nx-icon name="file-invoice" class="nx-margin-right-2xs"></nx-icon>
                {{ 'supplierOrder.addInvoiceData' | translate }}
              </button>
              <ng-container *ngIf="supplierOrderInvoices && supplierOrderInvoices.length>0">
                <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.supplierOrderInvoices' | translate }}</p>
                <ng-container *ngTemplateOutlet="invoiceInfo; context{invoice:supplierOrderInvoices[0]}"></ng-container>
              </ng-container>
              <ng-container *ngIf="loadInvoice">
                <div class="d-flex justify-content-center nx-margin-y-2m">
                  <app-spinner></app-spinner>
                </div>
              </ng-container>
              <ng-container *ngIf="model.attachments && model.attachments.length>0">
                <p class="text-dark mb-0 mt-3">{{ 'supplierOrder.files' | translate }}</p>
                <ul class="mx-3" style="list-style: none">
                  <ng-container *ngFor="let a of model.attachments">
                    <li class="d-flex justify-content-start align-items-center">
                      <button nxPlainButton class="mr-3" nxTooltip="{{'general.download' | translate}}"
                              [downloadFile]="{url:a.attachment.downloadLink, fileName:a.attachment.fileName}">
                        <nx-icon name="download" class="nx-margin-right-2xs"></nx-icon>
                      </button>
                      <span class="d-block"> {{ a.attachment.fileName }}</span>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
            </div>
          </div>
        </nx-card>
      </div>
      <div class="col-12">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="boxes-stacked"
            title="{{'supplierOrder.showHeadline' | translate}}"
          ></page-show-tool-bar>
          <div class="row nx-margin-top-2xs">
            <div class="col-12">
              <table nxTable class="nx-margin-top-2m">
                <thead>
                <tr nxTableRow>
                  <th nxHeaderCell>{{ 'supplierOrder.productName' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.supplierProductName' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.supplier' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.estimatedArrival' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.status' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.orderedCount' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let element of model.elements | arraySort:'id'">
                  <tr nxTableRow>
                    <td nxTableCell>
                      {{ element.product ? element.product.product.name : '' }}
                    </td>
                    <td nxTableCell>
                      {{ element.supplierProduct.name }}
                    </td>
                    <td nxTableCell>
                      {{ element.supplierProduct.supplier.companyName }}
                    </td>
                    <td nxTableCell>
                      {{ element.estimatedArrival ? (element.estimatedArrival.toDate() | date:'yyyy.MM.dd') : (model.estimatedArrival ? (model.estimatedArrival.toDate() | date:'yyyy.MM.dd') : '') }}
                    </td>
                    <td nxTableCell>
                      {{ 'supplierOrder.statuses.' + element.status  | translate }}
                    </td>
                    <td nxTableCell>
                      {{ element.count }} db
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </nx-card>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>
