import {Injectable} from '@angular/core';
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {PlantCultureIssueType} from "./plant-culture-issue-type.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/plant-culture-issue-types')
export class PlantCultureIssueTypeRepository extends CrudRepository<PlantCultureIssueType> {
  constructor() {
    super();
  }

}
