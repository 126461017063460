import { Injectable, Type } from '@angular/core';
import { CrudService } from '../../utilities/service/crud.service';
import { ProductRepository } from './product.repository';
import { ROUTING } from '../../utilities/routing';
import { ProductHistoryModel, ProductModel, SupplierProduct } from './product.model';
import { BehaviorSubject, combineLatest, concat, filter, map, Observable, of, take } from 'rxjs';
import { ProductHistoryRepository } from './product-history/product-history.repository';
import { PostPayload } from '../../models/post-payload.model';
import { ValidatorService } from '../../utilities/service/validator.service';
import { DeliveryConfig, FinancialConfig, OrderConfigModel, PackagingConfig } from '../category/category.model';
import { HistoryStatus } from '../../utilities/types';
import { EntityAttachment } from '../attachment/attachment.model';
import { ProductStepNumber } from '../../utilities/constatants';
import { HellparserService } from '@hellp/parser';
import { PageMetaDataConf } from '../../utilities/page-meta-data.conf';
import { ValidationError } from '../../models/validation.error.model';

export enum UploadType {
  MARKETING = 'MARKETING',
  SALES = 'SALES',
}

export enum ProductDtoTypes {
  ProductDTO = 'ProductDTO',
  SlimProductDTO = 'SlimProductDTO',
}

export enum ProductHistoryDtoTypes {
  ProductHistoryDTO = 'ProductHistoryDTO',
  ExceptDeprecatedProductHistoryDTO = 'ExceptDeprecatedProductHistoryDTO',
  PublishedOnlyProductHistoryDTO = 'PublishedOnlyProductHistoryDTO',
  SlimProductHistoryDTO = 'SlimProductHistoryDTO',
}

@Injectable({
  providedIn: 'root',
})
export class ProductService extends CrudService<ProductModel, ProductRepository<ProductModel>> {
  $uploadType: BehaviorSubject<UploadType> = new BehaviorSubject<UploadType>(UploadType.SALES);
  historyClazz: Type<any> = ProductHistoryModel;

  constructor(
    repo: ProductRepository<ProductModel>,
    private historyRepo: ProductHistoryRepository,
    private parserService: HellparserService,
  ) {
    super(ROUTING.PRODUCT, repo, ProductModel, PageMetaDataConf.PRODUCT);
  }

  public getAutocompleteLabelProcess() {
    return (items: ProductHistoryModel[]) => [
      ...new Set(items.map((item: ProductHistoryModel) => `${item.product.name} [${item.product.itemNr}]`)),
    ];
  }

  override navigateToBase() {
    if (this.$uploadType.value == UploadType.SALES) {
      this.path = ROUTING.SALES_PRODUCT;
    } else {
      this.path = ROUTING.MARKETING_PRODUCT;
    }
    super.navigateToBase();
  }

  override navigateToEdit(id: number, secondPart?: any) {
    if (this.$uploadType.value == UploadType.SALES) {
      this.path = ROUTING.SALES_PRODUCT;
    } else {
      this.path = ROUTING.MARKETING_PRODUCT;
    }
    super.navigateToEdit(id, secondPart);
  }

  override navigateToShow(id: number | string) {
    if (this.$uploadType.value == UploadType.SALES) {
      this.path = ROUTING.SALES_PRODUCT;
    } else {
      this.path = ROUTING.MARKETING_PRODUCT;
    }
    super.navigateToShow(id);
  }

  override navigateToNew(prefix: string = '', postfix: string = '') {
    if (this.$uploadType.value == UploadType.SALES) {
      this.path = ROUTING.SALES_PRODUCT;
    } else {
      this.path = ROUTING.MARKETING_PRODUCT;
    }
    super.navigateToNew(prefix, postfix);
  }

  override getAllByDeleted(dto: string, deleted = false): Observable<ProductModel[]> {
    const filter = this.filterService.filter(dto).equal('history_deleted', deleted).create();
    return this.repo.getAllByFilter(filter.stringify(), ProductModel);
  }

  getHistoriesByProductNameFilter(terms: string): Observable<ProductHistoryModel[]> {
    if (terms && terms.length > 0) {
      const filter = this.filterService
        .filter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO)
        .equal('published_status', HistoryStatus.PUBLISHED)
        .and()
        .greaterThan('stockProducts_count', 0)
        .and()
        .equal('stockProducts_warehouse_type', 'WEB_SHOP')
        .and()
        .match('published_name', terms.toLowerCase())
        .orBlock()
        .equal('published_status', HistoryStatus.PUBLISHED)
        .and()
        .greaterThan('stockProducts_count', 0)
        .and()
        .equal('stockProducts_warehouse_type', 'WEB_SHOP')
        .and()
        .match('published_itemNr', terms.toLowerCase())
        .create();
      return this.historyRepo.getSilentAllByFilter(filter.stringify(), ProductHistoryModel);
    }
    return of([]);
  }

  getHistoriesByProductNameFilterWithoutStockFilter(terms: string): Observable<ProductHistoryModel[]> {
    if (terms && terms.length > 0) {
      const filter = this.filterService
        .filter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO)
        .equal('published_status', HistoryStatus.PUBLISHED)
        .and()
        .match('published_name', terms.toLowerCase())
        .orBlock()
        .equal('published_status', HistoryStatus.PUBLISHED)
        .and()
        .match('published_itemNr', terms.toLowerCase())
        .create();
      return this.historyRepo.getSilentAllByFilter(filter.stringify(), ProductHistoryModel);
    }
    return of([]);
  }

  getHistoryByAutocompleteField(autocompleteString: string): Observable<ProductHistoryModel | undefined> {
    const sku = this.extractText(autocompleteString);
    if (!sku) {
      return of(undefined);
    }
    const filter = this.filterService
      .filter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO)
      .equal('published_status', HistoryStatus.PUBLISHED)
      .and()
      .greaterThan('stockProducts_count', 0)
      .and()
      .equal('published_itemNr', sku)
      .create();
    return this.historyRepo.getSilentAllByFilter(filter.stringify(), ProductHistoryModel).pipe(
      map((result) => {
        if (result.length > 0) {
          return result[0];
        }
        return undefined;
      }),
    );
  }

  getAllHistoryByDeleted(dto: string, deleted = false): Observable<ProductHistoryModel[]> {
    const filter = this.filterService.filter(dto).equal('deleted', deleted).create();
    return this.historyRepo.getAllByFilter(filter.stringify(), ProductHistoryModel);
  }

  getAllHistoryForListByFilter(filter: string) {
    return this.historyRepo.getAllByFilter(filter, ProductHistoryModel);
  }

  getAllHistoryWithPublished(dto: string): Observable<ProductHistoryModel[]> {
    const filter = this.filterService.filter(dto).isNotNull('published').create();
    return this.historyRepo.getAllByFilter(filter.stringify(), ProductHistoryModel);
  }

  getAllForProductMatrix(): Observable<ProductHistoryModel[]> {
    const filter = this.filterService
      .filter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO)
      .equal('elementOfProductMatrix', true)
      .and()
      .equal('deleted', false)
      .create();
    return this.historyRepo.getAllByFilter(filter.stringify(), ProductHistoryModel);
  }

  override getById(id: number, dto: string): Observable<ProductModel | undefined> {
    return super.getById(id, dto).pipe(
      map((product) => {
        if (product && !product.history.mainImage) {
          product.history.mainImage = new EntityAttachment();
        }
        if (product && !product.history.orderConfig) {
          if (product.history.category.orderConfig) {
            product.history.orderConfig = product.history.category.orderConfig;
          } else {
            product.history.orderConfig = new OrderConfigModel();
          }
        }
        return product;
      }),
    );
  }

  getAllForCategory(dto: string, id: number) {
    const filter = this.filterService.filter(dto).equal('category_id', id).create();
    return this.historyRepo.getAllByFilter(filter.stringify(), ProductHistoryModel);
  }

  getAllForSupplier(dto: string, id: number) {
    const filter = this.filterService.filter(dto).equal('supplierProduct_supplier_id', id).create();
    return this.historyRepo.getAllByFilter(filter.stringify(), ProductHistoryModel);
  }

  getPublishedFilter(dto: string): string {
    return this.filterService
      .filter(dto)
      .isNotNull('published')
      .and()
      .equal('deleted', false)
      .and()
      .isNull('draft')
      .create()
      .stringify();
  }

  getInProgressFilter(dto: string): string {
    return this.filterService
      .filter(dto)
      .isNotNull('published')
      .and()
      .equal('deleted', false)
      .and()
      .isNotNull('draft')
      .create()
      .stringify();
  }

  getDraftFilter(dto: string): string {
    return this.filterService.filter(dto).equal('deleted', false).and().isNotNull('draft').create().stringify();
  }

  getFullFilter(dto: string): string {
    return this.filterService.filter(dto).equal('deleted', false).create().stringify();
  }

  private extractText(str: string): string | undefined {
    const regex = /\[(.*?)\]/;
    const match = str.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return undefined;
  }

  /**
   * @param stepIndex
   * @param formModel
   * @throws Error
   */
  public stepSaver(stepIndex: number, formModel: ProductModel): Observable<ProductModel> {
    try {
      if (stepIndex > 0 && !formModel.history.id) {
        throw new Error('error.noHistoryId');
      }

      let processObservable: Observable<ProductModel> | undefined;
      let errors: ValidationError[] = [];
      if (stepIndex == ProductStepNumber.Base) {
        if (formModel.history && formModel.history.category) {
          formModel.sku = formModel.history.category.sku + formModel.sku;
        }
        errors = this.baseDataValidation(formModel);
        processObservable = this.baseDataSave(formModel);
      }
      if (stepIndex == ProductStepNumber.Supplier) {
        errors = this.supplierValidation(formModel);
        processObservable = this.supplierSave(formModel);
      }
      if (stepIndex == ProductStepNumber.TagAndConfig) {
        errors = this.orderConfigValidation(formModel);
        processObservable = this.tagAndOrderConfigSave(formModel);
      }
      if (stepIndex == ProductStepNumber.MainImage) {
        errors = this.mainImageValidation(formModel);
        processObservable = this.mainImageSave(formModel);
      }
      if (stepIndex == ProductStepNumber.Gallery) {
        // errors = this.galleryValidation(formModel); // Zi: én vettem ki
        processObservable = this.gallerySave(formModel);
      }
      if (stepIndex == ProductStepNumber.RelatedProducts) {
        processObservable = this.relatedProductSave(formModel);
      }
      if (stepIndex == ProductStepNumber.Summary) {
        processObservable = of(formModel);
      }
      if (errors.length > 0) {
        this.$validationError.next(errors);
        throw new Error('general.error');
      }
      if (!processObservable) {
        throw new Error('general.error');
      }
      return processObservable;
    } catch (e: any) {
      if (stepIndex == ProductStepNumber.Supplier) {
        formModel.history.supplierProduct = new SupplierProduct();
      }
      const message = e.message ?? e;
      throw new Error(message);
    }
  }

  private baseDataValidation(formModel: ProductModel) {
    let errors = ValidatorService.allIsRequired(
      formModel,
      [
        'name',
        'description',
        'shortDescription',
        'sku',
        // 'itemNr',
        'weightKg',
        'prices',
      ],
      ProductModel.name,
    );
    errors = errors.concat(ValidatorService.allIsRequired(formModel.history, ['category'], ProductModel.name));
    if (formModel.prices && !formModel.prices.every((p) => p.net > 0)) {
      errors.push(new ValidationError('error.ProductModel.prices', 'prices'));
    }

    if (formModel && formModel.sku) {
      const skuFilter = this.filterService
        .filter(ProductDtoTypes.SlimProductDTO)
        .equal('sku', formModel.sku)
        .and()
        .equal('deleted', false)
        .create();
      this.repo
        .getAllByFilter(skuFilter.stringify(), ProductModel)
        .pipe(filter((skus) => !skus.map((prod) => prod.historyId).includes(formModel.history.id)))
        .subscribe((result) => {
          if (result.length > 0) {
            errors.push(new ValidationError('error.ProductModel.sku.exist', 'sku'));
            errors = this.$validationError.value.concat(errors);
            this.$validationError.next(errors);
          }
        });
    }
    return errors;
  }

  private supplierValidation(formModel: ProductModel) {
    let errors = ValidatorService.allIsRequired(formModel.history, ['supplierProduct'], ProductModel.name);
    if (formModel.history.supplierProduct) {
      errors = errors.concat(
        ValidatorService.allIsRequired(formModel.history.supplierProduct, ['supplier'], ProductModel.name),
      );
    }
    return errors;
  }

  private orderConfigValidation(formModel: ProductModel) {
    const model = formModel.history;
    let errors: ValidationError[] = [];
    if (model.orderConfig && model.orderConfig.deliveryConfig) {
      errors = errors.concat(
        ValidatorService.allIsRequired(
          model.orderConfig.deliveryConfig,
          ['availableModes'],
          DeliveryConfig.name,
          'deliveryConfig',
        ),
      );
    }
    if (model.orderConfig && model.orderConfig.financialConfig) {
      errors = errors.concat(
        ValidatorService.allIsRequired(
          model.orderConfig.financialConfig,
          ['availableModes'],
          FinancialConfig.name,
          'financialConfig',
        ),
      );
    }
    if (model.orderConfig && model.orderConfig.packagingConfig) {
      errors = errors.concat(
        ValidatorService.allIsRequired(model.orderConfig.packagingConfig, ['icePackRanges'], PackagingConfig.name),
      );
    }
    return errors;
  }

  private mainImageValidation(formModel: ProductModel) {
    let errors = ValidatorService.allIsRequired(formModel.history, ['mainImage'], ProductModel.name);
    if (formModel.history.mainImage) {
      errors = errors.concat(
        ValidatorService.allIsRequired(formModel.history.mainImage, ['attachment'], ProductModel.name),
      );
    }

    return errors;
  }

  private galleryValidation(formModel: ProductModel) {
    const errors: ValidationError[] = [];
    if (!formModel.history.images || formModel.history.images.length < 1) {
      errors.push(new ValidationError('error.ProductModel.required.gallery', 'images'));
    }
    const noAttachment = formModel.history.images.filter((ac) => !ac.attachment);
    if (noAttachment.length > 0) {
      errors.push(new ValidationError('error.ProductModel.required.gallery', 'images'));
    }
    return errors;
  }

  override delete(id: number): Observable<any> {
    return this.historyRepo.deleteById(id);
  }

  private parseHistoryResponseToProduct(result: ProductHistoryModel, formModel: ProductModel) {
    formModel.history = this.parserService.parseApiDataToModel(result, ProductHistoryModel);
    return this.initIds(formModel);
  }

  private baseDataSave(formModel: ProductModel): Observable<ProductModel> {
    if (formModel.getHistoryId() < 1) {
      const historyModel: ProductHistoryModel = ProductHistoryModel.createNewInstanceFromSelf(
        formModel.history,
        formModel,
      );
      return this.historyRepo
        .save(
          new PostPayload<ProductHistoryModel>(historyModel, ProductHistoryDtoTypes.ProductHistoryDTO),
          ProductHistoryModel,
        )
        .pipe(
          take(1),
          map((result) => this.parseHistoryResponseToProduct(result, formModel)),
        );
    } else {
      let categorySave = this.historyRepo.patchCategory(formModel.id, formModel.history);
      if (formModel.history.hasPublishedVersion) {
        categorySave = of();
      }
      if (formModel.status == HistoryStatus.DRAFT) {
        const draftProductSave = this.repo
          .update(formModel.id, new PostPayload<ProductModel>(formModel, ProductDtoTypes.ProductDTO), ProductModel)
          .pipe(
            take(1),
            map((result) => this.initIds(result)),
          );
        return combineLatest([draftProductSave, categorySave]).pipe(
          map(([resultProduct, resultCategory]) => resultProduct),
        );
      } else {
        const historyModel: ProductHistoryModel = ProductHistoryModel.createNewForCreateDraft(
          formModel.history,
          formModel,
        );
        historyModel.draft.historyId = formModel.getHistoryId();
        const publishedProductSave = this.historyRepo.patchDraft(formModel.getHistoryId(), historyModel).pipe(
          take(1),
          map((result) => this.parseHistoryResponseToProduct(result, formModel)),
        );
        return combineLatest([publishedProductSave, categorySave]).pipe(
          map(([resultProduct, resultCategory]) => resultProduct),
        );
      }
    }
  }

  private supplierSave(formModel: ProductModel): Observable<ProductModel> {
    if (formModel.history.supplierProduct != null) {
      return this.historyRepo.patchSupplier(formModel.getHistoryId(), formModel.history).pipe(
        take(1),
        map((result) => this.parseHistoryResponseToProduct(result, formModel)),
      );
    }
    throw new Error('error.noSelectedSupplier');
  }

  private tagAndOrderConfigSave(formModel: ProductModel): Observable<ProductModel> {
    formModel.history.cartExclusive = formModel.history.orderConfig.cartExclusive;
    const patchOrderConfig = this.historyRepo.patchOrderConfig(formModel.getHistoryId(), formModel.history);
    const patchTag = this.historyRepo.patchTag(formModel.getHistoryId(), formModel.history);

    return concat(patchOrderConfig, patchTag).pipe(
      take(2),
      map((result) => this.parseHistoryResponseToProduct(result, formModel)),
    );
  }

  private mainImageSave(formModel: ProductModel): Observable<ProductModel> {
    formModel.history.mainImage = this.parserService.convertModelToApi(formModel.history.mainImage);
    return this.historyRepo.patchMainImage(formModel.getHistoryId(), formModel.history).pipe(
      take(2),
      map((result) => this.parseHistoryResponseToProduct(result, formModel)),
    );
  }

  private gallerySave(formModel: ProductModel): Observable<ProductModel> {
    formModel.history.images = this.parserService.convertModelListToApi(formModel.history.images);
    return this.historyRepo.patchGallery(formModel.getHistoryId(), formModel.history).pipe(
      take(1),
      map((result) => this.parseHistoryResponseToProduct(result, formModel)),
    );
  }

  private relatedProductSave(formModel: ProductModel): Observable<ProductModel> {
    const patchDynamicAttribute = this.historyRepo.patchDynamicAttribute(formModel.getHistoryId(), formModel.history);
    const patchRelatedProducts = this.historyRepo.patchRelatedProducts(formModel.getHistoryId(), formModel.history);

    return concat(patchDynamicAttribute, patchRelatedProducts).pipe(
      take(2),
      map((result) => this.parseHistoryResponseToProduct(result, formModel)),
    );
  }

  public initIds(formModel: ProductModel) {
    if (formModel.history && formModel.history.supplierProduct) {
      formModel.history.supplierProduct.supplierId = formModel.history.supplierProduct.supplier.id;
    }
    if (formModel.history && formModel.history.category) {
      formModel.history.categoryId = formModel.history.category.id;
    }
    if (formModel.history && formModel.history.orderConfig) {
      formModel.history.orderConfig.cartExclusive = formModel.history.cartExclusive;
    }
    return formModel;
  }

  publish(row: ProductHistoryModel) {
    return this.historyRepo.publish(row.id);
  }

  updateProductMatrix(row: ProductHistoryModel) {
    return this.historyRepo.patchTag(row.id, row);
  }

  getHistoryById(id: number, dto: string) {
    const filter = this.filterService.filter(dto).equal('id', id).create();
    return this.historyRepo.getAllByFilter(filter.stringify(), this.historyClazz).pipe(
      map((result) => {
        if (result.length > 0) {
          return result[0];
        } else {
          return undefined;
        }
      }),
    );
  }

  validateByStep(formModel: ProductModel, stepIndex: number) {
    let errors: ValidationError[] = [];
    if (stepIndex == ProductStepNumber.Base) {
      if (formModel.history && formModel.history.category) {
        formModel.sku = formModel.history.category.sku + formModel.sku;
      }
      errors = this.baseDataValidation(formModel);
    }
    if (stepIndex == ProductStepNumber.Supplier) {
      errors = this.supplierValidation(formModel);
    }
    if (stepIndex == ProductStepNumber.TagAndConfig) {
      errors = this.orderConfigValidation(formModel);
    }
    if (stepIndex == ProductStepNumber.MainImage) {
      errors = this.mainImageValidation(formModel);
    }
    if (stepIndex == ProductStepNumber.Gallery) {
    }
    if (stepIndex == ProductStepNumber.RelatedProducts) {
    }
    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error('general.error');
    }
  }
}
