<nx-card class="viewer">
  <page-form-header #formHeader iconName="icons" [model]="formModel"
                    [cancelSupplier]="cancel()" [saveSupplier]="save()"
                    [modalOptions]="toolbarAddOptions"
  ></page-form-header>
  <div nxRow>
    <div nxCol="12" class="nx-margin-top-2m">
      <h4 nxHeadline="subsection"></h4>
      <app-pdf-upload [(selectedAttachment)]="formModel.attachment" [firstPanelExpanded]="true"></app-pdf-upload>
    </div>
  </div>

</nx-card>