<div nxModalContent>
  <nx-card class="w-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'order.selectDeliveryPartner' | translate }}
    </h3>
    <div nxRow class="mt-5">
      <div nxCol="12">
        <ng-container *ngIf="data">
          <ng-container *ngFor="let partner of data.deliveryPartners | arraySort:'name'">
            <button [nxModalClose]="partner" nxButton="primary large" type="button" class="nx-margin-right-2m">
              <img src="{{partner.logo.src}}" style="height: 50px" alt="{{partner.name}}"/>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
</div>
