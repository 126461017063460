<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      (save)="onSave()"
                      (cancel)="onCancel()">

  <div class="nx-margin-top-2m">
    <div class="row align-items-end">
      <div class="col-md-6">
        <nx-formfield nxLabel="{{'supplierOrder.supplier' | translate}}" appearance="auto"
                      nxFloatLabel="always">
          <nx-dropdown name="test"  [(ngModel)]="selectedSupplierId"
            (selectionChange)="onSelectSupplier()"
          >
            <ng-container *ngFor="let supplier of suppliers | arraySort:'companyName'">
              <nx-dropdown-item [nxValue]="supplier.id">
                {{ supplier.companyName }}
              </nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
        </nx-formfield>
      </div>
      <ng-container *ngIf="!selectedSupplierId">
        <div class="col-10 mx-auto">
          <nx-message nxContext="warning">
            <span class="nx-font-weight-bold">{{ 'supplierOrder.noSupplierTitle' | translate }}:</span><br/>
            {{ 'supplierOrder.noSupplier' | translate }}
          </nx-message>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedWarehouse">
        <div class="col-md-6">
          <nx-formfield nxLabel="{{'supplierOrder.selectedWarehouse' | translate}}" appearance="auto"
                        nxFloatLabel="always">
            <input
              [disabled]="true"
              nxInput
              [ngModel]="selectedWarehouse.name"
            />
          </nx-formfield>
        </div>
        <div class="col-md-6">
          <nx-formfield nxLabel="{{'supplierOrder.note' | translate}}" appearance="auto" nxFloatLabel="always">
            <textarea nxInput [(ngModel)]="formModel.message"></textarea>
          </nx-formfield>
        </div>
        <div class="col-md-6">
          <nx-formfield nxLabel="{{'supplierOrder.estimatedDate' | translate}}" appearance="auto"
                        nxFloatLabel="always">
            <input
              nxDatefield
              nxInput
              [nxDatepicker]="myDatepicker"
              [(ngModel)]="formModel.estimatedArrival"
            />
            <nx-datepicker-toggle
              [for]="myDatepicker"
              nxFormfieldSuffix
            ></nx-datepicker-toggle>
            <nx-datepicker #myDatepicker></nx-datepicker>
          </nx-formfield>
        </div>
        <div class="col-md-12">
          <p class="d-flex justify-content-between">
            <span><b>{{ 'supplierOrder.sumCount' | translate }}: </b>{{ selectedWarehouse.sumOrderable }} db</span>
            <span><b>{{ 'supplierOrder.sumPrice' | translate }}: </b>{{ selectedWarehouse.sumOrderablePrice }}
              {{ currency }}</span>
          </p>
        </div>
        <div class="col-12 overflow-x-auto">
          <table nxTable>
            <thead>
            <tr nxTableRow>
              <th nxHeaderCell>

              </th>
              <th nxHeaderCell>{{ 'supplierOrder.productName' | translate }}</th>
              <th nxHeaderCell>{{ 'supplierOrder.supplierProductName' | translate }}</th>
              <th nxHeaderCell>{{ 'supplierOrder.supplier' | translate }}</th>
              <ng-container *ngFor="let month of MonthByIndex |keyvalue | arraySort:'key':true;">
                <th nxHeaderCell>{{ ('general.month.' + (month.key) | translate) | shortify:3:'' }}</th>
              </ng-container>
              <th nxHeaderCell>{{ 'supplierOrder.actualCount' | translate }}</th>
              <th nxHeaderCell>{{ 'supplierOrder.count' | translate }}</th>
              <th nxHeaderCell>
                <ng-container *ngIf="!selectAll && !editMode">
                  <button nxIconButton="primary small" class="bg-darker" (click)="onToggleSelectedAll()"
                          nxTooltip="{{'supplierOrder.addAll' | translate}}">
                    <nx-icon name="check"></nx-icon>
                  </button>
                </ng-container>
                <ng-container *ngIf="selectAll && !editMode">
                  <button nxIconButton="cta small" (click)="onToggleSelectedAll()"
                          nxTooltip="{{'supplierOrder.removeAll' | translate}}">
                    <nx-icon name="minus"></nx-icon>
                  </button>
                </ng-container>
              </th>
            </tr>
            <tr nxTableRow style="border-bottom: 1px solid #000">
              <td nxTableCell>

              </td>
              <td nxTableCell>
                <nx-formfield appearance="outline" nxFloatLabel="always">
                  <input nxInput [(ngModel)]="filter['productName']" type="text"
                         placeholder="{{'supplierOrder.productNameFilter' | translate}}"
                         (ngModelChange)="onFilter()"/>
                </nx-formfield>
              </td>
              <td nxTableCell>
                <nx-formfield appearance="outline" nxFloatLabel="always">
                  <input nxInput [(ngModel)]="filter['supplierProductName']" type="text"
                         placeholder="{{'supplierOrder.supplierProductNameFilter' | translate}}"
                         (ngModelChange)="onFilter()"/>
                </nx-formfield>

              </td>
              <td nxTableCell>
                <nx-formfield appearance="outline" nxFloatLabel="always">
                  <input nxInput [(ngModel)]="filter['supplierName']" type="text"
                         placeholder="{{'supplierOrder.supplierFilter' | translate}}"
                         (ngModelChange)="onFilter()"/>
                </nx-formfield>
              </td>
              <ng-container *ngFor="let month of MonthByIndex |keyvalue | arraySort:'key':true;">
                <td nxTableCell></td>
              </ng-container>
              <td nxTableCell></td>
              <td nxTableCell></td>
              <td nxTableCell>
                <button nxPlainButton (click)="onClearFilter()" nxTooltip="{{'general.clearFilter' | translate}}">
                  <nx-icon name="xmark"></nx-icon>
                </button>
              </td>
            </tr>

            </thead>
            <tbody>
            <ng-container *ngFor="let stockProduct of availableStockProducts| arraySort:'id':true">

              <tr nxTableRow>
                <td nxTableCell>
                  <button nxIconButton="primary small" (click)="onOpenFromTemplate(stockProduct)"
                          nxTooltip="{{'supplierOrder.addNote' | translate}}">
                    <nx-icon name="message"></nx-icon>
                  </button>
                </td>
                <td nxTableCell>
                  <span class="text-middle">{{ stockProduct.product.product.name }}</span><br>
                  <span class="text-small">{{ stockProduct.product.product.itemNr }}</span>
                </td>
                <td nxTableCell>
                    <span class="text-middle">{{ stockProduct.product.supplierProduct.name }}
                      (<b>{{ stockProduct.product.supplierProduct.basePrice | price:true }}</b>)</span><br>
                  <span class="text-small">{{ stockProduct.product.supplierProduct.itemNr }}</span>
                </td>
                <td nxTableCell>
                  {{ stockProduct.product.supplierProduct.supplier.companyName }}
                </td>
                <ng-container *ngFor="let month of MonthByIndex |keyvalue | arraySort:'key':true">
                  <td nxTableCell
                      class="{{actualMonth == month.value?'td-month-bg-actual':'td-month-bg'}}">
                    {{ (stockProduct.config.actualMinimumStock).monthlyMinimum[month.value] }}
                  </td>
                </ng-container>
                <td nxTableCell>
                  {{ stockProduct.count }} db
                </td>
                <td nxTableCell>
                  <nx-formfield appearance="outline">
                    <input nxInput [(ngModel)]="stockProduct.orderCount" type="number"
                           name="{{'order_'+stockProduct.id}}"/>
                  </nx-formfield>
                </td>
                <td nxTableCell>
                  <ng-container *ngIf="!stockProduct.addToOrder">
                    <button nxIconButton="primary small" (click)="onAdd(stockProduct)" class="m-1"
                            [disabled]="stockProduct.orderCount < 1"
                            nxTooltip="{{'supplierOrder.addToOrder' | translate}}">
                      <nx-icon name="check"></nx-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="stockProduct.addToOrder">
                    <button nxIconButton="cta small" (click)="onRemove(stockProduct)" class="m-1"
                            nxTooltip="{{'supplierOrder.removeFromOrder' | translate}}">
                      <nx-icon name="minus"></nx-icon>
                    </button>
                  </ng-container>
                </td>
              </tr>

            </ng-container>
            </tbody>
          </table>
        </div>

      </ng-container>
    </div>
  </div>
</hellp-form-container>

<ng-template #template>
  <div class="centered-content">
    <h3 nxHeadline="subsection-medium" class="nx-modal-margin-bottom">
      {{ 'supplierOrder.extraData' | translate }}
    </h3>
    <div class="row">
      <div class="col-12">
        <nx-formfield nxLabel="{{'supplierOrder.note' | translate}}" appearance="auto" nxFloatLabel="always">
          <textarea nxInput [(ngModel)]="tempNote"></textarea>
        </nx-formfield>
      </div>
      <div class="col-12">
        <nx-formfield nxLabel="{{'supplierOrder.estimatedDate' | translate}}" appearance="auto" nxFloatLabel="always">
          <input
            nxDatefield
            nxInput
            [nxDatepicker]="myDatepicker"
            [(ngModel)]="tempDate"
          />
          <nx-datepicker-toggle
            [for]="myDatepicker"
            nxFormfieldSuffix
          ></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker></nx-datepicker>
        </nx-formfield>
      </div>
    </div>
    <div class="two-button-container">
      <button nxButton="secondary small"
              (click)="onCloseTemplateDialog()"
              class="nx-margin-right-s"
      >
        {{ 'general.cancel' |translate }}
      </button>
      <button nxButton="primary small" (click)="onCloseTemplateDialog()">{{ 'general.save' | translate }}</button>
    </div>
  </div>
</ng-template>

