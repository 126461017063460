<div nxRow="">
  <div nxCol="8">
    <div class="page-header d-flex align-items-end p-3 justify-content-center"
         style="height:300px; background-image: url({{magazine.cover}}); transform: translate3d(0px, 0px, 0px);">
      <div class="mask bg-gradient-dark"></div>

      <div class="author align-items-center z-3">
        <img  [src]="magazine.author.avatarSrc" alt="marie" class="avatar shadow" loading="lazy">
        <div class="name ps-2 text-light">
          <span>{{magazine.author.name}}</span>
          <div class="stats text-light" *ngIf="magazine.publishedAt">
            <small>{{magazine.publishedAt.toDate() | date:'yyyy.MM.dd'}}</small>
          </div>
        </div>
      </div>
    </div>
    <h2>{{magazine.title}}</h2>
    <p #goUp>{{magazine.shortDescription}}</p>
    <ng-container *ngIf="magazine.pagedContent.length>0">
      <div class="blog-post" [innerHTML]="magazine.pagedContent[(page-1)] | safeHtml"></div>
      <nx-pagination
        [nxCount]="magazine.pagedContent.length - 1"
        [nxPage]="page"
        [nxPerPage]="perPage"
        nxType="advanced"
        (nxGoPrev)="prevPage()"
        (nxGoNext)="nextPage()"
        (nxGoPage)="goToPage($event)"
      >
      </nx-pagination>
    </ng-container>
  </div>
  <div nxCol="4">

    <ng-container *ngFor="let tag of magazine.tagList">
      <div class="tag {{'tag-'+tag.html.type}} me-3">
        {{tag.html.nxTaglistLabel}}
      </div>
    </ng-container>
  </div>
</div>