import {Component, ViewChild} from '@angular/core';
import {NxDatepickerComponent} from "@aposin/ng-aquila/datefield";
import moment, {Moment} from "moment";


export class FromTo {
  from: Moment = moment();
  to: Moment = moment();

  constructor(from: moment.Moment, to: moment.Moment) {
    this.from = from;
    this.to = to;
  }
}

@Component({
  selector: 'app-date-range-dialog',
  templateUrl: './date-range-dialog.component.html',
  styleUrls: ['./date-range-dialog.component.css']
})
export class DateRangeDialogComponent {
  @ViewChild('endDatepicker', {static: true})
  endDatepicker!: NxDatepickerComponent<Moment>;

  fromTo: FromTo = new FromTo(moment().subtract(30, "days"), moment());
  today: Moment = moment();

  updateEndDatepicker() {
    this.fromTo.to = this.fromTo.from;
    setTimeout(() => this.endDatepicker.open());
  }
}
