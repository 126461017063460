import {Component, EventEmitter, Input, OnInit, Output, Type} from '@angular/core';
import {AttachmentService} from "../../../services/attachment/attachment.service";
import {lastValueFrom} from "rxjs";
import {AttachmentElementComponent} from "../attachment-element/attachment-element.component";
import {AttachmentModel} from "../../../services/attachment/attachment.model";

@Component({
  selector: 'app-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.css']
})
export class PdfUploadComponent implements OnInit {

  @Input() itemAttachments: AttachmentModel[] = [];
  @Input() firstPanelExpanded = false;
  @Input() secondPanelExpanded = false;
  @Input() selectedAttachment?: AttachmentModel;
  @Output() selectedAttachmentChange: EventEmitter<any> = new EventEmitter<any>();

  imgViewComponent: Type<any> = AttachmentElementComponent;
  availableAttachments: AttachmentModel[] = [];


  constructor(private attachmentService: AttachmentService,) {
  }

  async ngOnInit(): Promise<void> {
    this.availableAttachments = await lastValueFrom(this.attachmentService.getAllIByExtension('pdf'));

    this.availableAttachments = this.availableAttachments.filter(attachment => {
      return !this.itemAttachments.map(a => a.id).includes(attachment.id);
    });
    if (this.selectedAttachment) {
      this.itemAttachments = [this.selectedAttachment];
    }
  }


  attachmentRefresh(): void {
    this.selectedAttachment = this.itemAttachments[0];
    this.selectedAttachmentChange.emit(this.itemAttachments[0])
  }

  canBeAddedToItemList() {
    return this.itemAttachments.length < 1;
  }

  attachmentSave(attachment: any): void {
    if (this.canBeAddedToItemList()) {
      this.itemAttachments.push(attachment);
    } else {
      this.availableAttachments.push(attachment);
    }
  }

}
