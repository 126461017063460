import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {
  SupplierOrder,
  SupplierOrderElement,
  SupplierOrderStatus
} from "../../../services/supplier-order/supplier-order.model";
import {SupplierOrderDtoType, SupplierOrderService} from "../../../services/supplier-order/supplier-order.service";
import {ActivatedRoute} from "@angular/router";
import {Month, MonthByIndex, StockProduct, WarehouseModel} from "../../../services/warehouse/warehouse.model";
import {ProductHistoryModel, SupplierProduct} from "../../../services/product/product.model";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {NxDialogService, NxModalRef} from '@aposin/ng-aquila/modal';
import {Moment} from "moment"
import {SupplierModel} from "../../../services/supplier/supplier.model";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-supplier-order-edit-form',
  templateUrl: './supplier-order-edit-form.component.html',
  styleUrls: ['./supplier-order-edit-form.component.css']
})
@Page({
  path: ['edit/:id'],
  pathPrefix: ROUTING.SUPPLIER_ORDER,
})
export class SupplierOrderEditFormComponent extends FormPage<SupplierOrder, SupplierOrderService> implements OnInit, OnDestroy {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  protected filter: any = {productName: '', supplierProductName: '', supplierName: ''};
  protected readonly MonthByIndex = MonthByIndex;
  protected readonly SupplierOrderStatus = SupplierOrderStatus;
  @ViewChild('template') templateRef!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  actualMonth!: string;
  note?: string;
  estimatedDate?: Moment;
  editMode = false;
  availableStockProduct: StockProduct[] = [];
  supplier?: SupplierModel

  constructor(service: SupplierOrderService, route: ActivatedRoute, private readonly dialogService: NxDialogService) {
    super(route, service, SupplierOrder, []);
  }

  ngOnInit(): void {
    this.initActualMonth();
    this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected override afterModelIsSetProcess() {
    if (this.formModel.id && this.formModel.id > 0) {
      this.supplier = this.getSupplierFromFormModel();
      if (this.supplier) {
        this.editMode = true;
        this.initFormModel();
        this.availableStockProduct = this.formModel.warehouse.products;
      }
      setTimeout(() => {
        this.componentIsReady = true
      }, 600);
    } else {
      this.service.notificationService.showWarning('general.title', 'supplierOrder.noSupplierForEdit');
    }
  }

  update(element: SupplierOrderElement, stockProduct: StockProduct) {
    element.count = stockProduct.orderCount;
    this.service.notificationService.showInfo('general.info', 'general.successUpdate')
  }

  override saveProcess() {
    this.service.notificationService.showInfo('general.info', 'supplierOrder.updateNotAvailable')
  }

  override updateProcess() {

    const patchSupplierOrderDTO = new SupplierOrder();
    patchSupplierOrderDTO.id = this.formModel.id;
    patchSupplierOrderDTO.elements = [];

    const addPatchSupplierOrderDTO = new SupplierOrder();
    addPatchSupplierOrderDTO.id = this.formModel.id;
    addPatchSupplierOrderDTO.elements = [];

    this.formModel.elements.forEach(element => {
      if (element.id) {
        patchSupplierOrderDTO.elements.push(element);
      } else {
        addPatchSupplierOrderDTO.elements.push(element)
      }
    });

    const update$ = this.service.updateElement(this.formModel.id, patchSupplierOrderDTO);
    const add$ = this.service.addElement(this.formModel.id, addPatchSupplierOrderDTO);
    forkJoin([update$, add$]).subscribe(res => {
      this.service.notificationService.showSuccess('general.success', 'general.successUpdate');
      this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
      this.service.navigateToBase();
    });
  }

  remove(element: SupplierOrderElement) {
    this.service.removeElement(this.formModel.id, element).subscribe(res => {
      this.service.notificationService.showSuccess('general.success', 'general.successRemove');
      this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
    });
  }

  add(stockProduct: StockProduct) {
    stockProduct.addToOrder = true;
    const supplierOrderElement = new SupplierOrderElement();
    supplierOrderElement.supplierProduct = stockProduct.product.supplierProduct;
    supplierOrderElement.prices = stockProduct.product.supplierProduct.prices;
    supplierOrderElement.count = stockProduct.orderCount;
    this.formModel.elements.push(supplierOrderElement);

  }

  removeNew(stockProduct: StockProduct) {
    stockProduct.addToOrder = false;
    this.formModel.elements = this.formModel.elements.filter(e => e.supplierProduct.id == stockProduct.product.supplierProduct.id);
  }

  onOpenFromTemplate(stockProduct?: StockProduct): void {

    this.templateDialogRef = this.dialogService.open(this.templateRef, {
      ariaLabel: 'A simple dialog',
      disableClose: true
    });
    this.templateDialogRef.afterClosed().subscribe(result => {
      if (stockProduct) {
        stockProduct.orderNote = this.note;
        stockProduct.estimatedDate = this.estimatedDate;
      }
    });
  }

  onCloseTemplateDialog() {
    this.templateDialogRef?.close();
  }

  onFilter() {
    if (this.filter.productName.length < 1 && this.filter.supplierProductName.length < 1) {
      this.availableStockProduct = this.formModel.warehouse.products;
    }
    this.availableStockProduct = this.formModel.warehouse.products.filter(sp => {

      const isProd = sp.product.product.name.toLowerCase().includes(this.filter.productName.toLowerCase());
      const isSupProd = sp.product.supplierProduct.name.toLowerCase().includes(this.filter.supplierProductName.toLowerCase());
      const isSup = sp.product.supplierProduct.supplier.companyName.toLowerCase().includes(this.filter.supplierName.toLowerCase());

      return isProd && isSupProd && isSup
    });

  }

  clearFilter() {
    this.filter.productName = '';
    this.filter.supplierProductName = '';
    this.filter.supplierName = '';
    this.onFilter();
  }

  approve(tempElement: SupplierOrderElement) {
    this.service.notificationService.confirm('inventory.approveElementConfirm', 'inventory.approveElementConfirmDesc', 'general.yes', 'general.no').then(res => {
      if (res.isConfirmed) {
        this.service.approveElement(this.formModel.id, tempElement).subscribe(result => {
          this.service.notificationService.showSuccess('general.success', 'inventory.successApprove');
          this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
        })
      }
    })

  }

  private initFormModel() {
    if (this.formModel.note) {
      this.formModel.message = this.formModel.note.message
    }
    this.formModel.warehouse = this.parser.parseApiDataToModel(this.formModel.warehouse, WarehouseModel);
    this.formModel.warehouse.products = this.formModel.warehouse.products
      .filter(sp => sp.product.supplierProduct.supplier.id == this.supplier?.id);
    this.formModel.warehouse.products.forEach(sp => {
      this.initStockProduct(sp);
    });
  }

  private initStockProduct(sp: StockProduct) {
    sp.product = this.parser.parseApiDataToModel(sp.product, ProductHistoryModel);
    const element = this.formModel.elements.find(s => s.supplierProduct.id == sp.product.supplierProduct.id);
    if (element) {
      sp.orderCount = element.count
      sp.tempElement = element;
      sp.addToOrder = true;
      element.supplierProduct = this.parser.parseApiDataToModel(element.supplierProduct, SupplierProduct);
    }
    sp.tempElement = element;
  }

  private initActualMonth() {
    const actualMonthIndex = (new Date()).getMonth();
    this.actualMonth = Object.keys(Month)[actualMonthIndex];
  }

  private getSupplierFromFormModel(): SupplierModel | undefined {
    if (this.formModel.elements[0] && this.formModel.elements[0].supplierProduct) {
      return this.formModel.elements[0].supplierProduct.supplier
    }
    return undefined;
  }
}
