import {Component, Inject} from '@angular/core';
import {StringStringMap} from "../../../utilities/types";
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

@Component({
  selector: 'app-add-dynamic-attribute',
  templateUrl: './add-dynamic-attribute.component.html',
  styleUrls: ['./add-dynamic-attribute.component.css']
})
export class AddDynamicAttributeComponent {

  dynamicAttribute: StringStringMap = {};

  constructor(@Inject(NX_MODAL_DATA) public model: StringStringMap) {

  }

}
