import {MyTreeNode} from "../../components/layout-sidebar/layout-sidebar.component";
import {ROUTING} from "../../utilities/routing";

export const ADMIN_MENU: MyTreeNode[] = [
  {
    icon: 'lines-leaning',
    label: 'menu.orderMain',
    query: '',
    children: [
      {
        icon: 'cart-shopping',
        label: 'menu.userOrder',
        query: ROUTING.ORDER,
      },
      {
        icon: 'cart-flatbed',
        label: 'menu.supplierOrder',
        query: ROUTING.SUPPLIER_ORDER,
      },
      {
        icon: 'info',
        label: 'menu.orderInfo',
        query: ROUTING.ORDER_INFO,
      },
    ]
  },
  {
    icon: 'warehouse',
    label: 'menu.warehouseMain',
    query: '',
    children: [
      {
        icon: 'house',
        label: 'menu.warehouse',
        query: ROUTING.WAREHOUSE,
      }, {
        icon: 'dolly',
        label: 'menu.goodsLanding',
        query: ROUTING.GOODS_LANDING,
      },
      {
        icon: 'right-left',
        label: 'menu.stockTransfer',
        query: ROUTING.STOCK_TRANSFER,
      },
      {
        icon: 'cubes',
        label: 'menu.inventory',
        query: ROUTING.INVENTORY,
      },
      {
        icon: 'scroll',
        label: 'menu.scrapping',
        query: ROUTING.SCRAPPING,
      },
    ]
  },
  {
    icon: 'box',
    label: 'menu.productMain',
    query: '',
    children: [
      {
        icon: 'truck-ramp-box',
        label: 'menu.supplier',
        query: ROUTING.SUPPLIER,
      },
      {
        icon: 'boxes-stacked',
        label: 'menu.category',
        query: ROUTING.CATEGORY,
      },
      {
        icon: 'leaf',
        label: 'menu.marketingProduct',
        query: ROUTING.MARKETING_PRODUCT,
      },
      {
        icon: 'leaf',
        label: 'menu.salesProduct',
        query: ROUTING.SALES_PRODUCT,
      },
    ]
  },
  {
    icon: 'newspaper',
    label: 'menu.publicContent',
    query: '',
    children: [
      {
        icon: 'newspaper',
        label: 'menu.magazine',
        query: ROUTING.MAGAZINE,
      },
      {
        icon: 'blog',
        label: 'menu.blog',
        query: ROUTING.ARTICLE,
      },
      {
        icon: 'table-cells',
        label: 'menu.productMatrix',
        query: ROUTING.PRODUCT_MATRIX,
      },
      {
        icon: 'at',
        label: 'menu.author',
        query: ROUTING.AUTHOR,
      },
    ]
  },
  {
    icon: 'apple-whole',
    label: 'menu.technologiesMain',
    query: '',
    children: [
      {
        icon: 'wheat-awn',
        label: 'menu.plantCulture',
        query: ROUTING.PLANT_CULTURE,
      },
      {
        icon: 'umbrella-beach',
        label: 'menu.plantIssueType',
        query: ROUTING.PLANT_CULTURE_ISSUE_TYPE,
      },
      {
        icon: 'viruses',
        label: 'menu.plantIssue',
        query: ROUTING.PLANT_CULTURE_ISSUE,
      },
      {
        icon: 'arrows-to-eye',
        label: 'menu.technologies',
        query: ROUTING.TECHNOLOGIES,
      }
    ]
  },
  {
    icon: 'file',
    label: 'menu.attachment',
    query: '',
    children: [
      {
        icon: 'flag',
        label: 'menu.banner',
        query: ROUTING.BANNER,
      },
      {
        icon: 'file',
        label: 'menu.attachment',
        query: ROUTING.ATTACHMENT,
      },
    ]
  },
  {
    icon: 'tag',
    label: 'menu.tag',
    query: ROUTING.TAG,
  },
  {
    icon: 'user',
    label: 'menu.user',
    query: ROUTING.USER,
  },
  {
    icon: 'info',
    label: 'menu.systemInformation',
    query: '',
    children: [
      {
        icon: 'paragraph',
        label: 'menu.aboutUs',
        query: ROUTING.ABOUT_US,
      },
      {
        icon: 'shield-halved',
        label: 'menu.privacyPolicy',
        query: ROUTING.PRIVACY_POLICY,
      },
      {
        icon: 'asterisk',
        label: 'menu.termsAndCondition',
        query: ROUTING.TERMS_AND_CONDITION
      },
      {
        icon: 'certificate',
        label: 'menu.bioCert',
        query: ROUTING.BIO_CERT
      },
      {
        icon: 'address-book',
        label: 'menu.contact',
        query: ROUTING.CONTACT,
      },
    ]
  }
]
