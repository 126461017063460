import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ListPage} from "../../../utilities/abstract/list-page";
import {TermsAndConditionModel} from "../../../services/terms-and-condition/terms-and-condition.model";
import {
  TermsAndConditionDtoTypes,
  TermsAndConditionService
} from "../../../services/terms-and-condition/terms-and-condition.service";
import {TableAction, TableCol} from "@hellp/table";
import {lastValueFrom} from "rxjs";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";

@Component({
  selector: 'app-terms-and-condition-list',
  templateUrl: './terms-and-condition-list.component.html',
  styleUrls: ['./terms-and-condition-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.TERMS_AND_CONDITION
})
export class TermsAndConditionListComponent extends ListPage<TermsAndConditionModel, TermsAndConditionService> implements OnInit {
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>;

  constructor(service: TermsAndConditionService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [];

    this.colDef = [
      TableCol.newString('status', await this.getTranslate('article.status')),
      TableCol.newDate('createdAt', await this.getTranslate('general.createdAt')),
      TableCol.newString('attachment.name', await this.getTranslate('general.attachmentName')),
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(TermsAndConditionDtoTypes.TermsAndConditionsDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

}
