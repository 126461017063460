import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductModel} from "../../../services/product/product.model";

@Component({
  selector: 'forms-product-pack-form',
  templateUrl: './product-pack-form.component.html',
  styleUrls: ['./product-pack-form.component.css']
})
export class ProductPackFormComponent {

  @Input() model!: ProductModel;
  @Output() modelChange: EventEmitter<ProductModel> = new EventEmitter();
}
