<div nxRow="" nxRowAlignItems="center" nxRowJustify="center">
  <div nxCol="10">
    <nx-formfield nxLabel="{{'product.publishedProducts' | translate}}" appearance="auto">
      <input
        nxInput
        type="text"
        [nxAutocomplete]="auto2"
        [nxAutocompleteItems]="dynamicBackendOptions"
        [(ngModel)]="selectedName"
      />
      <nx-autocomplete #auto2="nxAutocomplete"></nx-autocomplete>
    </nx-formfield>
  </div>
  <div nxCol="2">
    <button nxFormfieldAppendix nxIconButton="primary small" type="button" (click)="onAdd()">
      <nx-icon name="plus"></nx-icon>
    </button>
  </div>
</div>
<div nxRow="" class="nx-margin-top-2xl">
  <ng-container *ngFor="let relatedProduct of relatedProducts">
    <div nxCol="12,12,6,4" class="text-end">
      <display-product-card [product]="relatedProduct.relatedProduct"></display-product-card>
      <button nxButton="danger small" (click)="onRemove(relatedProduct)">{{ 'general.remove' | translate }}</button>
    </div>
  </ng-container>
</div>
