<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">

  <div nxRow="">
    <div nxCol="12" class="nx-margin-y-2m">
      <hellp-stepper-container [steps]="steps"></hellp-stepper-container>
    </div>
    <div nxCol="12" class="text-end">
      <button
        nxButton="primary small"
        (click)="onSave()"
        class="nx-margin-bottom-s"
        type="button">
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</hellp-form-container>

<ng-template #baseData>
  <div class="card p-3 mb-4 box-shadow">
    <h4 nxHeadline="subsection-small">{{ 'category.baseInformation' | translate }}</h4>
    <hr class="hr-dark">
    <forms-category-form [model]="formModel" [parents]="parents"
                         [validationError]="validationError"></forms-category-form>
  </div>

  <div class="card p-3 mb-4 box-shadow">
    <h4 nxHeadline="subsection-small">{{ 'category.dynamicAttributes' | translate }}</h4>
    <hr class="hr-dark">
    <forms-dynamic-attribute-form [(dynamicAttribute)]="formModel.dynamicAttributes"></forms-dynamic-attribute-form>
  </div>
</ng-template>
<ng-template #attachmentData>
  <app-image-upload [(selectedAttachment)]="formModel.image.attachment" [secondPanelExpanded]="true"></app-image-upload>
  <nx-error class="nx-margin-top-m" nxFormfieldError
            *ngIf="validationError.attachment"> {{ validationError.attachment | translate }}
  </nx-error>
</ng-template>
<ng-template #orderConfigData>
  <forms-order-config-form [(cartNote)]="formModel.cartNote" [(orderConfig)]="formModel.orderConfig"
                           [validationError]="validationError"></forms-order-config-form>
</ng-template>
<ng-template #summaryData>
  <div class="card p-3 mb-4 box-shadow">
    <div nxRow="">
      <div nxCol="12,12,12,4,4">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="circle-info"
            title="{{'category.baseInformation' | translate}}"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <app-category-base-panel [model]="formModel"></app-category-base-panel>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12,12,12,4,4">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="tag"
            title="{{'category.dynamicAttributes' | translate}}"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <app-dynamic-attribute-panel
                [dynamicAttribute]="formModel.dynamicAttributes"></app-dynamic-attribute-panel>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12,12,12,4,4">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="cart-shopping"
            title="{{'category.orderConfig' | translate}}"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <app-category-order-config-panel [model]="formModel.orderConfig"></app-category-order-config-panel>
            </div>
          </div>
        </nx-card>
      </div>
    </div>
  </div>
</ng-template>

