import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PriceModel} from "../../../models/price.model";
import {Currencies} from "../../../utilities/types";
import {FormComponent} from "../../../utilities/abstract/form-component";
import {ProductService} from "../../../services/product/product.service";

@Component({
  selector: 'forms-price-form',
  templateUrl: './price-form.component.html',
  styleUrls: ['./price-form.component.css']
})
export class PriceFormComponent extends FormComponent implements OnInit {
  @Input() title?: string;
  @Input() currencies: string[] = ['HUF']
  @Input() priceModels?: PriceModel[]
  @Output() priceModelsChange: EventEmitter<PriceModel[]> = new EventEmitter();
  @Input() priceModel?: PriceModel;
  @Output() priceModelChange: EventEmitter<PriceModel> = new EventEmitter();
  @Input() withoutVat = false;
  @Input() currencySelect = false;
  @Input() defaultVat?: number

  protected readonly Currencies = Currencies;

  constructor(service: ProductService) {
    super(service);
  }

  ngOnInit(): void {
    if (this.priceModels) {
      for (const currency of this.currencies) {
        const exist = this.priceModels.find(p => p.currency == currency);
        if (!exist) {
          this.priceModels.push(new PriceModel(currency as Currencies, 0, this.defaultVat ?? 0));
        }
      }
    }
    if (this.defaultVat && this.priceModel) {
      this.priceModel.vat = this.defaultVat;
    }
    this.initErrorHandlingOnInput();
  }
}
