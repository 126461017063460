import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {ProductMatrixModel} from "./product-matrix.model";
import {ProductMatrixRepository} from "./product-matrix.repository";

export enum ProductMatrixRelationDtoTypes {
  ProductMatrixRelationDTO = 'ProductMatrixRelationDTO'
}

@Injectable({
  providedIn: 'root'
})
export class ProductMatrixService extends CrudService<ProductMatrixModel, ProductMatrixRepository<ProductMatrixModel>> {


  constructor(repo: ProductMatrixRepository<ProductMatrixModel>) {
    super(ROUTING.PRODUCT_MATRIX, repo, ProductMatrixModel);
  }
}

