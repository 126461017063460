import {environment} from "../../../environments/environment";

export abstract class AttachmentUrlService {

  private static IMAGES_URL = 'images';
  private static RAW_URL = 'raw';

  public static getSvgImageUrl(slug: string) {
    return `${environment.attachmentServiceUrl}/${AttachmentUrlService.IMAGES_URL}/${slug}`;
  }

  public static getScaleHeightImageUrl(slug: string, height: number) {
    return `${environment.attachmentServiceUrl}/${AttachmentUrlService.IMAGES_URL}/scale/height/${height}/${slug}`;
  }

  public static getScaleWidthImageUrl(slug: string, width: number) {
    return `${environment.attachmentServiceUrl}/${AttachmentUrlService.IMAGES_URL}/scale/width/${width}/${slug}`;
  }

  public static getDownloadUrl(slug: string) {
    return `${environment.attachmentServiceUrl}/${AttachmentUrlService.RAW_URL}/${slug}`;
  }

}
