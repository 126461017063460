import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {PurchaseInfoDtoTypes, PurchaseInfoService} from "../../../services/purchase-info/purchase-info.service";
import {ActivatedRoute} from "@angular/router";
import {lastValueFrom, takeUntil} from "rxjs";
import {FormToolBarOption} from "../../../components/form-header/form-tool-bar-option.model";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {HellpNotificationServiceType} from "@hellp/service";
import {ExtraPriceModel} from "../../../services/category/category.model";
import {PriceModel} from "../../../models/price.model";
import {Currencies} from "../../../utilities/types";
import {DeliveryPartner, ExtraPriceType, PurchaseInfoModel} from "../../../services/purchase-info/purchase-info.model";
import {TableAction, TableCol} from "@hellp/table";
import {TranslateService} from "@ngx-translate/core";
import {Utilities} from "../../../utilities/utilities";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {
  AddDeliveryPartnerComponent
} from "../../../components/dialogs/add-delivery-partner/add-delivery-partner.component";

@Component({
  selector: 'app-purchase-info-form',
  templateUrl: './purchase-info-form.component.html',
  styleUrls: ['./purchase-info-form.component.css']
})
@Page({
  path: [''],
  pathPrefix: ROUTING.ORDER_INFO,
})
export class PurchaseInfoFormComponent extends FormPage<PurchaseInfoModel, PurchaseInfoService> implements OnInit, OnDestroy {
  @ViewChild('logoTpl') logoTpl!: TemplateRef<any>
  colDef!: TableCol[];
  actionDef!: TableAction[];
  isEdit = false;
  toolbarAddOptions!: FormToolBarOption[];
  newKeyValue = '0';
  cashMapPrice = 0;
  newDeliveryKey = '0';
  newDeliveryValue = 0;
  componentDialogRef!: NxModalRef<AddDeliveryPartnerComponent>;
  deliveryPartners: DeliveryPartner[] = [];


  constructor(route: ActivatedRoute, service: PurchaseInfoService, private elementRef: ElementRef, private translateService: TranslateService, public dialogService: NxDialogService,) {
    super(route, service, PurchaseInfoModel, []);

  }

  ngOnDestroy(): void {
    this._destroyed.unsubscribe();
  }

  ngOnInit(): void {
    this.toolbarAddOptions = [
      // new FormToolBarOption(this.edit(), 'pencil', 'general.edit'),
      // new FormToolBarOption(this.openAddImageModal(), 'file', 'general.addImage'),

    ]
    this.loadData();
    this.sortPriceMap();
  }

  async loadData() {
    const result = await lastValueFrom(this.service.getAllByDeleted(PurchaseInfoDtoTypes.PurchaseInfoDTO));
    if (result.length > 0) {
      this.formModel = result[0];
    }
    this.actionDef = [
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit')),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete')),
      new TableAction('circle-check', this.addToPurchaseConsumer(), await this.getTranslate('table.action.add'), this.isAddablePredicate()),
      new TableAction('ban', this.removeFromPurchaseConsumer(), await this.getTranslate('table.action.remove'), this.removablePredicate()),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
      TableCol.newString('name', await this.getTranslate('deliveryPartner.name')),
      TableCol.newString('shortDescription', await this.getTranslate('deliveryPartner.shortDesc')),
      TableCol.newString('logo', await this.getTranslate('deliveryPartner.logo')).template(() => this.logoTpl),
    ];
    this.loadDeliveryPartner();
    this.componentIsReady = true;
  }

  private loadDeliveryPartner() {
    this.deliveryPartners = [];
    this.service.getAllDeliveryPartners().pipe(takeUntil(this._destroyed)).subscribe(result => {
      this.deliveryPartners = result;
    });
  }

  edit() {
    return () => {
      this.isEdit = true;
    };
  }


  override cancel(): () => void {
    return () => {
      this.isEdit = false;
    };
  }

  protected override savePostProcess() {
    this.loadData();
    this.sortPriceMap();
    this.isEdit = false;

  }

  removeCashPrice(priceMapKey: string) {
    this.formModel.cashPrices = this.formModel.cashPrices.filter(kex => kex.key != priceMapKey);
    this.service.notificationService.showInfo('general.info', "orderInfo.removeCashPriceElement", HellpNotificationServiceType.Toast);
  }

  addCashPrice() {
    if (parseInt(this.newKeyValue) > 0) {
      this.formModel.cashPrices.push({
        key: this.newKeyValue,
        extraPrice: (new ExtraPriceModel(ExtraPriceType.CASH_PRICE, new PriceModel(Currencies.huf, this.cashMapPrice, 0)))
      });
      this.sortPriceMap();
      this.scrollToElement('#price' + this.newKeyValue);
      this.newKeyValue = '0';
      this.cashMapPrice = 0;
    } else {
      this.service.notificationService.showWarning('general.warning', "orderInfo.keyShouldBigger", HellpNotificationServiceType.Toast);
    }
  }

  removeDeliveryPrice(priceMapKey: string) {
    this.formModel.deliveryPrices = this.formModel.deliveryPrices.filter(kex => kex.key != priceMapKey);
    this.service.notificationService.showInfo('general.info', "orderInfo.removeDeliveryPriceElement", HellpNotificationServiceType.Toast);
  }

  addDeliveryPrice() {
    if (parseInt(this.newDeliveryKey) > 0) {
      this.formModel.deliveryPrices.push({
        key: this.newDeliveryKey,
        extraPrice: (new ExtraPriceModel(ExtraPriceType.DELIVERY_PRICE, new PriceModel(Currencies.huf, this.newDeliveryValue, 0)))
      });
      this.sortDeliveryMap();
      this.scrollToElement('#delivery' + this.newDeliveryKey);
      this.newDeliveryKey = '0';
      this.newDeliveryValue = 0;
    } else {
      this.service.notificationService.showWarning('general.warning', "orderInfo.keyShouldBigger", HellpNotificationServiceType.Toast);
    }
  }

  private sortPriceMap() {
    this.formModel.cashPrices = this.formModel.cashPrices.sort((a, b) => {
      return parseInt(a.key) - parseInt(b.key);
    });
  }

  private sortDeliveryMap() {
    this.formModel.deliveryPrices = this.formModel.deliveryPrices.sort((a, b) => {
      return parseInt(a.key) - parseInt(b.key);
    });
  }

  scrollToElement(querySelector: string) {
    const targetElement = this.elementRef.nativeElement.querySelector(querySelector);

    if (targetElement) {
      targetElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }

  protected async getTranslate(key: string): Promise<string> {
    return (await lastValueFrom(this.translateService.get(key)));
  }

  private editConsumer() {
    return (row: DeliveryPartner) => {
      this.dialogService.open(
        AddDeliveryPartnerComponent,
        Utilities.getDefaultCloseIconModalConfig(row)
      ).afterClosed().subscribe(result => {
        this.saveDeliveryPartner(result);
      });
    };
  }

  private deleteConsumer() {
    return (row: DeliveryPartner) => {
      this.service.notificationService.confirm(
        this.translateService.instant('general.warning'),
        this.translateService.instant('general.areUSure'),
        'general.ok',
        'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.deleteDeliveryPartner(row.id));
          this.loadDeliveryPartner();
        }
      });
    }
  }

  addNewDeliveryPartner() {
    this.dialogService.open(
      AddDeliveryPartnerComponent,
      Utilities.getDefaultCloseIconModalConfig(new DeliveryPartner())
    ).afterClosed().subscribe(result => {
      this.saveDeliveryPartner(result);
    });
  }

  private saveDeliveryPartner(result: DeliveryPartner) {
    if (result.id && result.id > 0) {
      this.service.updateDeliveryPartner(result).subscribe(result => {
        this.loadDeliveryPartner();
        this.service.notificationService.showSuccess("general.success", "orderInfo.deliveryPartnerSaveSuccess");
      });
    } else {
      this.service.saveDeliveryPartner(result).subscribe(result => {
        this.loadDeliveryPartner();
        this.service.notificationService.showSuccess("general.success", "orderInfo.deliveryPartnerSaveSuccess");
      });
    }
  }


  private refreshDeliveryPartners() {
    const tmp = [...this.deliveryPartners];
    this.deliveryPartners = [];
    this.deliveryPartners = [...tmp];
  }

  private removeFromPurchaseConsumer() {
    return (row: DeliveryPartner) => {
      const index = this.formModel.deliveryPartners.map(dp => dp.id).indexOf(row.id);
      this.formModel.deliveryPartners.splice(index, 1);
      this.service.notificationService.showSuccess("general.success", "orderInfo.successRemovePartner");
      this.refreshDeliveryPartners();
    };
  }

  private addToPurchaseConsumer() {
    return (row: DeliveryPartner) => {
      this.formModel.deliveryPartners.push(row);
      this.service.notificationService.showSuccess("general.success", "orderInfo.successAddPartner");
      this.refreshDeliveryPartners();
    };
  }

  private removablePredicate() {
    return (row: DeliveryPartner) => {
      const exist = this.formModel.deliveryPartners.find(dp => dp.id == row.id)
      return exist != undefined;
    };
  }

  private isAddablePredicate() {
    return (row: DeliveryPartner) => {
      const exist = this.formModel.deliveryPartners.find(dp => dp.id == row.id)
      return exist == undefined;
    };
  }
}
