import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {filter, map} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor(private titleService: Title,
              public activatedRoute: ActivatedRoute,
              public translateService: TranslateService,
              public router: Router) {
  }

  ngOnInit(): void {
    this.setTitleByPageDecorator();
  }

  private setTitleByPageDecorator() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this.activatedRoute.firstChild;
        if (child != null && child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      })
    ).subscribe((ttl: string) => {
      this.translateService.get(ttl).subscribe((result: string) => {
        this.titleService.setTitle(result);
      });
    });
  }
}
