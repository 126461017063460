import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {AttachmentModel} from "./attachment.model";
import {AttachmentRepository} from "./attachment.repository";
import {ROUTING} from "../../utilities/routing";
import {ValidatorService} from "../../utilities/service/validator.service";
import {BehaviorSubject, tap} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends CrudService<AttachmentModel, AttachmentRepository> {
  $images = new BehaviorSubject<AttachmentModel[]>([]);

  constructor(repo: AttachmentRepository) {
    super(ROUTING.ATTACHMENT, repo, AttachmentModel);
    this.loadAllImages();
  }


  loadAllImages(): void {
    this.getAllImages().pipe(
      tap(data => this.$images.next(data))
    ).subscribe();
  }


  getAllIByExtension(extension: string) {
    return this.repo.getAllIByExtension(extension);
  }

  getAllImages() {
    return this.repo.getAllImages();
  }

  override validate(model: AttachmentModel) {
    ValidatorService.required(model.name, "attachment.nameRequired");
  }


}

