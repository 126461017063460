import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'page-formly-form',
  templateUrl: './formly-form.component.html',
  styleUrls: ['./formly-form.component.css']
})
export class FormlyFormComponent implements OnInit {
  @Input() isOpenFormSection = false;
  @Output() isOpenFormSectionChange:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() formModel: any;
  @Input() fields!: FormlyFieldConfig[];
  @Input() options: FormlyFormOptions = {};

  @Input() saveSupplier!: () => void;
  @Input() cancelSupplier!: () => void;
  @Input() form = new FormGroup({});
  @Input() headline!: string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
