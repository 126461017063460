import {KeycloakProfile} from "keycloak-js";
import {AUTH_USER_KEY} from "../../app.config";
import {ServiceLocator} from "../service/service.locator";
import {ExtendedStorageService} from "@hellp/service";

export abstract class PermissionCheck {
  protected extendedStorageService: ExtendedStorageService;
  protected authUser: KeycloakProfile | any;
  public hasPermission = false;

  protected constructor() {
    this.extendedStorageService = ServiceLocator.injector.get(ExtendedStorageService);
  }

  protected initPermission() {
    this.authUser = this.extendedStorageService.getFromCookies(AUTH_USER_KEY, {});
    this.hasPermission = this.permission();

  }

  protected permission(): boolean {
    // const chmodElement = this.authUser.attributes.chmod[ROLE_INDEX[USER_ROLES.ADMIN]];
    return true;
  }

  protected rowPermission(): (row: any) => boolean {
    return (row: any) => {
      return this.hasPermission;
    }
  }
}
