<div nxModalContent>
  <nx-card class="w-100">
    <form #invoiceForm="ngForm">
      <div class="row">
        <div class="col-md-12">
          <h3>{{ data.partnerName }}</h3>
          <nx-formfield appearance="outline" nxLabel="{{'supplierInvoice.invoiceNumber' | translate}}*">
            <input nxInput type="text" [(ngModel)]="data.invoiceNumber" required
                   name="invoiceNumber"/>
          </nx-formfield>
        </div>
        <div class="col-12">
          <div class="scroll-container">
            <table nxTable zebra>
              <thead>
              <tr nxTableRow>
                <th nxHeaderCell>{{ 'supplierInvoice.productName' | translate }}</th>
                <th nxHeaderCell>{{ 'supplierInvoice.netPrice' | translate }}</th>
                <th nxHeaderCell>{{ 'supplierInvoice.count' | translate }}</th>
                <th nxHeaderCell>{{ 'supplierInvoice.countArrived' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let item of data.elements">
                <tr nxTableRow>
                  <td nxTableCell>{{ item.productName }}</td>
                  <td nxTableCell>
                    <nx-formfield appearance="outline">
                      <input nxInput type="text" [(ngModel)]="item.net"
                             name="itemNet"/>
                      <span nxFormfieldSuffix>{{ data.currency }}</span>
                    </nx-formfield>
                  </td>
                  <td nxTableCell>{{ item.count }}</td>
                  <td nxTableCell>
                    <nx-formfield appearance="outline">
                      <input nxInput type="text" [(ngModel)]="item.countArrived"
                             name="itemCount"/>
                    </nx-formfield>
                  </td>

                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </form>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [disabled]="!invoiceForm.valid"
    [nxModalClose]="data"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.save' | translate }}
  </button>
</div>
