import {Component, Input, OnInit} from '@angular/core';
import {TableAction} from "@hellp/table";
import {CategoryModel} from "../../services/category/category.model";
import {Utilities} from "../../utilities/utilities";

@Component({
  selector: 'app-category-element',
  templateUrl: './category-element.component.html',
  styleUrls: ['./category-element.component.scss']
})
export class CategoryElementComponent {

  @Input() public category!: CategoryModel;
  @Input() public parent!: CategoryModel | null;
  @Input() public actionDef!: TableAction[];
  @Input() public recursive = true;
  showSubTrans = false;


  isHidden(row: any, action: TableAction): boolean {
    if (action.isVisiblePredicate) {
      return action.isVisiblePredicate(row);
    }
    return true;
  }

  get descriptionWithoutHtml() {
    return Utilities.removeHtmlTags(this.category.description, true);
  }

}
