import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {AboutUsModel} from "../../../services/about-us/about-us.model";
import {AboutUsDtoTypes, AboutUsService} from "../../../services/about-us/about-us.service";
import {ActivatedRoute} from "@angular/router";
import {Editor, Toolbar} from "ngx-editor";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {takeUntil} from "rxjs";
import {FormToolBarOption} from "../../../components/form-header/form-tool-bar-option.model";
import {NGX_TOOLBAR} from "../../../utilities/types";

@Component({
  selector: 'app-about-us-form',
  templateUrl: './about-us-form.component.html',
  styleUrls: ['./about-us-form.component.css']
})

@Page({
  path: [''],
  pathPrefix: ROUTING.ABOUT_US,
})
export class AboutUsFormComponent extends FormPage<AboutUsModel, AboutUsService> implements OnInit, OnDestroy {
  editor: Editor = new Editor();
  toolbar: Toolbar = NGX_TOOLBAR;
  isEdit = false;
  toolbarAddOptions!: FormToolBarOption[];

  constructor(route: ActivatedRoute, service: AboutUsService) {
    super(route, service, AboutUsModel, []);
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  loadData() {
    this.service.getAllByDeleted(AboutUsDtoTypes.AboutUsDTO).pipe(
      takeUntil(this._destroyed)
    ).subscribe(result => {
      if (result.length > 0) {
        this.formModel = result[0];
      } else {
        this.service.notificationService.showWarning('general.warning', 'general.noInformation');
      }
    });
  }

  edit() {
    return () => {
      this.isEdit = true;
    };
  }

  override cancel(): () => void {
    return () => {
      this.isEdit = false;
    };
  }

  protected override savePostProcess() {
    this.loadData();
    this.isEdit = false;
  }
}
