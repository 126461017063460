import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {AdminLayout} from "../../../layouts/admin-layout/admin.layout";
import {ListPage} from "../../../utilities/abstract/list-page";
import {MagazineModel, MagazineStatus} from "../../../services/article/magazine.model";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {map, takeUntil} from "rxjs";
import {ROUTING} from "../../../utilities/routing";
import {ArticleService} from "../../../services/article/article.service";
import moment from "moment";

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.ARTICLE,
  layout: AdminLayout
})
export class ArticleListComponent extends ListPage<MagazineModel, ArticleService> implements OnInit, OnDestroy {
  protected readonly MagazineStatus = MagazineStatus;
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>;

  constructor(service: ArticleService) {
    super(service);
  }

  ngOnInit(): void {
    this.initTable();
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  loadData(): void {
    this.service.getAllArticle()
      .pipe(
        takeUntil(this._destroyed),
        map((blogs: MagazineModel[]) => {
          blogs.forEach(blog => {
            blog.status = (blog.isPublishedByDate ? MagazineStatus.PUBLISHED : MagazineStatus.DRAFT)
          })
          return blogs;
        }))
      .subscribe(result => {
        this.models = result;
        this.componentIsReady = true
      });
  }


  override async initTable() {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit')),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete')),
      new TableAction('paper-plane', this.publishConsumer(), await this.getTranslate('table.action.publish'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('status', await this.getTranslate('article.status')).filterable(
        TableFilter.newSelect(Object.keys(MagazineStatus).map(this.generateStatusFilterOption()))).template(() => {
        return this.statusTpl;
      }),
      TableCol.newString('title', await this.getTranslate('article.title')),
      TableCol.newDateTime('publishedAt', await this.getTranslate('article.publishedAt')),
      TableCol.newString('author.name', await this.getTranslate('article.author')),
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
    ];
  }

  publishConsumer() {
    return (row: MagazineModel) => {
      row.publishedAt = moment();
      this.service.publish(row).subscribe(result => {
        this.service.notificationService.showInfo('general.success', 'blog.successPublished');
        this.loadData();
      })
    }
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('magazine.' + status);
      return {label: label, value: status};
    }
  }

  protected override rowPermission(): (row: MagazineModel) => boolean {
    return (row: MagazineModel) => {
      return this.hasPermission && row.status == MagazineStatus.DRAFT;
    }
  }
}
