import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {ListPage} from "../../../utilities/abstract/list-page";
import {EmailData, OrderModel} from "../../../services/order/order";
import {OrderDtoTypes, OrderService} from "../../../services/order/order.service";
import {HellpTableComponent, TableAction, TableCol, TableFilter} from "@hellp/table";
import {Observable, takeUntil} from "rxjs";
import {
  DataFilterModel,
  DeliveryType,
  HistoryStatus,
  OrderStatus,
  PaymentType,
  StorageKey
} from "../../../utilities/types";
import {CustomToolbarAction} from "../../../components/show-tool-bar/show-tool-bar.component";
import {Utilities} from "../../../utilities/utilities";
import {LoaderService} from "../../../layouts/loader/loader.service";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {DateRangeDialogComponent} from "../../../components/dialogs/date-range-dialog/date-range-dialog.component";
import moment from "moment";
import {SendEmailDialogComponent} from "../../../components/dialogs/send-email-dialog/send-email-dialog.component";
import {fileDownloadProcess} from "../../../directives/download-file.directive";

@Component({
  selector: 'app-user-order-list',
  templateUrl: './user-order-list.component.html',
  styleUrls: ['./user-order-list.component.css']
})
@Page({
  path: '/',
  pathPrefix: ROUTING.ORDER,
  data: {title: 'title.userOrders'},
  authGuard: AdminGuard
})
export class UserOrderListComponent extends ListPage<OrderModel, OrderService> implements OnInit {

  private $load: Observable<OrderModel[]>;
  protected readonly HistoryStatus = HistoryStatus;
  protected readonly OrderStatus = OrderStatus;
  protected readonly PaymentType = PaymentType;
  protected readonly DeliveryType = DeliveryType;

  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>;
  @ViewChild('paymentTpl') paymentTpl!: TemplateRef<any>;
  @ViewChild('deliveryTpl') deliveryTpl!: TemplateRef<any>;
  @ViewChild('priceTpl') priceTpl!: TemplateRef<any>;
  @ViewChild('cartElementTpl') cartElementTpl!: TemplateRef<any>;
  @ViewChild('hellpTable') hellpTable!: HellpTableComponent;
  customActions: CustomToolbarAction[] | undefined;

  componentDialogRef?: NxModalRef<DateRangeDialogComponent>;
  dataFilters: DataFilterModel[] = [
    new DataFilterModel(true, moment().subtract(7, "days"), moment(), 'last7days', (filter: any) => this.filterData(filter)),
    new DataFilterModel(false, moment().subtract(30, "days"), moment(), 'last30days', (filter: any) => this.filterData(filter)),
    new DataFilterModel(false, moment().subtract(90, "days"), moment(), 'last90days', (filter: any) => this.filterData(filter)),
    new DataFilterModel(false, moment().subtract(90, "days"), moment(), 'filterByDateRange', (filter: any) => this.filterByDateRange(filter), 'calendar')
  ]
  productNameFilter = '';
  private allModel: OrderModel[] | undefined;

  constructor(service: OrderService, private loaderService: LoaderService, private readonly dialogService: NxDialogService) {
    super(service);
    this.$load = this.service.getAllByDeleted(OrderDtoTypes.OrderDTO)
  }

  ngOnInit(): void {
    this.initDataTable();
    this.initFilters();
  }

  private initFilters() {
    const filterKeyFromStorage: string = this.extendedStorageService.getFromCookies(StorageKey.ORDER_FILTER_KEY, 'last7days');
    this.dataFilters.forEach(df => {
      df.active = df.key == filterKeyFromStorage;
    });
    const filter = this.dataFilters.find(filter => filter.active);
    if (filter && filter.key == 'filterByDateRange') {
      this.dataFilters.forEach(df => df.active = false);
      this.dataFilters[0].active = true;
      this.filterData(this.dataFilters[0]);
    } else if (filter) {
      this.filterData(filter);
    } else {
      this.loadData();
    }
  }

  loadData(): void {
    this.$load.pipe(
      takeUntil(this._destroyed)
    ).subscribe(result => {
      this.models = result.sort((a, b) => {
        return a.createdAt.getTime() - b.createdAt.getTime();
      });
      this.componentIsReady = true;
    });
  }

  private async initDataTable() {
    this.customActions = [
      new CustomToolbarAction(this.exportPdf(), 'file-pdf', 'order.printAllElementList'),
      new CustomToolbarAction(this.sendEmail(), 'envelope', 'order.sendEmailToClients')
    ]
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('file-pdf', this.exportRowToPdf(), await this.getTranslate('order.printElementList'), this.notCanceledPredicate())
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
      TableCol.newDateTime('createdAt', await this.getTranslate('general.createdAt')),
      TableCol.newString('orderNumber', await this.getTranslate('order.orderNumber')),
      TableCol.newString('status', await this.getTranslate('order.statusTitle')).filterable(
        TableFilter.newSelect(Object.keys(OrderStatus).map(this.generateStatusFilterOption()))).template(
        () => this.statusTpl),
      TableCol.newString('paymentType', await this.getTranslate('order.paymentType')).filterable(
        TableFilter.newSelect(Object.keys(PaymentType).map(this.generatePaymentFilterOption()))).template(
        () => this.paymentTpl),
      TableCol.newString('deliveryType', await this.getTranslate('order.deliveryType')).filterable(
        TableFilter.newSelect(Object.keys(DeliveryType).map(this.generateDeliveryFilterOption()))).template(
        () => this.deliveryTpl),
      TableCol.newString('user.name', await this.getTranslate('order.userName')),
      TableCol.newString('user.email', await this.getTranslate('order.userEmail')),
      TableCol.newString('cart.sumPrice', await this.getTranslate('order.sumPrice')).template(
        () => this.priceTpl),
    ];
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  private generatePaymentFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  private generateDeliveryFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  filterData(filter: DataFilterModel) {
    this.dataFilters.forEach(df => df.active = false);
    filter.active = true;
    this.$load = this.service.getAllByDeletedAndDateRange(filter.from.toDate(), filter.to.add(1, "day").toDate());
    this.loadData();
    this.extendedStorageService.saveToCookies(StorageKey.ORDER_FILTER_KEY, filter.key, false);
  }

  filterByDateRange(filter: DataFilterModel) {
    this.dataFilters.forEach(df => df.active = false);
    filter.active = true;
    this.componentDialogRef = this.dialogService.open(
      DateRangeDialogComponent,
      Utilities.getDefaultCloseIconModalConfigWithoutData()
    );
    this.componentDialogRef.afterClosed().subscribe(result => {
      filter.from = result.from;
      filter.to = result.to;
      this.$load = this.service.getAllByDeletedAndDateRange(filter.from.toDate(), filter.to.add(1, "day").toDate());
      this.loadData();
      this.extendedStorageService.saveToCookies(StorageKey.ORDER_FILTER_KEY, filter.key, false);
    });
  }

  dataFilterTrackFn(index: number, value: DataFilterModel) {
    return value.active;
  }

  public exportPdf(): () => void {
    return () => this.exportMultiOrderPdf();
  }

  public exportRowToPdf(): (row: OrderModel) => void {
    return (row: OrderModel) => {
      if (this.hellpTable) {
        this.loaderService.show();
        this.service.generatePdf([row.orderNumber]).subscribe(response => {
          fileDownloadProcess(response, `rendeles-${row.orderNumber}.pdf`);
          this.loaderService.hide();
        });
      }
    }
  }

  async exportMultiOrderPdf() {
    if (this.hellpTable) {
      this.loaderService.show();
      const orderNumbers: string[] = [];

      for (let i = 0; i < this.hellpTable.currentlyAvailableElements.length; i++) {
        const tableElement = this.hellpTable.currentlyAvailableElements[i];
        const originalData = tableElement.originalData as any as OrderModel;
        orderNumbers.push(originalData.orderNumber);
      }
      this.service.generatePdf(orderNumbers).subscribe(response => {
        fileDownloadProcess(response, 'rendelesek.pdf');
        this.loaderService.hide();
      });
    }
  }

  private sendEmail() {
    return () => {
      const recipients = this.hellpTable.currentlyAvailableElements.map(element => {
        const user = (element.originalData as any as OrderModel).user;
        return user ? user.email : 'info@biokiskert.hu';
      });
      this.dialogService.open(
        SendEmailDialogComponent,
        Utilities.getDefaultModalConfig(new EmailData("", "", Array.from(new Set(recipients))))
      ).afterClosed().subscribe(result => {
        if (result instanceof EmailData) {
          this.service.sendEmailToUsers(result).subscribe(result => {
            this.service.notificationService.showSuccess("general.success", "general.successSend");
          });
        }
      });
    };
  }

  private notCanceledPredicate() {
    return (row: OrderModel) => {
      return row.status != OrderStatus.CANCELLED_BY_ADMIN;
    };
  }

  productFilter() {
    const filteredModels = this.models?.filter(order => {
      const filteredElements = order.cart.cartElements
        .filter(ce => ce.product.product.name.toLowerCase().includes(this.productNameFilter.toLowerCase()));
      return filteredElements.length > 0;
    });
    this.allModel = this.models;
    this.models = filteredModels;
  }

  clearProductFilter() {
    this.models = this.allModel;
    this.productNameFilter = '';
  }
}
