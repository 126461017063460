import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type} from '@angular/core';
import {AttachmentService} from "../../../services/attachment/attachment.service";
import {AttachmentElementComponent} from "../attachment-element/attachment-element.component";
import {AttachmentModel} from "../../../services/attachment/attachment.model";
import {map, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit, OnChanges {

  private readonly _destroyed = new Subject<void>();
  @Input() itemAttachments: AttachmentModel[] = [];
  @Input() firstPanelExpanded = false;
  @Input() secondPanelExpanded = false;
  @Input() selectedAttachment?: AttachmentModel;
  @Output() selectedAttachmentChange: EventEmitter<any> = new EventEmitter<any>();

  imgViewComponent: Type<any> = AttachmentElementComponent;
  availableAttachments: AttachmentModel[] = [];


  constructor(private attachmentService: AttachmentService,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedAttachment = changes['selectedAttachment'].currentValue;
    if (this.selectedAttachment) {
      this.itemAttachments = [this.selectedAttachment];
      this.attachmentRefresh();
    }
  }


  ngOnInit(): void {
    this.attachmentService.$images.pipe(
      takeUntil(this._destroyed),
      map(attachments => {
        return attachments.filter(attachment => !this.itemAttachments.map(a => a.id).includes(attachment.id));
      })
    ).subscribe(result => this.availableAttachments = result);
  }


  attachmentRefresh(): void {
    this.selectedAttachment = this.itemAttachments[0];
    this.selectedAttachmentChange.emit(this.itemAttachments[0])
  }

  canBeAddedToItemList() {
    return this.itemAttachments.length < 1;
  }

  attachmentSave(attachment: any): void {
    if (this.canBeAddedToItemList()) {
      this.itemAttachments.push(attachment);
    } else {
      this.availableAttachments.push(attachment);
    }
  }

}
