import {BaseModel} from "../../utilities/abstract/base.model";

export class PlantCulture extends BaseModel {
  name = '';
  type: PlantCultureType = PlantCultureType.VEGETABLE;
}

export enum PlantCultureType {
  VEGETABLE = 'VEGETABLE',
  FRUIT = 'FRUIT'
}