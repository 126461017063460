import {Injectable} from '@angular/core';
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {SupplierOrderInvoice} from "./supplier-order-invoice.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/supplier-order-invoices')
export class SupplierOrderInvoiceRepository extends CrudRepository<SupplierOrderInvoice> {
  constructor() {
    super();
  }

}
