import {Component, Inject} from '@angular/core';
import {NX_MODAL_DATA} from '@aposin/ng-aquila/modal';
import {Cart} from '../../../services/order/order';
import {HellparserService} from '@hellp/parser';
import {CartService} from '../../../services/order/cart.service';
import {ProductService} from '../../../services/product/product.service';
import {map, Subject, takeUntil} from 'rxjs';
import {ProductHistoryModel} from '../../../services/product/product.model';
import {OrderService} from "../../../services/order/order.service";

@Component({
  selector: 'app-edit-order-dialog',
  templateUrl: './edit-order-dialog.component.html',
  styleUrls: ['./edit-order-dialog.component.css'],
})
export class EditOrderDialogComponent {
  protected readonly _destroyed = new Subject<void>();
  model!: Cart;
  cartService!: CartService;
  dynamicBackendOptions: (term: string) => any;
  selectedName?: string;

  constructor(@Inject(NX_MODAL_DATA) public data: any,
              private parser: HellparserService,
              private orderService: OrderService,
              private productService: ProductService,) {
    this.orderService.getCartById(data.order.cart.id).subscribe(result => {
      this.model = result ?? new Cart();
      this.cartService = new CartService(
        this.model,
        data.order.paymentType,
        data.order.deliveryType,
        data.purchaseInfo,
      );
    });

    this.dynamicBackendOptions = (term: string) =>
      productService.getHistoriesByProductNameFilter(term).pipe(
        takeUntil(this._destroyed),
        map(this.productService.getAutocompleteLabelProcess()),
      );
  }

  add() {
    if (this.selectedName && this.selectedName.length > 0) {
      this.productService
        .getHistoryByAutocompleteField(this.selectedName)
        .subscribe((result) => {
          if (result) {
            this.cartService.add(result, 1);
            this.selectedName = undefined;
          } else {
            this.productService.notificationService.showWarning(
              'general.warning',
              'general.unknownProductName',
            );
          }
        });
    }
  }
}
