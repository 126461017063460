<div nxLayout="grid" class="nx-margin-top-2m">
  <div nxRow="">
    <div nxCol="12, 12, 12, 6" nxColOffset="0,0,0,3" class="img-upload-wrap">
      <img loading="lazy" *ngIf="fileString"
        class="img-upload-bg"
        [src]="fileString"
        alt="">
      <div class="img-upload-content">
      <app-drag-and-drop  (addFileEvent)="addFile($event)"
                         [acceptString]="acceptString">
      </app-drag-and-drop>
      </div>
    </div>

    <div nxCol="12, 12, 12, 6" nxColOffset="0,0,0,3">
          <nx-formfield nxLabel="{{'general.name' | translate}}" nxFloatLabel="auto" appearance="auto">
            <input name="firstName" type="text" nxInput [(ngModel)]="attachment.name" required/>
            <span nxFormfieldHint nxTooltip="{{'attachment.titleHint' | translate}}">{{'attachment.titleHint' | translate}}</span>
          </nx-formfield>
        </div>
<!--        {{fileString | json}}-->
  </div>
</div>
