<form [formGroup]="form" (submit)="saveSupplier()">
  <div class="card p-3 mb-4 box-shadow">
    <formly-form [model]="formModel" [fields]="fields" [options]="options" [form]="form"></formly-form>
  </div>
  <a
    *ngIf="cancelSupplier"
    class="float-end nx-margin-left-s"
    nxButton="small secondary"
    (click)="cancelSupplier()"
  >
    {{'general.cancel' | translate}}
  </a>

  <button
    *ngIf="saveSupplier"
    class="float-end nx-margin-left-s"
    nxButton="small primary"
    type="submit"
  >
    {{'general.save' | translate}}
  </button>
</form>
