import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shortify'
})
export class ShortifyPipe implements PipeTransform {

  transform(value: string, length: number, tail = '...'): string {
    if (length < value.length) {
      return `${value.substring(0, length)}${tail}`;
    }
    return value
  }

}
