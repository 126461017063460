import {Injectable} from "@angular/core";
import {Patch, PathVariable, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/articles')
export class ArticleRepository<MODEL> extends CrudRepository<MODEL> {

  @Patch('/{id}/publish')
  publish(@PathVariable() id: number, @RequestBody() model: MODEL) {
    return this.createRequest().send();
  }
}