<ng-container *ngIf="data">
  <div class="card nx-margin-bottom-2m" data-animation="true">
    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow border-radius-lg"
         style="height: 170px; background: #fff; display: flex; align-items:center;justify-content: center">
      <a class="d-block blur-shadow-image">
        <ng-container *ngIf="data.isImage">
          <img loading="lazy" [src]="data.src" alt="img-blur-shadow" style="height: 170px; object-fit: contain"
               class="img-fluid" (error)=" data.src='assets/img/default.jpg'">

        </ng-container>
        <ng-container *ngIf="!data.isImage">
          <div class="img-fluid "
               style="display:block; width:100%;height:100%;font-size:50px;text-align:center; ">
            <nx-icon name="file"></nx-icon>
          </div>
        </ng-container>
      </a>
    </div>
    <div class="card-body text-center">
      <p class="mb-0">
        {{ data.name }}
      </p>
    </div>
    <hr class="dark horizontal my-0">
    <div class="card-footer text-center">
      <p class="text-sm my-auto"> {{ data.mimeType }}</p>
    </div>
  </div>
</ng-container>
