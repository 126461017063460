import {Injectable} from '@angular/core';
import {AbstractProcessorService} from "@hellp/parser";
import {AddressModel} from "../models/address.model";
import {Utilities} from "../utilities/utilities";
import {CategoryModel, OrderConfigModel} from "./category/category.model";
import {EntityTag, ProductModel, SupplierProduct, Tag, TagHtml} from "./product/product.model";
import {Slugable} from "../utilities/abstract/base.model";
import * as moment from "moment";
import {Moment} from "moment";
import {EntityRelatedProduct} from "../models/entity-relation-product";

export enum ATTACHMENT_DTOS {
  AttachmentDTO = 'AttachmentDTO'
}

@Injectable({
  providedIn: 'root'
})
export class ProcessorService extends AbstractProcessorService {

  getSupplierOrderId(model: any) {
    return model.supplierOrder ? model.supplierOrder.id : 0;
  }

  plantCultureId(model: any) {
    return model.plantCulture.id;
  }

  plantCultureIssueId(model: any) {
    return model.plantCultureIssue.id;
  }

  plantCultureIssueTypeId(model: any) {
    return model.plantCultureIssueType.id;
  }

  sku(sku: string) {
    const tmp = sku.split('/');
    return tmp[tmp.length - 1];
  }

  addNote(model: any): any | undefined {
    if (model.message) {
      return {message: model.message};
    }
    return undefined;
  }

  getBillingDataId(model: any): number {
    return model.billingData.id
  }

  getStockProductId(model: any): number {
    return model.stockProduct.id;
  }

  getSourceId(model: any): number {
    return model.source.id;
  }

  getTargetId(model: any): number {
    return model.target.id;
  }

  getSupplierOrderElementId(model: any): number {
    return model.supplierOrderElement.id;
  }


  getUserId(model: any): number {
    return model.user.id;
  }

  getUserIdIfNotNull(model: any): number {
    if (model.userId && model.userId > 0) {
      return model.userId
    }
    return model.user.id;
  }

  getDeliveryDataId(model: any): number | null {
    if (model.deliveryData) {
      return model.deliveryData.id;
    }
    return null;
  }

  setNull(data: any): null {
    return null;
  }

  getProductIdIfExist(model: any) {
    return model.product ? model.product.id : null;
  }

  getWarehouseIdIfExist(model: any) {
    return model.warehouse ? model.warehouse.id : null;
  }

  getSupplierProductIdIfExist(model: any) {
    return model.supplierProduct ? model.supplierProduct.id : null;
  }

  getLogoId(model: any) {
    return model.logo.id;
  }

  addDeliveryPartnerId(model: any) {
    return model.deliveryPartners.map((dp: any) => dp.id);
  }

  parseDate(date: string): Date {
    return new Date(date);
  }

  parseMoment(date: string): Moment {
    return moment(date);
  }

  convertMoment(date: Moment) {

    return date ? Utilities.dateToBackendDateTimeString(date) : undefined;
  }

  convertProductToApi(product: ProductModel): any {
    return this.parser.convertModelToApi(product);
  }

  anyToString(tags: any): string {
    if (tags) {
      return JSON.stringify(tags);
    }
    return "";
  }

  anyToObject(tags: any): any {
    if (typeof tags == "string") {
      if (tags.length > 0) {
        return JSON.parse(tags);
      }
      return {};
    }
    return tags;
  }

  tagToString(tags: Tag[]): string {
    if (tags.length < 1) {
      return ""
    }
    return JSON.stringify(tags);
  }

  tagToObject(tags: any): Tag[] {
    if (typeof tags == "string") {
      if (tags.length > 0) {
        return JSON.parse(tags);
      }
      return [];
    }
    return tags;
  }


  temporaryTagProcess(tags: any): any {
    if (typeof tags == "string") {
      if (tags.length > 0) {
        const htmls = JSON.parse(tags);
        const array: any[] = []
        htmls.forEach((htmlTag: TagHtml) => {
          array.push({
            html: htmlTag,
            name: htmlTag.nxTaglistLabel,
            slug: Utilities.generateSlug(htmlTag.nxTaglistLabel, Date.now().toString())
          });
        });
        return array;
      }
      return [];
    }
    return tags;
  }

  getAttachmentId(model: any): number | undefined {
    return model.attachment.id;
  }


  getAuthorId(model: any): number | undefined {
    return model.author.id;
  }

  getTagId(model: EntityTag): number | undefined {
    return model.tag.id;
  }

  getRelatedProductId(model: EntityRelatedProduct): number | undefined {
    return model.relatedProduct.id;
  }

  supplierId(model: SupplierProduct): number | undefined {
    return model.supplier?.id;
  }


  getParentId(model: CategoryModel) {
    return model.parent?.id;
  }

  ifEmptySetNull(orderConfig: OrderConfigModel) {

    const keys = Object.keys(orderConfig);
    if (keys.length > 0) {
      return orderConfig
    }
    return null;
  }

  addressProcess(address: any): AddressModel {
    return this.parser.parseApiDataToModel(address, AddressModel);
  }

  dateProcess(date: string): Date {
    return new Date(date);
  }

  slugGenerator(model: Slugable): string {
    if (!model.slug || model.slug.length < 1) {
      return Utilities.generateSlug(model.slugField, Date.now().toString())
    }
    return model.slug;
  }

  getMediumId(model: any): number | undefined {
    return model.medium.id;
  }

  getNormalId(model: any): number | undefined {
    return model.normal.id;
  }

  getSmallId(model: any): number | undefined {
    return model.small.id;
  }

}
