import {Component, Inject} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {SupplierOrderInvoice} from "../../../services/supplier-order/supplier-order-invoice.model";
import {Month} from "../../../services/warehouse/warehouse.model";

@Component({
  selector: 'dialog-supplier-order-invoice',
  templateUrl: './supplier-order-invoice-dialog.component.html',
  styleUrls: ['./supplier-order-invoice-dialog.component.css']
})
export class SupplierOrderInvoiceDialogComponent {
  constructor(@Inject(NX_MODAL_DATA) readonly data: SupplierOrderInvoice) {
  }

  protected readonly Month = Month;
}
