import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {AdminLayout} from "../../../layouts/admin-layout/admin.layout";
import {ListPage} from "../../../utilities/abstract/list-page";
import {MagazineModel, MagazineStatus} from "../../../services/article/magazine.model";
import {MagazineDtoTypes, MagazineService} from "../../../services/article/magazine.service";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {lastValueFrom} from "rxjs";
import {ROUTING} from "../../../utilities/routing";
import {HistoryStatus} from "../../../utilities/types";

@Component({
  selector: 'app-article-list',
  templateUrl: './magazine-list.component.html',
  styleUrls: ['./magazine-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.MAGAZINE,
  layout: AdminLayout
})
export class MagazineListComponent extends ListPage<MagazineModel, MagazineService> implements OnInit {
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>;

  constructor(service: MagazineService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit')),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('status', await this.getTranslate('article.status')).filterable(
        TableFilter.newSelect(Object.keys(MagazineStatus).map(this.generateStatusFilterOption()))).template(() => {
        return this.statusTpl;
      }),
      TableCol.newString('title', await this.getTranslate('article.title')),
      TableCol.newDateTime('publishedAt', await this.getTranslate('article.publishedAt')),
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(MagazineDtoTypes.MagazineDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('magazine.' + status);
      return {label: label, value: status};
    }
  }

  protected override rowPermission(): (row: MagazineModel) => boolean {
    return (row: MagazineModel) => {
      return this.hasPermission && row.status == MagazineStatus.DRAFT;
    }
  }

  protected readonly HistoryStatus = HistoryStatus;
  protected readonly MagazineStatus = MagazineStatus;
}
