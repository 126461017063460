import {Currencies} from "../utilities/types";
import {IgnoreProperty} from "@hellp/parser";

export class PriceModel {
  @IgnoreProperty
  id = 0;
  net = 0;
  vat = 0;
  currency: Currencies = Currencies.huf;
  grossPrice = 0;


  constructor(currency?: Currencies, net?: number, vat?: number) {
    this.currency = currency ?? Currencies.huf;
    this.net = net ?? 0;
    this.vat = vat ?? 0;
  }

  public gross(): number {
    return parseInt(this.net.toString()) + parseInt((this.net * this.vat / 100).toString());
  }
}
