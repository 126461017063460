<ng-template #validationInfo let-control="control">
  <ng-container *ngIf="control.invalid && control.errors && (control.dirty || control.touched)">
    <nx-error nxFormfieldError *ngIf="control.errors['required']"> {{ 'error.form.required' | translate }}</nx-error>
    <nx-error nxFormfieldError *ngIf="control.errors['pattern']"> {{ 'error.form.notMatch' + control.name | translate }}
    </nx-error>
  </ng-container>
</ng-template>

<form #billingDataForm="ngForm">
  <div class="row">
    <div class="col-12">
      <nx-switcher class="mt-2" [(ngModel)]="isCompany" labelSize="small" name="isCompany">
        {{ 'profile.isCompany' | translate }}
      </nx-switcher>
    </div>
    <ng-container *ngIf="isCompany">
      <div class="col-md-6">
        <nx-formfield nxLabel="{{'profile.companyName' | translate}}"
                      appearance="auto" nxFloatLabel="auto">
          <input type="text" name="companyName" nxInput [(ngModel)]="billingData.companyName"
                 (ngModelChange)="billingDataChange.emit(billingData)"/>
        </nx-formfield>
      </div>
      <div class="col-md-6">
        <nx-formfield nxLabel="{{'profile.taxNumber' | translate}}"
                      appearance="auto" nxFloatLabel="auto">
          <input type="text" name="taxNumber" nxInput [(ngModel)]="billingData.taxNumber"
                 (ngModelChange)="billingDataChange.emit(billingData)"/>
        </nx-formfield>
      </div>
    </ng-container>
    <div class="col-md-6">
      <nx-formfield nxLabel="{{'profile.firstName' | translate}}"
                    appearance="auto" nxFloatLabel="auto">
        <input type="text" name="firstName" nxInput required [(ngModel)]="billingData.firstName"
               (ngModelChange)="billingDataChange.emit(billingData)"
               #firstName="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:firstName}">
        </ng-container>
      </nx-formfield>
    </div>
    <div class="col-md-6">
      <nx-formfield nxLabel="{{'profile.lastName' | translate}}" appearance="auto"
                    nxFloatLabel="auto">
        <input type="text" name="lastName" nxInput required [(ngModel)]="billingData.lastName"
               (ngModelChange)="billingDataChange.emit(billingData)"
               #lastName="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:lastName}">
        </ng-container>
      </nx-formfield>
    </div>
    <div class="col-md-6">
      <nx-formfield nxLabel="{{'profile.phone' | translate}}" appearance="auto"
                    nxFloatLabel="auto">
                    <span nxFormfieldAppendix>
                      <a [nxPopoverTriggerFor]="phoneInfo"
                         href="javascript:void(0)"
                         title="Telefon info"
                         nxPopoverDirection="top"
                         nxPopoverTrigger="hover">
                          <nx-icon name="info-circle-o"></nx-icon>
                      </a>
                    </span>
        <nx-popover #phoneInfo>
          <div style="max-width: 300px">
            {{ 'profile.phoneInfo' | translate }}
          </div>
        </nx-popover>
        <input type="text" pattern="^\+\d{2}\d{8,}$" name="phone" nxInput required
               (ngModelChange)="billingDataChange.emit(billingData)"
               [(ngModel)]="billingData.phoneNumber" #phone="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:phone}">
        </ng-container>
      </nx-formfield>
    </div>
    <div class="col-md-6">
      <nx-formfield nxLabel="{{'profile.email' | translate}}" appearance="auto"
                    nxFloatLabel="auto">
        <input type="email" name="email" nxInput required [(ngModel)]="billingData.email"
               (ngModelChange)="billingDataChange.emit(billingData)"
               pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
               #email="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:email}">
        </ng-container>
      </nx-formfield>
    </div>
    <div class="col-md-2">
      <nx-formfield nxLabel="{{'profile.zipCode' | translate}}"
                    appearance="auto" nxFloatLabel="auto">
        <input type="number" name="zipCode" nxInput required [(ngModel)]="billingData.address.zipCode"
               (ngModelChange)="billingDataChange.emit(billingData)"
               #zipCode="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:zipCode}">
        </ng-container>
      </nx-formfield>
    </div>
    <div class="col-md-5">
      <nx-formfield nxLabel="{{'profile.country' | translate}}"
                    appearance="auto" nxFloatLabel="auto">
        <input type="text" name="country" nxInput required [(ngModel)]="billingData.address.country"
               (ngModelChange)="billingDataChange.emit(billingData)"
               #country="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:country}">
        </ng-container>
      </nx-formfield>
    </div>
    <div class="col-md-5">
      <nx-formfield nxLabel="{{'profile.city' | translate}}" appearance="auto"
                    nxFloatLabel="auto">
        <input type="text" name="city" nxInput required [(ngModel)]="billingData.address.city"
               (ngModelChange)="billingDataChange.emit(billingData)" #city="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:city}">
        </ng-container>

      </nx-formfield>
    </div>

    <div class="col-md-5">
      <nx-formfield nxLabel="{{'profile.street' | translate}}"
                    appearance="auto" nxFloatLabel="auto">
        <input type="text" name="street" nxInput required [(ngModel)]="billingData.address.street"
               (ngModelChange)="billingDataChange.emit(billingData)"
               #street="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:street}">
        </ng-container>

      </nx-formfield>
    </div>
    <div class="col-md-2">
      <nx-formfield nxLabel="{{'profile.streetNumber' | translate}}"
                    appearance="auto" nxFloatLabel="auto">
        <input type="text" name="streetNumber" nxInput required [(ngModel)]="billingData.address.streetNumber"
               (ngModelChange)="billingDataChange.emit(billingData)"
               #streetNumber="ngModel"/>
        <ng-container *ngTemplateOutlet="validationInfo; context:{control:streetNumber}">
        </ng-container>

      </nx-formfield>
    </div>
    <div class="col-md-5">
      <nx-formfield nxLabel="{{'profile.streetOther' | translate}}"
                    appearance="auto" nxFloatLabel="auto">
        <input type="text" name="streetOther" nxInput [(ngModel)]="billingData.address.other"
               (ngModelChange)="billingDataChange.emit(billingData)"/>
      </nx-formfield>
    </div>
  </div>
</form>