import {Component, OnInit} from '@angular/core';
import {ListPage} from "../../../utilities/abstract/list-page";
import {PlantCulture} from "../../../services/technologies/plant-culture.model";
import {PlantCultureDtoType} from "../../../services/technologies/plant-culture.service";
import {TableAction, TableCol} from "@hellp/table";
import {lastValueFrom} from "rxjs";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminLayout} from "../../../layouts/admin-layout/admin.layout";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {Utilities} from "../../../utilities/utilities";
import {
  AddPlantCultureDialogComponent
} from "../../../components/dialogs/add-plant-culture-dialog/add-plant-culture-dialog.component";
import {PlantCultureIssue} from "../../../services/technologies/plant-culture-issue.model";
import {
  PlantCultureIssueDtoType,
  PlantCultureIssueService
} from "../../../services/technologies/plant-culture-issue.service";
import {
  AddPlantCultureIssueDialogComponent
} from "../../../components/dialogs/add-plant-culture-issue-dialog/add-plant-culture-issue-dialog.component";

@Component({
  selector: 'app-plant-culture-issue-list',
  templateUrl: './plant-culture-issue-list.component.html',
  styleUrls: ['./plant-culture-issue-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.PLANT_CULTURE_ISSUE,
  layout: AdminLayout
})
export class PlantCultureIssueListComponent extends ListPage<PlantCultureIssue, PlantCultureIssueService> implements OnInit {

  constructor(service: PlantCultureIssueService, private readonly dialogService: NxDialogService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit')),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('name', await this.getTranslate('general.name')),
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(PlantCultureIssueDtoType.PlantCultureIssueDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }


  protected override editProcess(model: PlantCultureIssue) {
    this.dialogService.open(
      AddPlantCultureIssueDialogComponent,
      Utilities.getDefaultModalConfig(model),
    ).afterClosed().subscribe(result => {
      if (result instanceof PlantCultureIssue) {
        try {
          this.service.validate(result);
          this.service.update(result).subscribe(res => {
            this.service.notificationService.showSuccess('general.success', 'general.successUpdate');
            this.loadData();
          });
        } catch (error: any) {
          this.service.validationErrorHandler(error);
          this.editProcess(result);
        }
      }
    });
  }

  protected override newProcess(initialValue: PlantCultureIssue = new PlantCultureIssue()) {
    this.dialogService.open(
      AddPlantCultureIssueDialogComponent,
      Utilities.getDefaultModalConfig(initialValue),
    ).afterClosed().subscribe(result => {
      if (result instanceof PlantCultureIssue) {
        try {
          this.service.validate(result);
          this.service.save(result).subscribe(res => {
            this.loadData();
            this.service.notificationService.confirm('plantCulture.nextNew', 'plantCulture.addNextIssue', 'general.yes', 'general.no').then(r => {
              if (r.isConfirmed) {
                this.newProcess();
              }
            })
          });
        } catch (error: any) {
          this.service.validationErrorHandler(error);
          this.newProcess(result);
        }
      }
    });
  }
}
