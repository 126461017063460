import {Component, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ShowPage} from "../../../utilities/abstract/show-page";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {WarehouseModel} from "../../../services/warehouse/warehouse.model";
import {WarehouseDtoTypes, WarehouseService} from "../../../services/warehouse/warehouse.service";
import {ActivatedRoute} from "@angular/router";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {ProductService} from "../../../services/product/product.service";

@Component({
  selector: 'app-warehouse-show',
  templateUrl: './warehouse-show.component.html',
  styleUrls: ['./warehouse-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.WAREHOUSE,
})
export class WarehouseShowComponent extends ShowPage<WarehouseModel, WarehouseService> implements OnInit {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  colDef!: TableCol[];
  actionDef!: TableAction[];
  products: ProductHistoryModel[] = [];

  constructor(service: WarehouseService, route: ActivatedRoute, private productService: ProductService) {
    super(service, route);
  }


  async ngOnInit(): Promise<void> {
    this.onInit(WarehouseDtoTypes.WarehouseDTO);

  }

  protected override initProcess() {
    this.initTable();
    this.products = this.model.products.map(product => {
      return this.parser.parseApiDataToModel(product.product, ProductHistoryModel);
    });
  }

  async initTable(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showProductConsumer(), await this.getTranslate('table.action.show')),
    ];

    this.colDef = [

      TableCol.newString('product.itemNr', await this.getTranslate('product.itemNr')),
      TableCol.newString('product.name', await this.getTranslate('product.name')),
      TableCol.newString('product.sku', await this.getTranslate('product.SKU')),
      TableCol.newString('product.supplierProduct.name', await this.getTranslate('product.supplierProductName')),
      TableCol.newString('product.supplierProduct.supplier.name', await this.getTranslate('product.supplierName')),
      TableCol.newString('category.name', await this.getTranslate('product.category')),

    ];
  }

  public showProductConsumer(): (row: ProductHistoryModel) => void {
    return (model: ProductHistoryModel) => this.productService.navigateToShow(model.product.id);
  }

}
