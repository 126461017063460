import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {BannerModel} from "./banner.model";
import {BannerRepository} from "./banner.repository";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum BannerDtoTypes {
  BannerDTO = 'BannerDTO'
}

@Injectable({
  providedIn: 'root'
})
export class BannerService extends CrudService<BannerModel, BannerRepository<BannerModel>> {

  constructor(repo: BannerRepository<BannerModel>) {
    super(ROUTING.BANNER, repo, BannerModel, PageMetaDataConf.BANNER);
  }
}

