import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {MagazineModel} from "./magazine.model";
import {ROUTING} from "../../utilities/routing";
import {AuthorDtoTypes, AuthorService} from "./author.service";
import {ValidatorService} from "../../utilities/service/validator.service";
import {MagazineRepository} from "./magazine.repository";
import {ValidationError} from "../../models/validation.error.model";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";


export enum MagazineDtoTypes {
  MagazineDTO = 'MagazineDTO'
}

@Injectable({
  providedIn: 'root'
})
export class MagazineService extends CrudService<MagazineModel, MagazineRepository<MagazineModel>> {

  constructor(repo: MagazineRepository<MagazineModel>, private authorService: AuthorService) {
    super(ROUTING.MAGAZINE, repo, MagazineModel, PageMetaDataConf.MAGAZINE);
  }

  getAuthors() {
    return this.authorService.getAllByDeleted(AuthorDtoTypes.AuthorDTO);
  }

  override validate(model: MagazineModel) {
    const errors: ValidationError[] = this.validationBaseData(model).concat(this.validateImage(model));
    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

  validateByStep(model: MagazineModel, stepCount: number) {
    let errors: ValidationError[] = [];
    if (stepCount == 1) {
      errors = this.validationBaseData(model);
    }
    if (stepCount == 2) {
      errors = this.validateImage(model);
    }

    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

  private validationBaseData(model: MagazineModel) {
    let errors: ValidationError[] = [];
    errors = ValidatorService.allIsRequired(model, ['title', 'shortDescription', 'authorId'], MagazineModel.name)
    if (model.shortDescription.length > 254) {
      errors.push(new ValidationError("error.magazine.shortDescriptionToLong", 'shortDescription'));
    }
    const items = ValidatorService.isRequired(model.coverPhoto.attachment, "error.MagazineModel.coverIsRequired", 'coverPhoto');
    if (items) {
      errors.push(items);
    }
    return errors;
  }

  private validateImage(model: MagazineModel) {
    return ValidatorService.allIsRequired(model, ['content'], MagazineModel.name);
  }
}
