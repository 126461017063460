import { Pipe, PipeTransform } from '@angular/core';
import {PriceModel} from "../models/price.model";

@Pipe({
  name: 'grossPrice'
})
export class GrossPricePipe implements PipeTransform {
  currencyMap:{[key:string]:string} ={
    'HUF':"Ft"
  }
  transform(value: PriceModel): string {
    return `${value.gross()} ${this.currencyMap[value.currency]}`;
  }

}
