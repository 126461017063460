import {BaseModel} from "../../utilities/abstract/base.model";
import {Note, SupplierOrderStatus} from "../supplier-order/supplier-order.model";
import {ConvertFrom, ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {StockProduct, WarehouseModel} from "../warehouse/warehouse.model";
import {ProcessorService} from "../processor.service";

export class ScrappingElement extends BaseModel {
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT
  count = 0;
  @ParseTo(StockProduct)
  stockProduct!: StockProduct;

  note: Note = new Note()

  message = '';

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getStockProductId'}, "model")
  stockProductId = 0;
}

export class ScrappingModel extends BaseModel {
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  createdAt: Date = new Date();
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  processedAt?: Date;

  note?: Note;
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT

  @ParseTo(ScrappingElement)
  @ConvertFrom
  elements: ScrappingElement[] = [];

  @ParseTo(WarehouseModel)
  warehouse!: WarehouseModel

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getWarehouseIdIfExist'}, "model")
  warehouseId = 0;
}