import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {GoodsLandingRepository} from "./goods-landing.repository";
import {GoodsLanding, GoodsLandingElement} from "./goods-landing.model";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum GoodsLandingDtoType {
  GoodsLandingDTO = 'GoodsLandingDTO',
  SlimGoodsLandingDTO = 'SlimGoodsLandingDTO',
}

@Injectable({
  providedIn: 'root'
})
export class GoodsLandingService extends CrudService<GoodsLanding, GoodsLandingRepository> {

  constructor(repo: GoodsLandingRepository) {
    super(ROUTING.GOODS_LANDING, repo, GoodsLanding, PageMetaDataConf.GOODS_LANDING);
  }

  override validate(model: GoodsLanding) {

  }

  approveElement(goodsLanding: GoodsLanding, goodsLandingElement: GoodsLandingElement) {
    return this.repo.approveElement(goodsLanding.id, goodsLandingElement.id);
  }

  processElement(goodsLanding: GoodsLanding, goodsLandingElement: GoodsLandingElement) {
    return this.repo.processElement(goodsLanding.id, goodsLandingElement.id);
  }

  process(row: GoodsLanding) {
    return this.repo.process(row.id);
  }

  approve(row: GoodsLanding) {
    return this.repo.approve(row.id);
  }

  updateElements(goodsLanding: GoodsLanding) {
    return this.repo.updateElements(goodsLanding.id, goodsLanding);
  }
}

