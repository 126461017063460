import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {takeUntil} from 'rxjs';

import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {AttachmentShowComponent} from "../../../components/dialogs/attachment-show/attachment-show.component";

import {Utilities} from "../../../utilities/utilities";
import {ROUTING} from "../../../utilities/routing";
import {Page} from "@hellp/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {ListPage} from "../../../utilities/abstract/list-page";
import {AttachmentModel} from "../../../services/attachment/attachment.model";
import {AttachmentService} from "../../../services/attachment/attachment.service";
import {ATTACHMENT_DTOS} from "../../../services/processor.service";
import {TableAction} from "@hellp/table";
import {HellpNotificationServiceType} from "@hellp/service";

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
@Page({
  path: '/',
  pathPrefix: ROUTING.ATTACHMENT,
  data: {title: 'title.attachments'},
  authGuard: AdminGuard
})
export class AttachmentListComponent extends ListPage<AttachmentModel, AttachmentService> implements OnInit, OnDestroy {
  @ViewChild('template') templateRef!: TemplateRef<any>;
  @ViewChild('test')

  hdrTpl?: TemplateRef<any>;
  componentDialogRef!: NxModalRef<AttachmentShowComponent>;
  opened = false;
  selectedModel?: AttachmentModel;


  constructor(service: AttachmentService, public dialogService: NxDialogService,) {
    super(service);
  }

  ngOnInit(): void {
    this.initTable();
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  loadData(): void {
    this.service.getAllByDeleted(ATTACHMENT_DTOS.AttachmentDTO).pipe(
      takeUntil(this._destroyed)
    ).subscribe(result => {
      this.models = result;
      this.componentIsReady = true;
    });
  }

  override async initTable() {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      // new TableAction('fas fa-edit', this.edit(),'table.action.edit', this.hasPermission()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];
  }

  protected override showProcess(model: any) {
    this.componentDialogRef = this.dialogService.open(
      AttachmentShowComponent,
      Utilities.getDefaultCloseIconModalConfig(model)
    );
  }

  onOpenSide(model: AttachmentModel) {
    this.opened = !this.opened
    this.selectedModel = model;

    setTimeout(() => {
      if (!this.opened) {
        this.opened = true;
      }
    }, 400)
  }

  onCopyLink() {
    this.service.notificationService.showInfo('general.success', 'attachment.successCopy', HellpNotificationServiceType.Toast);
  }
}
