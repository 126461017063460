import {Injectable} from '@angular/core';
import {AbstractProcessorService} from "@hellp/parser";
import {ProductHistoryModel, ProductModel} from "./product/product.model";
import {ExtraPriceModel} from "./category/category.model";

@Injectable({
  providedIn: 'root'
})
export class ExtendedProcessorService extends AbstractProcessorService {
  getProductCartExclusive(model: any) {
    return model.product.cartExclusive;
  }

  getHistoryId(model: any) {
    return model.productHistory.id;
  }

  getHistoryColId(model: any) {
    return model.productHistoryCol.id;
  }

  setHistoryColId(model: any) {
    return model.productHistoryRow.id;
  }

  getHistoryRowId(model: any) {
    return model.productHistoryRow.id;
  }

  setHistoryRowId(model: any) {
    return model.productHistoryRow.id;
  }

  productToHistory(product: any): ProductHistoryModel {
    if (product.category) {
      return this.parser.parseApiDataToModel(product, ProductHistoryModel);
    }
    const history = {...product.history};
    delete product.history;
    product = this.parser.parseApiDataToModel(product, ProductModel);
    return this.parser.parseApiDataToModel(ProductHistoryModel.createNewInstanceFromSelf(history, product), ProductHistoryModel);
  }

  parseExtraPriceMap(cashPriceMap: { [key: string]: any }): any {
    Object.keys(cashPriceMap).forEach(key => {
      cashPriceMap[key] = this.parser.parseApiDataToModel(cashPriceMap[key], ExtraPriceModel);
    });
    return cashPriceMap;
  }
}
