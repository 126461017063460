import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminLayout} from "../../../layouts/admin-layout/admin.layout";
import {ListPage} from "../../../utilities/abstract/list-page";
import {Author} from "../../../services/article/magazine.model";
import {AuthorDtoTypes, AuthorService} from "../../../services/article/author.service";
import {TableAction, TableCol} from "@hellp/table";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-author-list',
  templateUrl: './author-list.component.html',
  styleUrls: ['./author-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.AUTHOR,
  layout: AdminLayout
})
export class AuthorListComponent extends ListPage<Author, AuthorService> implements OnInit, OnDestroy {
  @ViewChild("avatar") avatarTpl!: TemplateRef<any>;

  constructor(service: AuthorService) {
    super(service);
  }

  ngOnInit(): void {
    this.tableInit();
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  loadData(): void {
    this.service.getAllByDeleted(AuthorDtoTypes.AuthorDTO).pipe(takeUntil(this._destroyed)).subscribe(result => {
      this.models = result;
      this.componentIsReady = true;
    })
  }

  private async tableInit() {
    this.actionDef = [
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('name', await this.getTranslate('author.name')),
      TableCol.newString('avatarSrc', await this.getTranslate('author.avatar')).filterable(false).template(() => this.avatarTpl),
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
    ];
  }
}
