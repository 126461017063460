import {Component, Input} from '@angular/core';
import {DeliveryPartner} from "../../../services/purchase-info/purchase-info.model";

@Component({
  selector: 'form-delivery-partner',
  templateUrl: './delivery-partner-form.component.html',
  styleUrls: ['./delivery-partner-form.component.css']
})
export class DeliveryPartnerFormComponent {
  @Input() model!: DeliveryPartner;
}
