import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {ListPage} from "../../../utilities/abstract/list-page";
import {Inventory} from "../../../services/inventory/inventory.model";
import {InventoryDtoType, InventoryService} from "../../../services/inventory/inventory.service";
import {TableAction, TableCol} from "@hellp/table";
import {lastValueFrom} from "rxjs";
import {SupplierOrderStatus} from "../../../services/supplier-order/supplier-order.model";

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.INVENTORY,
  authGuard: AdminGuard
})
export class InventoryListComponent extends ListPage<Inventory, InventoryService> implements OnInit {
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>

  constructor(service: InventoryService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show'), this.isShowable()),
      new TableAction('clipboard-check', this.processConsumer(), await this.getTranslate('table.action.process'), this.isNotShowable()),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.isNotShowable()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.isNotShowable()),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).filterable(false),
      TableCol.newDate('createdAt', await this.getTranslate('inventory.createdAt')),
      TableCol.newDate('processedAt', await this.getTranslate('inventory.processedAt')),
      TableCol.newString('status', await this.getTranslate('inventory.status')).filterable(false).template(() => this.statusTpl),
      TableCol.newString('warehouse.name', await this.getTranslate('supplierOrder.warehouse')),

    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(InventoryDtoType.SlimInventoryDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  private isShowable() {
    return (row: Inventory) => {
      return row.status == SupplierOrderStatus.PROCESSED;
    };
  }

  private isNotShowable() {
    return (row: Inventory) => {
      return row.status == SupplierOrderStatus.DRAFT;
    };
  }

  private processConsumer() {
    return (row: Inventory) => {
      this.service.notificationService.confirm('inventory.processElementConfirm', 'inventory.processElementConfirmDesc', 'general.yes', 'general.no').then(res => {
        if (res.isConfirmed) {
          this.service.approve(row).subscribe(res => {
            this.service.process(row).subscribe(result => {
              this.service.notificationService.showSuccess('general.success', 'inventory.successProcess');
              this.loadData();
            })
          })
        }
      })
    };
  }
}
