import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {PlantCultureIssue} from "./plant-culture-issue.model";
import {PlantCultureIssueRepository} from "./plant-culture-issue.repository";
import {ValidatorService} from "../../utilities/service/validator.service";

export enum PlantCultureIssueDtoType {
  PlantCultureIssueDTO = 'PlantCultureIssueDTO',
  SlimPlantCultureIssueDTO = 'SlimPlantCultureIssueDTO',
}

@Injectable({
  providedIn: 'root'
})
export class PlantCultureIssueService extends CrudService<PlantCultureIssue, PlantCultureIssueRepository> {

  constructor(repo: PlantCultureIssueRepository) {
    super(ROUTING.PLANT_CULTURE_ISSUE, repo, PlantCultureIssue);
  }


  override validate(model: PlantCultureIssue) {
    ValidatorService.allRequired(model, ['name'], PlantCultureIssue.name);
  }
}

