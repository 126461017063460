import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {ScrappingElement, ScrappingModel} from "../../../services/scrapping/scrapping.model";
import {ScrappingDtoType, ScrappingService} from "../../../services/scrapping/scrapping.service";
import {ActivatedRoute} from "@angular/router";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {StockProduct, WarehouseModel} from "../../../services/warehouse/warehouse.model";
import {WarehouseDtoTypes, WarehouseService} from "../../../services/warehouse/warehouse.service";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {Note, SupplierOrderStatus} from 'src/app/services/supplier-order/supplier-order.model';

@Component({
  selector: 'app-scrapping-form',
  templateUrl: './scrapping-form.component.html',
  styleUrls: ['./scrapping-form.component.css']
})
@Page({
  path: ['new', 'edit/:id', 'show/:id'],
  pathPrefix: ROUTING.SCRAPPING,
})
export class ScrappingFormComponent extends FormPage<ScrappingModel, ScrappingService> implements OnInit, OnDestroy {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  editMode = false;
  warehouses: WarehouseModel[] = [];
  selectedWarehouse?: WarehouseModel;
  note?: string;
  protected availableElements: StockProduct[] = [];

  constructor(service: ScrappingService, route: ActivatedRoute, private warehouseService: WarehouseService,) {
    super(route, service, ScrappingModel, []);
  }

  ngOnInit(): void {
    this.onInit(ScrappingDtoType.ScrappingDTO);
  }

  protected override afterModelIsSetProcess() {
    if (this.formModel.id && this.formModel.id > 0) {
      this.formModel.elements.forEach(element => {
        element.stockProduct.product = this.parser.parseApiDataToModel(element.stockProduct.product, ProductHistoryModel);
        element.message = element.note.message;
      })
      this.editMode = true;
      this.selectedWarehouse = this.formModel.warehouse;
      this.note = this.formModel.note?.message;
      this.selectedWarehouse.products.forEach(stockProduct => {
        stockProduct.product = this.parser.parseApiDataToModel(stockProduct.product, ProductHistoryModel);
        const element = this.formModel.elements.find(element => element.stockProduct.id == stockProduct.id);
        if (element) {
          stockProduct.tempElement = element;
          stockProduct.addToOrder = true;
          stockProduct.orderCount = element.count;
        }
      });
      if (this.formModel.status == SupplierOrderStatus.DRAFT) {
        this.availableElements = this.selectedWarehouse.products;
      } else {
        this.availableElements = this.selectedWarehouse.products.filter(sp => sp.tempElement);
      }
    } else {
      this.warehouseService.getAllByDeleted(WarehouseDtoTypes.ScrappingWarehouseDTO).subscribe(result => {
        this.warehouses = result;
        if (!this.selectedWarehouse) {
          this.changeWarehouse(this.warehouses[0]);
        }
      })
    }

    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  changeWarehouse(warehouse: WarehouseModel) {
    this.selectedWarehouse = warehouse;
    this.formModel.warehouse = warehouse;
    this.note = this.formModel.note?.message;
    this.selectedWarehouse.products.forEach(stockProduct => {
      stockProduct.product = this.parser.parseApiDataToModel(stockProduct.product, ProductHistoryModel);
    });
    this.availableElements = this.selectedWarehouse.products;
  }

  add(stockProduct: StockProduct) {
    stockProduct.addToOrder = true;
    const scrappingElement = new ScrappingElement();
    scrappingElement.stockProduct = {...stockProduct};
    scrappingElement.count = stockProduct.orderCount;
    stockProduct.tempElement = scrappingElement;
    this.formModel.elements.push(scrappingElement);
    if (this.editMode) {
      const scrappingModel = new ScrappingModel();
      scrappingModel.id = this.formModel.id;
      scrappingModel.elements = [scrappingElement];
      this.service.addElement(this.formModel.id, scrappingModel).subscribe(result => {
        this.service.notificationService.showSuccess('general.success', 'inventory.successApprove');
        this.onInit(ScrappingDtoType.ScrappingDTO);
      })
    }
  }

  remove(stockProduct: StockProduct) {
    stockProduct.addToOrder = false;
    const scrappingElement = this.formModel.elements.find(scrappingElement => scrappingElement.stockProduct.id === stockProduct.id);
    if (scrappingElement) {
      const index = this.formModel.elements.map(inv => inv.stockProduct.id).indexOf(scrappingElement.stockProduct.id);
      this.formModel.elements.splice(index, 1);
    }
  }

  protected override savePreProcess() {
    this.formModel.note = new Note(this.note)
    this.formModel.elements.forEach(scrappingElement => {
      if (scrappingElement.message && scrappingElement.message.length > 0) {
        scrappingElement.note = new Note(scrappingElement.message)
      }
    });
  }

  protected filter: any = {productName: '', supplierProductName: ''};

  onFilter() {
    if (this.selectedWarehouse) {
      if (this.filter.productName.length < 1 && this.filter.supplierProductName.length < 1) {
        this.availableElements = this.selectedWarehouse.products;
      }
      this.availableElements = this.selectedWarehouse.products.filter(sp => {
        const isProd = sp.product.product.name.toLowerCase().includes(this.filter.productName.toLowerCase());
        const isSupProd = sp.product.supplierProduct.name.toLowerCase().includes(this.filter.supplierProductName.toLowerCase());

        return isProd && isSupProd
      });

    }

  }

  clearFilter() {
    this.filter.productName = '';
    this.filter.supplierProductName = '';
    this.onFilter();
  }

  approveElements(scrappingElement: ScrappingElement) {
    this.service.notificationService.confirm('inventory.approveElementConfirm', 'inventory.approveElementConfirmDesc', 'general.yes', 'general.no').then(res => {
      if (res.isConfirmed) {
        this.service.approveElement(this.formModel, scrappingElement).subscribe(result => {
          this.service.notificationService.showSuccess('general.success', 'inventory.successApprove');
          this.onInit(ScrappingDtoType.ScrappingDTO);
        })
      }
    })

  }

  override updateProcess() {
    this.updateElements();
  }

  updateElements() {
    this.service.updateElements(this.formModel).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'inventory.successRefresh');
      this.onInit(ScrappingDtoType.ScrappingDTO);
    });
  }

  removeElement(tempElement: ScrappingElement) {
    this.service.removeElement(this.formModel.id, tempElement).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'inventory.successRefresh');
      this.onInit(ScrappingDtoType.ScrappingDTO);
    })
  }

  protected readonly SupplierOrderStatus = SupplierOrderStatus;
}
