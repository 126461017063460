import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {BillingData} from "../../../services/order/order";

@Component({
  selector: 'form-billing-data',
  templateUrl: './billing-data-form.component.html',
  styleUrls: ['./billing-data-form.component.scss'],
  exportAs: 'billingDataForm'
})
export class BillingDataFormComponent {
  @ViewChild('billingDataForm') billingDataForm?: NgForm
  @Input() billingData!: BillingData;
  @Output() billingDataChange: EventEmitter<BillingData> = new EventEmitter<BillingData>();
  isCompany = false;

  public get valid(): boolean {
    return this.billingDataForm != undefined && this.billingDataForm.valid != null && this.billingDataForm.valid;
  }
}
