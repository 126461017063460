import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  DELIVERY_MULTI_SELECT_ARRAY,
  DeliveryType,
  MultiSelectElement,
  PaymentType,
  PRICING_MULTI_SELECT_ARRAY
} from "../../../utilities/types";
import {
  DeliveryConfig,
  FinancialConfig,
  IcePackRange,
  OrderConfigModel,
  PackagingConfig
} from "../../../services/category/category.model";
import {TranslateService} from "@ngx-translate/core";
import {CategoryService} from "../../../services/category/category.service";
import {FormComponent} from "../../../utilities/abstract/form-component";

@Component({
  selector: 'forms-order-config-form',
  templateUrl: './order-config-form.component.html',
  styleUrls: ['./order-config-form.component.css']
})
export class OrderConfigFormComponent extends FormComponent implements OnInit, OnChanges {

  @Input() orderConfig!: OrderConfigModel;
  @Output() orderConfigChange: EventEmitter<OrderConfigModel> = new EventEmitter<OrderConfigModel>();
  @Input() notForCategory = false;
  @Input() fullSize = false;
  @Input() cartNote?: string | null;
  @Output() cartNoteChange: EventEmitter<string> = new EventEmitter<string>();

  deliveryTypes: MultiSelectElement[] = DELIVERY_MULTI_SELECT_ARRAY;
  pricingTypes: MultiSelectElement[] = PRICING_MULTI_SELECT_ARRAY;
  hasDeliveryMode = false;
  hasPricingMode = false;
  hasIcePackRange = false;


  constructor(service: CategoryService, private translateService: TranslateService) {
    super(service)
  }

  ngOnInit(): void {
    this.initErrorHandlingOnInput();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.orderConfig) {
      this.orderConfig = new OrderConfigModel();
      this.orderConfigChange.emit(this.orderConfig);
    } else {
      this.hasDeliveryMode = this.orderConfig.deliveryConfig != undefined;
      this.hasPricingMode = this.orderConfig.financialConfig != undefined;
      this.hasIcePackRange = this.orderConfig.packagingConfig != undefined;
    }
  }

  percentFormatter = (value: string | number) => `${Number(value)} %`;
  labelTranslate = (value: MultiSelectElement) => this.translateService.instant(value.label);

  togglePricingMode() {
    if (!this.hasPricingMode) {
      this.orderConfig.financialConfig = undefined;
    } else {
      this.orderConfig.financialConfig = new FinancialConfig();
    }
    this.orderConfigChange.emit(this.orderConfig);
  }

  toggleDeliveryMode() {
    if (!this.hasDeliveryMode) {
      this.orderConfig.deliveryConfig = undefined;
    } else {
      this.orderConfig.deliveryConfig = new DeliveryConfig();
    }
    this.orderConfigChange.emit(this.orderConfig);
  }

  selectDeliveryMode(selected: string[]) {
    if (this.orderConfig.deliveryConfig) {
      this.orderConfig.deliveryConfig.availableModes = selected as DeliveryType[];
      this.orderConfigChange.emit(this.orderConfig);
    }
  }

  selectPricingMode(selected: string[]) {
    if (this.orderConfig.financialConfig) {
      this.orderConfig.financialConfig.availableModes = selected as PaymentType[];
      this.orderConfigChange.emit(this.orderConfig);
    }
  }

  toggleIcePackRange() {
    if (!this.hasIcePackRange) {
      this.orderConfig.packagingConfig = undefined;
    } else {
      this.orderConfig.packagingConfig = new PackagingConfig();
      this.orderConfig.packagingConfig.icePackRanges = []
      this.addIcePack();
    }
    this.orderConfigChange.emit(this.orderConfig);
  }

  addIcePack() {
    if (this.orderConfig.packagingConfig && this.orderConfig.packagingConfig.icePackRanges) {
      const icePackRanges = this.orderConfig.packagingConfig.icePackRanges;
      if (icePackRanges.length > 0) {
        const lastIcePack = icePackRanges[icePackRanges.length - 1];
        icePackRanges.push(new IcePackRange(lastIcePack.end, lastIcePack.end + 1, lastIcePack.count + 1));
      } else {
        icePackRanges.push(new IcePackRange());
      }
    }
  }

}
