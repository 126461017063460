import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {EntityTag, ProductHistoryModel, ProductModel, Tag} from "../../../services/product/product.model";
import {
  ProductDtoTypes,
  ProductHistoryDtoTypes,
  ProductService,
  UploadType
} from "../../../services/product/product.service";
import {ActivatedRoute} from "@angular/router";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AttachmentModel, EntityAttachment} from "../../../services/attachment/attachment.model";
import {GalleryPanelComponent} from "../../../components/panel/gallery-panel/gallery-panel.component";
import {ExtendedStorageService, HellpNotificationServiceType} from "@hellp/service";
import {ProductStepNumber} from "../../../utilities/constatants";
import {HistoryStatus, SKIP_VALIDATION} from "../../../utilities/types";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {
  ProductDetailsModalComponent
} from "../../../components/dialogs/product-details-modal/product-details-modal.component";
import {Utilities} from "../../../utilities/utilities";
import {StepElement} from "../../../models/step-element.model";
import {takeUntil} from "rxjs";
import {
  StepChangeEvent,
  StepperContainerComponent
} from "../../../components/container/stepper-container/stepper-container.component";

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})

@Page({
  path: [`${ROUTING.MARKETING_PRODUCT}/new`,
    `${ROUTING.MARKETING_PRODUCT}/edit/:id`,
    `${ROUTING.SALES_PRODUCT}/new`,
    `${ROUTING.SALES_PRODUCT}/edit/:id`
  ]
})
export class ProductFormComponent extends FormPage<ProductModel, ProductService> implements OnInit, OnDestroy {
  protected readonly ProductStepNumber = ProductStepNumber;
  protected readonly HistoryStatus = HistoryStatus;
  @ViewChild('stepper') stepper!: StepperContainerComponent;
  @ViewChild('gallery') gallery!: GalleryPanelComponent;
  @ViewChild('baseData') baseData!: TemplateRef<any>;
  @ViewChild('supplierProduct') supplierProduct!: TemplateRef<any>;
  @ViewChild('tagsAndOrderConfig') tagsAndOrderConfig!: TemplateRef<any>;
  @ViewChild('attachmentData') attachmentData!: TemplateRef<any>;
  @ViewChild('galleryData') galleryData!: TemplateRef<any>;
  @ViewChild('summaryData') summaryData!: TemplateRef<any>;
  @ViewChild('relatedProduct') relatedProductData!: TemplateRef<any>;

  productHistory?: ProductHistoryModel;
  steps!: StepElement[]
  componentDialogRef?: NxModalRef<ProductDetailsModalComponent>;

  constructor(route: ActivatedRoute, service: ProductService, private extendedStorage: ExtendedStorageService,
              private readonly dialogService: NxDialogService, private cdr: ChangeDetectorRef) {
    super(route, service, ProductModel, []);
  }

  ngOnInit(): void {
    this.onInit(ProductDtoTypes.ProductDTO);
    this.initSteps();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected override afterModelIsSetProcess() {
    this.formModel = this.service.initIds(this.formModel);
    this.formModel.history.orderConfig.cartExclusive = this.formModel.cartExclusive
    if (this.formModel.getHistoryId() && this.formModel.hasPublished) {
      this.service.getHistoryById(this.formModel.getHistoryId(), ProductHistoryDtoTypes.PublishedOnlyProductHistoryDTO)
        .pipe(takeUntil(this._destroyed))
        .subscribe(result => {
          this.productHistory = result;

          this.componentIsReady = true
        });
    } else {
      this.componentIsReady = true
    }
    this.initErrorHandlingOnInput();
  }

  initSteps() {
    if (this.service.$uploadType.value == UploadType.SALES) {
      this.steps = [
        new StepElement(ProductStepNumber.Base, 'product.baseData', () => this.baseData, this.validateStep(ProductStepNumber.Base)),
        new StepElement(ProductStepNumber.Supplier, 'product.supplierProduct', () => this.supplierProduct, this.validateStep(ProductStepNumber.Supplier)),
        new StepElement(ProductStepNumber.TagAndConfig, 'product.tagAndOrderConfig', () => this.tagsAndOrderConfig, this.validateStep(ProductStepNumber.TagAndConfig)),
        new StepElement(ProductStepNumber.RelatedProducts, 'product.relatedProductAndDynamicAttribute', () => this.relatedProductData, this.validateStep(ProductStepNumber.RelatedProducts)),
        new StepElement(ProductStepNumber.Summary, 'product.summary', () => this.summaryData, SKIP_VALIDATION),
      ]
    } else {
      this.steps = [
        new StepElement(ProductStepNumber.Base, 'product.baseData', () => this.baseData, this.validateStep(ProductStepNumber.Base)),
        new StepElement(ProductStepNumber.MainImage, 'product.mainImage', () => this.attachmentData, this.validateStep(ProductStepNumber.MainImage)),
        new StepElement(ProductStepNumber.Gallery, 'product.gallery', () => this.galleryData, this.validateStep(ProductStepNumber.Gallery)),
        new StepElement(ProductStepNumber.Summary, 'product.summary', () => this.summaryData, SKIP_VALIDATION),
      ]
    }
  }

  validateStep(stepCount: number) {
    return () => {
      try {
        this.service.validateByStep(this.formModel, stepCount);
        return true;
      } catch (e) {
        console.warn(e);
        return false
      }
    }
  }


  override saveProcess() {
    this.saveByStep(this.stepper.currentStepCount, true);
  }

  override updateProcess() {
    this.saveByStep(this.stepper.currentStepCount, true);
  }

  changeStep(event: StepChangeEvent) {
    this.saveByStep(event.stepCount);
  }

  private saveByStep(saveStepIndex: number, endOfEdit?: boolean) {
    const productModelObservable = this.service.stepSaver(saveStepIndex, this.formModel);
    productModelObservable.subscribe(result => {
      this.formModel = result;
      if (!endOfEdit) {
        this.service.notificationService.showSuccess("general.success", "product.successStepSave", HellpNotificationServiceType.Toast);
      } else {
        this.endOfProcess();
      }
    });
  }

  endOfProcess() {
    this.service.notificationService.showSuccess('general.success', 'general.successSave');
    this.service.navigateToBase();
  }

  selectedAttachmentsChanges(attachments: AttachmentModel[]) {
    this.formModel.history.images = [];
    attachments.forEach(attachment => {
      const historyAttachment = new EntityAttachment();
      historyAttachment.attachment = attachment;
      this.formModel.history.images.push(historyAttachment)
    });
    if (this.gallery) {
      this.gallery.refresh(attachments.concat([this.formModel.history.mainImage.attachment]));
    }
  }

  selectedAttachmentChanges(attachment: AttachmentModel) {
    this.formModel.history.mainImage.attachment = attachment;
    if (this.gallery) {
      this.gallery.refresh(this.formModel.history.images.map(ac => ac.attachment).concat([this.formModel.history.mainImage.attachment]));
    }
  }

  tagsChange(tags: Tag[]) {
    this.formModel.history.tags = tags.map(t => new EntityTag(t));
  }

  openPublished() {
    this.componentDialogRef = this.dialogService.open(
      ProductDetailsModalComponent,
      Utilities.getDefaultCloseIconModalConfig(this.productHistory)
    );
  }

  protected readonly UploadType = UploadType;
}
