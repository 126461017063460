import {TemplateRef} from "@angular/core";

export enum StepState {
  DONE = 'done',
  CURRENT = 'current',
  NEXT = 'next',
  ERROR = 'error'
}

export class StepElement {
  stepCount: number;
  label: string;
  completed = false;
  icon?: string
  state: StepState = StepState.NEXT
  templateSupplier: () => TemplateRef<any>;
  validationSupplier: () => boolean;

  constructor(stepCount: number, label: string, templateSupplier: () => TemplateRef<any>, validationSupplier: () => boolean, icon?: string, state?: StepState) {
    this.stepCount = stepCount;
    this.label = label;
    this.templateSupplier = templateSupplier;
    this.validationSupplier = validationSupplier;
    this.icon = icon;
    this.state = state ?? StepState.NEXT;
  }
}
