import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShowPage} from "../../../utilities/abstract/show-page";
import {ActivatedRoute} from "@angular/router";
import {MagazineModel} from "../../../services/article/magazine.model";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ArticleDtoType, ArticleService} from "../../../services/article/article.service";
import moment from "moment/moment";
import {CustomToolbarAction} from "../../../components/show-tool-bar/show-tool-bar.component";

@Component({
  selector: 'app-article-show',
  templateUrl: './article-show.component.html',
  styleUrls: ['./article-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.ARTICLE,
})
export class ArticleShowComponent extends ShowPage<MagazineModel, ArticleService> implements OnInit, OnDestroy {
  toolbar: CustomToolbarAction[] = [];

  constructor(service: ArticleService, route: ActivatedRoute) {
    super(service, route);
  }

  ngOnInit() {
    this.onInit(ArticleDtoType.ArticleDTO);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected override initProcess() {
    if (!this.model.isPublishedByDate) {
      this.toolbar = [
        new CustomToolbarAction(this.publishConsumer(), 'paper-plane', 'table.action.publish')
      ]
    }
  }

  publishConsumer() {
    return () => {
      this.model.publishedAt = moment();
      this.service.publish(this.model).subscribe(result => {
        this.service.notificationService.showInfo('general.success', 'blog.successPublished');
        this.loadData(ArticleDtoType.ArticleDTO);
      })
    }
  }

}
