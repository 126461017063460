<div class="card p-3 mb-4 box-shadow">

  <div nxRow="">
    <div nxCol="12">
      <nx-formfield nxLabel="{{'product.weight' |translate}}" appearance="auto">
        <input type="number" nxInput name="weight" required [(ngModel)]="model.weightKg"/>
        <span nxFormfieldHint
              nxTooltip="{{ 'product.weightUnitInfo' | translate }}">{{ 'product.weightUnitInfo' | translate }}</span>
      </nx-formfield>
    </div>
    <div nxCol="12">
      <hr/>
    </div>
    <div nxCol="12,12,12">
      <div nxRow>
        <div nxCol="12,12,4">
          <nx-formfield nxLabel="{{'product.dimensionWidth' |translate}}" appearance="auto">
            <input type="text" nxInput name="width" required [(ngModel)]="model.dimensions.width"/>
          </nx-formfield>
        </div>
        <div nxCol="12,12,4">
          <nx-formfield nxLabel="{{'product.dimensionHeight' |translate}}" appearance="auto">
            <input type="text" nxInput name="height" required [(ngModel)]="model.dimensions.height"/>
          </nx-formfield>
        </div>
        <div nxCol="12,12,4">
          <nx-formfield nxLabel="{{'product.dimensionLength' |translate}}" appearance="auto">
            <input type="text" nxInput name="length" required [(ngModel)]="model.dimensions.length"/>
            <span nxFormfieldHint
                  nxTooltip="{{ 'product.dimensionInfo' | translate }}">{{ 'product.dimensionInfo' | translate }}</span>
          </nx-formfield>
        </div>
      </div>
    </div>
  </div>
</div>
