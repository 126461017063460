import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AbstractControl} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {WarehouseType} from "./warehouse/warehouse.model";

export type FormylValidator = {
  expression: (c: AbstractControl) => boolean,
  message: (error: any, field: FormlyFieldConfig) => string
};

@Injectable({
  providedIn: 'root'
})
export class FormlyService {

  constructor(private translateService: TranslateService) {
  }

  public emailValidator(): FormylValidator {
    return {
      expression: (c: AbstractControl) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(c.value),
      message: (error: any, field: FormlyFieldConfig) => `"${field.formControl?.value}" ${this.translateService.instant('error.invalidEmail')}`,
    }
  }

  public lengthValidator(length: number): FormylValidator {
    return {
      expression: (c: AbstractControl) => c.value.length < length,
      message: (error: any, field: FormlyFieldConfig) => `"${field.formControl?.value}" ${this.translateService.instant('error.textTooLong')}`,
    }
  }

  public shortNameValidator(): FormylValidator {
    return {
      expression: (c: AbstractControl) => c.value.length > 3,
      message: (error: any, field: FormlyFieldConfig) => `"${field.formControl?.value}" ${this.translateService.instant('error.shortShortName')}`,
    }
  }

  public get contactFormConfig(): FormlyFieldConfig[] {
    return [{
      fieldGroupClassName: 'row',
      fieldGroup: [

        {
          key: 'baseEmail',
          className: 'col-md-6',
          type: 'input',
          props: {
            required: true,
            type: 'email',
            addonLeft: {
              class: 'fa fa-envelope',
            },
          },
          expressions: {
            'props.label': this.translateService.stream('contact.baseEmail'),
          },

        },
        {
          key: 'basePhone',
          className: 'col-md-6',
          type: 'input',
          props: {
            required: true, addonLeft: {
              class: 'fa-solid fa-phone',
            },
          },
          expressions: {
            'props.label': this.translateService.stream('contact.basePhone'),
          },

        },
        {
          key: 'headOfShopName',
          className: 'col-md-6',
          type: 'input',
          props: {
            required: true, addonLeft: {
              class: 'fa fa-address-card',
            },
          },
          expressions: {
            'props.label': this.translateService.stream('contact.headOfShopName'),
          },

        },
        {
          key: 'headOfShopPhone',
          className: 'col-md-6',
          type: 'input',
          props: {
            required: true, addonLeft: {
              class: 'fa-solid fa-phone',
            },
          },
          expressions: {
            'props.label': this.translateService.stream('contact.headOfShopName'),
          },

        },
        {
          key: 'ceoName',
          className: 'col-md-6',
          type: 'input',
          props: {
            required: true, addonLeft: {
              class: 'fa fa-address-card',
            },
          },
          expressions: {
            'props.label': this.translateService.stream('contact.ceoName'),
          },

        },
        {
          key: 'ceoPhone',
          className: 'col-md-6',
          type: 'input',
          props: {
            required: true, addonLeft: {
              class: 'fa-solid fa-phone',
            },
          },
          expressions: {
            'props.label': this.translateService.stream('contact.ceoPhone'),
          },

        },
        {
          key: 'hq',
          className: 'col-md-12',
          type: 'input',
          props: {
            required: true, addonLeft: {
              class: 'fa-solid fa-location-pin',
            },
          },
          expressions: {
            'props.label': this.translateService.stream('contact.hq'),
          },

        },
      ]
    }];
  }

  public get contactInfoFormConfig(): FormlyFieldConfig[] {
    return [{
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'baseInfo',
          className: 'col-12 textarea-height',
          type: 'textarea',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('contact.baseInfo'),
          },

        },
        {
          key: 'hqInfo',
          className: 'col-12  textarea-height',
          type: 'textarea',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('contact.hqInfo'),
          },

        },
        {
          key: 'hqDeliveryInfo',
          className: 'col-12 textarea-height',
          type: 'textarea',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('contact.hqDeliveryInfo'),
          },

        },
      ]
    }];
  }

  public get addressFormConfig(): FormlyFieldConfig[] {
    return [{
      fieldGroupClassName: 'row',
      fieldGroup: [

        {
          key: 'address.country',
          className: 'col-md-12 col-lg-5',
          type: 'input',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('address.country'),
          },

        },
        {
          key: 'address.zipCode',
          className: 'col-md-3 col-lg-2',
          type: 'input',
          props: {
            required: true,
            type: 'number',
            min: 1000,
            max: 9999,
          },
          expressions: {
            'props.label': this.translateService.stream('address.zipCode'),
          },

        },
        {
          key: 'address.city',
          className: 'col-md-9 col-lg-5',
          type: 'input',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('address.city'),
          },

        },

      ]
    },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [

          {
            key: 'address.street',
            className: 'col-md-12 col-lg-4',
            type: 'input',
            props: {
              required: true,
            },
            expressions: {
              'props.label': this.translateService.stream('address.street'),
            },

          },
          {
            key: 'address.streetNumber',
            className: 'col-md-6 col-lg-4',
            type: 'input',
            props: {
              required: true,
            },
            expressions: {
              'props.label': this.translateService.stream('address.streetNumber'),
            },
          },
          {
            key: 'address.other',
            className: 'col-md-6 col-lg-4',
            type: 'input',
            props: {},
            expressions: {
              'props.label': this.translateService.stream('address.other'),
            },

          },
        ]
      }];
  }

  public get warehouseFormConfig(): FormlyFieldConfig[] {
    return [{
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-6',
          key: 'name',
          type: 'input',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('warehouse.name'),
          },
        },
        {
          className: 'col-md-6',
          key: 'type',
          type: 'select',
          props: {
            required: true,
            options: Object.keys(WarehouseType).map(type => ({
              id: type,
              name: type
            })),
            valueProp: 'id',
            labelProp: 'name',
          },
          hooks: {
            onInit: (field) => {
              if (field.props && field.props.options) {
                field.props.options.forEach((opt) => {
                  opt.name = this.translateService.instant(`warehouse.type.${opt.name}`);
                })
              }
            },
          },
          expressions: {
            'props.label': this.translateService.stream('warehouse.typeLabel'),
          },
        },
        {
          className: 'col-12',
          key: 'description',
          type: 'textarea',
          props: {
            required: true,
            maxLength: 255
          },
          expressions: {
            'props.label': this.translateService.stream('warehouse.description'),
          },
          validators: {
            email: this.lengthValidator(254),
          },
        },
      ]
    }]
  }

  public get supplierFormConfig(): FormlyFieldConfig[] {

    return [{
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-lg-4 col-md-12',
          key: 'companyName',
          type: 'input',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('supplier.companyName'),
          },
        },
        {
          className: 'col-lg-4 col-md-6',
          key: 'bankAccountNumber',
          type: 'input',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('supplier.bankAccountNumber'),
          },
        },
        {
          className: 'col-lg-4 col-md-6',
          key: 'taxNumber',
          type: 'input',
          props: {
            required: true,
          },
          expressions: {
            'props.label': this.translateService.stream('supplier.taxNumber'),
          },
        },
      ]
    },
      {
        className: 'section-label',
        template: '<hr />',
      },
      ...this.addressFormConfig,
      {
        className: 'section-label',
        template: '<hr />',
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-lg-4 col-md-12',
            key: 'contactName',
            type: 'input',
            props: {
              required: true,
              addonLeft: {
                class: 'fa fa-user',
              },
            },
            expressions: {
              'props.label': this.translateService.stream('supplier.contactName'),
            },
          },
          {
            className: 'col-lg-4 col-md-6',
            key: 'contactEmail',
            type: 'input',
            props: {
              required: true,
              type: 'email',
              addonLeft: {
                class: 'fa fa-envelope',
              },
            },
            validators: {
              email: this.emailValidator(),
            },
            expressions: {
              'props.label': this.translateService.stream('supplier.contactEmail'),
            },
          },
          {
            className: 'col-lg-4 col-md-6',
            key: 'contactPhone',
            type: 'input',
            props: {
              required: true,
              addonLeft: {
                class: 'fa fa-phone',
              },
            },
            expressions: {
              'props.label': this.translateService.stream('supplier.contactPhone'),
            },
          },
        ]
      },
    ];
  }
}
