import {Component, Input} from '@angular/core';
import {StringStringMap} from "../../../utilities/types";

@Component({
  selector: 'app-dynamic-attribute-panel',
  templateUrl: './dynamic-attribute-panel.component.html',
  styleUrls: ['./dynamic-attribute-panel.component.css']
})
export class DynamicAttributePanelComponent {
  @Input() dynamicAttribute!: StringStringMap;
}
