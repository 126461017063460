import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {PurchaseInfoRepository} from "./purchase-info.repository";
import {DeliveryPartner, PurchaseInfoModel} from "./purchase-info.model";
import {DeliveryPartnerRepository} from "./delivery-partner.repository";
import {PostPayload} from "../../models/post-payload.model";
import {PutPayload} from "../../models/put-payload.model";

export enum PurchaseInfoDtoTypes {
  PurchaseInfoDTO = 'PurchaseInfoDTO',
  DeliveryPartnerDTO = 'DeliveryPartnerDTO'
}

@Injectable({
  providedIn: 'root'
})
export class PurchaseInfoService extends CrudService<PurchaseInfoModel, PurchaseInfoRepository> {

  constructor(repo: PurchaseInfoRepository, private deliveryPartnerRepository: DeliveryPartnerRepository) {
    super(ROUTING.ORDER_INFO, repo, PurchaseInfoModel);
  }

  updateDeliveryPartner(result: DeliveryPartner) {
    return this.deliveryPartnerRepository.update(result.id, new PutPayload<DeliveryPartner>(result), DeliveryPartner);
  }

  saveDeliveryPartner(result: DeliveryPartner) {
    return this.deliveryPartnerRepository.save(new PostPayload<DeliveryPartner>(result), DeliveryPartner);
  }

  getAllDeliveryPartners() {
    const filter = this.filterService.filter(PurchaseInfoDtoTypes.DeliveryPartnerDTO)
      .equal('deleted', false)
      .create();
    return this.deliveryPartnerRepository.getAllByFilter(filter.stringify(), DeliveryPartner);
  }

  deleteDeliveryPartner(id: number) {
    return this.deliveryPartnerRepository.deleteById(id);
  }
}
