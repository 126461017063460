import {Component, Input, OnInit} from '@angular/core';
import {CategoryModel} from "../../../services/category/category.model";
import {CategoryService} from "../../../services/category/category.service";
import {FormComponent} from "../../../utilities/abstract/form-component";

@Component({
  selector: 'forms-category-form',
  templateUrl: './category-base-form.component.html',
  styleUrls: ['./category-base-form.component.css']
})
export class CategoryBaseFormComponent extends FormComponent implements OnInit {
  @Input() model!: CategoryModel;
  @Input() parents!: CategoryModel[];
  parentId?: number;

  constructor(service: CategoryService) {
    super(service)
  }

  ngOnInit(): void {
    this.parentId = this.model.parent?.id;
    this.initErrorHandlingOnInput();
  }


  refreshParent() {
    const find = this.parents.find(p => p.id == this.parentId);
    if (find) {
      this.model.parent = find;
      this.model.parentId = find.id;
    } else {
      this.model.parent = null;
    }
  }
}
