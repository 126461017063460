import {Injectable} from '@angular/core';
import {Get, Patch, Path, PathVariable, RepoPath} from "@hellp/repository";
import {AttachmentModel} from "./attachment.model";
import {CrudRepository} from "../../utilities/service/crud.repository";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/attachments')
export class AttachmentRepository extends CrudRepository<AttachmentModel> {
  constructor() {
    super();
  }


  @Get('/{extension}')
  getAllIByExtension(@PathVariable() extension: string) {
    return super.createRequest().withModel(AttachmentModel).send();
  }

  @Get('/images')
  getAllImages() {
    return super.createRequest().withModel(AttachmentModel).send();
  }

  @Patch('/{id}/clear-cache')
  clearCacheForAttachment(@PathVariable() id: number) {
    return super.createRequest().send();
  }


}
