import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Page } from '@hellp/routing';
import { ROUTING } from '../../../utilities/routing';
import { AdminGuard } from '../../../layouts/admin-layout/admin.guard';
import { ListPage } from '../../../utilities/abstract/list-page';
import { GoodsLanding } from '../../../services/goods-landing/goods-landing.model';
import { GoodsLandingDtoType, GoodsLandingService } from '../../../services/goods-landing/goods-landing.service';
import { TableAction, TableCol, TableFilter } from '@hellp/table';
import { takeUntil } from 'rxjs';
import { SlimSupplierOrderStatus, SupplierOrderStatus } from '../../../services/supplier-order/supplier-order.model';

@Component({
  selector: 'app-goods-landing-list',
  templateUrl: './goods-landing-list.component.html',
  styleUrls: ['./goods-landing-list.component.css'],
})
@Page({
  path: '',
  pathPrefix: ROUTING.GOODS_LANDING,
  authGuard: AdminGuard,
})
export class GoodsLandingListComponent extends ListPage<GoodsLanding, GoodsLandingService> implements OnInit {
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>;

  constructor(service: GoodsLandingService) {
    super(service);
  }

  ngOnInit(): void {
    this.initTable();
    this.loadData();
  }

  override async initTable() {
    this.actionDef = [
      new TableAction(
        'clipboard-check',
        this.showConsumer(),
        await this.getTranslate('table.action.process'),
        this.isNotShowable(),
      ),
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show'), this.isShowable()),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).filterable(false),
      TableCol.newString('supplierName', await this.getTranslate('supplierOrder.supplier')),
      TableCol.newDate('createdAt', await this.getTranslate('inventory.createdAt')),
      TableCol.newDate('processedAt', await this.getTranslate('inventory.processedAt')),
      TableCol.newString('status', await this.getTranslate('inventory.status'))
        .filterable(TableFilter.newSelect(Object.keys(SlimSupplierOrderStatus).map(this.generateStatusFilterOption())))
        .template(() => this.statusTpl),
      TableCol.newString('warehouse.name', await this.getTranslate('supplierOrder.warehouse')),
    ];
  }

  loadData(): void {
    this.service
      .getAllByDeleted(GoodsLandingDtoType.SlimGoodsLandingDTO)
      .pipe(takeUntil(this._destroyed))
      .subscribe((result) => {
        result.forEach((item) => {
          if (item.elements.some((element) => element.status == SupplierOrderStatus.APPROVED)) {
            item.status = SupplierOrderStatus.APPROVED;
          }
        });
        this.models = result;

        this.componentIsReady = true;
      });
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('goodsLanding.listStatus.' + status);
      return { label: label, value: status };
    };
  }

  private isShowable() {
    return (row: GoodsLanding) => {
      return row.status == SupplierOrderStatus.PROCESSED;
    };
  }

  private isNotShowable() {
    return (row: GoodsLanding) => {
      return row.status == SupplierOrderStatus.DRAFT || row.status == SupplierOrderStatus.APPROVED;
    };
  }
}
