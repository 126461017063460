<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      [withoutCancel]="true"
                      (save)="onSave()"
                      (cancel)="onCancel()">

  <div class="row nx-margin-top-2m">

    <ng-container *ngFor="let phase of contactCard">
      <div class="col-md-6">
        <ng-container *ngTemplateOutlet="contactCardTpl; context:{phaseOfContact:phase}">
        </ng-container>
      </div>
    </ng-container>
  </div>
</hellp-form-container>

<ng-template #contactCardTpl let-phaseOfContact="phaseOfContact">
  <ng-container
    *ngTemplateOutlet="contactHeader; context:{title:phaseOfContact.title, type:phaseOfContact.type, editable:phaseOfContact.editable}"></ng-container>
  <ng-container
    *ngTemplateOutlet="phaseOfContact.template(); context:{editable:phaseOfContact.editable}"></ng-container>
</ng-template>

<ng-template #contactHeader let-title="title" let-type="type" let-editable="editable">
  <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-m">{{ title | translate }}
    <nx-link (click)="editToggle(type)">
      <ng-container *ngIf="editable">
        <nx-icon name="xmark"></nx-icon>
      </ng-container>
      <ng-container *ngIf="!editable">
        <nx-icon name="pencil"></nx-icon>
      </ng-container>
    </nx-link>
  </h3>
</ng-template>

<ng-template #base let-editable="editable">
  <ng-container *ngIf="editable">
    <page-formly-form #formly
                      [formModel]="formModel"
                      [fields]="fields"></page-formly-form>
  </ng-container>

  <ng-container *ngIf="!editable">
    <div class="card p-3 mb-4 box-shadow">
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'contact.baseEmail' | translate }}</nx-data-display-label>
        {{ formModel.baseEmail }}
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'contact.basePhone' | translate }}</nx-data-display-label>
        {{ formModel.basePhone }}
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'contact.headOfShopName' | translate }}</nx-data-display-label>
        {{ formModel.headOfShopName }}
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'contact.headOfShopPhone' | translate }}</nx-data-display-label>
        {{ formModel.headOfShopPhone }}
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'contact.ceoName' | translate }}</nx-data-display-label>
        {{ formModel.ceoName }}
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'contact.ceoPhone' | translate }}</nx-data-display-label>
        {{ formModel.ceoPhone }}
      </nx-data-display>

      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'contact.hq' | translate }}</nx-data-display-label>
        {{ formModel.hq }}
      </nx-data-display>
    </div>
  </ng-container>
</ng-template>

<ng-template #openingHour let-editable="editable">
  <div class="card p-3 mb-4 box-shadow">
    <ng-container *ngIf="editable">
      <forms-dynamic-attribute-form
        [(dynamicAttribute)]="formModel.openingHours"></forms-dynamic-attribute-form>
    </ng-container>
    <ng-container *ngIf="!editable">
      <ng-container *ngFor="let entry of formModel.openingHours |keyvalue">
        <nx-data-display class="nx-margin-bottom-2xs">
          <nx-data-display-label>{{ entry.key }}</nx-data-display-label>
          {{ entry.value }}
        </nx-data-display>
      </ng-container>
    </ng-container>
  </div>

</ng-template>

<ng-template #hqDeliveryHours let-editable="editable">
  <div class="card p-3 mb-4 box-shadow">
    <ng-container *ngIf="editable">
      <forms-dynamic-attribute-form
        [(dynamicAttribute)]="formModel.hqDeliveryHours"></forms-dynamic-attribute-form>
    </ng-container>
    <ng-container *ngIf="!editable">
      <ng-container *ngFor="let entry of formModel.hqDeliveryHours |keyvalue">
        <nx-data-display class="nx-margin-bottom-2xs">
          <nx-data-display-label>{{ entry.key }}</nx-data-display-label>
          {{ entry.value }}
        </nx-data-display>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #info let-editable="editable">
  <ng-container *ngIf="editable">
    <page-formly-form [formModel]="formModel"
                      [fields]="hqFields">
    </page-formly-form>
  </ng-container>

  <ng-container *ngIf="!editable">
    <div class="card p-3 mb-4 box-shadow">
      <h6>{{ 'contact.baseInfo' | translate }}</h6>
      <p>{{ formModel.baseInfo }}</p>
      <h6>{{ 'contact.hqInfo' | translate }}</h6>
      <p>{{ formModel.hqInfo }}</p>
      <h6>{{ 'contact.hqDeliveryInfo' | translate }}</h6>
      <p>{{ formModel.hqDeliveryInfo }}</p>
    </div>
  </ng-container>
</ng-template>
