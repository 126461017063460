import {Component, OnInit} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {ActivatedRoute} from "@angular/router";
import {Editor, Toolbar} from "ngx-editor";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {lastValueFrom} from "rxjs";
import {FormToolBarOption} from "../../../components/form-header/form-tool-bar-option.model";
import {TermsAndConditionModel} from "../../../services/terms-and-condition/terms-and-condition.model";
import {
  TermsAndConditionDtoTypes,
  TermsAndConditionService
} from "../../../services/terms-and-condition/terms-and-condition.service";

@Component({
  selector: 'app-terms-and-condition-form',
  templateUrl: './terms-and-condition-form.component.html',
  styleUrls: ['./terms-and-condition-form.component.css']
})

@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.TERMS_AND_CONDITION,
})
export class TermsAndConditionFormComponent extends FormPage<TermsAndConditionModel, TermsAndConditionService> implements OnInit {

  toolbarAddOptions!: FormToolBarOption[];


  constructor(route: ActivatedRoute, service: TermsAndConditionService,) {
    super(route, service, TermsAndConditionModel, []);
  }

  ngOnInit(): void {
    this.toolbarAddOptions = [
      // new FormToolBarOption(this.edit(), 'pencil', 'general.edit'),
      // new FormToolBarOption(this.openAddImageModal(), 'file', 'general.addImage'),

    ]
    this.loadData();
  }

  async loadData() {
    const result = await lastValueFrom(this.service.getAllByDeleted(TermsAndConditionDtoTypes.TermsAndConditionsDTO));
    if (result.length > 0) {
      this.formModel = result[0];
    }
  }
}
