<ng-container *ngIf="model && componentIsReady">
  <div nxLayout="grid">
    <div nxRow>
      <div nxCol="12,12,12,8">
        <div nxRow="">
          <div nxCol="12">
            <nx-card class="viewer">
              <page-show-tool-bar
                iconName="boxes-stacked"
                title="{{'user.info' | translate}}"
                [backSupplier]="back()"
              ></page-show-tool-bar>
              <div nxRow="" class="nx-margin-top-2xs">
                <div nxCol="12">
                  <nx-data-display label="{{'user.name' | translate}}">
                    <h3 class="p-2" nxHeadline="subsection-medium">{{ model.name }}</h3>
                  </nx-data-display>
                  <nx-data-display label="{{'user.email' | translate}}">
                    <h3 class="p-2" nxHeadline="subsection-medium">{{ model.email }}</h3>
                  </nx-data-display>
                  <nx-data-display label="{{'user.email' | translate}}">
                    <nx-link class="p-2">
                      <a href="{{environment.keycloak.issuer + environment.keycloakUserUrl + model.keycloakId }}"
                         target="_blank"
                         title="{{'user.keycloakLink' | translate}}">
                        <nx-icon name="up-right-from-square" class="nx-margin-right-2s"></nx-icon>
                        {{ 'user.keycloakLink' | translate }}
                      </a>
                    </nx-link>
                  </nx-data-display>
                </div>
              </div>
            </nx-card>
          </div>
          <div nxCol="12">
            <nx-card class="viewer">
              <page-show-tool-bar
                iconName="shopping-cart"
                title="{{'user.ordersOfUser' | translate}}"
              ></page-show-tool-bar>
              <div nxRow="" class="nx-margin-top-2xs">
                <div nxCol="12">
                  <hellp-table [colDefinitions]="colDef" [rows]="orders" [actionConfig]="actionDef"></hellp-table>
                </div>
              </div>
            </nx-card>
          </div>
        </div>
      </div>
      <div nxCol="12,12,12,4">
        <div nxRow="">
          <div nxCol="12">
            <nx-card class="viewer">
              <page-show-tool-bar
                iconName="address-book"
                title="{{'user.billingData' | translate}}"
              ></page-show-tool-bar>
              <div nxRow="" class="nx-margin-top-2xs">
                <div nxCol="12" class="max-vh-50 overflow-y-auto">
                  <ng-container *ngFor="let billingData of model.billingData">
                    <ng-container *ngTemplateOutlet="billingDataTpl; context:{billingData:billingData}"></ng-container>
                    <hr class="hr-dark">
                  </ng-container>
                </div>
              </div>
            </nx-card>
          </div>
          <div nxCol="12">
            <nx-card class="viewer">
              <page-show-tool-bar
                iconName="location-dot"
                title="{{'user.deliveryData' | translate}}"
              ></page-show-tool-bar>
              <div nxRow="" class="nx-margin-top-2xs">
                <div nxCol="12">
                  <ng-container *ngFor="let billingData of model.deliveryData">
                    <ng-container *ngTemplateOutlet="billingDataTpl; context:{billingData:billingData}"></ng-container>
                    <hr class="hr-dark">
                  </ng-container>
                </div>
              </div>
            </nx-card>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>

<ng-template #statusTpl let-value="value">
  <span
    class="badge-sm {{value+'-badge'}}">
  {{ 'enum.' + value | translate }}
    </span>
</ng-template>

<ng-template #paymentTpl let-value="value">
  <span class="badge-sm {{value+'-badge'}}">
    <nx-icon *ngIf="value == PaymentType.BARION" name="credit-card"></nx-icon>
    <nx-icon *ngIf="value == PaymentType.CASH" name="coins"></nx-icon>
  </span>
  {{ 'enum.' + value | translate }}
</ng-template>

<ng-template #deliveryTpl let-value="value">
  <span class="badge-sm {{value+'-badge'}} ">
    <nx-icon *ngIf="value == DeliveryType.DELIVERY" name="truck"></nx-icon>
    <nx-icon *ngIf="value == DeliveryType.ONSITE" name="building"></nx-icon>
  </span>
  {{ 'enum.' + value | translate }}
</ng-template>
<ng-template #priceTpl let-value="value">
  {{ value }} Ft
</ng-template>

<ng-template #billingDataTpl let-billingData="billingData">
  <p class="text-small mt-2">
    <nx-icon name="address-book" class="me-2"></nx-icon>
    <span *ngIf="billingData.taxNumber">
                       {{ billingData.companyName }}
      ({{ billingData.firstName }} {{ billingData.lastName }})
                    </span>
    <span *ngIf="!billingData.taxNumber">
                       {{ billingData.firstName }} {{ billingData.lastName }}
                    </span>

    <br>
    <span *ngIf="billingData.taxNumber">
                      <nx-icon name="receipt" class="me-2"></nx-icon>
      {{ billingData.taxNumber }}<br>
                    </span>
    <nx-icon name="envelope" class="me-2"></nx-icon>
    {{ billingData.email }}<br>
    <nx-icon name="phone" class="me-2"></nx-icon>
    {{ billingData.phoneNumber }}<br>
    <nx-icon name="location-pin" class="me-2"></nx-icon>
    {{ billingData.address.toString() }}
  </p>
</ng-template>
