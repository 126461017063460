import {Injectable} from "@angular/core";
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {DeliveryPartner, PurchaseInfoModel} from "./purchase-info.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/delivery-partners')
export class DeliveryPartnerRepository extends CrudRepository<DeliveryPartner> {
  constructor() {
    super();
  }
}