<ng-container *ngIf="model && componentIsReady">

  <div nxLayout="grid" class="position-relative">
    <div class="deleted-watermark" *ngIf="model.deleted">
      <p>{{ 'order.deleted' | translate }}</p>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="signal"
            title="{{'order.controlPanel' | translate}}"
            [customToolbarActions]="customToolbarAction"
            [backSupplier]="back()"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-s" *ngIf="!model.deleted">
            <div nxCol="12" class="w-100 d-flex justify-content-center align-items-center mb-3 overflow-x-auto">
              <ng-container *ngFor="let statusObject of statuses">

                <button nxButton="emphasis small" type="button"
                        class="{{model.status != statusObject.name?statusObject.className:''}} nx-margin-right-s"
                        (click)="statusObject.action()"
                        [nxTooltip]="'enum.' + statusObject.name |translate"
                        [disabled]="!statusObject.available && !statusObject.statusesForEnable.includes(model.status)"
                        [ngClass]="{'actual-status':model.status == statusObject.name}">
                  <nx-icon name="{{statusObject.icon}}" nxIconPositionStart
                           [ngClass]="{'nx-margin-right-s':!mobile}"></nx-icon>
                  <ng-container *ngIf="!mobile">
                    {{ 'enum.' + statusObject.name |translate }}
                  </ng-container>
                </button>

              </ng-container>
            </div>
          </div>
        </nx-card>
      </div>
      <div nxCol="12">
        <ng-container *ngIf="purchaseInfo">
          <nx-card class="viewer">
            <page-show-tool-bar
              iconName="cart-shopping"
              [title]="model.orderNumber"
            ></page-show-tool-bar>
            <display-order-details #orderDisplay id="pdfData" (loadData)="loadData($event)" [model]="model"
                                   [purchaseInfo]="purchaseInfo"></display-order-details>
          </nx-card>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>
