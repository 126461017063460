import {EntityAttachment} from "../attachment/attachment.model";
import {BaseModel, Slugable} from "../../utilities/abstract/base.model";
import {EntityTag, Tag} from "../product/product.model";
import {ConvertFrom, ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../processor.service";
import * as moment from "moment";
import {Moment} from "moment";


export class Author extends BaseModel {
  // override id: number = 0;
  name = '';
  @ParseTo(EntityAttachment)
  @ConvertFrom
  coverPhoto: EntityAttachment = new EntityAttachment();
  user: any;

  get avatarSrc() {
    if (this.coverPhoto.attachment) {
      return this.coverPhoto.attachment.src;
    }
    return '';
  }
}

export enum MagazineStatus {
  DRAFT = 'DRAFT',      // még nem volt publisholve
  PRIVATE = 'PRIVATE',    // privát published
  PUBLISHED = 'PUBLISHED',  // publikus published
  INACTIVE = 'INACTIVE'  // inaktiválva

}

export enum ArticleType {
  MAGAZINE = "MAGAZINE",
  BLOG = "BLOG"
}

export class MagazineModel extends BaseModel implements Slugable {
  title = '';
  shortDescription = '';
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'slugGenerator'}, "model")
  slug = '';
  @ParseTo(EntityAttachment)
  @ConvertFrom
  coverPhoto: EntityAttachment = new EntityAttachment();

  status: MagazineStatus = MagazineStatus.DRAFT;

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseMoment'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'convertMoment'})
  publishedAt!: Moment;

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseTimeMagazine'}, "model")
  publishedAtTime = '00:01';
  @ParseTo(Author)
  @ConvertFrom
  author: Author = new Author();
  content = '';

  type: ArticleType = ArticleType.MAGAZINE;
  @ParseTo(EntityTag)
  @ConvertFrom
  tags: EntityTag[] = [];


  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getAuthorId'}, "model")
  authorId = 0


  get tagList(): Tag[] {
    return this.tags.map(t => t.tag);
  }

  get cover() {
    if (this.coverPhoto.attachment) {
      return this.coverPhoto.attachment.src;
    }
    return '';
  }

  get slugField(): string {
    return this.title;
  }

  get pagedContent(): string[] {
    const h4regExp = /<h4\b[^>]*>/g;
    const tmp = this.content.replaceAll(h4regExp, '|-|<h4>');
    return tmp.split('|-|');
  }

  get isPublishedByDate(): boolean {
    return this.publishedAt && this.publishedAt.isBefore(moment());
  }

}
