import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StringStringMap} from "../../../utilities/types";

@Component({
  selector: 'forms-dynamic-attribute-form',
  templateUrl: './dynamic-attribute-form.component.html',
  styleUrls: ['./dynamic-attribute-form.component.css']
})
export class DynamicAttributeFormComponent {
  @Input() dynamicAttribute: StringStringMap = {};
  @Output() dynamicAttributeChange:EventEmitter<StringStringMap> = new EventEmitter<StringStringMap>();

  key='';
  value='';


  add() {
    //TODO check is empty
    this.dynamicAttribute[this.key] = this.value;
    this.key = '';
    this.value = '';
  }
  remove(key:string){
    delete this.dynamicAttribute[key];
  }
}
