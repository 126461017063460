<div nxModalContent>
  <nx-card class="w-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'order.editBillingData' | translate }}
    </h3>
    <div nxRow>
      <div nxCol="12">
        <form-billing-data #billingDataForm [(billingData)]="model"></form-billing-data>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    (click)="save()"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.save' | translate }}
  </button>
</div>
