import {Component, OnInit} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {AboutUsModel} from "../../../services/about-us/about-us.model";
import {ActivatedRoute} from "@angular/router";
import {Editor, Toolbar} from "ngx-editor";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {lastValueFrom} from "rxjs";
import {FormToolBarOption} from "../../../components/form-header/form-tool-bar-option.model";
import {PrivacyPolicyModel} from "../../../services/privacy-policy/privacy-policy.model";
import {PrivacyPolicyDtoTypes, PrivacyPolicyService} from "../../../services/privacy-policy/privacy-policy.service";
import {NGX_TOOLBAR} from "../../../utilities/types";

@Component({
  selector: 'app-bio-cert-form',
  templateUrl: './privacy-policy-form.component.html',
  styleUrls: ['./privacy-policy-form.component.css']
})

@Page({
  path: [''],
  pathPrefix: ROUTING.PRIVACY_POLICY,
})
export class PrivacyPolicyFormComponent extends FormPage<PrivacyPolicyModel, PrivacyPolicyService> implements OnInit {
  editor: Editor;

  toolbar: Toolbar = NGX_TOOLBAR;
  isEdit = false;
  toolbarAddOptions!: FormToolBarOption[];


  constructor(route: ActivatedRoute, service: PrivacyPolicyService,) {
    super(route, service, AboutUsModel, []);

    this.editor = new Editor();

  }

  ngOnInit(): void {
    this.toolbarAddOptions = [
      // new FormToolBarOption(this.edit(), 'pencil', 'general.edit'),
      // new FormToolBarOption(this.openAddImageModal(), 'file', 'general.addImage'),

    ]
    this.loadData();
  }

  async loadData() {
    const result = await lastValueFrom(this.service.getAllByDeleted(PrivacyPolicyDtoTypes.PrivacyPolicyDTO));
    if (result.length > 0) {
      this.formModel = result[0];
    }
  }

  edit() {
    return () => {
      this.isEdit = true;
    };
  }


  override cancel(): () => void {
    return () => {
      this.isEdit = false;
    };
  }

  protected override savePostProcess() {
    this.loadData();
    this.isEdit = false;

  }
}
