import {Component, Inject} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {AddAttachmentModalInput} from "../add-image-to-model/add-image-to-model.component";

@Component({
  selector: 'app-add-pdf-to-model',
  templateUrl: './add-pdf-to-model.component.html',
  styleUrls: ['./add-pdf-to-model.component.css']
})
export class AddPdfToModelComponent {
  model: any;
  itemAttachments: any[] = [];
  selectedAttachment: any[] = [];

  constructor(@Inject(NX_MODAL_DATA) public componentConfig: AddAttachmentModalInput,) {
    this.model = this.componentConfig.model;

  }

  async ngOnInit(): Promise<void> {
    if (this.model.attachments) {
      this.itemAttachments = [...this.model.attachments];
    }
  }

}
