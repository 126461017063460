<div nxModalContent style="height: calc(100% - 72px)">
  <nx-card class="w-100 h-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'plantCulture.addNewIssueType' | translate }}
    </h3>
    <form #plantForm="ngForm">
      <div nxRow>
        <div nxCol="12,12,6">
          <nx-formfield nxLabel="{{'plantCulture.issueTypeName' | translate}}*" nxFloatLabel="auto" appearance="auto">
            <input nxInput name="name" type="text" required minlength="1" [(ngModel)]="plantCultureIssueType.name"/>
          </nx-formfield>
        </div>
        <div nxCol="12,12,6">
          <nx-formfield nxLabel="{{'plantCulture.type' | translate}}*" nxFloatLabel="auto" appearance="auto">
            <nx-dropdown required [(ngModel)]="plantCultureIssueType.type" name="type">
              <nx-dropdown-item
                *ngFor="let option of PlantCultureType | keyvalue"
                [nxValue]="option.key"
              >{{ 'plantCulture.' + option.key | translate }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="12,12,8,6" class="nx-margin-top-m">
          <nx-formfield  appearance="auto" nxFloatLabel="always"
                        nxLabel="{{'technologies.monthStart' | translate}}*">
            <nx-dropdown required [(ngModel)]="plantCultureIssueType.monthStart" name="monthStart">
              <nx-dropdown-item
                *ngFor="let month of MonthByIndex | keyvalue | arraySort:'key':true;"
                [nxValue]="month.value"
              >{{ ('general.month.' + (month.key) | translate) }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="12,12,4,6" class="nx-margin-top-m">
          <nx-formfield appearance="auto" nxFloatLabel="always"
                        nxLabel="{{'technologies.partOfMonthStart' | translate}}*">
            <nx-dropdown required [(ngModel)]="plantCultureIssueType.partOfMonthStart" name="partOfMonthStart">
              <nx-dropdown-item
                *ngFor="let month of PartOfMonthByIndex | keyvalue | arraySort:'key':true;"
                [nxValue]="month.value"
              >{{ ('general.partOfMonth.' + (month.key) | translate) }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>

        <div nxCol="12,12,8,6" class="nx-margin-top-m">
          <nx-formfield appearance="auto" nxFloatLabel="always"
                        nxLabel="{{'technologies.monthEnd' | translate}}*">
            <nx-dropdown required [(ngModel)]="plantCultureIssueType.monthEnd" name="monthEnd">
              <nx-dropdown-item
                *ngFor="let month of MonthByIndex | keyvalue | arraySort:'key':true;"
                [nxValue]="month.value"
              >{{ ('general.month.' + (month.key) | translate) }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="12,12,4,6" class="nx-margin-top-m">
          <nx-formfield appearance="auto" nxFloatLabel="always"
                        nxLabel="{{'technologies.partOfMonthEnd' | translate}}*">
            <nx-dropdown required [(ngModel)]="plantCultureIssueType.partOfMonthEnd" name="partOfMonthEnd">
              <nx-dropdown-item
                *ngFor="let month of PartOfMonthByIndex | keyvalue | arraySort:'key':true;"
                [nxValue]="month.value"
              >{{ ('general.partOfMonth.' + (month.key) | translate) }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
      </div>

    </form>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [disabled]="!plantForm.valid"
    [nxModalClose]="plantCultureIssueType"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.add' | translate }}
  </button>
</div>
