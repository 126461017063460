import {Injectable} from '@angular/core';
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {PlantCulture} from "./plant-culture.model";
import {CultureIssuePairingModel} from "./culture-issue-pairing.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/culture-issue-pairings')
export class CultureIssuePairingRepository extends CrudRepository<CultureIssuePairingModel> {
  constructor() {
    super();
  }

}
