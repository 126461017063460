import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {StepElement, StepState} from "../../../models/step-element.model";
import {NxMultiStepperComponent} from "@aposin/ng-aquila/progress-stepper";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {Subject, takeUntil} from "rxjs";

export type StepChangeEvent = { current: number, prev: number, stepCount: number };

@Component({
  selector: 'hellp-stepper-container',
  templateUrl: './stepper-container.component.html',
  styleUrls: ['./stepper-container.component.css']
})
export class StepperContainerComponent implements OnInit {
  private readonly _destroyed = new Subject<void>();
  @ViewChild('stepperComponent') stepper!: NxMultiStepperComponent;
  @Input() steps!: StepElement[];
  @Output() changeStepEvent: EventEmitter<StepChangeEvent> = new EventEmitter<StepChangeEvent>();
  noLabel = false;

  constructor(private readonly viewportService: NxViewportService) {
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_2XLARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.noLabel = !isGreaterThanXLarge;
      });
  }

  currentStep = 0;
  currentStepCount = 0;


  ngOnInit(): void {
    if (this.steps && this.steps.length > 0) {
      this.steps.forEach(step => step.state = StepState.NEXT);
      this.steps[this.currentStep].state = StepState.CURRENT;
    }
  }


  nextStep() {
    if (this.currentStep < this.steps.length - 1) {
      if (this.steps[this.currentStep].validationSupplier()) {
        this.steps[this.currentStep].state = StepState.DONE
        const stepCount = this.steps[this.currentStep].stepCount;
        this.currentStep++;
        this.steps[this.currentStep].state = StepState.CURRENT;
        this.currentStepCount = this.steps[this.currentStep].stepCount;
        this.changeStepEvent.emit({prev: this.currentStep - 1, current: this.currentStep, stepCount: stepCount});
      } else {
        this.steps[this.currentStep].state = StepState.ERROR
      }
    }
  }

  previousStep() {
    if (this.currentStep > 0) {
      if (this.steps[this.currentStep].validationSupplier()) {
        this.steps[this.currentStep].state = StepState.DONE
        const stepCount = this.steps[this.currentStep].stepCount;
        this.currentStep--;
        this.steps[this.currentStep].state = StepState.CURRENT
        this.currentStepCount = this.steps[this.currentStep].stepCount;
        this.changeStepEvent.emit({prev: this.currentStep + 1, current: this.currentStep, stepCount: stepCount});
      } else {
        this.steps[this.currentStep].state = StepState.ERROR
      }
    }
  }

  goToStep(index: number) {
    if (this.steps[this.currentStep].validationSupplier()) {
      const current = this.currentStep;
      this.steps[this.currentStep].state = StepState.DONE
      const stepCount = this.steps[this.currentStep].stepCount;
      this.currentStep = index;
      this.steps[this.currentStep].state = StepState.CURRENT
      this.currentStepCount = this.steps[this.currentStep].stepCount;
      this.changeStepEvent.emit({prev: current, current: this.currentStep, stepCount: stepCount})
    } else {
      this.steps[this.currentStep].state = StepState.ERROR
    }
  }

}
