import {Component} from '@angular/core';
import {Layout} from "@hellp/routing";
import {AdminGuard} from "./admin.guard";
import {ADMIN_MENU} from "./admin-menu.const";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin.layout.html',
  styleUrls: ['./admin.layout.css']
})
@Layout({
  path: '',
  default: true,
  data: {title: 'title.default'},
  authGuard: AdminGuard,
})
export class AdminLayout {
  protected readonly ADMIN_MENU = ADMIN_MENU;

  constructor() {
  }
}
