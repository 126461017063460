import {Injectable} from "@angular/core";
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/contact')
export class ContactRepository<MODEL> extends CrudRepository<MODEL> {

}
