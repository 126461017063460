<nx-card class="viewer">
  <page-list-view-header
    [newConsumer]="newConsumer()"
    [title]="'technologies.headline'"
    iconName="arrows-to-eye"
  ></page-list-view-header>
  <div class="nx-margin-top-2m">
    <ng-container *ngIf="componentIsReady">
      <hellp-table id="supplier-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                   [showAsList]="mobile"></hellp-table>
    </ng-container>

    <ng-container *ngIf="!componentIsReady">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </div>
</nx-card>

<ng-template #tagTpl let-value="value">
  <ng-container *ngFor="let tag of value">
  <span>
    {{ tag.name }}
    </span>
  </ng-container>
</ng-template>

<ng-template #monthTpl let-value="value">
  {{ ('general.monthName.' + (value[0]) | translate) }} {{ ('general.partOfMonthName.' + (value[1]) | translate) }}
</ng-template>

<ng-template #translateTpl let-value="value">
  {{ 'plantCulture.' + value | translate }}
</ng-template>
