import {BaseModel} from "../../utilities/abstract/base.model";
import {StringStringMap} from "../../utilities/types";
import {ConvertProcess, ParseProcess} from "@hellp/parser";
import {ProcessorService} from "../processor.service";

export class ContactModel extends BaseModel {
  baseEmail = "ugyfelszolgalat@biokiskert.hu";
  basePhone = "+36-70-432-4951";
  hq = "1202 Budapest, Lázár utca 48.";
  baseInfo = "Rendeléssel kacsolatos kérdések/problémák esetén kérjük keressen minket a fenti elérhetőségeken!";

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'anyToObject'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'anyToString'})
  openingHours: StringStringMap = {
    'Kedd-Szerda-Péntek': '09:00-16:00',
    'Csütörtök': '10:00-18:00',
    'Szombat-Vasárnap': 'Zárva'
  };
  hqInfo = "Értesítés után a rendelések telephelyi átvétele munkanapokon 9-16 óra között, csütörtökön 10-18 óra között lehetséges.";
  hqDeliveryInfo = "Telefonos megkereséseket kizárólag munkanapokon, csak az alábbi intervallumokban áll módunkban fogadni:";

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'anyToObject'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'anyToString'})
  hqDeliveryHours: StringStringMap = {
    'Kedd-Szerda-Péntek': '09:00-11:00 és 13:00-15:00',
    'Csütörtök': '10:00-12:00 és 14:00-16:00'
  }
  headOfShopName = "Katona Edina";
  headOfShopPhone = "+36-70-432-4951";
  ceoName = "Székely Gyöngyvér";
  ceoPhone = "+36-70-637-3883";
}