<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div nxLayout="grid">
    <div nxRow="" class="nx-margin-top-2m">
      <div nxCol="12">
        <forms-tag-form [(tags)]="tags" [embeddedMode]="false"></forms-tag-form>
        <ng-container *ngIf="!componentIsReady">
          <div class="d-flex justify-content-center nx-margin-y-2m">
            <app-spinner></app-spinner>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <nx-card class="viewer nx-margin-top-2m">
    <page-form-header iconName="info" [withActions]="false"></page-form-header>
    <div nxLayout="grid">
      <div nxRow="" class="nx-margin-top-2m">
        <div nxCol="12, 12,4">
          <nx-data-display label="{{'tag.info.nameWorkNameTitle' | translate}}" orientation="vertical">
            <p class="nx-margin-top-2s">{{ 'tag.info.nameWorkNameDesc' | translate }}</p>
          </nx-data-display>
        </div>
        <div nxCol="12, 12,4">
          <nx-data-display label="{{'tag.info.typeTitle' | translate}}" orientation="vertical">
            <p class="nx-margin-top-2s">{{ 'tag.info.typeDesc' | translate }}</p>
          </nx-data-display>
        </div>
        <div nxCol="12, 12,4">
          <nx-data-display label="{{'tag.info.keywordTitle' | translate}}" orientation="vertical">
            <p class="nx-margin-top-2s">{{ 'tag.info.keywordDesc' | translate }}</p>
          </nx-data-display>
        </div>
      </div>
    </div>
  </nx-card>
</hellp-form-container>

