<div class="stepper">
  <div class="stepper-header">
    <ng-container *ngFor="let step of steps; let i = index">
      <div [class]="step.state" (click)="goToStep(i)" class="step">
        <div class="step-icon border-radius-xl">
          <ng-container *ngIf="step.icon">
            <nx-icon name="{{step.icon}}"></nx-icon>
          </ng-container>
          <ng-container *ngIf="!step.icon">
            {{ i + 1 }}
          </ng-container>
        </div>
          <div class="step-label">
            {{ step.label  | translate }}
          </div>
      </div>
<!--      <div *ngIf="i < steps.length - 1" class="line"-->
<!--           [ngStyle]="{'width': ((100/steps.length) / (steps.length - 1)) + '%'}"></div>-->
    </ng-container>
    <div class="full-line"></div>
  </div>
  <div class="stepper-content">
    <div *ngFor="let step of steps; let i = index" [hidden]="i !== currentStep">
      <ng-container *ngIf="currentStep == i"
                    [ngTemplateOutlet]="step.templateSupplier()"></ng-container>
    </div>
  </div>
  <div class="stepper-buttons">
    <button nxButton="cta small" (click)="previousStep()" [disabled]="currentStep === 0">
      <nx-icon name="chevron-left"></nx-icon>
      {{ 'general.previous' | translate }}
    </button>
    <button nxButton="cta small" (click)="nextStep()" [disabled]="currentStep === steps.length - 1">
      {{ 'general.next' | translate }}
      <nx-icon name="chevron-right"></nx-icon>
    </button>
  </div>
</div>
