import {Component, Input} from '@angular/core';
import {CategoryModel, OrderConfigModel} from "../../../services/category/category.model";

@Component({
  selector: 'app-category-order-config-panel',
  templateUrl: './category-order-config-panel.component.html',
  styleUrls: ['./category-order-config-panel.component.css']
})
export class CategoryOrderConfigPanelComponent {
  @Input() model!: OrderConfigModel;

  @Input() notForCategory = false;
}
