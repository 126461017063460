import {BaseModel} from "../../utilities/abstract/base.model";
import {AttachmentModel} from "../attachment/attachment.model";
import {Moment} from "moment/moment";
import * as moment from "moment/moment";
import {ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../processor.service";

export class BannerModel extends BaseModel {
  @ParseTo(AttachmentModel)
  small!: AttachmentModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getSmallId'}, "model")
  smallId = 0;

  @ParseTo(AttachmentModel)
  medium!: AttachmentModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getMediumId'}, "model")
  mediumId = 0;

  @ParseTo(AttachmentModel)
  normal!: AttachmentModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getNormalId'}, "model")
  normalId = 0;

  href = '';

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseMoment'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'convertMoment'})
  activeFrom: Moment = moment();

  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseMoment'})
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'convertMoment'})
  activeTo: Moment = moment();
}