import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  Type,
  ViewChildren
} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {AttachmentHandlerView, RefreshEventModel} from './attachment-handler.model';
import {ViewAnchorDirective} from '../../../directives/view-anchor.directive';
import {AttachmentModel} from "../../../services/attachment/attachment.model";
import {AttachmentService} from "../../../services/attachment/attachment.service";
import {Observable, of} from "rxjs";

export const ATTACHMENT_REFRESH_EVENT_TYPES = {CHANGE_DATA: 'CHANGE_DATA'}

@Component({
  selector: 'app-attachment-handler',
  templateUrl: './attachment-handler.component.html',
  styleUrls: ['./attachment-handler.component.scss']
})
export class AttachmentHandlerComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() availableItems!: any[];
  @Input() attachedItems!: any[];
  @Input() availableViewComponent!: Type<any>;
  @Input() attachedViewComponent!: Type<any>;
  @Input() availableTitle!: string;
  @Input() attachedTitle!: string;
  @Input() acceptString!: string;
  @Input() isMultiple = true;
  @Input() maxElement = 1;

  @Input() secondPanelExpanded = false;
  @Input() firstPanelExpanded = false;

  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() attachmentSaveEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(ViewAnchorDirective) viewAnchor!: QueryList<ViewAnchorDirective>;

  filteredOptionalElements!: any[];
  filterValue!: string;

  autoCompleteDynamic!: (term: string) => Observable<string[]>;

  ATTACHED = 'attached';
  AVAILABLE = 'available';
  file!: File;
  attachment: AttachmentModel = new AttachmentModel();

  constructor(private changeDetectorRef: ChangeDetectorRef, private service: AttachmentService,) {
  }

  ngOnInit(): void {
    if (!this.isMultiple) {
      this.maxElement = 1;
    }
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(): void {
    if (this.availableViewComponent) {
      this.filteredOptionalElements = this.availableItems;
      this.autoCompleteDynamic = (term: string) => {
        return of(this.availableItems.filter(item => {
          return term && (item.name.toLowerCase().includes(term.toLowerCase()))
        }).map(item => item.name));
      }


      setTimeout(() => {
        this.loadComponent();
      }, 1000);
    }
  }

  loadComponent() {
    this.viewAnchor.forEach(ad => {
      const viewContainerRef = ad.viewContainerRef;
      viewContainerRef.clear();
      let componentRef;
      if (ad.isOptional) {
        componentRef = viewContainerRef.createComponent<AttachmentHandlerView>(this.availableViewComponent);
      } else {
        componentRef = viewContainerRef.createComponent<AttachmentHandlerView>(this.attachedViewComponent);
      }

      componentRef.instance.data = ad.data;
      componentRef.instance.isOptional = ad.isOptional;
      componentRef.instance.refreshEvent = ad.refreshEvent;
    });
    this.changeDetectorRef.detectChanges();

  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        0);
      if (event.container.id == this.ATTACHED && ((!this.isMultiple && event.container.data.length > 1) || (this.isMultiple && event.container.data.length > this.maxElement))) {
        for (let i = (event.container.data.length - 1); i >= this.maxElement; i--) {
          transferArrayItem(event.container.data,
            event.previousContainer.data,
            i,
            0);
        }
      }
    }
    this.refresh();
  }

  public save() {
    if (this.file != undefined && this.attachment.name && this.attachment.name.length > 0) {

      this.attachment.slug = this.attachment.name;
      this.service.saveFile(this.file, this.attachment).subscribe(resp => {
        this.secondPanelExpanded = true;
        this.firstPanelExpanded = false;
        this.attachmentSaveEvent.emit(resp);
        this.refresh();
      });
    } else {
      this.service.notificationService.showWarning('general.warning', 'general.invalidData');
    }
  }

  public refreshFromChild($event: RefreshEventModel) {
    /** Csere esemény érkezett -> adatcsere végrehajtása, majd szülő komponens értesítése a módosulásról */
    if ($event && $event.type == ATTACHMENT_REFRESH_EVENT_TYPES.CHANGE_DATA) {
      let arrayToSearch: any[];
      if ($event.isOptional) {
        arrayToSearch = this.availableItems;
      } else {
        arrayToSearch = this.attachedItems;
      }
      const currentIndex = arrayToSearch.findIndex(element => {
        return element.id == $event.oldId;
      });

      if (currentIndex >= 0) {
        arrayToSearch[currentIndex] = $event.newData;
      }
    }

    this.refresh()
  }

  private refresh() {
    setTimeout(() => {
      this.loadComponent()
    }, 200);
    this.refreshEvent.emit();
  }

  filter() {
    this.filteredOptionalElements = this.availableItems.filter(attachment => {
      return attachment.name.toLowerCase().search(this.filterValue.toLowerCase()) > -1;
    });
    this.refresh();
  }
}
