import {BaseModel} from "./base.model";
import {CrudService} from "../service/crud.service";
import {PermissionCheck} from "./permission-check";
import {lastValueFrom, Subject, Subscription, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {SweetAlertResult} from "sweetalert2";
import {ServiceLocator} from "../service/service.locator";
import {TranslateService} from "@ngx-translate/core";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";

export abstract class ShowPage<MODEL extends BaseModel, SERVICE extends CrudService<MODEL, any>> extends PermissionCheck {
  componentIsReady = false;
  protected viewportService: NxViewportService;
  protected readonly _destroyed = new Subject<void>();
  protected translateService: TranslateService;
  public eventWhereMobileIsTrue?: () => void;
  protected sub!: Subscription;
  model!: MODEL;

  public mobile = false;
  private id!: number;

  protected constructor(protected service: SERVICE, protected route: ActivatedRoute) {
    super();
    this.viewportService = ServiceLocator.injector.get(NxViewportService);
    this.translateService = ServiceLocator.injector.get(TranslateService);
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
        if (this.eventWhereMobileIsTrue) {
          this.eventWhereMobileIsTrue();
        }
      });

    this.initPermission();
  }

  onInit(dto: string): void {
    this.sub = this.route.params.subscribe(async params => {
      this.id = params['id'];
      if (!this.id) {
        this.navigateToListWithConfirm();
      } else {
        this.loadData(dto);
      }
    });
  }

  protected loadData(dto: string) {
    this.service.getById(this.id, dto)
      .pipe(
        takeUntil(this._destroyed)
      )
      .subscribe(result => {
        if (result == undefined) {
          this.navigateToListWithConfirm();
        } else {
          this.model = result;
          this.initProcess();
          this.componentIsReady = true
        }
      });
  }

  private navigateToListWithConfirm() {
    this.service.notificationService.swalAlertPromise('warning', 'general.warning', 'error.modelNotExist').then((result: SweetAlertResult) => {
      this.service.navigateToBase();
    });
  }

  onDestroy(): void {
    this.sub.unsubscribe();
    this._destroyed.complete();
    this._destroyed.unsubscribe();
  }

  back() {
    return () => this.service.navigateToBase();

  }

  edit() {
    return () => this.service.navigateToEdit(this.model.id);
  }

  protected initProcess() {

  }

  protected async getTranslate(key: string): Promise<string> {
    return (await lastValueFrom(this.translateService.get(key)));
  }
}
