import {Component, Input} from '@angular/core';

@Component({
  selector: 'hellp-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.css']
})
export class CardContainerComponent {
  @Input() title?: string;
}
