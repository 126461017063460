import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortifyPipe } from './shortify.pipe';
import { OnlyPathPipe } from './only-path.pipe';
import { PricePipe } from './price.pipe';
import { GrossPricePipe } from './gross-price.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ArraySortPipe } from './array-sort.pipe';
import { SafeUrlPipe } from './safe-url.pipe';



@NgModule({
  declarations: [
    ShortifyPipe,
    OnlyPathPipe,
    PricePipe,
    GrossPricePipe,
    SafeHtmlPipe,
    ArraySortPipe,
    SafeUrlPipe
  ],
  exports: [
    ShortifyPipe,
    OnlyPathPipe,
    PricePipe,
    GrossPricePipe,
    SafeHtmlPipe,
    ArraySortPipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }
