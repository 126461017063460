import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {ListPage} from "../../../utilities/abstract/list-page";
import {UserModel} from "../../../services/user/user.model";
import {UserService} from "../../../services/user/user.service";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})

@Page({
  path: '',
  pathPrefix: ROUTING.USER,
  authGuard: AdminGuard
})
export class UserListComponent extends ListPage<UserModel, UserService> implements OnInit {
  @ViewChild('keycloakLinkTpl') keycloakLinkTpl!: TemplateRef<any>


  constructor(service: UserService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).filterable(false),
      TableCol.newString('name', await this.getTranslate('user.name')).filterable(TableFilter.newDefaultText()),
      TableCol.newMailto('email', await this.getTranslate('user.email')),
      TableCol.newBoolean('isSubscribeToNewsletter', await this.getTranslate('user.subscribe')).filterable(false),
      TableCol.newString('keycloakId', await this.getTranslate('user.keycloakId')).template(() => this.keycloakLinkTpl).filterable(false)

    ];
    this.models = await lastValueFrom(this.service.getAllRegistered());
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  protected readonly environment = environment;
}
