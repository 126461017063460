import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TableAction, TableCol} from "@hellp/table";
import {ListPage} from "../../../utilities/abstract/list-page";
import {StockTransferModel} from "../../../services/stock-transfer/stock-transfer.model";
import {StockTransferDtoType, StockTransferService} from "../../../services/stock-transfer/stock-transfer.service";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {Inventory} from "../../../services/inventory/inventory.model";
import {SupplierOrderStatus} from "../../../services/supplier-order/supplier-order.model";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-stock-transfer-list',
  templateUrl: './stock-transfer-list.component.html',
  styleUrls: ['./stock-transfer-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.STOCK_TRANSFER,
  authGuard: AdminGuard
})
export class StockTransferListComponent extends ListPage<StockTransferModel, StockTransferService> implements OnInit {
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>

  constructor(service: StockTransferService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.initTable();
    this.service.getAllByDeleted(StockTransferDtoType.SlimStockTransferDTO).pipe(
      takeUntil(this._destroyed)
    ).subscribe(result => {
      this.models = result;
      this.componentIsReady = true
    });
  }


  override async initTable() {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show'), this.isApproved()),
      new TableAction('thumbs-up', this.approveConsumer(), await this.getTranslate('table.action.approve'), this.isNotShowable()),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.isNotShowable()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.isNotShowable()),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).filterable(false),
      TableCol.newDate('createdAt', await this.getTranslate('inventory.createdAt')),
      TableCol.newString('sourceWarehouse.name', await this.getTranslate('stockTransfer.sourceWarehouse')),
      TableCol.newString('targetWarehouse.name', await this.getTranslate('stockTransfer.targetWarehouse')),
      TableCol.newString('status', await this.getTranslate('inventory.status')).filterable(false).template(() => this.statusTpl),
    ];
  }

  private isNotShowable() {
    return (row: Inventory) => {
      return row.status == SupplierOrderStatus.DRAFT;
    };
  }

  private isApproved() {
    return (row: Inventory) => {
      return row.status == SupplierOrderStatus.APPROVED || row.status == SupplierOrderStatus.PROCESSED;
    };
  }

  private approveConsumer() {
    return (row: StockTransferModel) => {
      this.service.notificationService.confirm('stockTransfer.approveElementConfirm', 'stockTransfer.approveElementConfirmDesc', 'general.yes', 'general.no').then(res => {
        if (res.isConfirmed) {
          this.service.approve(row).subscribe(res => {
            this.loadData();
          })
        }
      })
    };
  }
}
