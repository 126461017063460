<ng-container *ngIf="model && componentIsReady">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="boxes-stacked"
            title="{{'supplierOrder.showHeadline' | translate}}"
            [backSupplier]="back()"
          ></page-show-tool-bar>
          <div class="row nx-margin-top-2xs">
            <div class="col-12">
              <div class="row">
                <div class="col-md-12 mx-auto my-3 d-flex justify-content-between align-items-center">
                  <h3 nxHeadline="subsection-medium">
                    {{ 'supplierOrder.warehouse' | translate }}: {{ model.warehouse.name }}
                  </h3>
                  <button nxButton="cta small" type="button" [disabled]="!isAllProcessed"
                          (click)="processAll()">{{ 'goodsLanding.process' | translate }}
                  </button>
                </div>


              </div>

              <hr class="hr-dark">

              <table nxTable class="nx-margin-top-2m">
                <thead>
                <tr nxTableRow>
                  <th nxHeaderCell>{{ 'supplierOrder.productName' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.supplierProductName' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.supplier' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.status' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.orderedCount' | translate }}</th>
                  <th nxHeaderCell>{{ 'supplierOrder.validCount' | translate }}</th>
                  <th nxHeaderCell></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let element of model.elements | arraySort:'id'">
                  <tr nxTableRow>
                    <td nxTableCell>
                      {{ element.stockProduct ? element.stockProduct.productName : '' }}
                    </td>
                    <td nxTableCell>
                      {{ element.supplierOrderElement.supplierProduct.name }}
                    </td>
                    <td nxTableCell>
                      {{ element.supplierOrderElement.supplierProduct.supplier.companyName }}
                    </td>
                    <td nxTableCell>
                      {{ 'goodsLanding.statuses.' + element.status  | translate }}
                    </td>
                    <td nxTableCell>
                      {{ element.supplierOrderElement.count }} db
                    </td>
                    <td nxTableCell>
                      <nx-formfield appearance="outline">
                        <input nxInput [(ngModel)]="element.count" type="number"
                               [disabled]="element.status !== 'DRAFT'"
                               name="{{'order_'+element.id}}"/>
                      </nx-formfield>
                    </td>
                    <td nxTableCell>
                      <button nxButton="primary small" (click)="approveElement(element)"
                              [disabled]="element.status !== 'DRAFT' || element.count<0"
                      >
                        <nx-icon name="thumbs-up" class="nx-margin-right-s"></nx-icon>
                        {{ 'goodsLanding.approveElement' | translate }}
                      </button>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>

            </div>
          </div>
        </nx-card>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>
