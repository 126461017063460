import {HttpBackend, HttpClient, HttpErrorResponse} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {KeycloakService} from "keycloak-angular";
import {environment} from "../environments/environment";
import {ErrorHandlerService, HellpNotificationServiceType, NotificationConfig} from "@hellp/service";
import {Overlay, ScrollStrategy} from "@angular/cdk/overlay";
import {RxStompConfig} from "@stomp/rx-stomp";
import {RxStompService} from "./utilities/service/rx-stomp.service";
import {Utilities} from "./utilities/utilities";
import {ErrorHandlerFunction} from "@hellp/repository";
import {Observable} from "rxjs";
import SockJS from "sockjs-client";
import {Injectable} from "@angular/core";
import {NxDatepickerIntl} from "@aposin/ng-aquila/datefield";
import {MissingTranslationHandler, MissingTranslationHandlerParams} from "@ngx-translate/core";
import {NxDropdownIntl} from "@aposin/ng-aquila/dropdown";

export type GENERIC_CLASS<T> = { new(): T };

export const DATE_PICKER_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const AUTH_USER_KEY = 'AUTH_USER';
export const ROLE_INDEX: { [key: string]: number } = {
  "admin": 0,
}
export const SCALE = 1.5;

export const USER_ROLES = {'ADMIN': 'admin'};


const notificationConfig = new NotificationConfig(HellpNotificationServiceType.Toast);
notificationConfig.swalToastConfig = {
  toast: true,
  position: 'bottom-end',
  timer: 5000,
  timerProgressBar: true,
  showConfirmButton: false
}
export const NOTIFICATION_CONFIG = notificationConfig;

export function httpTranslateLoader(http: HttpBackend) {
  const client = new HttpClient(http)
  return new TranslateHttpLoader(client, './assets/i18n/', '.json');
}

export function keycloakInit(keycloak: KeycloakService): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise((resolve, reject) => {
      keycloak.init({
        config: {
          url: environment.keycloak.issuer,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId
        },
        loadUserProfileAtStartUp: false,
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false,
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['https://ivrit.medialab.bme.hu']
      }).then(() => {
        resolve();
      }).catch((error: Error) => {
        reject(error);
      });
    });
  };
}

export function scrollStrategyFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.close({threshold: 100});
}

export const HandleError: (errorHandlerService: ErrorHandlerService) => ErrorHandlerFunction = (errorHandlerService: ErrorHandlerService) => {
  return (error: HttpErrorResponse, caught: Observable<any>) => {

    return errorHandlerService.handleError(error, caught);
  }
}

export const myRxStompConfig: RxStompConfig = {
  // Which server?
  brokerURL: environment.wsServiceUrl,
  webSocketFactory: () => {
    return new SockJS(environment.wsServiceUrl);
  },

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: 'guest',
    passcode: 'guest',
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    console.log(Utilities.dateToBackendDateTimeStringByDate(new Date()), msg);
  },
};

export class ImplMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.warn(`Missing translation for key: ${params.key}`);
    return params.key;
  }
}

export function RxStompServiceFactory() {
  const rxStomp = new RxStompService();
  rxStomp.configure(myRxStompConfig);
  rxStomp.activate();
  return rxStomp;
}

@Injectable()
export class AppDateIntl extends NxDatepickerIntl {
  override calendarLabel = 'Naptár';
  override openCalendarLabel = 'Naptár megnyitása';
  override prevMonthLabel = 'Előző hónap';
  override nextMonthLabel = 'Következő hónap';
  override prevYearLabel = 'Elöző év';
  override nextYearLabel = 'Következő';
  override prevMultiYearLabel = 'Elöző 20 év';
  override nextMultiYearLabel = 'Következő 20 év';
  override switchToMonthViewLabel = 'Válassz egy dátumot';
  override switchToMultiYearViewLabel = 'Nézetet váltok';

}

export class AppDropdownIntl extends NxDropdownIntl {
  override selectAll = `Összes kiválasztása`;
  override clearAll = `Kijelölés törlése`;
}


