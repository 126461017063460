import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {Page} from '@hellp/routing';
import {ROUTING} from '../../../utilities/routing';
import {FormPage} from '../../../utilities/abstract/form-page';
import {CultureIssuePairingModel, SuggestedProduct,} from '../../../services/technologies/culture-issue-pairing.model';
import {
  CultureIssuePairingDtoType,
  CultureIssuePairingService,
} from '../../../services/technologies/culture-issue-pairing.service';
import {ActivatedRoute} from '@angular/router';
import {NxDialogService} from '@aposin/ng-aquila/modal';
import {EntityTag, ProductHistoryModel, ProductModel, Tag,} from '../../../services/product/product.model';
import {PlantCulture} from '../../../services/technologies/plant-culture.model';
import {PlantCultureIssue} from '../../../services/technologies/plant-culture-issue.model';
import {
  PlantCultureIssueDtoType,
  PlantCultureIssueService,
} from '../../../services/technologies/plant-culture-issue.service';
import {PlantCultureDtoType, PlantCultureService,} from '../../../services/technologies/plant-culture.service';
import {MonthByIndex} from '../../../services/warehouse/warehouse.model';
import {PartOfMonthByIndex, SKIP_VALIDATION} from '../../../utilities/types';
import {Utilities} from '../../../utilities/utilities';
import {forkJoin, map, takeUntil} from 'rxjs';
import {ProductService} from '../../../services/product/product.service';
import {HellparserService} from '@hellp/parser';
import {ServiceLocator} from '../../../utilities/service/service.locator';
import {NxDropdownSelectChange} from '@aposin/ng-aquila/dropdown/dropdown';
import {PlantCultureIssueType} from '../../../services/technologies/plant-culture-issue-type.model';
import {
  PlantCultureIssueTypeDtoType,
  PlantCultureIssueTypeService,
} from '../../../services/technologies/plant-culture-issue-type.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ValidatedInputDirective} from "../../../directives/validated-input.directive";
import {StepElement} from "../../../models/step-element.model";
import {StepChangeEvent} from "../../../components/container/stepper-container/stepper-container.component";

@Component({
  selector: 'app-culture-issue-pairing-form',
  templateUrl: './culture-issue-pairing-form.component.html',
  styleUrls: ['./culture-issue-pairing-form.component.css'],
})
@Page({
  path: ['new', 'edit/:id', 'new/:plantCultureId'],
  pathPrefix: ROUTING.TECHNOLOGIES,
})
export class CultureIssuePairingFormComponent extends FormPage<CultureIssuePairingModel, CultureIssuePairingService> implements OnInit, OnDestroy {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  @ViewChild('baseData') baseData!: TemplateRef<any>;
  @ViewChild('suggestionData') suggestionData!: TemplateRef<any>;
  @ViewChild('summaryData') summaryData!: TemplateRef<any>;
  @ViewChildren(ValidatedInputDirective) override inputs!: QueryList<ValidatedInputDirective>;
  saveIsEnable = false;

  plantCultures: PlantCulture[] = [];
  plantIssue: PlantCultureIssue[] = [];
  plantIssueTypes: PlantCultureIssueType[] = [];
  allPlantIssue: PlantCultureIssue[] = [];
  dynamicBackendOptions: (term: string) => any;
  selectedName?: string;

  steps!: StepElement[];

  protected readonly MonthByIndex = MonthByIndex;
  protected readonly PartOfMonthByIndex = PartOfMonthByIndex;
  youtubeLink = '';
  disableCulture = false;
  editMode = false;

  constructor(
    service: CultureIssuePairingService,
    route: ActivatedRoute,
    public dialogService: NxDialogService,
    private plantCultureService: PlantCultureService,
    private plantCultureIssueTypeService: PlantCultureIssueTypeService,
    private plantCultureIssueService: PlantCultureIssueService,
    private productService: ProductService, private cdr: ChangeDetectorRef
  ) {
    super(route, service, CultureIssuePairingModel, []);
    this.dynamicBackendOptions = (term: string) =>
      productService.getHistoriesByProductNameFilter(term).pipe(
        takeUntil(this._destroyed),
        map(this.productService.getAutocompleteLabelProcess()),
      );
  }

  ngOnInit(): void {
    this.onInit(CultureIssuePairingDtoType.CultureIssuePairingDTO);
    this.initSteps();
    this.cdr.detectChanges();
  }

  private loadData() {
    const culture$ = this.plantCultureService.getAllByDeleted(
      PlantCultureDtoType.PlantCultureDTO,
    );
    const issue$ = this.plantCultureIssueService.getAllByDeleted(
      PlantCultureIssueDtoType.PlantCultureIssueDTO,
    );
    const issueType$ = this.plantCultureIssueTypeService.getAllByDeleted(
      PlantCultureIssueTypeDtoType.PlantCultureIssueTypeDTO,
    );

    forkJoin([culture$, issue$, issueType$])
      .pipe(takeUntil(this._destroyed))
      .subscribe(([cultures, issues, issueTypes]) => {
        this.plantCultures = cultures;
        this.allPlantIssue = issues;
        this.plantIssueTypes = issueTypes;
        if (!this.editMode) {
          this.initCulturePreset();
        } else {
          this.plantIssue = this.allPlantIssue;
        }
      });
  }

  initSteps() {
    this.steps = [
      new StepElement(1, 'technologies.baseInformation', () => this.baseData, this.validateStep(1)),
      new StepElement(2, 'technologies.suggestedProduct', () => this.suggestionData, this.validateStep(2)),
      new StepElement(3, 'category.summary', () => this.summaryData, SKIP_VALIDATION),
    ]
  }


  validateStep(stepCount: number) {
    return () => {
      try {
        this.service.validateByStep(this.formModel, stepCount);
        return true;
      } catch (e) {
        console.warn(e);
        return false
      }
    }
  }

  protected override afterModelIsSetProcess() {
    if (this.formModel.id) {
      this.formModel.plantCultureId = this.formModel.plantCulture.id;
      this.formModel.plantCultureIssueId = this.formModel.plantCultureIssue.id;
      this.formModel.plantCultureIssueTypeId =
        this.formModel.plantCultureIssueType.id;
      this.saveIsEnable = true;
      this.editMode = true;
    } else {
      this.formModel.plantCultureId = undefined;
      this.formModel.plantCultureIssueId = undefined;
      this.formModel.plantCultureIssueTypeId = undefined;
    }

    this.loadData();
    this.initErrorHandlingOnInput();
    setTimeout(() => {
      this.componentIsReady = true;
    }, 600);
  }

  private initCulturePreset() {
    if (!this.editMode) {
      this.sub = this.route.params.subscribe(async (params) => {
        const plantCultureId = params['plantCultureId'];
        if (plantCultureId) {
          this.formModel.plantCultureId = parseInt(plantCultureId);
          this.disableCulture = true;
          const tmp = this.plantCultures.find(
            (pi) => pi.id == this.formModel.plantCultureId,
          );
          if (tmp) {
            this.formModel.plantCulture = tmp;
            this.intersectionIssue();
          }
        }
      });
    }
  }

  private intersectionIssue() {
    this.service
      .getAllForCulture(this.formModel.plantCulture)
      .pipe(takeUntil(this._destroyed))
      .subscribe((result) => {
        const existingIssue = result.map((tech) => tech.plantCultureIssue.id);
        this.plantIssue = this.allPlantIssue.filter(
          (pi) => !existingIssue.includes(pi.id),
        );
      });
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }


  changeStep($event: StepChangeEvent) {
    if (this.youtubeLink.length > 0) {
      this.addYoutubeLink();
    }
  }

  tagsChange(tags: Tag[]) {
    this.formModel.tags = tags.map((t) => new EntityTag(t));
  }

  addYoutubeLink() {
    const a = Utilities.extractVideoId(this.youtubeLink);
    if (a.length < 1) {
      this.service.notificationService.showError(
        'general.error',
        'technologies.invalidLink',
      );
    } else {
      this.formModel.youtubeLinks.push(this.youtubeLink);
    }
    this.youtubeLink = '';
  }

  removeYoutubeLink() {
    this.formModel.youtubeLinks = [];
  }

  addSuggestionProduct() {
    if (this.selectedName && this.selectedName.length > 0) {
      this.productService
        .getHistoryByAutocompleteField(this.selectedName)
        .pipe(takeUntil(this._destroyed))
        .subscribe((result) => {
          if (result) {
            result.published = this.parser.parseApiDataToModel(
              result.published,
              ProductModel,
            );
            const suggestedProduct: SuggestedProduct = new SuggestedProduct();
            suggestedProduct.productHistory = result;
            suggestedProduct.orderNumber =
              this.formModel.suggestedProducts.length + 1;
            this.formModel.suggestedProducts.push(suggestedProduct);
            this.selectedName = undefined;
          } else {
            this.productService.notificationService.showWarning(
              'general.warning',
              'general.unknownProductName',
            );
          }
        });
    }
  }

  changeCulture(event: NxDropdownSelectChange) {
    const tmp = this.plantCultures.find((pi) => pi.id == event.value);
    if (tmp) {
      this.formModel.plantCulture = tmp;
      this.intersectionIssue();
    }
  }

  changeIssue(event: NxDropdownSelectChange) {
    const tmp = this.plantIssue.find((pi) => pi.id == event.value);
    if (tmp) {
      this.formModel.plantCultureIssue = tmp;
    }
  }

  changeIssueType(event: NxDropdownSelectChange) {
    const tmp = this.plantIssueTypes.find((pi) => pi.id == event.value);
    if (tmp) {
      this.formModel.plantCultureIssueType = tmp;
    }
  }

  protected override savePostProcess() {
    this.service.notificationService
      .confirm(
        'general.next',
        'technologies.sameCulture',
        'general.yes',
        'general.no',
      )
      .then((r) => {
        if (r.isConfirmed) {
          this.service.navigateToNew('/', `/${this.formModel.plantCultureId}`);
        } else {
          this.service.navigateToBase();
        }
      });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.formModel.suggestedProducts,
      event.previousIndex,
      event.currentIndex,
    );

    this.formModel.suggestedProducts.forEach((sp, index) => {
      sp.orderNumber = index + 1;
    });
  }
}
