import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {AuthorRepository} from "./author.repository";
import {Author} from "./magazine.model";
import {ValidationError} from "../../models/validation.error.model";
import {ValidatorService} from "../../utilities/service/validator.service";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";


export enum AuthorDtoTypes {
  AuthorDTO = 'AuthorDTO'
}

@Injectable({
  providedIn: 'root'
})
export class AuthorService extends CrudService<Author, AuthorRepository<Author>> {

  constructor(repo: AuthorRepository<Author>) {
    super(ROUTING.AUTHOR, repo, Author, PageMetaDataConf.AUTHOR);
  }

  override validate(model: Author) {
    let errors: ValidationError[] = ValidatorService.allIsRequired(model, ['name', 'coverPhoto',], Author.name);
    errors = errors.concat(ValidatorService.allIsRequired(model.coverPhoto, ['attachment'], Author.name));
    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }

  }
}
