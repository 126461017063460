import { Injectable } from '@angular/core';
import { CrudService } from '../../utilities/service/crud.service';
import { ROUTING } from '../../utilities/routing';
import { ContactModel } from './contact.model';
import { ContactRepository } from './contact.repository';
import { PageMetaDataConf } from '../../utilities/page-meta-data.conf';

export enum ContactDtoTypes {
  ContactDTO = 'ContactDTO',
}

@Injectable({
  providedIn: 'root',
})
export class ContactService extends CrudService<ContactModel, ContactRepository<ContactModel>> {
  constructor(repo: ContactRepository<ContactModel>) {
    super(ROUTING.CONTACT, repo, ContactModel, PageMetaDataConf.CONTACT);
  }
}
