import {Component, Input} from '@angular/core';
import {ProductModel} from "../../../services/product/product.model";

@Component({
  selector: 'app-product-base-panel',
  templateUrl: './product-base-panel.component.html',
  styleUrls: ['./product-base-panel.component.css']
})
export class ProductBasePanelComponent {
  @Input() model!: ProductModel
}
