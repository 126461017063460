<h3 class="mb-3 d-flex justify-content-between" nxHeadline="subsection-large">
  <span>{{ 'cart.cartElements' | translate }}</span>
  <ng-container *ngIf="isEditable">
    <button nxPlainButton (click)="edit()" class="text-dark">
      <nx-icon name="pencil"></nx-icon>
    </button>
  </ng-container>
</h3>
<table class="table table-striped table-hover">
  <caption>
    <div class="row">
      <div class="col-md-6 text-center text-md-start">
        {{ 'cart.countSum' | translate:({x: order.cart.countElements}) }}
      </div>
      <div class="col-md-6 text-center text-md-end mt-3 mt-md-0">
        {{ 'cart.sumPrice' | translate: ({x: order.cart.sumPrice}) }}
      </div>
    </div>
  </caption>
  <tbody>

  <ng-container *ngFor="let element of order.cart.cartElements; let i = index">

    <tr>
      <th scope="row">
        <a [href]="environment.publicUrl+'/bio-termekek'+'/bio-termek/'+element.product.product.slug"
           title="{{element.product.product.name}}"
        >

          <div class="d-flex align-items-center">
            <div>
              <img loading="lazy" [src]="element.product.mainImage.attachment.src" class="avatar avatar-sm me-3">
            </div>
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">
                {{ element.product.product.name }}</h6>
              <p class="text-xs text-darker mb-0">
                {{ element.product.category.name }}</p>
            </div>
          </div>
        </a>
      </th>
      <td class="text-end">
        <div class="d-flex justify-content-end">
          <div class="d-flex flex-column justify-content-end align-items-end">
            <p class="mb-0 text-xs">
              <a href="javascript:void(0)" class="mx-3">{{ element.count }} <span
              >{{ 'general.amount' | translate }}</span></a>
            </p>
            <p class="text-xs text-secondary mb-0">
              {{ element.price }} Ft
            </p>
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
  <ng-container *ngFor="let element of order.cart.extraCartElements; let i = index">

    <tr>
      <th scope="row">

        <div class="d-flex align-items-center">
          <div>
            <img loading="lazy" [src]="'assets/img/'+element.key+'.svg'" class="avatar avatar-sm me-3">
          </div>
          <div class="d-flex flex-column justify-content-center">
            <h6 class="mb-0 text-xs">
              {{ 'cart.extraPrice.' + element.key + '.name' | translate }}</h6>
            <p class="text-xs text-darker mb-0">
              {{ 'cart.extraPrice.' + element.key + '.hint' | translate }}</p>
          </div>
        </div>
      </th>
      <td class="text-end">
        <div class="d-flex justify-content-end">
          <div class="d-flex flex-column justify-content-end align-items-end">
            <p class="mb-0 text-xs">
              <a href="javascript:void(0)"
                 class="mx-3">{{ element.count }} {{ 'general.amount' | translate }}</a>
            </p>
            <p class="text-xs text-secondary mb-0">
              {{ element.grossPrice }} Ft
            </p>
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>