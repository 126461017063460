import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {Note, SupplierOrder, SupplierOrderElement} from "../../../services/supplier-order/supplier-order.model";
import {SupplierOrderDtoType, SupplierOrderService} from "../../../services/supplier-order/supplier-order.service";
import {WarehouseDtoTypes, WarehouseService} from "../../../services/warehouse/warehouse.service";
import {ActivatedRoute} from "@angular/router";
import {Month, MonthByIndex, StockProduct, WarehouseModel} from "../../../services/warehouse/warehouse.model";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {NxDialogService, NxModalRef} from '@aposin/ng-aquila/modal';
import {Moment} from "moment"
import {SupplierDtoTypes, SupplierService} from "../../../services/supplier/supplier.service";
import {forkJoin, takeUntil} from "rxjs";
import {SupplierModel} from "../../../services/supplier/supplier.model";
import {Currencies} from "../../../utilities/types";

@Component({
  selector: 'app-supplier-order-form',
  templateUrl: './supplier-order-form.component.html',
  styleUrls: ['./supplier-order-form.component.css']
})
@Page({
  path: ['new'],
  pathPrefix: ROUTING.SUPPLIER_ORDER,
})
export class SupplierOrderFormComponent extends FormPage<SupplierOrder, SupplierOrderService> implements OnInit, OnDestroy {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);

  protected filter: any = {productName: '', supplierProductName: '', supplierName: ''};
  protected readonly MonthByIndex = MonthByIndex;

  private note?: string;
  private estimatedDate?: Moment;
  private mainWareHouse?: WarehouseModel;
  private actualSupplierId?: number;
  @ViewChild('template') templateRef!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  warehouses: WarehouseModel[] = [];
  selectedWarehouse?: WarehouseModel;
  selectAll = false;
  actualMonth!: string;
  tempNote = '';
  tempDate?: Moment;
  editMode = false;
  availableStockProducts: StockProduct[] = [];
  suppliers!: SupplierModel[];
  selectedSupplierId?: number;
  currency: Currencies = Currencies.huf;

  constructor(service: SupplierOrderService, route: ActivatedRoute,
              private readonly warehouseService: WarehouseService,
              private readonly supplierService: SupplierService,
              private readonly dialogService: NxDialogService) {
    super(route, service, SupplierOrder, []);
  }

  ngOnInit(): void {
    this.initActualMonth();
    this.onInit(SupplierOrderDtoType.SupplierOrderDTO);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected override afterModelIsSetProcess() {
    const suppliersGet$ = this.supplierService.getAllByDeleted(SupplierDtoTypes.SlimSupplierDTO);
    const warehouseGet$ = this.warehouseService.getMainHQ(WarehouseDtoTypes.SlimWarehouseDTO);
    forkJoin([suppliersGet$, warehouseGet$])
      .pipe(takeUntil(this._destroyed))
      .subscribe(([supplierResult, warehouseGResult]) => {
        this.suppliers = supplierResult;
        if (!warehouseGResult) {
          this.service.notificationService.showWarning('general.warning', 'supplierOrder.missingWarehouse');
        } else {
          this.mainWareHouse = warehouseGResult;
        }
        this.componentIsReady = true;
      });
  }

  override updateProcess() {
    this.service.notificationService.showInfo('general.info', 'supplierOrder.updateNotAvailable')
  }

  changeWarehouse(warehouse: WarehouseModel) {
    this.selectedWarehouse = warehouse;
    this.formModel.warehouse = warehouse;
    this.formModel.note = new Note("");
    this.formModel.elements = [];
    this.availableStockProducts = [];
    this.selectedWarehouse.products.forEach(stockProduct => {
      this.initSelectedWarehouseStockProducts(stockProduct);
    });
    this.availableStockProducts = this.selectedWarehouse.products;
  }

  onAdd(stockProduct: StockProduct) {
    stockProduct.addToOrder = true;
    this.formModel.elements.push(SupplierOrderElement.initSupplierOrderElementByStockProduct(stockProduct));
  }

  onRemove(stockProduct: StockProduct) {
    stockProduct.addToOrder = false;
    this.formModel.elements = this.formModel.elements.filter(e => e.supplierProduct.id == stockProduct.product.supplierProduct.id);
  }

  onToggleSelectedAll() {
    this.selectAll = !this.selectAll;
    if (this.selectedWarehouse) {
      this.selectedWarehouse.products
        .filter(elem => elem.orderCount > 0)
        .forEach(stockProduct => {
          if (this.selectAll) {
            this.onAdd(stockProduct);
          } else {
            this.onRemove(stockProduct);
          }
        });
    }
  }

  onOpenFromTemplate(stockProduct?: StockProduct): void {
    if (stockProduct && stockProduct.estimatedDate) {
      this.tempDate = stockProduct.estimatedDate;
    }
    this.templateDialogRef = this.dialogService.open(this.templateRef);
    this.templateDialogRef.afterClosed().subscribe(result => {
      this.updateStockProductAfterModalClose(stockProduct);
    });
  }

  onCloseTemplateDialog() {
    this.templateDialogRef?.close();
  }

  onFilter() {
    if (this.selectedWarehouse) {
      if (this.filter.productName.length < 1 && this.filter.supplierProductName.length < 1 && this.filter.supplierName.length < 1) {
        this.availableStockProducts = this.selectedWarehouse.products;
      }
      this.availableStockProducts = this.selectedWarehouse.products.filter(stockProduct => {
        const isProd = stockProduct.product.product.name.toLowerCase().includes(this.filter.productName.toLowerCase());
        const isSupProd = stockProduct.product.supplierProduct.name.toLowerCase().includes(this.filter.supplierProductName.toLowerCase());
        const isSup = stockProduct.product.supplierProduct.supplier.companyName.toLowerCase().includes(this.filter.supplierName.toLowerCase());

        return isProd && isSupProd && isSup;
      });

    }

  }

  onClearFilter() {
    this.filter.productName = '';
    this.filter.supplierProductName = '';
    this.filter.supplierName = '';
    this.onFilter();
  }

  onSelectSupplier() {
    if (this.selectedSupplierId) {
      const supplierId = this.selectedSupplierId;
      const productUnderProcess = this.selectedWarehouse?.products.filter(sp => sp.addToOrder);
      if (productUnderProcess && productUnderProcess.length > 0) {
        this.service.notificationService.confirm(
          'supplierOrder.changeSupplierConfirm',
          'supplierOrder.changeSupplierConfirmDesc',
          'general.confirm',
          'general.cancel').then(res => {
          if (res.isConfirmed) {
            this.selectSupplier(supplierId);
          } else {
            this.selectedSupplierId = this.actualSupplierId;
          }
        });
      } else {
        this.selectSupplier(supplierId);
      }
    }
  }

  private updateStockProductAfterModalClose(stockProduct: StockProduct | undefined) {
    if (stockProduct) {
      stockProduct.orderNote = this.tempNote;
      stockProduct.estimatedDate = this.tempDate;
    } else {
      this.note = this.tempNote
      this.estimatedDate = this.tempDate;
      this.formModel.elements
        .filter(elem => !elem.estimatedArrival)
        .forEach(elem => elem.estimatedArrival = this.tempDate);
    }
    this.tempNote = '';
  }

  private initSelectedWarehouseStockProducts(stockProduct: StockProduct) {
    stockProduct.product = this.parser.parseApiDataToModel(stockProduct.product, ProductHistoryModel);
    if (stockProduct.product.supplierProduct.prices.length > 0) {
      this.currency = stockProduct.product.supplierProduct.prices[0].currency;
    }
    const monthlyMinimumElement = stockProduct.config.actualMinimumStock.monthlyMinimum[this.actualMonth];
    const monthlyCount = monthlyMinimumElement - stockProduct.count;
    if (monthlyCount > 0) {
      stockProduct.orderCount = monthlyCount;
      this.onAdd(stockProduct);
    } else {
      stockProduct.orderCount = 0;
    }
  }

  private initActualMonth() {
    const actualMonthIndex = (new Date()).getMonth();
    this.actualMonth = Object.keys(Month)[actualMonthIndex];
  }

  private selectSupplier(supplierId: number) {
    this.actualSupplierId = supplierId;
    this.warehouseService.getStockProductsForMainHQ(supplierId)
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => {
        const tmp = result.filter(sp => sp.warehouseId == this.mainWareHouse?.id);
        if (tmp.length == result.length && this.mainWareHouse) {
          this.mainWareHouse.products = result;
          this.changeWarehouse(this.mainWareHouse);
        } else {
          this.service.notificationService.showError('general.error', 'error.supplierOrder.warehouseMismatch')
        }
      });
  }
}
