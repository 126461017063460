import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {BannerModel} from "../../../services/banner/banner.model";
import {BannerDtoTypes, BannerService} from "../../../services/banner/banner.service";
import {ActivatedRoute} from "@angular/router";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {Moment} from "moment";
import {NxDatepickerComponent} from "@aposin/ng-aquila/datefield";

@Component({
  selector: 'app-banner-form',
  templateUrl: './banner-form.component.html',
  styleUrls: ['./banner-form.component.css']
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.BANNER,
})
export class BannerFormComponent extends FormPage<BannerModel, BannerService> implements OnInit, OnDestroy {
  @ViewChild('toDatepicker') endDatepicker!: NxDatepickerComponent<Moment>;

  constructor(route: ActivatedRoute, service: BannerService) {
    super(route, service, BannerModel, []);
  }

  ngOnInit(): void {
    this.onInit(BannerDtoTypes.BannerDTO);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected override afterModelIsSetProcess() {
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  updateEndDatepicker() {
    this.formModel.activeTo = this.formModel.activeTo.add(1, "day");
    setTimeout(() => this.endDatepicker.open(), 100);
  }

}
