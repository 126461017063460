export class PageMetaData {
  icon: string;
  listHeadline: string;
  formHeadlineNew: string;
  formHeadlineEdit: string;
  detailsHeadline: string;

  constructor(
    icon: string,
    listHeadline: string,
    formHeadlineNew: string,
    formHeadlineEdit: string,
    detailsHeadline: string,
  ) {
    this.icon = icon;
    this.listHeadline = listHeadline;
    this.formHeadlineNew = formHeadlineNew;
    this.formHeadlineEdit = formHeadlineEdit;
    this.detailsHeadline = detailsHeadline;
  }
}

export const PageMetaDataConf = {
  PRODUCT: new PageMetaData(
    'leaf',
    'product.headline',
    'product.formHeadlineNew',
    'product.formHeadlineEdit',
    'product.details',
  ),
  BLOG: new PageMetaData('newspaper', 'blog.headline', 'blog.formHeadlineNew', 'blog.formHeadlineEdit', 'blog.details'),
  CATEGORY: new PageMetaData(
    'boxes-stacked',
    'category.headline',
    'category.formHeadlineNew',
    'category.formHeadlineEdit',
    'category.details',
  ),
  MAGAZINE: new PageMetaData(
    'newspaper',
    'magazine.headline',
    'magazine.formHeadlineNew',
    'magazine.formHeadlineEdit',
    'magazine.details',
  ),
  AUTHOR: new PageMetaData(
    'at',
    'author.headline',
    'author.formHeadlineNew',
    'author.formHeadlineEdit',
    'author.details',
  ),
  TECHNOLOGIES: new PageMetaData(
    'arrows-to-eye',
    'author.headline',
    'author.formHeadlineNew',
    'author.formHeadlineEdit',
    'author.details',
  ),
  SCRAPPING: new PageMetaData(
    'scroll',
    'scrapping.headline',
    'scrapping.formHeadlineNew',
    'scrapping.formHeadlineEdit',
    'scrapping.details',
  ),
  INVENTORY: new PageMetaData(
    'truck-ramp-box',
    'inventory.headline',
    'inventory.formHeadlineNew',
    'inventory.formHeadlineEdit',
    'inventory.details',
  ),
  WAREHOUSE: new PageMetaData(
    'truck-ramp-box',
    'warehouse.headline',
    'warehouse.formHeadlineNew',
    'warehouse.formHeadlineEdit',
    'warehouse.details',
  ),
  STOCK_TRANSFER: new PageMetaData(
    'right-left',
    'stockTransfer.headline',
    'stockTransfer.formHeadlineNew',
    'stockTransfer.formHeadlineEdit',
    'stockTransfer.details',
  ),
  SUPPLIER: new PageMetaData(
    'truck-ramp-box',
    'stockTransfer.headline',
    'stockTransfer.formHeadlineNew',
    'stockTransfer.formHeadlineEdit',
    'stockTransfer.details',
  ),
  SUPPLIER_ORDER: new PageMetaData(
    'truck-ramp-box',
    'supplierOrder.headline',
    'supplierOrder.formHeadlineNew',
    'supplierOrder.formHeadlineEdit',
    'supplierOrder.details',
  ),
  USER_ORDER: new PageMetaData(
    'shopping-cart',
    'userOrder.headline',
    'userOrder.formHeadlineNew',
    'userOrder.formHeadlineEdit',
    'userOrder.details',
  ),
  BANNER: new PageMetaData(
    'flag',
    'banner.headline',
    'banner.formHeadlineNew',
    'banner.formHeadlineEdit',
    'banner.details',
  ),
  BIO_CERT: new PageMetaData(
    'newspaper',
    'bioCert.headline',
    'bioCert.formHeadlineNew',
    'bioCert.formHeadlineEdit',
    'bioCert.details',
  ),
  GOODS_LANDING: new PageMetaData(
    'dolly',
    'goodsLanding.headline',
    'goodsLanding.formHeadlineNew',
    'goodsLanding.formHeadlineEdit',
    'goodsLanding.details',
  ),
  CONTACT: new PageMetaData(
    'address-book',
    'contact.headline',
    'contact.formHeadlineNew',
    'contact.formHeadlineEdit',
    'contact.details',
  ),
};
