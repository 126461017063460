<nx-card class="viewer">
  <ng-container *ngIf="!isEdit">
    <page-list-view-header
      [newConsumer]="edit()"
      newIcon="pencil"
      title="{{'aboutUs.headline' | translate}}"
      iconName="paragraph"
    ></page-list-view-header>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-2m">
        <div [innerHTML]="formModel.content | safeHtml"></div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isEdit">
    <page-form-header iconName="icons"
                      [model]="formModel"
                      [cancelSupplier]="cancel()"
                      [saveSupplier]="save()"
                      [modalOptions]="toolbarAddOptions"
    ></page-form-header>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-2m">
        <div class="NgxEditor__Wrapper" style="min-height: 500px">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
          <ngx-editor style="height: 500px"
                      name="description"
                      placeholder="{{'aboutUs.aboutUs' | translate}}"
                      [editor]="editor"
                      [(ngModel)]="formModel.content"
                      [disabled]="false"
          ></ngx-editor>
        </div>
      </div>
    </div>
  </ng-container>
</nx-card>
