import {Component, Inject} from '@angular/core';
import {DeliveryPartner} from "../../../services/purchase-info/purchase-info.model";
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

@Component({
  selector: 'app-add-delivery-partner',
  templateUrl: './add-delivery-partner.component.html',
  styleUrls: ['./add-delivery-partner.component.css']
})
export class AddDeliveryPartnerComponent {
  deliveryPartner: DeliveryPartner;

  constructor(@Inject(NX_MODAL_DATA) public injectData: DeliveryPartner) {
    this.deliveryPartner = injectData;
  }

  validate() {

  }
}
