<div nxRow="">
  <div nxCol="12,12,12,{{uploadType == UploadType.MARKETING?'12':'6'}}">
    <hellp-card-container>
      <div nxRow="">
        <div nxCol="12">
          <nx-formfield nxLabel="{{'product.name' |translate}}" appearance="auto">
            <input appValidatedInput type="text" nxInput name="name" required [(ngModel)]="model.name"
                   (keyup)="changeSKU()"/>
            <nx-error class="nx-margin-bottom-m" nxFormfieldError
                      *ngIf="validationError.name"> {{ validationError.name | translate }}
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="12" *ngIf="uploadType == UploadType.SALES">
          <nx-formfield nxLabel="{{'product.category' |translate}}" appearance="auto">
            <nx-dropdown appValidatedInput required [(ngModel)]="model.history.categoryId"
                         (selectionChange)="refreshCategory()"
                         [nxShowFilter]="true"
                         name="category"
                         [disabled]="model.history.hasPublished"
                         nxFilterPlaceholder="{{'general.typeToSearch' | translate}}">
              <nx-dropdown-item
                *ngFor="let category of categories"
                [nxValue]="category.id"
              > {{ category.name }}
              </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError
                      *ngIf="validationError.category"> {{ validationError.category | translate }}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
    </hellp-card-container>
  </div>
  <div nxCol="12,12,12,6" *ngIf="uploadType == UploadType.SALES">
    <div class="card p-3 mb-4 box-shadow">
      <div nxRow="">
        <div nxCol="12">
          <nx-formfield nxLabel="{{'product.itemNr' |translate}}" appearance="auto">
            <input appValidatedInput type="text" nxInput name="itemNr" required [(ngModel)]="model.itemNr"/>
            <nx-error class="nx-margin-bottom-m" nxFormfieldError
                      *ngIf="validationError.itemNr"> {{ validationError.itemNr | translate }}
            </nx-error>
          </nx-formfield>
        </div>

        <div nxCol="12">
          <label class="nx-formfield__label" style="left: auto; font-weight: 500">{{ 'product.SKU' |translate }}</label>
          <nx-formfield appearance="auto">
<span nxFormfieldPrefix
      *ngIf="model.history && model.history.category">{{ model.history.category.sku }}</span>
            <input appValidatedInput type="text" nxInput name="SKU" required [(ngModel)]="model.sku"
                   (focusin)="focusSKU()"
                   (ngModelChange)="model.sku = model.sku.toUpperCase()"/>
            <nx-error class="nx-margin-bottom-m" nxFormfieldError
                      *ngIf="validationError.sku"> {{ validationError.sku | translate }}
            </nx-error>
            <span nxFormfieldHint
                  nxTooltip="{{ 'product.SKUinfo' | translate }}">{{ 'product.SKUinfo' | translate }}</span>
          </nx-formfield>
        </div>
      </div>
    </div>
  </div>
  <div nxCol="12" *ngIf="uploadType == UploadType.MARKETING">
    <div class="card p-3 mb-4 box-shadow">
      <div nxRow="">
        <div nxCol="12">
          <nx-formfield nxLabel="{{'product.shortDescription' |translate}}" appearance="auto">
            <textarea appValidatedInput required nxInput maxlength="255" name="shortDescription"
                      [(ngModel)]="model.shortDescription"></textarea>
            <nx-error class="nx-margin-bottom-m" nxFormfieldError
                      *ngIf="validationError.shortDescription"> {{ validationError.shortDescription | translate }}
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="12">
          <h6>{{ 'product.description' | translate }}</h6>
          <div class="NgxEditor__Wrapper" style="min-height: 500px">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor style="height: 500px" [editor]="editor" name="description" [(ngModel)]="model.description"
                        [disabled]="false"
                        placeholder="{{'product.description' | translate}}"></ngx-editor>

          </div>
          <nx-error class="nx-margin-bottom-m nx-margin-top-s" nxFormfieldError
                    *ngIf="validationError.description"> {{ validationError.description | translate }}
          </nx-error>
        </div>
      </div>
    </div>
  </div>
</div>




