import {Component, EventEmitter, Input} from '@angular/core';
import {AttachmentModel} from "../../../services/attachment/attachment.model";
import {AttachmentHandlerView} from "../attachment-handler/attachment-handler.model";

@Component({
    selector: 'app-attachment-element',
    templateUrl: './attachment-element.component.html',
    styleUrls: ['./attachment-element.component.css']
})
export class AttachmentElementComponent implements AttachmentHandlerView {
    @Input() isOptional!: boolean;
    @Input() data!: AttachmentModel;
    refreshEvent!: EventEmitter<any>;
}
