import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DownloadFileDirective} from './download-file.directive';
import {DragNDropFileDirective} from './drag-n-drop-file.directive';
import {ViewAnchorDirective} from './view-anchor.directive';
import { ValidatedInputDirective } from './validated-input.directive';
import { CopyClipboardDirective } from './copy-clipboard.directive';


@NgModule({
  declarations: [DownloadFileDirective, DragNDropFileDirective, ViewAnchorDirective, ValidatedInputDirective, CopyClipboardDirective],
  exports: [DownloadFileDirective, DragNDropFileDirective, ViewAnchorDirective, ValidatedInputDirective, CopyClipboardDirective],
  imports: [
    CommonModule,
  ],
})
export class DirectivesModule {
}
