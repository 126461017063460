import {Injectable} from "@angular/core";
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {PurchaseInfoModel} from "./purchase-info.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/purchase-infos')
export class PurchaseInfoRepository extends CrudRepository<PurchaseInfoModel> {
  constructor() {
    super();
  }
}