import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {ValidatorService} from "../../utilities/service/validator.service";
import {PrivacyPolicyModel} from "./privacy-policy.model";
import {PrivacyPolicyRepository} from "./privacy-policy.repository";

export enum PrivacyPolicyDtoTypes {
  PrivacyPolicyDTO = 'PrivacyPolicyDTO'
}

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyService extends CrudService<PrivacyPolicyModel, PrivacyPolicyRepository> {

  constructor(repo: PrivacyPolicyRepository) {
    super(ROUTING.PRIVACY_POLICY, repo, PrivacyPolicyModel);
  }

  override validate(model: PrivacyPolicyModel) {
    ValidatorService.required(model.content, "error.policy.contentRequired");
  }

}
