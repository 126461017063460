import {ConvertFrom, ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {PriceModel} from "../../models/price.model";
import {ProcessorService} from "../processor.service";
import {ProductHistoryModel} from "../product/product.model";
import {BaseModel} from "../../utilities/abstract/base.model";
import {
  Currencies,
  DeliveryType,
  InvoiceDocumentType,
  OrderStatus,
  PaymentStatus,
  PaymentType
} from "../../utilities/types";
import {PackagingConfig} from "../category/category.model";
import {ExtendedProcessorService} from "../extended-processor.service";
import {SlimUserModel} from "../user/slim-user.model";
import {DeliveryPartner} from "../purchase-info/purchase-info.model";
import {CASH_PRICE_KEY, DELIVERY_PRICE_KEY} from "./cart.service";
import {UserModel} from "../user/user.model";
import {Utilities} from "../../utilities/utilities";

export class EmailData {
  subject: string;
  message: string;
  recipients: string[] = [];

  constructor(subject: string, message: string, recipients: string[]) {
    this.subject = subject;
    this.message = message;
    this.recipients = recipients;
  }
}

export class ExtraCartElement {
  id!: number;
  count = 1;
  key = '';
  @ParseTo(PriceModel)
  @ConvertFrom
  price: PriceModel = new PriceModel();
  productId = 0;

  constructor(productId: number, key: string, price: PriceModel, count = 1) {
    this.productId = productId;
    this.key = key;
    this.price = price;
    this.count = count;
  }

  public get grossPrice() {
    return Math.round(this.price.gross() * this.count);
  }

}

export class CartElement {
  count = 1;
  @ParseProcess({processorClass: ExtendedProcessorService, processorFunctionName: 'productToHistory'})
  product: ProductHistoryModel;
  productId = 0;

  constructor(count: number, product: ProductHistoryModel) {
    this.product = product;
    this.count = count;
    this.productId = product ? product.id : 0;
  }

  public get price() {
    return Math.round(this.product.product.basePrice.gross() * this.count);
  }
}

export class AddressModel {
  zipCode = 0;
  country = '';
  city = '';
  street = '';
  streetNumber = '';
  other = '';

  public toString(): string {
    return `${this.country}, ${this.zipCode} ${this.city}, ${this.street} ${this.streetNumber} ${this.other}`;
  }
}

export class BillingData extends BaseModel {
  firstName = '';
  lastName = '';
  companyName?: string;
  taxNumber?: string;
  phoneNumber = '';
  email = '';
  @ParseTo(AddressModel)
  address!: AddressModel;
  user!: SlimUserModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getUserIdIfNotNull'}, "model")
  userId = 0;

  get title(): string {
    return `${this.firstName} ${this.lastName} (${this.email}) - ${this.address.toString()}`;
  }

  isSame(other: any): boolean {
    const that: any = this;
    const keys = ['firstName', 'lastName', 'companyName', 'taxNumber', 'phoneNumber', 'email'];
    if (other.address.toString() != this.address.toString()) {
      return false;
    }

    for (const key of keys) {
      if (that[key] && other[key] && that[key].toString().toLowerCase() != other[key].toString().toLowerCase()) {
        return false;
      }
    }
    return true;
  }


  static newEmptyWithUser(user: UserModel) {
    const bd = new BillingData();
    bd.address = new AddressModel();
    bd.user = user;
    return bd;
  }
}

export class Cart extends BaseModel {


  @ParseTo(CartElement)
  cartElements: CartElement[] = [];
  @ParseTo(ExtraCartElement)
  @ConvertFrom
  extraCartElements: ExtraCartElement[] = []

  public get countElements() {
    let count = 0;
    this.cartElements.forEach(ce => count += ce.count);
    this.extraCartElements.forEach(ce => count += ce.count);
    return count;
  }

  public get sumPrice() {
    let price = 0;
    this.cartElements.forEach(ce => price += ce.price);
    this.extraCartElements.forEach(ce => price += ce.grossPrice);
    return price;
  }

  public get sumWeight() {
    let weight = 0;
    this.cartElements.forEach(ce => weight += ce.product.product.weightKg * ce.count);
    return weight;
  }

  hasExtraTo(key: string) {
    const tmp = this.extraCartElements.find(ece => ece.key == key);
    return tmp != undefined;
  }

  getCashPriceElement(): ExtraCartElement | undefined {
    return this.extraCartElements.find(ce => ce.key == CASH_PRICE_KEY)
  }

  getDeliveryPriceElement(): ExtraCartElement | undefined {
    return this.extraCartElements.find(ce => ce.key == DELIVERY_PRICE_KEY)
  }

}

export class BarionPayment {
  status: PaymentStatus = PaymentStatus.Prepared
  paymentId = '';
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: "parseDate"})
  completedAt: Date = new Date(); // (egyelőre?) csak cash paymentnél
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: "parseDate"})
  canceledAt: Date = new Date();
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: "parseDate"})
  createdAt: Date = new Date();

  get showDate(): Date {
    if (this.status == PaymentStatus.Prepared) {
      return this.createdAt;
    }
    return this.completedAt;
  }

  get showDateLabel(): string {
    if (this.status == PaymentStatus.Prepared) {
      return "order.payment.createdAt";
    }
    return "order.payment.completedAt";
  }

  get isInPendingWindow(): boolean {
    const tmp = Utilities.addHoursToDate(this.createdAt, 1);
    const now = new Date();
    return tmp.getTime() > now.getTime();
  }
}

export class BillingoInvoice {
  documentId!: number;
  documentNumber = '';
  blockId!: number;
  publicUrl = '';
  documentType: InvoiceDocumentType = InvoiceDocumentType.ADVANCE;
  correctionType: InvoiceDocumentType = InvoiceDocumentType.CANCELLATION;
}


export class Payment extends BaseModel {
  @ParseTo(BarionPayment)
  barionPayments: BarionPayment[] = [];
  cashPayments: BarionPayment[] = [];

  get isPending(): boolean {
    const tmp = this.barionPayments.find(bp => bp.status == PaymentStatus.Prepared);
    if (!tmp) {
      return false;
    }

    return tmp.isInPendingWindow;
  }

  get isCompleted(): boolean {
    const success = this.barionPayments.find(p => p.status != PaymentStatus.Succeeded);
    return success == undefined;
  }
}

export class Invoice extends BaseModel {
  @ParseTo(BillingoInvoice)
  billingoInvoice!: BillingoInvoice;
}

export enum DeliveryStatus {
  PREPARE = "PREPARE"
}

export class DeliveryProcess {
  deliveryPartner!: DeliveryPartner;
  deliveryStatus: DeliveryStatus = DeliveryStatus.PREPARE;


  constructor(deliveryPartner: DeliveryPartner) {
    this.deliveryPartner = deliveryPartner;
  }
}

export class CsomagpiacShipment {
  cspIdentifier = '';
  handlerIdentifier = '';
  handler = '';
  costPrediction = '';
  packages: string[] = [];
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  shipmentCreatedAt: Date = new Date();
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  pickupAt: Date = new Date();
  statusId = '';
  status = '';
  canceled = false;
}

export class GlsShipment {
  parcelNumber = '';
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  createdAt: Date = new Date();
}

export class OrderShipment extends BaseModel {
  activeShipmentId = '';
  lastCanceledShipmentId = '';
  lastKnownStatus = '';
  lastKnownStatusDateTime: Date = new Date();
  @ParseTo(DeliveryPartner)
  deliveryPartner!: DeliveryPartner;
  csomagpiacShipments: CsomagpiacShipment[] = [];
  glsShipments: GlsShipment[] = [];
}

export class OrderModel extends BaseModel {
  paid = false;
  orderNumber!: string;
  status: OrderStatus = OrderStatus.ORDERED;
  deliveryProcess?: DeliveryProcess; //TODO 2024-05-1 (hans):Ez ki kerül
  @ParseTo(OrderShipment)
  shipment?: OrderShipment;//TODO 2024-05-1 (hans):OrderShipmentDTP
  deleted = false;
  @ParseTo(Payment)
  payment!: Payment;
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: "parseDate"})
  createdAt: Date = new Date();
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: "parseDate"})
  updatedAt: Date = new Date();

  @ParseTo(Invoice)
  invoices: Invoice[] = [];

  @ParseTo(Cart)
  @ConvertFrom
  cart: Cart = new Cart();
  message = ''
  currency: Currencies = Currencies.huf;
  acceptedTerms = false;
  @ParseTo(SlimUserModel)
  user?: SlimUserModel
  userId: number | undefined = 0;
  paymentType: PaymentType = PaymentType.BARION;
  deliveryType: DeliveryType = DeliveryType.ONSITE;
  packagingData?: PackagingConfig;
  deliveryDataSameAsBillingData = true;

  @ParseTo(BillingData)
  billingData!: BillingData;
  @ParseTo(BillingData)
  deliveryData?: BillingData;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: "getBillingDataId"}, "model")
  billingDataId = 0;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: "getDeliveryDataId"}, "model")
  deliveryDataId = 0;
  adminOrder = false;

  public get deliveryInfo() {
    if (this.deliveryData) {
      return this.deliveryData;
    }

    return BillingData.newEmptyWithUser(this.user as UserModel);
  }

  refreshDeliveryData(deliveryData: BillingData) {
    this.deliveryData = deliveryData;
  }

}
