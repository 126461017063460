import {Component, EventEmitter, Input, OnInit, Output, Type} from '@angular/core';
import {AttachmentService} from "../../../services/attachment/attachment.service";
import {lastValueFrom} from "rxjs";
import {AttachmentElementComponent} from "../attachment-element/attachment-element.component";
import {AttachmentModel} from "../../../services/attachment/attachment.model";

@Component({
  selector: 'app-pdfs-upload',
  templateUrl: './pdfs-upload.component.html',
  styleUrls: ['./pdfs-upload.component.css']
})
export class PdfsUploadComponent implements OnInit {

  @Input() itemAttachments: AttachmentModel[] = [];

  @Input() secondPanelExpanded = false;
  @Input() selectedAttachments?: AttachmentModel[];
  @Output() selectedAttachmentsChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() isMultiple = true;
  @Input() maxElement = 2;


  imgViewComponent: Type<any> = AttachmentElementComponent;
  availableAttachments: AttachmentModel[] = [];


  constructor(private attachmentService: AttachmentService,) {
  }

  async ngOnInit(): Promise<void> {
    this.availableAttachments = await lastValueFrom(this.attachmentService.getAllIByExtension('pdf'));

    this.availableAttachments = this.availableAttachments.filter(attachment => {
      return !this.itemAttachments.map(a => a.id).includes(attachment.id);
    });
    if (this.selectedAttachments) {
      this.itemAttachments = this.selectedAttachments;
    }
  }


  attachmentRefresh(): void {
    this.selectedAttachments = this.itemAttachments;
    this.selectedAttachmentsChange.emit(this.itemAttachments)
  }

  canBeAddedToItemList() {
    return this.itemAttachments.length < this.maxElement;
  }

  attachmentSave(attachment: any): void {
    if (this.canBeAddedToItemList()) {
      this.itemAttachments.push(attachment);
    } else {
      this.availableAttachments.push(attachment);
    }
  }

}
