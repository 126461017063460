import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {PlantCulture} from "./plant-culture.model";
import {CultureIssuePairingModel} from "./culture-issue-pairing.model";
import {CultureIssuePairingRepository} from "./culture-issue-pairing.repository";
import {ValidatorService} from "../../utilities/service/validator.service";
import {ValidationError} from "../../models/validation.error.model";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum CultureIssuePairingDtoType {
  CultureIssuePairingDTO = 'CultureIssuePairingDTO',
  SlimCultureIssuePairingDTO = 'SlimCultureIssuePairingDTO',
}

@Injectable({
  providedIn: 'root'
})
export class CultureIssuePairingService extends CrudService<CultureIssuePairingModel, CultureIssuePairingRepository> {

  constructor(repo: CultureIssuePairingRepository) {
    super(ROUTING.TECHNOLOGIES, repo, CultureIssuePairingModel, PageMetaDataConf.AUTHOR);
  }

  getAllForCulture(culture: PlantCulture) {
    const filterer = this.filterService.filter(CultureIssuePairingDtoType.SlimCultureIssuePairingDTO)
      .equal('plantCulture_id', culture.id)
      .and()
      .equal('deleted', false)
      .create();
    return this.repo.getAllByFilter(filterer.stringify(), CultureIssuePairingModel);
  }

  override validate(model: CultureIssuePairingModel) {
    const errors: ValidationError[] = this.validateData(model);
    // if (model.suggestedProducts.length < 1) {
    //   throw new Error('error.cultureIssuePairing.suggestedProductsRequired');
    // }
    // if (model.youtubeLinks.length < 1) {
    //   throw new Error('error.cultureIssuePairing.youtubeLinkRequired');
    // }
    // if (model.tags.length < 1) {
    //   throw new Error('error.cultureIssuePairing.tagsRequired');
    // }
    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

  private validateData(model: CultureIssuePairingModel) {
    return ValidatorService.allIsRequired(model, ['plantCulture', 'plantCultureIssue', 'plantCultureIssueType', 'monthStart', 'partOfMonthStart', 'monthEnd', 'partOfMonthEnd'], CultureIssuePairingModel.name);
  }

  validateByStep(model: CultureIssuePairingModel, stepCount: number) {
    let errors: ValidationError[] = [];
    if (stepCount == 1) {
      errors = this.validateData(model);
    }
    if (stepCount == 2) {
      errors = this.validateData(model);
    }

    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

}

