import {BaseRepository, Delete, Get, PathVariable, Post, Put, QueryParam, RequestBody} from "@hellp/repository";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";
import {PostPayload} from "../../models/post-payload.model";
import {PutPayload} from "../../models/put-payload.model";
import {Type} from "@angular/core";
import {AppInterceptor} from "../../app.interceptor";

export abstract class CrudRepository<MODEL> extends BaseRepository {
  @Get('/filter')
  getAllByFilter(@QueryParam() filter: string, clazz: Type<MODEL>): Observable<MODEL[]> {
    return this.createRequest().withModel(clazz).send();
  }

  @Get('/filter')
  getSilentAllByFilter(@QueryParam() filter: string, clazz: Type<MODEL>): Observable<MODEL[]> {
    const headers: HttpHeaders = new HttpHeaders().append(AppInterceptor.SILENT_REQUEST_HEADER_KEY, 'yes');
    return this.createRequest().withOption({headers: headers}).withModel(clazz).send();
  }

  @Post('')
  save(@RequestBody() payload: PostPayload<MODEL>, clazz: Type<MODEL>): Observable<any> {
    return this.createRequest().withModel(clazz).send();
  }

  @Post('')
  saveForm(@RequestBody() payload: FormData, clazz: Type<MODEL>): Observable<any> {
    return this.createRequest().withModel(clazz).send();
  }

  @Put('/{id}')
  update(@PathVariable() id: number, @RequestBody() payload: PutPayload<MODEL>, clazz: Type<MODEL>): Observable<any> {
    return this.createRequest().withModel(clazz).send();
  }

  @Delete('/{id}')
  deleteById(@PathVariable() id: number): Observable<any> {
    return this.createRequest().send()
  }
}
