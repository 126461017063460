<div class="bg-gradient-dark  border-radius-lg p-3 pe-1 text-center position-relative mx-3 z-2">
  <h4 class="font-weight-bolder mt-1 mb-0">{{ 'profile.billingDataForm' | translate }}</h4>
</div>

<div class="card border-radius-lg mt-n4 pt-5 p-3 w-600">

  <div class="row">
    <div class="col-lg-10 mx-auto">
      <form-billing-data #billingDataForm="billingDataForm" [billingData]="billingData"></form-billing-data>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-5 text-center text-md-start ms-auto">
      <button nxButton="emphasis small" nxModalClose="cancel">
        {{ 'general.cancel' | translate }}
      </button>
    </div>
    <div class="col-md-5 text-center text-md-end mt-3 mt-md-0 me-auto">
      <button nxButton="primary small" (click)="save()">
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</div>
