<nx-card class="viewer">
  <page-list-view-header
    iconName="at"
    [newConsumer]="newConsumer()"
    [title]="'author.headline'"
  ></page-list-view-header>
  <div class="nx-margin-top-2m">
    <ng-container *ngIf="componentIsReady">
      <hellp-table id="magazine-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                   [showAsList]="mobile"></hellp-table>
    </ng-container>

    <ng-container *ngIf="!componentIsReady">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </div>
</nx-card>
<ng-template #avatar let-value="value">
  <img [src]="value" alt="marie" class="avatar shadow" loading="lazy">
</ng-template>

