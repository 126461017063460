import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {ListPage} from "../../../utilities/abstract/list-page";
import {BannerModel} from "../../../services/banner/banner.model";
import {BannerDtoTypes, BannerService} from "../../../services/banner/banner.service";
import {TableAction, TableCol} from "@hellp/table";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})

@Page({
  path: '',
  pathPrefix: ROUTING.BANNER,
  data: {title: 'title.banner'},
  authGuard: AdminGuard
})
export class BannerListComponent extends ListPage<BannerModel, BannerService> implements OnInit, OnDestroy {
  constructor(service: BannerService,) {
    super(service);
  }

  ngOnInit(): void {
    this.initTable();
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  override async initTable() {
    this.actionDef = [
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
      TableCol.newString('href', await this.getTranslate('banner.href')),
      TableCol.newDate('activeFrom', await this.getTranslate('banner.activeFrom')),
      TableCol.newDate('activeTo', await this.getTranslate('banner.activeTo')),
    ];
  }

  loadData() {
    this.service.getAllByDeleted(BannerDtoTypes.BannerDTO).pipe(
      takeUntil(this._destroyed)
    ).subscribe(result => {
      this.models = result;
      this.componentIsReady = true;
    });
  }
}
