import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ListPage} from "../../../utilities/abstract/list-page";
import {CultureIssuePairingModel} from "../../../services/technologies/culture-issue-pairing.model";
import {
  CultureIssuePairingDtoType,
  CultureIssuePairingService
} from "../../../services/technologies/culture-issue-pairing.service";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {SupplierDtoTypes} from "../../../services/supplier/supplier.service";
import {lastValueFrom} from "rxjs";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {PlantCultureType} from "../../../services/technologies/plant-culture.model";

@Component({
  selector: 'app-culture-issue-pairing-list',
  templateUrl: './culture-issue-pairing-list.component.html',
  styleUrls: ['./culture-issue-pairing-list.component.css']
})

@Page({
  path: '',
  pathPrefix: ROUTING.TECHNOLOGIES,
})
export class CultureIssuePairingListComponent extends ListPage<CultureIssuePairingModel, CultureIssuePairingService> implements OnInit {
  @ViewChild('tagTpl') tagTpl!: TemplateRef<any>;
  @ViewChild('monthTpl') monthTpl!: TemplateRef<any>;
  @ViewChild('translateTpl') translateTpl!: TemplateRef<any>;


  constructor(service: CultureIssuePairingService,) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('plantCulture.name', await this.getTranslate('technologies.cultureName')).filterable(TableFilter.newAutoComplete()),
      TableCol.newString('plantCultureIssue.name', await this.getTranslate('technologies.issueName')).filterable(TableFilter.newAutoComplete()),
      TableCol.newString('plantCultureIssueType.name', await this.getTranslate('plantCulture.issueTypeName')).filterable(TableFilter.newAutoComplete()),
      TableCol.newString('plantCultureIssueType.type', await this.getTranslate('plantCulture.issueTypeName')).template(() => this.translateTpl)
        .filterable(TableFilter.newSelect(Object.keys(PlantCultureType).map(t => ({
          label: this.translateService.instant('plantCulture.' + t),
          value: t
        })))),
      TableCol.newString('startOfMonth', await this.getTranslate('technologies.monthStart')).template(() => this.monthTpl),
      TableCol.newString('endOfMonth', await this.getTranslate('technologies.monthEnd')).template(() => this.monthTpl),
      TableCol.newString('tagList', await this.getTranslate('technologies.tags')).template(() => this.tagTpl).filterable(TableFilter.newDefaultText()),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(CultureIssuePairingDtoType.SlimCultureIssuePairingDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }


}
