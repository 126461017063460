import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {FormlyFormComponent} from "../../../components/formly-form/formly-form.component";
import {ActivatedRoute} from "@angular/router";
import {WarehouseModel} from "../../../services/warehouse/warehouse.model";
import {WarehouseDtoTypes, WarehouseService} from "../../../services/warehouse/warehouse.service";

@Component({
  selector: 'app-warehouse-form',
  templateUrl: './warehouse-form.component.html',
  styleUrls: ['./warehouse-form.component.css']
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.WAREHOUSE,
})
export class WarehouseFormComponent extends FormPage<WarehouseModel, WarehouseService> implements OnInit, OnDestroy {

  @ViewChild('formly') override formlyComponent!: FormlyFormComponent;

  constructor(service: WarehouseService, route: ActivatedRoute) {
    super(route, service, WarehouseModel, service.formlyService.warehouseFormConfig);
  }

  ngOnInit(): void {
    this.onInit(WarehouseDtoTypes.WarehouseDTO);
  }

  protected override afterModelIsSetProcess() {
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }
}
