import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ShowPage} from "../../../utilities/abstract/show-page";
import {DeliveryProcess, OrderModel} from "../../../services/order/order";
import {OrderDtoTypes, OrderService} from "../../../services/order/order.service";
import {ActivatedRoute} from "@angular/router";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {CANCELLED_ORDER_STATUSES, DeliveryType, OrderStatus, PaymentType} from "../../../utilities/types";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {Utilities} from "../../../utilities/utilities";
import {CustomToolbarAction} from "../../../components/show-tool-bar/show-tool-bar.component";
import {
  StartDeliveryDialogComponent
} from "../../../components/dialogs/start-delivery-dialog/start-delivery-dialog.component";
import {PurchaseInfoDtoTypes, PurchaseInfoService} from "../../../services/purchase-info/purchase-info.service";
import {DeliveryPartner, PurchaseInfoModel} from "../../../services/purchase-info/purchase-info.model";
import {takeUntil} from "rxjs";
import {fileDownloadProcess} from "../../../directives/download-file.directive";
import {
  OrderDetailsDisplayComponent
} from "../../../components/dispaly/order-details-display/order-details-display.component";
import {LoaderService} from "../../../layouts/loader/loader.service";

class OrderStatusViewModel {
  icon: string;
  name: OrderStatus;
  action: () => void;
  available = false;
  statusesForEnable: OrderStatus[];

  constructor(available: boolean, icon: string, name: OrderStatus, action: () => void, statusesForEnable: OrderStatus[]) {
    this.icon = icon;
    this.name = name;
    this.action = action;
    this.statusesForEnable = statusesForEnable;
    this.available = available;
  }

  public get className(): string {
    return `${this.name}-badge`;
  }
}

@Component({
  selector: 'app-user-order-show',
  templateUrl: './user-order-show.component.html',
  styleUrls: ['./user-order-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.ORDER,
})
export class UserOrderShowComponent extends ShowPage<OrderModel, OrderService> implements OnInit, OnDestroy {
  private readonly DELETABLE_STATUSES: OrderStatus[] = [OrderStatus.ORDERED];

  protected readonly OrderStatus = OrderStatus;
  protected readonly DeliveryType = DeliveryType;
  protected readonly PaymentType = PaymentType;

  @ViewChild('orderDisplay') orderDisplayComponent!: OrderDetailsDisplayComponent;

  statuses: OrderStatusViewModel[] = [];
  customToolbarAction?: CustomToolbarAction[]
  purchaseInfo!: PurchaseInfoModel;

  constructor(service: OrderService, route: ActivatedRoute, public loaderService: LoaderService, public dialogService: NxDialogService, private purchaseService: PurchaseInfoService) {
    super(service, route);
  }

  ngOnInit(): void {
    this.onInit(OrderDtoTypes.OrderDTO);
  }

  protected override initProcess() {
    this.model.deliveryProcess = this.extendedStorageService.getFromCookies(`ORDER_${this.model.id}`, undefined, DeliveryProcess);
    super.initProcess();
    this.initStatuses();
    this.initActions();
    this.statuses.forEach(status => {
      status.available = this.service.statusActionIsAvailable(this.model, status.name);
    });

    this.purchaseService.getAllByDeleted(PurchaseInfoDtoTypes.PurchaseInfoDTO).pipe(takeUntil(this._destroyed)).subscribe(result => {
      this.purchaseInfo = result[0];
    });

    this.service.$cartRefresh.subscribe(result => {
      if (result) {
        this.loadData(OrderDtoTypes.OrderDTO);
      }
    });
  }


  ngOnDestroy(): void {
    this.onDestroy();
  }

  public statusAction(nextStatus: OrderStatus) {
    if (this.model.paymentType == PaymentType.BARION && !this.isPayed) {
      this.service.notificationService.confirm('order.notPayedTitle', 'order.notPayedDesc', 'general.ok', 'general.cancel').then(result => {
        if (result.isConfirmed) {
          this.statusProcess(nextStatus);
        }
      });
    } else {
      this.statusProcess(nextStatus);
    }

  }

  private statusProcess(nextStatus: OrderStatus) {
    if (nextStatus == this.model.status) {
      this.service.notificationService.showWarning("general.warning", "order.sameStatus");
      return;
    }
    this.service.changeStatus(this.model.id, nextStatus).subscribe(result => {
      this.loadData(OrderDtoTypes.OrderDTO);
    });
  }

  public get deliveryIsAvailable(): boolean {
    return (this.model.status == OrderStatus.PACKAGE_CHECKED || this.model.status == OrderStatus.AWAITING_COURIER_PICKUP)
      && this.model.deliveryType == DeliveryType.DELIVERY && !this.model.deliveryProcess;
  }

  public get isDelivery(): boolean {
    return this.model.deliveryType == DeliveryType.DELIVERY;
  }

  public get isDeletable(): boolean {
    return this.DELETABLE_STATUSES.includes(this.model.status);
  }

  public get isPayed(): boolean {
    return this.model.paymentType == PaymentType.BARION && this.model.payment && this.model.payment.isCompleted
  }


  public get isBillable() {
    return this.model.paymentType == PaymentType.CASH && this.model.paid && this.model.invoices && this.model.invoices.length < 1;
  }

  printOrder() {
    this.service.generatePdf([this.model.orderNumber])
      .subscribe(response => {
        fileDownloadProcess(response, `rendeles-${this.model.orderNumber}.pdf`)
      });

  }

  async startDelivery() {
    this.dialogService.open(
      StartDeliveryDialogComponent,
      Utilities.getDefaultModalConfig(this.purchaseInfo)
    ).afterClosed().subscribe((result: any) => {
      if (result instanceof DeliveryPartner) {
        this.service.startDeliveryProcess(this.model.id, result.type).subscribe(res => {
          this.service.notificationService.showSuccess('general.success', 'order.successDeliveryStart');
          this.loadData(OrderDtoTypes.OrderDTO);
        });
      }
    });
  }

  delete() {
    this.service.notificationService.confirm('general.areUSure', 'order.deleteConfirmMessage', 'general.delete', "general.cancel")
      .then(async result => {
        if (result.value) {
          // await lastValueFrom(this.service.delete(this.model.id));
          this.model.deleted = true;
        }
      });
  }

  resendEmail() {
    this.service.resendEmail(this.model.id).subscribe(res => {

    });
  }

  resendPaymentLink() {
    this.service.sendPaymentLinkViaEmail(this.model.orderNumber).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'order.successPaymentLink');
    });
  }

  private payByCash() {
    this.service.payByCash(this.model).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'order.successPaidByCash');
      this.loadData(OrderDtoTypes.OrderDTO);
    });
  }

  createInvoice() {
    this.service.createInvoice(this.model.id).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'order.successInvoiceCreate');
      this.loadData(OrderDtoTypes.OrderDTO);
    });
  }

  private initActions() {
    const tmp: CustomToolbarAction[] = [];
    //tmp.push(new CustomToolbarAction(() => this.resendEmail(), "envelope", 'order.resendEmail', this.model.status != OrderStatus.ORDERED));
    tmp.push(new CustomToolbarAction(() => this.printOrder(), "print", 'order.printElementList', false));
    tmp.push(new CustomToolbarAction(() => this.resendPaymentLink(), "coins", 'order.resendPaymentLink', (this.model.paid || this.model.paymentType != PaymentType.BARION)));
    tmp.push(new CustomToolbarAction(() => this.payByCash(), "coins", 'order.paidByCash', (this.model.paid || this.model.paymentType != PaymentType.CASH)));
    tmp.push(new CustomToolbarAction(() => this.createInvoice(), "file-invoice", 'order.createInvoice', !this.isBillable));
    tmp.push(new CustomToolbarAction(() => this.startDelivery(), "truck", 'order.startDelivery', !this.deliveryIsAvailable));
    tmp.push(new CustomToolbarAction(() => this.cancelOrder('ADMIN'), "ban", 'order.cancelByAdmin'))
    if (!CANCELLED_ORDER_STATUSES.includes(this.model.status)) {
      this.customToolbarAction = tmp;
    }
  }

  private initStatuses() {
    this.statuses = [
      new OrderStatusViewModel(true, 'shopping-cart', OrderStatus.ORDERED, () => {
          return () => {
          };
        },
        []
      ),
      // new OrderStatusViewModel(true, 'box-open', OrderStatus.BEING_PREPARED, () => {
      //     return this.statusAction(OrderStatus.BEING_PREPARED)
      //   },
      //   [
      //     OrderStatus.ORDERED,
      //     OrderStatus.PREPARED,
      //   ]
      // ),
      new OrderStatusViewModel(true, 'box', OrderStatus.PREPARED, () => {
        return this.statusAction(OrderStatus.PREPARED)
      }, [
        OrderStatus.ORDERED,
        // OrderStatus.BEING_PREPARED,
        // OrderStatus.PACKAGE_BEING_CHECKED,
        OrderStatus.PACKAGE_CHECKED,
      ]),
      // new OrderStatusViewModel(true, 'list', OrderStatus.PACKAGE_BEING_CHECKED, () => {
      //   return this.statusAction(OrderStatus.PACKAGE_BEING_CHECKED)
      // }, [
      //   OrderStatus.PREPARED,
      //   OrderStatus.PACKAGE_CHECKED,
      // ]),
      new OrderStatusViewModel(true, 'list-check', OrderStatus.PACKAGE_CHECKED, () => {
        return this.statusAction(OrderStatus.PACKAGE_CHECKED)
      }, [
        OrderStatus.PREPARED,
        OrderStatus.AWAITING_ONSITE_PICKUP,
        OrderStatus.AWAITING_COURIER_PICKUP,
      ]),
    ];

    if (this.isDelivery) {
      this.statuses.push(
        new OrderStatusViewModel(this.isDelivery, 'truck-ramp-box', OrderStatus.AWAITING_COURIER_PICKUP, () => {
          return this.statusAction(OrderStatus.AWAITING_COURIER_PICKUP)
        }, [
          OrderStatus.PACKAGE_CHECKED
        ]));
      this.statuses.push(
        new OrderStatusViewModel(this.isDelivery, 'truck', OrderStatus.IN_TRANSIT, () => {
          return this.statusAction(OrderStatus.IN_TRANSIT)
        }, [
          OrderStatus.AWAITING_COURIER_PICKUP
        ]));
    } else {
      this.statuses.push(new OrderStatusViewModel(!this.isDelivery, 'house', OrderStatus.AWAITING_ONSITE_PICKUP, () => {
        return this.statusAction(OrderStatus.AWAITING_ONSITE_PICKUP)
      }, [
        OrderStatus.PACKAGE_CHECKED,
      ]))
    }
    this.statuses.push(new OrderStatusViewModel(true, 'check', OrderStatus.COMPLETED, () => {
        return this.statusAction(OrderStatus.COMPLETED)
      },
      [
        OrderStatus.IN_TRANSIT,
        OrderStatus.AWAITING_ONSITE_PICKUP,
      ]
    ),)
  }

  private cancelOrder(admin: 'ADMIN' | 'USER') {
    this.service.cancelOrderByAdmin(this.model).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'order.successCancel');
      this.loadData(OrderDtoTypes.OrderDTO);
      //TODO 2024-07-31 (hans):faszomat ez egészbe ezt javítani majd
      this.loaderService.show();
      setTimeout(() => {
        this.orderDisplayComponent.updateChangeability();
        this.loaderService.hide();
      }, 400);
    })
  }
}
