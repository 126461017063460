<div nxRow="" nxRowAlignItems="center">
  <div nxCol="{{fullSize?'12':'12,12,12,6,6'}}">
    <div class="card p-3 mb-4 box-shadow">
      <nx-switcher [(ngModel)]="hasDeliveryMode" (ngModelChange)="toggleDeliveryMode()" class="nx-margin-bottom-m"
                   labelSize="small">{{ 'category.enableDeliveryMode' | translate }}
      </nx-switcher>
      <nx-formfield nxLabel="{{'category.deliveryData.mode' | translate}}" appearance="auto">
        <nx-multi-select
          appValidatedInput
          placeholder="{{'general.choose' | translate}}"
          filterPlaceholder="{{'general.filter' | translate}}"
          [options]="deliveryTypes"
          [required]="hasDeliveryMode"
          [selectLabel]="labelTranslate"
          selectValue="value"
          [disabled]="!hasDeliveryMode"
          [ngModel]="orderConfig.deliveryConfig?.availableModes"
          (selectionChange)="selectDeliveryMode($event)"
        ></nx-multi-select>
        <nx-error class="nx-margin-top-m" nxFormfieldError
                  *ngIf="validationError.deliveryConfig"> {{ validationError.deliveryConfig | translate }}
        </nx-error>
      </nx-formfield>
    </div>
  </div>
  <div nxCol="{{fullSize?'12':'12,12,12,6,6'}}">
    <div class="card p-3 mb-4 box-shadow">
      <nx-switcher [(ngModel)]="hasPricingMode" (ngModelChange)="togglePricingMode()" class="nx-margin-bottom-m"
                   labelSize="small">{{ 'category.enablePricingMode' | translate }}
      </nx-switcher>
      <div nxRow>
        <div nxCol="8">
          <nx-formfield nxLabel="{{'category.financialData.mode' | translate}}" appearance="auto">
            <nx-multi-select
              appValidatedInput
              placeholder="{{'general.choose' | translate}}"
              filterPlaceholder="{{'general.filter' | translate}}"
              [options]="pricingTypes"
              [required]="hasPricingMode"
              [selectLabel]="labelTranslate"
              selectValue="value"
              [disabled]="!hasPricingMode"
              [ngModel]="orderConfig.financialConfig?.availableModes"
              (selectionChange)="selectPricingMode($event)"
            ></nx-multi-select>
            <nx-error class="nx-margin-top-m" nxFormfieldError
                      *ngIf="validationError.financialConfig"> {{ validationError.financialConfig | translate }}
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="4">
          <nx-formfield
            *ngIf="orderConfig.financialConfig && !notForCategory"
            nxLabel="{{'category.financialData.tax' | translate}}"
            appearance="auto"
          >
            <input nxInput type="number" name="dddd" [(ngModel)]="orderConfig.financialConfig.tax"/>
            <span nxFormfieldSuffix>%</span>
          </nx-formfield>
        </div>
      </div>
    </div>
  </div>
  <div nxCol="{{fullSize?'12':'12,12,12,6,6'}}">
    <div class="card p-3 mb-4 box-shadow">
      <nx-switcher [(ngModel)]="hasIcePackRange" (ngModelChange)="toggleIcePackRange()"
                   class="nx-margin-bottom-m"
                   labelSize="small">{{ 'category.enableIcePackRange' | translate }}
      </nx-switcher>

      <div nxRow="">
        <div nxCol="12" class="d-flex justify-content-between align-items-center nx-margin-bottom-2m">
          <p nxCopytext="small">
            {{ 'category.icePackRangeInfo' | translate }}
          </p>
          <ng-container *ngIf="hasIcePackRange">
            <button nxIconButton="primary small" [title]=" 'category.addIcePack' | translate "
                    (click)="addIcePack()">
              <nx-icon name="plus"></nx-icon>
            </button>
          </ng-container>
        </div>
        <ng-container *ngIf="orderConfig.packagingConfig && orderConfig.packagingConfig.icePackRanges">
          <ng-container *ngFor="let icePack of orderConfig.packagingConfig?.icePackRanges">
            <div nxRow class="mb-2">
              <div nxCol="12,12,4">
                <nx-number-stepper
                  nxSize="normal"
                  nxLabel="{{'category.icePackStart' | translate}}"
                  inputAriaLabel="Number of travellers"
                  [(ngModel)]="icePack.start"
                ></nx-number-stepper>
              </div>
              <div nxCol="12,12,4">
                <nx-number-stepper
                  nxSize="normal"
                  nxLabel="{{'category.icePackEnd' | translate}}"
                  inputAriaLabel="Number of travellers"
                  [(ngModel)]="icePack.end"
                ></nx-number-stepper>
              </div>
              <div nxCol="12,12,4">
                <nx-number-stepper
                  nxSize="normal"
                  nxLabel="{{'category.icePackCount' | translate}}"
                  inputAriaLabel="Number of travellers"
                  [(ngModel)]="icePack.count"
                ></nx-number-stepper>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <nx-error class="nx-margin-top-m" nxFormfieldError
                *ngIf="validationError.attachment"> {{ validationError.attachment | translate }}
      </nx-error>
    </div>
  </div>
  <div nxCol="{{fullSize?'12':'12,12,12,6,6'}}">
    <div class="card p-3 mb-4 box-shadow">
      <nx-switcher [(ngModel)]="orderConfig.cartExclusive" class="nx-margin-bottom-m"
                   labelSize="small">{{ 'product.cartExclusive' |translate }}
      </nx-switcher>
      <ng-container *ngIf="cartNote">
        <nx-formfield nxLabel="{{'category.cartNote' |translate}}" appearance="auto">
          <textarea nxInput name="cartNote" [(ngModel)]="cartNote"></textarea>
        </nx-formfield>
      </ng-container>
    </div>
  </div>
</div>
