<header class="top-border d-flex " nx-header>
  <div class="ms-auto">
  <nx-header-actions>
    <nx-context-menu #userContext="nxContextMenu">
      <ng-container *ngIf="authUser">
        <button disabled nxContextMenuItem>
          <nx-icon aria-hidden="true" name="user-o" class="nx-margin-right-s"></nx-icon>
          {{authUser.lastName}} {{authUser.firstName}} <br>{{authUser.email}}</button>
        <button nxContextMenuItem type="button" (click)="logout()">{{'general.logout' | translate}}</button>
      </ng-container>

    </nx-context-menu>

    <nx-link size="large">
      <a href="javascript:void(0)" class="nx-margin-left-s"
         [nxNotificationPanelTriggerFor]="notificationPanel">
        <nx-icon name="bell-o" size="s" aria-label="notification"></nx-icon>
        <nx-indicator *ngIf="unreadNotification$ | async as number"
                      position="over-icon"
        >{{unreadNotification$ | async}}</nx-indicator>
      </a>
    </nx-link>

    <nx-link size="large">
      <a href="javascript:void(0)" class="nx-margin-left-s "
         scrollStrategy="close"
         [nxContextMenuTriggerFor]="userContext">
        <nx-icon name="user-o" size="s" aria-label="Logged in"></nx-icon>
      </a>
    </nx-link>

  </nx-header-actions>
  </div>
</header>

<ng-template #notificationPanel>
  <nx-notification-panel>
    <nx-notification-header>
      <h3 nxCopytext="small" class="nx-font-weight-semibold">{{'general.notifications' | translate}}</h3>
      <button nxPlainButton="small" type="button" (click)="readAll()">
        {{'header.readAll' | translate}}
        <nx-icon name="check" nxIconPositionEnd></nx-icon>
      </button>
    </nx-notification-header>
    <ng-container *ngFor="let notification of notifications; let i=index; trackBy: notificationTrackBy">
      <a nxNotificationPanelItem [read]="notification.read" clickable="false" class="nx-margin-bottom-xs border-bottom-primary">
        <nx-notification-item-metadata>
          <nx-icon
            class="nx-margin-right-2xs"
            [name]="notification.iconName"
            aria-hidden="true"
          ></nx-icon
          >
          {{notification.title}} &middot; {{notification.createdAt | date:'HH:mm'}}
        </nx-notification-item-metadata>
        <nx-notification-item-content>
                <span class="nx-font-weight-semibold">
                  {{notification.description}}
                </span>
        </nx-notification-item-content>
        <nx-notification-item-actions>
          <nx-link><a href="javascript:void(0)" (click)="read(i)">{{'general.show' | translate}}</a></nx-link>
          <nx-link><a href="javascript:void(0)" (click)="deleteNotification(i)">
            <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
          </a></nx-link>
        </nx-notification-item-actions>
      </a>
    </ng-container>
  </nx-notification-panel>
</ng-template>
