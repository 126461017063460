import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {ValidatorService} from "../../utilities/service/validator.service";
import {AboutUsModel} from "./about-us.model";
import {AboutUsRepository} from "./about-us.repository";

export enum AboutUsDtoTypes {
  AboutUsDTO = 'AboutUsDTO'
}

@Injectable({
  providedIn: 'root'
})
export class AboutUsService extends CrudService<AboutUsModel, AboutUsRepository> {

  constructor(repo: AboutUsRepository) {
    super(ROUTING.ABOUT_US, repo, AboutUsModel);
  }

  override validate(model: AboutUsModel) {
    ValidatorService.required(model.content, "aboutUs.contentRequired");
  }

}
