import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

@Component({
  selector: 'app-show-attachment-list',
  templateUrl: './attachment-show.component.html',
  styleUrls: ['./attachment-show.component.scss']
})
export class AttachmentShowComponent implements OnInit {
  constructor(@Inject(NX_MODAL_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.data.isImage = this.data.mimeType.includes('image');
    }
  }
}
