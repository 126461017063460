import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {PermissionCheck} from "../../utilities/abstract/permission-check";

export class CustomToolbarAction {
  supplier: () => void;
  iconName: string;
  title: string;
  disabled: boolean

  constructor(supplier: () => void, iconName: string, title: string, disabled?: boolean) {
    this.supplier = supplier;
    this.iconName = iconName;
    this.title = title;
    this.disabled = disabled??false;
  }
}

@Component({
  selector: 'page-show-tool-bar',
  templateUrl: './show-tool-bar.component.html',
  styleUrls: ['./show-tool-bar.component.scss']
})
export class ShowToolBarComponent extends PermissionCheck implements OnInit {
  @Input() backSupplier?: () => void;
  @Input() editSupplier?: () => void;
  @Input() title!: string;
  @Input() iconName = 'eye';
  @Input() customToolbarActions?: CustomToolbarAction[]

  mobile = false;

  private readonly _destroyed = new Subject<void>();

  constructor(public viewportService: NxViewportService,
              private _cdr: ChangeDetectorRef,) {
    super()
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_MEDIUM)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
    this.initPermission();
  }

}
