import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {DeliveryType} from "../../../utilities/types";
import {ContactModel} from "../../../services/contact/contact.model";
import {BillingData} from "../../../services/order/order";
import {ContactDtoTypes, ContactService} from "../../../services/contact/contact.service";
import {Utilities} from "../../../utilities/utilities";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {
  BILLING_DATA_MODAL_ID,
  EditBillingDataDialogComponent,
  Mode
} from "../../dialogs/edit-billing-data-dialog/edit-billing-data-dialog.component";

@Component({
  selector: 'display-order-billing-data',
  templateUrl: './order-billing-data-display.component.html',
  styleUrls: ['./order-billing-data-display.component.scss']
})
export class OrderBillingDataDisplayComponent implements OnInit, OnDestroy {
  @Input() billingData!: BillingData;
  @Output() billingDataChange: EventEmitter<BillingData> = new EventEmitter<BillingData>();
  @Input() title = '';
  @Input() mode: Mode = "billing";
  @Input() printMode = false;
  @Input() isEditable = false;


  private contactSub?: Subscription
  contactInfo: ContactModel = new ContactModel();

  constructor(private contactService: ContactService, public dialogService: NxDialogService,) {
  }

  ngOnInit(): void {
    if (this.mode == "onsite") {
      this.contactSub = this.contactService.getAllByDeleted(ContactDtoTypes.ContactDTO).subscribe(result => {
        this.contactInfo = result[0];
      });
    }
    if ((!this.billingData.id || this.billingData.id < 1) && this.mode != "onsite") {
      this.isEditable = true;
    }
  }

  ngOnDestroy(): void {
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
  }


  protected readonly DeliveryType = DeliveryType;

  edit() {
    this.dialogService.open(
      EditBillingDataDialogComponent,
      Utilities.getDefaultModalConfig({mode: this.mode, model: this.billingData}, BILLING_DATA_MODAL_ID)
    ).afterClosed().subscribe(result => {
      if (result instanceof BillingData) {
        this.billingData = result;
        this.billingDataChange.emit(result);
      }
    });
  }
}
