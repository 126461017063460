<div nxModalContent style="height: calc(100% - 72px)">
  <nx-card class="w-100 h-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'plantCulture.addNew' | translate }}
    </h3>
    <div nxRow>
      <div nxCol="12">
        <form #plantForm="ngForm">
          <nx-formfield nxLabel="{{'plantCulture.name' | translate}}*" nxFloatLabel="auto" appearance="auto">
            <input nxInput name="name" type="text" required [minLength]="1" [(ngModel)]="plantCulture.name"/>
          </nx-formfield>
          <nx-formfield nxLabel="{{'plantCulture.type' | translate}}" nxFloatLabel="auto" appearance="auto">
            <nx-dropdown required [(ngModel)]="plantCulture.type" name="type">
              <nx-dropdown-item
                *ngFor="let option of PlantCultureType | keyvalue"
                [nxValue]="option.key"
              >{{ 'plantCulture.' + option.key | translate }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </form>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [disabled]="!plantForm.valid"
    [nxModalClose]="plantCulture"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.add' | translate }}
  </button>
</div>
