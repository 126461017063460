<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">

  <div nxRow class="nx-margin-top-2m">
    <div nxCol="12,12,12,6" nxColOffset="0,0,0,3">

      <hellp-card-container>
        <nx-formfield nxLabel="{{'article.authorName' | translate}}" appearance="auto">
          <input appValidatedInput required nxInput name="title" [(ngModel)]="formModel.name"/>
          <nx-error class="nx-margin-bottom-m" nxFormfieldError
                    *ngIf="validationError.name"> {{ validationError.name | translate }}
          </nx-error>
        </nx-formfield>
        <app-image-upload [(selectedAttachment)]="formModel.coverPhoto.attachment"
                          [secondPanelExpanded]="true"></app-image-upload>
        <nx-error class="nx-margin-top-m" nxFormfieldError
                  *ngIf="validationError.attachment"> {{ validationError.attachment | translate }}
        </nx-error>
      </hellp-card-container>
    </div>
  </div>
</hellp-form-container>

