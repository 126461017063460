import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ShowPage} from "../../../utilities/abstract/show-page";
import {CategoryModel} from "../../../services/category/category.model";
import {CategoryDtoTypes, CategoryService} from "../../../services/category/category.service";
import {ActivatedRoute} from "@angular/router";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {ProductHistoryDtoTypes, ProductService, UploadType} from "../../../services/product/product.service";
import {lastValueFrom} from "rxjs";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {HistoryStatus} from "../../../utilities/types";

@Component({
  selector: 'app-category-show',
  templateUrl: './category-show.component.html',
  styleUrls: ['./category-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.CATEGORY,
})
export class CategoryShowComponent extends ShowPage<CategoryModel, CategoryService> implements OnInit, OnDestroy {
  showAction!: TableAction;
  showDynamicData = true;
  showOrderConfig = true;


  @ViewChild('priceTpl') priceTpl!: TemplateRef<any>
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>
  colDef!: TableCol[];
  actionDef!: TableAction[];

  products?: ProductHistoryModel[];


  constructor(service: CategoryService, route: ActivatedRoute, private productService: ProductService) {
    super(service, route);
  }

  async ngOnInit(): Promise<void> {
    this.showAction = new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show'));
    this.onInit(CategoryDtoTypes.ProductCategoryDTO);
  }


  protected override async initProcess() {
    super.initProcess();
    await this.initTable();
    this.products = await lastValueFrom(this.productService.getAllForCategory(ProductHistoryDtoTypes.ProductHistoryDTO, this.model.id));
    this.showDynamicData = this.model.dynamicAttributes && (Object.keys(this.model.dynamicAttributes).length > 0);
    this.showOrderConfig = (this.model.orderConfig != undefined && this.model.orderConfig.hasData());
  }

  async initTable(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showProductConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('dollar-sign', this.editProductConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('pencil', this.editMarketingProductConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('status', await this.getTranslate('product.status')).filterable(
        TableFilter.newSelect(Object.keys(HistoryStatus).map(this.generateStatusFilterOption()))).template(
        () => this.statusTpl),
      TableCol.newString('product.itemNr', await this.getTranslate('product.itemNr')),
      TableCol.newString('product.name', await this.getTranslate('product.name')),
      TableCol.newString('product.sku', await this.getTranslate('product.SKU')),
      TableCol.newString('category.name', await this.getTranslate('product.category')),
      TableCol.newString('product.prices', await this.getTranslate('product.price')).template(
        () => this.priceTpl),
    ];
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  public showConsumer(): (row: any) => void {
    return (model: any) => this.service.navigateToShow(model.id);
  }

  public showProductConsumer(): (row: ProductHistoryModel) => void {
    return (model: ProductHistoryModel) => this.productService.navigateToShow(model.product.id);
  }

  public editProductConsumer(): (row: ProductHistoryModel) => void {
    return (model: ProductHistoryModel) => {
      this.productService.$uploadType.next(UploadType.SALES);
      if (model.editInProgress) {
        this.productService.navigateToEdit(model.draft.id);
      } else {
        this.productService.navigateToEdit(model.product.id);
      }
    }
  }

  public editMarketingProductConsumer(): (row: ProductHistoryModel) => void {
    return (model: ProductHistoryModel) => {
      this.productService.$uploadType.next(UploadType.MARKETING);
      if (model.editInProgress) {
        this.productService.navigateToEdit(model.draft.id);
      } else {
        this.productService.navigateToEdit(model.product.id);
      }
    }
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  protected readonly HistoryStatus = HistoryStatus;
}
