import {BaseModel} from "../../utilities/abstract/base.model";
import {AddressModel} from "../../models/address.model";
import { ParseTo } from "@hellp/parser";

export class SupplierModel extends BaseModel {
  companyName = '';
  taxNumber = '';
  bankAccountNumber = '';
  @ParseTo(AddressModel)
  address: AddressModel = new AddressModel();
  contactName = '';
  contactEmail = '';
  contactPhone = '';
}



