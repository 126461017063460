import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SupplierListComponent} from './supplier/supplier-list/supplier-list.component';
import {NxCardModule} from "@aposin/ng-aquila/card";
import {HellpTableModule} from "@hellp/table";
import {ComponentsModule} from "../components/components.module";
import {SupplierShowComponent} from './supplier/supplier-show/supplier-show.component';
import {NxGridModule} from "@aposin/ng-aquila/grid";
import {TranslateModule} from "@ngx-translate/core";
import {NxDataDisplayModule} from "@aposin/ng-aquila/data-display";
import {NxIconModule} from "@aposin/ng-aquila/icon";
import {SupplierFormComponent} from './supplier/supplier-form/supplier-form.component';
import {CategoryListComponent} from './category/category-list/category-list.component';
import {NxTreeModule} from "@aposin/ng-aquila/tree";
import {NxActionModule} from "@aposin/ng-aquila/action";
import {RouterLink} from "@angular/router";
import {NxButtonModule} from "@aposin/ng-aquila/button";
import {NxTooltipModule} from "@aposin/ng-aquila/tooltip";
import {CategoryShowComponent} from './category/category-show/category-show.component';
import {NxSidepanelModule} from "@aposin/ng-aquila/sidepanel";
import {NxRadioToggleModule} from "@aposin/ng-aquila/radio-toggle";
import {FormsModule} from "@angular/forms";
import {NxBadgeModule} from "@aposin/ng-aquila/badge";
import {NxListModule} from "@aposin/ng-aquila/list";
import {NxLinkModule} from "@aposin/ng-aquila/link";
import {CategoryFormComponent} from './category/category-form/category-form.component';
import {NxTabsModule} from "@aposin/ng-aquila/tabs";
import {NxHeadlineModule} from "@aposin/ng-aquila/headline";
import {NxCopytextModule} from "@aposin/ng-aquila/copytext";
import {AttachmentListComponent} from "./attachment/attachment-list/attachment-list.component";
import {AttachmentFormComponent} from "./attachment/attachment-form/attachment-form.component";
import {DirectivesModule} from "../directives/directives.module";
import {NxImageModule} from "@aposin/ng-aquila/image";
import {NxLabelModule} from "@aposin/ng-aquila/base";
import {PipeModule} from "../pipe/pipe.module";
import {NxProgressStepperModule} from "@aposin/ng-aquila/progress-stepper";
import {NxSpinnerModule} from "@aposin/ng-aquila/spinner";
import {ProductListComponent} from './product/product-list/product-list.component';
import {ProductFormComponent} from './product/product-form/product-form.component';
import {ProductShowComponent} from './product/product-show/product-show.component';
import {GalleryModule} from "ng-gallery";
import {NxSwitcherModule} from "@aposin/ng-aquila/switcher";
import {NxFormfieldModule} from "@aposin/ng-aquila/formfield";
import {NxInputModule} from "@aposin/ng-aquila/input";
import {TagFormComponent} from "./tag/tag-form/tag-form.component";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {MagazineListComponent} from './magazine/magazine-list/magazine-list.component';
import {MagazineShowComponent} from './magazine/magazine-show/magazine-show.component';
import {MagazineFormComponent} from './magazine/magazine-form/magazine-form.component';
import {AboutUsFormComponent} from './about-us/about-us-form/about-us-form.component';
import {NgxEditorModule} from "ngx-editor";
import {ContactFormComponent} from './contact/contact-form/contact-form.component';
import {NxDropdownModule} from "@aposin/ng-aquila/dropdown";
import {NxDatefieldModule} from "@aposin/ng-aquila/datefield";
import {NxTimefieldModule} from "@aposin/ng-aquila/timefield";
import {QuillEditorComponent} from "ngx-quill";
import {
  TermsAndConditionFormComponent
} from "./terms-and-condition/terms-and-condition-form/terms-and-condition-form.component";
import {UserOrderFormComponent} from './user-order/user-order-form/user-order-form.component';
import {UserOrderListComponent} from './user-order/user-order-list/user-order-list.component';
import {UserOrderShowComponent} from './user-order/user-order-show/user-order-show.component';
import {BannerListComponent} from './banner/banner-list/banner-list.component';
import {BannerFormComponent} from './banner/banner-form/banner-form.component';
import {
  TermsAndConditionListComponent
} from './terms-and-condition/terms-and-condition-list/terms-and-condition-list.component';
import {PrivacyPolicyFormComponent} from "./privacy-policy/privacy-policy-form/privacy-policy-form.component";
import {BioCertFormComponent} from "./bio-cert/bio-cert-form/bio-cert-form.component";
import {BioCertListComponent} from "./bio-cert/bio-cert-list/bio-cert-list.component";
import {PurchaseInfoFormComponent} from './purchase-info/purchase-info-form/purchase-info-form.component';
import {NxTableModule} from "@aposin/ng-aquila/table";
import {ProductMatrixListComponent} from './product-matrix/product-matrix-list/product-matrix-list.component';
import {WarehouseListComponent} from './warehouse/warehouse-list/warehouse-list.component';
import {WarehouseFormComponent} from './warehouse/warehouse-form/warehouse-form.component';
import {WarehouseShowComponent} from './warehouse/warehouse-show/warehouse-show.component';
import {ArticleShowComponent} from "./article/article-show/article-show.component";
import {ArticleListComponent} from "./article/article-list/article-list.component";
import {ArticleFormComponent} from "./article/article-form/article-form.component";
import {SupplierOrderListComponent} from './supplier-order/supplier-order-list/supplier-order-list.component';
import {SupplierOrderFormComponent} from './supplier-order/supplier-order-form/supplier-order-form.component';
import {InventoryListComponent} from './inventory/inventory-list/inventory-list.component';
import {InventoryFormComponent} from './inventory/inventory-form/inventory-form.component';
import {SupplierOrderShowComponent} from './supplier-order/supplier-order-show/supplier-order-show.component';
import {NxPopoverModule} from "@aposin/ng-aquila/popover";
import {UserListComponent} from './user/user-list/user-list.component';
import {UserShowComponent} from './user/user-show/user-show.component';
import {ScrappingListComponent} from './scrapping/scrapping-list/scrapping-list.component';
import {ScrappingFormComponent} from './scrapping/scrapping-form/scrapping-form.component';
import {NxAutocompleteModule} from "@aposin/ng-aquila/autocomplete";
import {NxRadioModule} from "@aposin/ng-aquila/radio-button";
import {
  SupplierOrderEditFormComponent
} from "./supplier-order/supplier-order-edit-form/supplier-order-edit-form.component";
import {StockTransferListComponent} from './stock-transfer/stock-transfer-list/stock-transfer-list.component';
import {StockTransferFormComponent} from './stock-transfer/stock-transfer-form/stock-transfer-form.component';
import {NxMessageModule} from "@aposin/ng-aquila/message";
import {StockTransferShowComponent} from './stock-transfer/stock-transfer-show/stock-transfer-show.component';
import {
  PlantCultureIssueListComponent
} from "./plant-culture-issue/plant-culture-issue-list/plant-culture-issue-list.component";
import {PlantCultureListComponent} from "./plant-culture/plant-culture-list/plant-culture-list.component";
import {
  CultureIssuePairingListComponent
} from './technologies/culture-issue-pairing-list/culture-issue-pairing-list.component';
import {
  CultureIssuePairingFormComponent
} from './technologies/culture-issue-pairing-form/culture-issue-pairing-form.component';
import { CultureIssuePairingShowComponent } from './technologies/culture-issue-pairing-show/culture-issue-pairing-show.component';
import {
  PlantCultureIssueTypeListComponent
} from "./plant-culture-issue-type/plant-culture-issue-type-list/plant-culture-issue-type-list.component";
import { AuthorListComponent } from './author/author-list/author-list.component';
import { AuthorFormComponent } from './author/author-form/author-form.component';
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {ProductListMarketingComponent} from "./product/product-list-marketing/product-list-marketing.component";
import { GoodsLandingListComponent } from './goods-landing/goods-landing-list/goods-landing-list.component';
import { GoodLandingShowComponent } from './goods-landing/good-landing-show/good-landing-show.component';


@NgModule({
  declarations: [
    SupplierListComponent,
    SupplierShowComponent,
    SupplierFormComponent,
    CategoryListComponent,
    CategoryShowComponent,
    CategoryFormComponent,
    AttachmentListComponent,
    AttachmentFormComponent,
    ProductListComponent,
    ProductFormComponent,
    ProductShowComponent,
    TagFormComponent,
    MagazineListComponent,
    MagazineShowComponent,
    MagazineFormComponent,
    AboutUsFormComponent,
    ContactFormComponent,
    TermsAndConditionFormComponent,
    UserOrderFormComponent,
    UserOrderListComponent,
    UserOrderShowComponent,
    BannerListComponent,ProductListMarketingComponent,
    BannerFormComponent,PlantCultureIssueTypeListComponent,
    ArticleShowComponent, ArticleListComponent, ArticleFormComponent, SupplierOrderEditFormComponent, PlantCultureIssueListComponent,
    TermsAndConditionListComponent, PrivacyPolicyFormComponent, BioCertFormComponent, BioCertListComponent, PurchaseInfoFormComponent, ProductMatrixListComponent, WarehouseListComponent, WarehouseFormComponent, WarehouseShowComponent, SupplierOrderListComponent, SupplierOrderFormComponent, InventoryListComponent, InventoryFormComponent, SupplierOrderShowComponent, UserListComponent, UserShowComponent, ScrappingListComponent, ScrappingFormComponent, StockTransferListComponent, StockTransferFormComponent, StockTransferShowComponent, PlantCultureListComponent, CultureIssuePairingListComponent, CultureIssuePairingFormComponent, CultureIssuePairingShowComponent, AuthorListComponent, AuthorFormComponent, GoodsLandingListComponent, GoodLandingShowComponent,
  ],
  imports: [
    CommonModule,
    NxCardModule,
    HellpTableModule,
    ComponentsModule,
    NxGridModule,
    TranslateModule,
    NxDataDisplayModule,
    NxIconModule,
    NxTreeModule,
    NxActionModule,
    RouterLink,
    NxButtonModule,
    NxTooltipModule,
    NxSidepanelModule,
    NxRadioToggleModule,
    FormsModule,
    NxBadgeModule,
    NxListModule,
    NxLinkModule,
    NxTabsModule,
    NxHeadlineModule,
    NxCopytextModule,
    DirectivesModule,
    NxImageModule,
    NgOptimizedImage,
    NxLabelModule,
    PipeModule,
    NxProgressStepperModule,
    NxSpinnerModule,
    GalleryModule,
    NxSwitcherModule,
    NxFormfieldModule,
    NxInputModule,
    ClipboardModule,
    NgxEditorModule,
    NxDropdownModule,
    NxDatefieldModule,
    NxTimefieldModule,
    QuillEditorComponent,
    NxTableModule,
    NxPopoverModule,
    NxAutocompleteModule,
    NxRadioModule,
    NxMessageModule,
    CdkDropList,
    CdkDrag
  ]
})
export class PagesModule {
}
