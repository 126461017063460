import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ListPage} from "../../../utilities/abstract/list-page";
import {ProductHistoryModel, ProductModel} from "../../../services/product/product.model";
import {ProductHistoryDtoTypes, ProductService, UploadType} from "../../../services/product/product.service";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {ROUTING} from "../../../utilities/routing";
import {AdminGuard} from "../../../layouts/admin-layout/admin.guard";
import {Page} from "@hellp/routing";
import {lastValueFrom, takeUntil} from "rxjs";
import {HistoryStatus} from "../../../utilities/types";
import {HellpNotificationServiceType} from "@hellp/service";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {
  StockConfigDialogComponent
} from "../../../components/dialogs/stock-config-dialog/stock-config-dialog.component";
import {Utilities} from "../../../utilities/utilities";
import {StockProduct, WarehouseModel} from "../../../services/warehouse/warehouse.model";
import {WarehouseDtoTypes, WarehouseService} from "../../../services/warehouse/warehouse.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
@Page({
  path: '/',
  pathPrefix: ROUTING.SALES_PRODUCT,
  data: {title: 'title.products'},
  authGuard: AdminGuard
})
export class ProductListComponent extends ListPage<ProductHistoryModel | ProductModel, ProductService> implements OnInit {
  @ViewChild('priceTpl') priceTpl!: TemplateRef<any>
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>
  @ViewChild('inProgressTpl') inProgressTpl!: TemplateRef<any>
  @ViewChild('hasPublishedTpl') hasPublishedTpl!: TemplateRef<any>
  warehouses!: WarehouseModel[];
  private filterString: string;
  statusFilter = HistoryStatus.DRAFT;

  constructor(service: ProductService, private readonly dialogService: NxDialogService, private warehouseService: WarehouseService) {
    super(service);
    this.filterString = this.service.getFullFilter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO);
    this.service.pageMetadata.listHeadline = 'menu.salesProduct'
  }

  ngOnInit(): void {
    this.service.$uploadType.next(UploadType.SALES);
    this.initTable();
    this.loadData();
    this.loadWarehouse();
  }

  loadData(): void {
    this.service.getAllHistoryForListByFilter(this.filterString)
      .pipe(takeUntil(this._destroyed))
      .subscribe(products => {
        this.models = products;
        this.componentIsReady = true;
      });
  }

  loadWarehouse() {
    this.warehouseService.getAllByDeleted(WarehouseDtoTypes.SlimWarehouseDTO)
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => this.warehouses = result);
  }

  override async initTable() {
    this.actionDef = [
      new TableAction('warehouse', this.openStockConfigModal(), await this.getTranslate('table.action.stock'), this.rowPermission()),
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('paper-plane', this.publishConsumer(), await this.getTranslate('table.action.publish'), this.isPublishableConsumer()),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
      new TableAction('xmark', this.updateProductMatrixConsumer(), await this.getTranslate('table.action.removeFromMatrix'), this.isElementOfMatrix()),
      new TableAction('plant-wilt', this.updateProductMatrixConsumer(), await this.getTranslate('table.action.addToMatrix'), this.isNOTElementOfMatrix()),
    ];

    this.colDef = [
      TableCol.newString('status', await this.getTranslate('product.status'))
        .filterable(
          TableFilter.newSelect(Object.keys(HistoryStatus).map(this.generateStatusFilterOption())))
        .template(
          () => this.statusTpl
        ),
      TableCol.newString('product.itemNr', await this.getTranslate('product.itemNr')),
      TableCol.newString('product.name', await this.getTranslate('product.name')),
      TableCol.newString('stockCountString', await this.getTranslate('product.stockCount')),
      TableCol.newString('category.name', await this.getTranslate('product.category')).filterable(TableFilter.newAutoComplete()),
      TableCol.newBoolean('published', await this.getTranslate('product.hasPublished')).filterable(false).template(
        () => this.hasPublishedTpl),
      TableCol.newString('product.prices', await this.getTranslate('product.price')).template(
        () => this.priceTpl),
    ];
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  protected override showProcess(model: ProductHistoryModel): void {
    this.service.navigateToShow(model.product.id);
  }

  protected override editProcess(model: ProductHistoryModel): void {
    if (model.editInProgress) {
      this.service.navigateToEdit(model.draft.id);
    } else {
      this.service.navigateToEdit(model.product.id);
    }
  }

  protected override deleteProcess(row: ProductModel): void {
    this.service.notificationService.confirm(
      this.translateService.instant('general.warning'),
      this.translateService.instant('general.areUSure'),
      'general.ok',
      'general.cancel').then(async result => {
      if (result.value) {
        await lastValueFrom(this.service.delete(row.getHistoryId()));
        this.loadData();
      }
    });
  }

  private publishConsumer() {
    return (row: ProductHistoryModel) => {
      if (this.isPublishableConsumer()(row)) {
        this.service.publish(row).subscribe(result => {
          this.service.notificationService.showSuccess('general.success', 'general.successPublish');
          this.loadData();
        });
      } else {
        this.service.notificationService.showWarning('general.warning', 'product.isNotDraft');
      }
    };
  }

  private isPublishableConsumer() {
    return (row: ProductHistoryModel) => {
      return (row.editInProgress || row.product.status == HistoryStatus.DRAFT) && this.rowPermission()(row);
    };
  }

  private isElementOfMatrix() {
    return (row: ProductHistoryModel) => {
      return row.elementOfProductMatrix;
    };
  }

  private isNOTElementOfMatrix() {
    return (row: ProductHistoryModel) => {
      return !row.elementOfProductMatrix;
    };
  }

  protected readonly HistoryStatus = HistoryStatus;

  private openStockConfigModal() {
    return (row: ProductHistoryModel) => {
      this.dialogService.open(
        StockConfigDialogComponent,
        Utilities.getDefaultModalConfig({product: row, warehouses: this.warehouses})
      ).afterClosed().subscribe((result: StockProduct) => {
        if (!result.id || result.id > 0) {
          this.warehouseService.saveStockProduct(result).subscribe(stockProduct => {
            row.stockProducts.push(stockProduct);
          });
        } else {
          this.warehouseService.updateStockConfig(result).subscribe(stockProduct => {
            const indexOfStock = row.stockProducts.map(s => s.id).indexOf(result.id);
            if (indexOfStock > -1) {
              row.stockProducts[indexOfStock] = stockProduct;
            } else {
              row.stockProducts.push(stockProduct);
            }
          });
        }
      });
    };
  }

  private updateProductMatrixConsumer() {
    return (row: ProductHistoryModel) => {
      row.elementOfProductMatrix = !row.elementOfProductMatrix;
      this.service.updateProductMatrix(row).subscribe(result => {
        this.service.notificationService.showSuccess('general.success', 'general.successUpdate', HellpNotificationServiceType.Toast);
        this.loadData();
      })
    };
  }

  protected readonly environment = environment;

  loadDraft() {
    this.statusFilter = HistoryStatus.DRAFT;
    this.filterString = this.service.getDraftFilter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO);
    this.loadData();
  }

  loadDPublished() {
    this.statusFilter = HistoryStatus.PUBLISHED;
    this.filterString = this.service.getPublishedFilter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO);
    this.loadData();
  }

  loadInProgress() {
    this.statusFilter = HistoryStatus.IN_PROGRESS;
    this.filterString = this.service.getInProgressFilter(ProductHistoryDtoTypes.ExceptDeprecatedProductHistoryDTO);
    this.loadData();
  }
}
