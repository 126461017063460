import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {OrderRepository} from "./order.repository";
import {BillingData, OrderModel} from "./order";
import {DeliveryType, OrderStatus} from "../../utilities/types";
import {Observable} from "rxjs";
import {Utilities} from "../../utilities/utilities";
import {BillingDataRepository} from "./billing-data.repository";
import {PostPayload} from "../../models/post-payload.model";

export enum BillingDataDtoTypes {
  BillingDataDTO = 'BillingDataDTO',
  DeliveryDataDTO = 'DeliveryDataDTO',
}

@Injectable({
  providedIn: 'root'
})
export class BillingDataService extends CrudService<BillingData, BillingDataRepository> {

  constructor(repo: BillingDataRepository) {
    super(ROUTING.ORDER, repo, BillingData);
  }

  createBillingDataForUser(billingData: BillingData): Observable<BillingData> {
    return this.repo.create(new PostPayload<BillingData>(billingData, BillingDataDtoTypes.BillingDataDTO))
  }

  createDeliveryDataForUser(billingData: BillingData): Observable<BillingData> {
    return this.repo.createDeliveryData(new PostPayload<BillingData>(billingData, BillingDataDtoTypes.DeliveryDataDTO))
  }


  updateBillingData(billingData: BillingData): Observable<BillingData> {
    return this.repo.updateBillingData(billingData.id, new PostPayload<BillingData>(billingData, BillingDataDtoTypes.BillingDataDTO))
  }

  updateDeliveryData(billingData: BillingData): Observable<BillingData> {
    return this.repo.updateDeliveryData(billingData.id, new PostPayload<BillingData>(billingData, BillingDataDtoTypes.DeliveryDataDTO))
  }
}
