import { Injectable } from '@angular/core';
import { Cart, CartElement, ExtraCartElement } from './order';
import { ProductHistoryModel } from '../product/product.model';
import { PurchaseInfoModel } from '../purchase-info/purchase-info.model';
import { HellpNotificationService, HellpNotificationServiceType } from '@hellp/service';
import { ServiceLocator } from '../../utilities/service/service.locator';
import { ExtraPriceModel, PackagingConfig } from '../category/category.model';
import { Utilities } from '../../utilities/utilities';
import { DeliveryType, PaymentType } from '../../utilities/types';

export const DELIVERY_PRICE_KEY = 'DELIVERY_PRICE';
export const CASH_PRICE_KEY = 'CASH_PRICE';
export const ICE_PACK_KEY = 'ICE_PACK_PRICE';
export const DELIVERY_PRICE_ID = 999;
export const CASH_PRICE_ID = 888;
export const ICE_PACK_ID = 777;

export const ExtraKeyIdMap: { [key: string]: number } = {
  DELIVERY_PRICE: DELIVERY_PRICE_ID,
  CASH_PRICE: CASH_PRICE_ID,
  ICE_PACK_PRICE: ICE_PACK_ID,
};

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private notificationService: HellpNotificationService = ServiceLocator.injector.get(HellpNotificationService);

  constructor(
    private cart: Cart,
    private paymentType: PaymentType,
    private deliveryType: DeliveryType,
    private purchaseInfo: PurchaseInfoModel,
  ) {}

  add(product: ProductHistoryModel, count = 1) {
    let cartElement = this.cart.cartElements.find((ce) => ce.productId == product.product.id);

    const isAddable = this.productIsAddable(product, cartElement);
    if (!isAddable) {
      return;
    }
    if (cartElement) {
      cartElement.count += count;
    } else {
      cartElement = new CartElement(count, product);
      this.cart.cartElements.push(cartElement);
    }
    setTimeout(() => {
      this.addIcePacks();
      this.addDeliveryPrice();
      this.addPaymentPrice();
    }, 300);
  }

  increment(productId: number, count = 1) {
    const cartElement = this.cart.cartElements.find((ce) => ce.productId == productId);
    if (cartElement) {
      cartElement.count += count;
    } else {
      console.warn(`Cart element not found to increment (${productId})`);
    }

    this.addIcePacks();
    this.addDeliveryPrice();
    this.addPaymentPrice();
  }

  decrement(productId: number, count = 1) {
    const cartElement = this.cart.cartElements.find((ce) => ce.productId == productId);
    if (cartElement) {
      cartElement.count -= count;
    } else {
      console.warn(`Cart element not found to decrement (${productId})`);
    }

    this.addIcePacks();
    this.addDeliveryPrice();
    this.addPaymentPrice();
  }

  remove(index: number, productId: number) {
    const cartElement = this.cart.cartElements[index];
    if (cartElement.productId == productId) {
      this.cart.cartElements.splice(index, 1);
    } else {
      console.warn(`Cart element on ${index} not found to remove (${productId})`);
    }
    this.addIcePacks();
    this.addDeliveryPrice();
    this.addPaymentPrice();
  }

  addIcePacks() {
    const cartElement = this.cart.extraCartElements.find((ce) => ce.productId == ICE_PACK_ID);
    if (cartElement) {
      this.removeIcePacks();
    }
    this.addIcePackRange();
  }

  removeIcePacks() {
    const index = this.cart.extraCartElements.map((e) => e.productId).indexOf(ICE_PACK_ID);

    if (index > -1) {
      this.cart.extraCartElements.splice(index, 1);
    }
  }

  addDeliveryPrice() {
    let cartElement = this.cart.extraCartElements.find((ce) => ce.productId == DELIVERY_PRICE_ID);
    if (cartElement) {
      this.removeDeliveryPrice();
    }

    if (this.deliveryType == DeliveryType.DELIVERY) {
      const deliveryPrice = this.calcDeliveryPrice();
      if (deliveryPrice) {
        cartElement = new ExtraCartElement(DELIVERY_PRICE_ID, deliveryPrice.key, deliveryPrice.price);
        this.cart.extraCartElements.push(cartElement);
      } else {
        this.notificationService.showWarning('general.warning', 'general.noCashPrice');
      }
    }
    this.addPaymentPrice();
  }

  removeDeliveryPrice() {
    const index = this.cart.extraCartElements.map((e) => e.productId).indexOf(DELIVERY_PRICE_ID);

    if (index > -1) {
      this.cart.extraCartElements.splice(index, 1);
    }
  }

  addPaymentPrice() {
    let cartElement = this.cart.extraCartElements.find((ce) => ce.productId == CASH_PRICE_ID);
    if (cartElement) {
      this.removePaymentPrice();
    }
    if (this.paymentType == PaymentType.CASH && this.deliveryType == DeliveryType.DELIVERY) {
      const cashPrice = this.calcCashPrice();
      if (cashPrice) {
        cartElement = new ExtraCartElement(CASH_PRICE_ID, cashPrice.key, cashPrice.price);
        this.cart.extraCartElements.push(cartElement);
      } else {
        this.notificationService.showWarning('general.warning', 'general.noCashPrice');
      }
    }
  }

  removePaymentPrice() {
    const index = this.cart.extraCartElements.map((e) => e.productId).indexOf(CASH_PRICE_ID);

    if (index > -1) {
      this.cart.extraCartElements.splice(index, 1);
    }
  }

  private productIsAddable(product: ProductHistoryModel, cartElement?: CartElement): boolean {
    if (cartElement) {
      return true;
    }

    if (this.cart.cartElements.length == 0) {
      return true;
    }

    if (this.cart.cartElements.length > 0) {
      const cartAlreadyHasExclusiveElement = this.cart.cartElements.find((ce) => ce.product.product.cartExclusive);

      if (product.product.cartExclusive || cartAlreadyHasExclusiveElement) {
        this.notificationService.showWarning(
          'general.warning',
          'product.isCartExclusive',
          HellpNotificationServiceType.Popup,
        );
        return false;
      }
    }
    return true;
  }

  private addIcePackRange() {
    let icePackCount = 0;
    this.cart.cartElements.forEach((cartElement) => {
      if (cartElement.product) {
        const packagingConfig: PackagingConfig | undefined = cartElement.product.packagingConfig;
        if (packagingConfig && packagingConfig.icePackRanges) {
          let range = packagingConfig.icePackRanges.find(
            (ipr) => ipr.start <= cartElement.count && ipr.end > cartElement.count,
          );
          if (!range) {
            range = packagingConfig.icePackRanges.sort((a, b) => b.end - a.end)[0];
          }
          icePackCount += range.count;
        }
      }
    });
    if (icePackCount > 0) {
      const cartElement = new ExtraCartElement(
        ICE_PACK_ID,
        this.purchaseInfo.icePackPrice.key,
        this.purchaseInfo.icePackPrice.price,
        icePackCount,
      );
      this.cart.extraCartElements.push(cartElement);
    }
  }

  public calcCashPrice(): ExtraPriceModel | undefined {
    const cashKeys: number[] = this.purchaseInfo.cashPrices.map((s) => parseInt(s.key));
    const cashKey = Utilities.nearestBigger(cashKeys, this.cart.sumPrice);
    const keyPrice = this.purchaseInfo.cashPrices.find((cp) => cp.key == cashKey.toString());
    return keyPrice ? keyPrice.extraPrice : undefined;
  }

  public calcDeliveryPrice(): ExtraPriceModel | undefined {
    const weightKeys: number[] = this.purchaseInfo.deliveryPrices.map((s) => parseInt(s.key));
    const weightKey = Utilities.nearestBigger(weightKeys, this.cart.sumWeight);
    const keyPrice = this.purchaseInfo.deliveryPrices.find((cp) => cp.key == weightKey.toString());
    return keyPrice ? keyPrice.extraPrice : undefined;
  }

  changeDeliveryType(deliveryType: DeliveryType) {
    this.deliveryType = deliveryType;
    this.addDeliveryPrice();
  }

  changePaymentType(paymentType: PaymentType) {
    this.paymentType = paymentType;
    this.addPaymentPrice();
  }
}
