import {Component, Input, QueryList, ViewChildren} from "@angular/core";
import {ValidatedInputDirective} from "../../directives/validated-input.directive";
import {Subject, takeUntil} from "rxjs";
import {CrudService} from "../service/crud.service";

@Component({template: ''})
export abstract class FormComponent {
  protected readonly _destroyed = new Subject<void>();
  @ViewChildren(ValidatedInputDirective) inputs!: QueryList<ValidatedInputDirective>;
  @Input() validationError!: any;

  protected constructor(public service: CrudService<any, any>) {
  }

  initErrorHandlingOnInput() {
    this.service.$validationError.pipe(takeUntil(this._destroyed)).subscribe(result => {
      if (this.inputs) {
        this.inputs.forEach(item => {
          item.ngControl?.control?.markAsTouched();
        });
      }
      this.validationError = {};
      result.forEach(error => {
        this.validationError[error.attribute] = error.message;
      });
    });
  }
}
