<div class="card border-radius-lg">
  <div [ngClass]="{'bg-gradient-dark':!printMode, 'text-white':!printMode}"
       class="card-header border-radius-lg border-radius-lg px-4 py-3">
    <p class="m-0 p-0 d-flex justify-content-between">
      <span>{{ title | translate }}</span>
      <ng-container *ngIf="isEditable">
        <button nxPlainButton (click)="edit()" class="text-white">
          <nx-icon name="pencil"></nx-icon>
        </button>
      </ng-container>
    </p>
  </div>
  <ng-container *ngIf="mode!=='onsite'">
    <ng-container *ngTemplateOutlet="billingBody"></ng-container>
  </ng-container>
  <ng-container *ngIf="mode==='onsite'">
    <div class="card-body px-4 py-3">
      <h5 nxHeadline="subsection-small">{{ 'cart.summary.onsiteDelivery' | translate }}</h5>

      <p class="text-small mt-2">
        {{ 'cart.summary.onsiteDesc' | translate }}
        <br>
        <nx-icon name="location-pin" class="me-2"></nx-icon>
        {{ contactInfo.hq }}
      </p>
    </div>
  </ng-container>
</div>


<ng-template #billingBody>
  <ng-container *ngIf="billingData">
    <div class="card-body px-4 py-3">
      <h5 nxHeadline="subsection-small"
          *ngIf="billingData.taxNumber">{{ 'cart.summary.company' | translate }}</h5>
      <h5 nxHeadline="subsection-small"
          *ngIf="!billingData.taxNumber">{{ 'cart.summary.personal' | translate }}</h5>
      <p class="text-small mt-2">
        <nx-icon name="address-book" class="me-2"></nx-icon>
        <span *ngIf="billingData.taxNumber">
                       {{ billingData.companyName }}
          ({{ billingData.firstName }} {{ billingData.lastName }})
                    </span>
        <span *ngIf="!billingData.taxNumber">
                       {{ billingData.firstName }} {{ billingData.lastName }}
                    </span>

        <br>
        <span *ngIf="billingData.taxNumber">
                      <nx-icon name="receipt" class="me-2"></nx-icon>
          {{ billingData.taxNumber }}<br>
                    </span>
        <nx-icon name="envelope" class="me-2"></nx-icon>
        {{ billingData.email }}<br>
        <nx-icon name="phone" class="me-2"></nx-icon>
        {{ billingData.phoneNumber }}<br>
        <nx-icon name="location-pin" class="me-2"></nx-icon>
        {{ billingData.address.toString() }}
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="!billingData">
    <p class="text-warning">
      {{ 'order.noBillingData' | translate }}
    </p>
  </ng-container>
</ng-template>