<app-attachment-handler
        [attachedItems]="itemAttachments"
        [availableItems]="availableAttachments"
        [attachedTitle]="'general.attachedImages'"
        [availableTitle]="'general.availableImages'"
        [attachedViewComponent]="imgViewComponent"
        [availableViewComponent]="imgViewComponent"
        [acceptString]="'image/*'"
        [isMultiple]="isMultiple"
        [maxElement]="maxElement"
        [secondPanelExpanded]="secondPanelExpanded"
        (refreshEvent)="attachmentRefresh()"
        (attachmentSaveEvent)="attachmentSave($event)"
>
</app-attachment-handler>
