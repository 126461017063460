<div nxModalContent>
  <nx-card class="w-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'general.filterByDateRange' | translate }}
    </h3>

    <div nxLayout="grid">
      <div nxRow>
        <div nxCol="6">
          <nx-formfield nxLabel="{{'general.filterByDateRangeFrom' | translate}}">
            <input
              nxDatefield
              nxInput
              [nxParseFormat]="['MM/DD/YYYY', 'YYYY.MM.DD']"
              nxDisplayFormat="YYYY.MM.DD"
              [nxDatepicker]="startDatepicker"
              [(ngModel)]="fromTo.from"
              [nxMax]="fromTo.to"
              (dateChange)="updateEndDatepicker()"
            />
            <span nxFormfieldHint>YYYY.MM.DD</span>
            <nx-datepicker-toggle
              [for]="startDatepicker"
              nxFormfieldSuffix
            ></nx-datepicker-toggle>
            <nx-datepicker #startDatepicker></nx-datepicker>
          </nx-formfield>
        </div>

        <div nxCol="6">
          <nx-formfield nxLabel="{{'general.filterByDateRangeTo' | translate}}">
            <input
              nxDatefield
              nxInput
              [nxParseFormat]="['MM/DD/YYYY', 'YYYY.MM.DD']"
              nxDisplayFormat="YYYY.MM.DD"
              [nxDatepicker]="endDatepicker"
              [(ngModel)]="fromTo.to"
              [nxMin]="fromTo.from"
              [nxMax]="today"
            />
            <span nxFormfieldHint>YYYY.MM.DD</span>
            <nx-datepicker-toggle
              [for]="endDatepicker"
              nxFormfieldSuffix
            ></nx-datepicker-toggle>
            <nx-datepicker #endDatepicker></nx-datepicker>
          </nx-formfield>
        </div>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [nxModalClose]="fromTo"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.set' | translate }}
  </button>
</div>
