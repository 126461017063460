<nx-card class="viewer">
  <page-list-view-header
    [title]="'user.headline'"
    iconName="truck-ramp-box"
  ></page-list-view-header>
  <div class="nx-margin-top-2m">
    <ng-container *ngIf="componentIsReady">
      <hellp-table id="user-list-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                   [showAsList]="mobile"></hellp-table>
    </ng-container>

    <ng-container *ngIf="!componentIsReady">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </div>
</nx-card>

<ng-template #keycloakLinkTpl let-value="value">
  <a class="table-mailto" href="{{environment.keycloak.issuer + environment.keycloakUserUrl + value }}"
     target="_blank"
     title="{{'user.keycloakLink' | translate}}">
    <nx-icon name="up-right-from-square" class="nx-margin-right-2s"></nx-icon>
    {{ 'user.keycloakLink' | translate }}
  </a>
</ng-template>