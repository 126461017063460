import {Injectable, Type} from "@angular/core";
import {Get, Patch, PathVariable, Post, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {Cart, ExtraCartElement, OrderModel} from "./order";
import {OrderStatus} from "../../utilities/types";
import {DeliveryPartnerType} from "../purchase-info/purchase-info.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/orders')
export class OrderRepository extends CrudRepository<OrderModel> {
  constructor() {
    super();
  }

  @Post('/admin/create')
  adminOrderSave(@RequestBody() payload: OrderModel, clazz: Type<OrderModel>): Observable<any> {
    return this.createRequest().withModel(clazz).send();
  }

  @Get('/{id}/resend-email')
  resendEmail(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/status/{status}')
  changeStatus(@PathVariable() id: number, @PathVariable() status: OrderStatus) {
    return this.createRequest().send();
  }

  @Patch('/{id}/create-invoice')
  createInvoice(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/delivery-data/add')
  addDeliveryData(@PathVariable() id: number, @RequestBody() patchDto: { deliveryDataId: number, id: number }) {
    return this.createRequest().send();
  }

  @Get('/{id}/shipment/refresh-status')
  refreshDeliveryStatus(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/recreate-cart')
  reCreateCart(@PathVariable() id: number, @RequestBody() cart: Cart) {
    return this.createRequest().send();
  }

  @Post('/barion/start-payment/{orderNumber}')
  sendPaymentLink(@PathVariable() orderNumber: string) {
    return this.createRequest().send();
  }

  @Post('/pdf')
  generatePdf(@RequestBody() orderNumbers: string[]): Observable<any> {
    return this.createRequest().withOption({
      responseType: 'blob',
      observe: 'response',
    }).send();
  }

  @Patch('/cash/start-payment/{id}')
  payByCash(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('{id}/shipment/{partnerType}/start')
  startDeliveryProcess(@PathVariable() id: number, @PathVariable() partnerType: DeliveryPartnerType) {
    return this.createRequest().send();
  }

  @Get('/{id}/shipment/{partnerType}/label/{shipmentId}')
  getShipmentLabel(@PathVariable() id: number, @PathVariable() partnerType: DeliveryPartnerType, @PathVariable() shipmentId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/cancel')
  cancelOrderByAdmin(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/payment/cash-to-barion/{extraCartElementId}')
  changePaymentFromCashToBarion(@PathVariable() id: number, @PathVariable() extraCartElementId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/payment/barion-to-cash')
  changePaymentFromBarionToCash(@PathVariable() id: number, @RequestBody() extraCartElement: ExtraCartElement) {
    return this.createRequest().send();
  }

  @Patch('/{id}/shipment/onsite-to-delivery')
  changeShipmentFromOnsiteToDelivery(@PathVariable() id: number, @RequestBody() extraCartElement: ExtraCartElement) {
    return this.createRequest().send();
  }

  @Patch('/{id}/shipment/delivery-to-onsite/{extraCartElementId}')
  changeShipmentFromDeliveryToOnsite(@PathVariable() id: number, @PathVariable() extraCartElementId: number) {
    return this.createRequest().send();
  }
}
