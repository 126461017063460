import {environment} from '../../environments/environment';
import {formatDate} from '@angular/common';
import * as moment from "moment";
import {Moment} from "moment";

export class Utilities {


  static dateToBackendString(date: Date) {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  static onlyTimeToBackendString(date: Date) {
    return formatDate(date, 'HH:mm:ss', 'en-US');
  }

  static dateToBackendDateTimeString(date: Moment, addTdelimiter?: boolean) {
    return date.format('YYYY-MM-DD HH:mm:ss').replace(' ', addTdelimiter ? 'T' : ' ');
  }

  static dateToBackendDateTimeStringByDate(date: Date, addTdelimiter?: boolean) {
    return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'en-US').replace(' ', addTdelimiter ? 'T' : ' ');
  }

  static getHours(start: number, end: number): string[] {
    const result: string[] = [];
    for (let i = start; i <= end; i++) {
      const label = i.toString().padStart(2, '0') + ':00';
      result.push(label);
    }
    return result;
  }

  static setStartOfDay(start: string) {
    let date: Moment = moment.utc(start).utcOffset(0);
    date = date.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    return Utilities.dateToBackendDateTimeString(date, false);
  }

  static setEndOfDay(end: string) {
    const date: Moment = moment.utc(end).utcOffset(0);
    date.set({hour: 23, minute: 59, second: 59, millisecond: 0})
    return Utilities.dateToBackendDateTimeString(date, false);
  }


  static timeToBackendString(timeString: string) {
    const time = timeString.split('-');
    return time[0].trim() + ':00';
  }

  static addDays(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    return date;
  }

  static minusDays(date: Date, days: number) {
    date.setDate(date.getDate() - days);
    return date;
  }

  static addMinutesToDate(date: Date, minutes: number): Date {
    const timeStamp = date.getTime() + (minutes * 60000);
    return new Date(timeStamp);
  }

  static addHoursToDate(date: Date, hours: number): Date {
    const timeStamp = date.getTime() + (hours * 1000 * 60 * 60);
    return new Date(timeStamp);
  }

  static isProduction() {
    return environment.production;
  }

  static findMin(array: number[]): number {
    return Math.min(...array)
  }

  static findMax(array: number[]): number {
    return Math.max(...array)
  }

  static validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static dateToPath(date: Date) {
    return formatDate(date, 'yyyy_MM_dd_HH_mm_ss', 'en-US')
  }

  static huToEn(str: string) {

    const ekezet = ["Ö", "ö", "Ü", "ü", "Ó", "ó", "O", "o", "Ú", "ú", "Á", "á", "U", "u", "É", "é", "Í", "í",
      "/", "ő", "Ű", "ű", "Ő", "Ä",
      " ", ",", "(", ")", "{", "}",
      ".", "@", "?", "#", '"', "'"];
    const nekezet = ["O", "o", "U", "u", "O", "o", "O", "o", "U", "u", "A", "a", "U", "u", "E", "e", "I", "i",
      "", "o", "U", "u", "O", "A",
      "-", "-", "", "", "", "",
      "", "", "", "", "", ""];

    const map: any = {};
    ekezet.forEach((el, index) => {
      map[el] = nekezet[index];
    })
    return str.replace(/[^A-Za-z0-9]/g, (ch) => {
      return map[ch] || ch;
    });
  }

  static generateSlug(str: string, tail = '', separator = '-') {
    return str.toString()
      .normalize('NFD')                   // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, separator) + '-' + tail;
  }

  static getDefaultModalConfig(data: any, id = "modal-ka") {
    return {
      id: id,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      showCloseIcon: false,
      fullscreen: true,
      data: data
    }
  }

  static getDefaultModalConfigWithoutModel(id = "modal-ka") {
    return {
      id: id,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      showCloseIcon: false,
      fullscreen: true,
    }
  }

  static getDefaultCloseIconModalConfig(data: any, id = "modal-ka") {
    return {
      id: id,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      showCloseIcon: true,
      data: data
    }
  }

  static getDefaultCloseIconModalConfigWithoutData(id = "modal-ka") {
    return {
      id: id,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      showCloseIcon: true,
    }
  }

  static getDatesInRange(startDate: Date, endDate: Date): Date[] {
    const date = new Date(startDate.getTime());

    const dates: Date[] = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  static convertToCSV(objArray: any, headerList: string[]) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No, ';
    for (const index in headerList) {
      row += headerList[index] + ', ';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '& #039;&# 039;';
      for (const index in headerList) {
        const head = headerList[index];
        line += '& #039;', '&# 039;' + array[i][head];
      }
      str += line + '& #039;\r\n&# 039';
    }
    return str;
  }

  static downloadFile(data: any, filename = 'data') {
    const blob = new Blob([JSON.stringify(data)], {
      type: 'application/json;charset=utf-8;'
    });
    const dwldLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf(
        'Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;

    //if Safari open in new window to save file with random filename.
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".json");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  static toSKU(name: string) {
    const splitted = name.split(' ');
    const elements: string[] = [];
    splitted.forEach(part => {
      elements.push(
        Utilities.huToEn(part.substring(0, 2)).toUpperCase()
      );
    });
    return elements.join('');
  }

  static nearestBigger(numberArray: number[], num: number): number {
    numberArray = numberArray.sort((a, b) => a - b);
    const firstBigger = numberArray.find(a => a > num);
    return firstBigger ?? numberArray[numberArray.length - 1];
  }

  static removeHtmlTags(input: string, keepLineBreaks = false): string {
    const cleanedString = input.replace(/<[^>]+>/g, '');
    if (keepLineBreaks) return cleanedString;
    return cleanedString.replace(/\n/g, '');
  }

  static extractVideoId(url: string): string {
    const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*v=([^&]+)|youtu\.be\/([^?&]+)/);
    return videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
  }

}
