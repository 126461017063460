import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ArticleType, MagazineModel} from "./magazine.model";
import {ROUTING} from "../../utilities/routing";
import {AuthorDtoTypes, AuthorService} from "./author.service";
import {ValidatorService} from "../../utilities/service/validator.service";
import {ArticleRepository} from "./article.repository";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";
import {ValidationError} from "../../models/validation.error.model";


export enum ArticleDtoType {
  ArticleDTO = 'ArticleDTO'
}

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends CrudService<MagazineModel, ArticleRepository<MagazineModel>> {

  constructor(repo: ArticleRepository<MagazineModel>, private authorService: AuthorService) {
    super(ROUTING.ARTICLE, repo, MagazineModel, PageMetaDataConf.BLOG);
  }

  getAllArticle() {
    const filter = this.filterService.filter(ArticleDtoType.ArticleDTO)
      .equal('type', ArticleType.BLOG)
      .and()
      .equal('deleted', false)
      .create();
    return this.repo.getAllByFilter(filter.stringify(), MagazineModel);
  }

  publish(model: MagazineModel) {
    return this.repo.publish(model.id, model);
  }

  getAuthors() {
    return this.authorService.getAllByDeleted(AuthorDtoTypes.AuthorDTO);
  }

  override validate(model: MagazineModel) {
    const errors: ValidationError[] = ValidatorService.allIsRequired(model, ['title', 'shortDescription', 'content', 'authorId'], MagazineModel.name)
    if (model.shortDescription.length > 254) {
      errors.push(new ValidationError("error.magazine.shortDescriptionToLong", 'shortDescription'));
    }
    const items = ValidatorService.isRequired(model.coverPhoto.attachment, "error.MagazineModel.coverIsRequired", 'coverPhoto');
    if (items) {
      errors.push(items);
    }
    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

  validateByStep(model: MagazineModel, stepCount: number) {
    let errors: ValidationError[] = [];
    if (stepCount == 1) {
      errors = ValidatorService.allIsRequired(model, ['title', 'shortDescription', 'authorId'], MagazineModel.name)
      if (model.shortDescription.length > 254) {
        errors.push(new ValidationError("error.magazine.shortDescriptionToLong", 'shortDescription'));
      }
      const items = ValidatorService.isRequired(model.coverPhoto.attachment, "error.MagazineModel.coverIsRequired", 'coverPhoto');
      if (items) {
        errors.push(items);
      }
    }
    if (stepCount == 2) {
      this.validate(model);
    }

    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }
}
