import {Component, Inject} from '@angular/core';
import {PurchaseInfoModel} from "../../../services/purchase-info/purchase-info.model";
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

@Component({
  selector: 'app-start-delivery-dialog',
  templateUrl: './start-delivery-dialog.component.html',
  styleUrls: ['./start-delivery-dialog.component.css']
})
export class StartDeliveryDialogComponent {

  constructor(@Inject(NX_MODAL_DATA) public data: PurchaseInfoModel) {

  }
}
