<div nxModalContent>
  <nx-card class="w-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'product.stockConfig' | translate }}
    </h3>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6">
        {{ product.product.name }}
      </div>
      <div class="col-md-6">
        <nx-formfield nxFloatLabel="always" nxLabel="{{'product.warehouseSelect' | translate}}">
          <nx-dropdown [ngModel]="selectedWarehouse" (nxValueChange)="changeWarehouse($event)">
            <nx-dropdown-item
              *ngFor="let option of warehouses"
              [nxValue]="option"
            >
              {{ option.name }}
            </nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
      </div>
      <ng-container *ngIf="model">
        <div class="col-12">
          <div class="d-flex justify-content-end m-3">
            <button nxButton="secondary small" (click)="addNewYear()">{{ 'stockConfig.newYear' | translate }}</button>
          </div>
          <nx-tab-group [(selectedIndex)]="currentIndex">
            <ng-container *ngFor="let minStock of model.config.minimumStocks">
              <nx-tab label="{{minStock.year}}">
                <ng-template nxTabContent>
                  <form>
                    <table nxTable condensed class="nx-table--scrollable">
                      <thead>
                      <tr nxTableRow>
                        <th nxHeaderCell>{{ 'stockConfig.month' | translate }}</th>
                        <th nxHeaderCell>{{ 'stockConfig.monthMinStock' | translate }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.1' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.JANUARY]"
                                   name="{{minStock.year+'_1'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.2' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.FEBRUARY]"
                                   name="{{minStock.year+'_2'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.3' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.MARCH]"
                                   name="{{minStock.year+'_3'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.4' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.APRIL]"
                                   name="{{minStock.year+'_4'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.5' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.MAY]"
                                   name="{{minStock.year+'_5'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.6' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.JUNE]"
                                   name="{{minStock.year+'_6'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.7' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.JULY]"
                                   name="{{minStock.year+'_7'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.8' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.AUGUST]"
                                   name="{{minStock.year+'_8'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.9' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.SEPTEMBER]"
                                   name="{{minStock.year+'_9'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.10' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.OCTOBER]"
                                   name="{{minStock.year+'_10'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.11' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.NOVEMBER]"
                                   name="{{minStock.year+'_11'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>{{ 'general.month.12' | translate }}</td>
                        <td nxTableCell>
                          <nx-formfield appearance="outline">
                            <input nxInput type="number" [(ngModel)]="minStock.monthlyMinimum[Month.DECEMBER]"
                                   name="{{minStock.year+'_12'}}"/>
                          </nx-formfield>
                        </td>
                      </tr>

                      </tbody>
                    </table>
                  </form>
                </ng-template>
              </nx-tab>
            </ng-container>

          </nx-tab-group>
        </div>
      </ng-container>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [nxModalClose]="model"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.save' | translate }}
  </button>
</div>
