<div nxModalContent>
  <nx-card class="w-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'order.editOrder' | translate }}
    </h3>
    <ng-container *ngIf="model">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-8 col-md-7 ms-auto">
          <nx-formfield nxLabel="{{'order.availableProducts' | translate}}"
                        appearance="outline"
                        nxFloatLabel="always">
            <input
              nxInput
              type="text"
              [nxAutocomplete]="auto2"
              [nxAutocompleteItems]="dynamicBackendOptions"
              [(ngModel)]="selectedName"
            />
            <nx-autocomplete #auto2="nxAutocomplete"></nx-autocomplete>
          </nx-formfield>
        </div>
        <div class="col-lg-2 col-md-3 me-auto">
          <button nxPlainButton="" (click)="add()">{{ 'general.add' | translate }}</button>
        </div>
        <div class="col-12">
          <div style="height: 400px; overflow-y: auto; padding-bottom: 50px;">
            <ng-container *ngIf="model.cartElements">
              <ng-container *ngFor="let element of model.cartElements; let i = index">
                <display-product-cart-card [cartElement]="element" [index]="i"
                                           [cartService]="cartService"></display-product-cart-card>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="model.extraCartElements">
              <ng-container *ngFor="let element of model.extraCartElements; let i = index">
                <display-product-cart-card [extraElement]="element" [index]="i"
                                           [cartService]="cartService"></display-product-cart-card>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!model">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [nxModalClose]="model"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.save' | translate }}
  </button>
</div>
