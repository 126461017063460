import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  NxFlatTreeControl,
  NxFlatTreeNode,
  NxTreeComponent,
  NxTreeFlatDataSource,
  NxTreeNode
} from "@aposin/ng-aquila/tree";
import {Subject, takeUntil} from "rxjs";
import {NxSidebarComponent} from "@aposin/ng-aquila/sidebar";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {AUTH_USER_KEY} from "../../app.config";
import {KeycloakService} from "keycloak-angular";
import {ExtendedStorageService} from "@hellp/service";
import {KeycloakProfile} from "keycloak-js";
import {environment} from "../../../environments/environment";

export interface MyTreeNode extends NxTreeNode {
  label: string;
  icon?: string;
  query?: any;
  children?: MyTreeNode[];
}

interface MyFlatTreeNode extends NxFlatTreeNode {
  label: string;
  icon?: string;
  query?: any;
  prefix?: string;
}

@Component({
  selector: 'layout-sidebar',
  templateUrl: './layout-sidebar.component.html',
  styleUrls: ['./layout-sidebar.component.css']
})
export class LayoutSidebarComponent implements OnDestroy, OnInit, AfterViewInit {
  applyTopScrollShadow = false;
  applyBottomScrollShadow = false;

  MOBILE_BREAK = 430;
  @Input() actions: MyTreeNode[] = [];
  @Input() prefix = '';

  @ViewChild('treeComponent') treeComponent!: NxTreeComponent<any>;
  @ViewChild('menus') menus!: ElementRef;

  public authUser!: KeycloakProfile;

  dataSource!: NxTreeFlatDataSource<MyTreeNode, MyFlatTreeNode>;

  treeControl!: NxFlatTreeControl<MyFlatTreeNode>;

  private readonly _destroyed = new Subject<void>();

  @ViewChild('sidebar') sidebar!: NxSidebarComponent;
  @ViewChild('sidebar') sideBarElement!: ElementRef;
  mobile = false;
  tablet = false;
  openSideBar = true;

  constructor(public viewportService: NxViewportService,
              private keycloakService: KeycloakService,
              private myStorageService: ExtendedStorageService,
              private _cdr: ChangeDetectorRef,) {


  }

  private initViewport() {
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanMedium => {
        this.tablet = !isGreaterThanMedium;
        if (isGreaterThanMedium && !this.sidebar.open) {
          this.sidebar.expand(280);
        } else if (!isGreaterThanMedium) {
          if (this.sidebar.open) {
            this.sidebar.close();
          }
        }
      });
    this.viewportService
      .min(this.MOBILE_BREAK)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanSmall => {
        this.mobile = !isGreaterThanSmall;
        this.openSideBar = isGreaterThanSmall;
      });
  }

  ngOnInit(): void {
    this.authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, {});
    this.treeControl = new NxFlatTreeControl();
    this.dataSource = new NxTreeFlatDataSource(
      this.treeControl,
      this.actions,
    );

  }

  ngAfterViewInit(): void {
    this.treeComponent.focus();
    this.treeComponent.expandCurrentFocusedNode();
    this.initViewport();
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  hasChild = (_: number, node: NxFlatTreeNode) => node.expandable;

  toggleSideBar() {
    this.openSideBar = !this.openSideBar;
  }

  onScroll() {
    this.applyTopScrollShadow = this.menus.nativeElement.scrollTop > 5;
    this.applyBottomScrollShadow = this.menus.nativeElement.scrollHeight - this.menus.nativeElement.scrollTop - 5 > this.menus.nativeElement.offsetHeight;
  }

  sidebarToggle() {
    if (this.sidebar.open) {
      this.sidebar.close();
    } else {
      this.sidebar.expand(280);
    }
  }

  async logout() {
    this.myStorageService.deleteFromCookies(AUTH_USER_KEY);
    await this.keycloakService.logout();
  }

  protected readonly environment = environment;
}
