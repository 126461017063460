import {Injectable} from "@angular/core";
import {AddressModel} from "../../models/address.model";
import {ValidationError} from "../../models/validation.error.model";

@Injectable({
  providedIn: 'root'
})
export abstract class ValidatorService {


  public static backendMapIsEmpty(map: any, errorMessage: string) {
    if (!map || Object.keys(map).length < 1) {
      throw new Error(errorMessage);
    }
  }

  /**
   * Megvizsgálja a @value értéket, hogy ki van-e töltve, ha nincs, akkor @errorMessage hibát dob
   * @param value
   * @param errorMessage
   */
  public static required(value: any, errorMessage: string) {
    if (value == null || value.length == 0 || !value) {
      throw new Error(errorMessage)
    }
  }

  /**
   * Megvizsgálja a @value értéket, hogy ki van-e töltve, ha nincs, akkor @errorMessage hibát dob
   * @param value
   * @param errorMessage
   * @param attribute
   */
  public static isRequired(value: any, errorMessage: string, attribute: string): ValidationError | undefined {
    if (value == null || value.length == 0 || !value) {
      return new ValidationError(errorMessage, attribute);
    }
    return undefined;
  }

  /**
   * Az @object összes olyan tulajdonságát megvizsgálja, mely szerepel az @attributeList-ben
   * @param object
   * @param attributeList
   * @param modelName - vizsgált objektum neve, csak a hibaüzenethez kell
   */
  public static allRequired(object: any, attributeList: any[], modelName: string) {
    attributeList.forEach(attribute => {
      this.required(object[attribute], "error." + modelName + ".required." + attribute)
    })
  }

  public static allIsRequired(object: any, attributeList: any[], modelName: string, attributeName?: string): ValidationError[] {
    const result: ValidationError[] = [];
    attributeList.forEach(attribute => {
      const req = this.isRequired(object[attribute], "error." + modelName + ".required." + attribute, attributeName ?? attribute);
      if (req) {
        result.push(req);
      }
    });
    return result;
  }

  public static matchRegExp(value: string, regExp: any, errorMessage = 'error.regExpError') {
    if (!regExp.test(value)) {
      throw new Error(errorMessage)
    }
  }

  public static validateStartEndDate(start: Date, end: Date, startCanBeInPast = false) {
    if (!startCanBeInPast && start.getTime() < (new Date()).getTime()) {
      throw new Error("error.date.startInPast");
    }
    if (end.getTime() < start.getTime()) {
      throw new Error("error.date.endBeforeStart");
    }
  }


  /**
   * Egy Address model-t validál
   * @param address
   * @param modelName - vizsgált objektum neve, csak a hibaüzenethez kell
   */
  public static validateAddress(address: AddressModel, modelName: string) {
    if ((address.country == null || address.country.length < 1) ||
      (address.city == null || address.city.length < 1) ||
      (address.street == null || address.street.length < 1) ||
      (address.streetNumber == null || address.streetNumber.length < 1)
      //||
      // (address.zipCode == null || address.zipCode < 1000 || address.zipCode > 9999)
    ) {
      throw new Error("error." + modelName + ".address.invalid");
    }
  }
}
