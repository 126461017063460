import {SubscriptionType, UserRole, UserType} from "../../utilities/types";
import {ParseTo} from "@hellp/parser";
import {BillingData} from "../order/order";
import {BaseModel} from "../../utilities/abstract/base.model";


export class Subscription {
  type: SubscriptionType = SubscriptionType.Newsletter;
}

export class UserModel extends BaseModel {
  type: UserType = UserType.simple
  role!: UserRole;
  email = '';
  firstName = '';
  lastName = '';
  keycloakId = '';
  bankAccountNumber = '';
  realmRoles: string[] = [UserRole.CUSTOMER];

  @ParseTo(BillingData)
  deliveryData: BillingData[] = [];

  @ParseTo(BillingData)
  billingData: BillingData[] = [];

  subscriptionTypes: SubscriptionType[] = [];
  subscriptions: Subscription[] = [];

  public static getSlimUserFrom(user: UserModel): UserModel {
    const slim = new UserModel();
    slim.id = user.id;
    slim.firstName = user.firstName;
    slim.lastName = user.lastName;
    slim.email = user.email;
    return slim;
  }

  public static getDummy() {
    const user = new UserModel();
    user.billingData = [];
    user.deliveryData = [];
    user.type = UserType.dummy;
    return user;
  }

  public get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  public isSubscribe(type: SubscriptionType) {
    return this.subscriptions.map(s => (s as Subscription).type).includes(type);
  }

  get isSubscribeToNewsletter(): boolean {
    return this.isSubscribe(SubscriptionType.Newsletter);
  }

}

