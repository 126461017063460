import {Directive, HostListener, Input} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

export interface FileDownloadConfig {
  url: string;
  fileName: string;
  method?: 'POST' | 'GET';
  body?: any;
}

export const fileDownloadProcess = (response: HttpResponse<Blob>, fallbackFileName: string) => {
  if (response.body != null) {
    const url = URL.createObjectURL(response.body);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = getFilenameFromHeaders(response.headers) || fallbackFileName;
    anchor.click();
    URL.revokeObjectURL(url);
  } else {
    console.warn("Response body is empty");
  }
}

export const getFilenameFromHeaders = (headers: HttpHeaders) => {
  const contentDisposition = headers.get('Content-Disposition');
  if (!contentDisposition) {
    return null;
  }

  const leadIn = 'filename=';
  const start = contentDisposition.search(leadIn);
  if (start < 0) {
    return null;
  }

  const value = contentDisposition.substring(start + leadIn.length).trim();
  if (value.length === 0) {
    return null;
  }

  const firstCharacter = value[0];
  if (firstCharacter !== '\"' && firstCharacter !== '\'') {
    return value;
  }

  if (value.length < 2) {
    return null;
  }

  const lastCharacter = value[value.length - 1];
  if (lastCharacter !== firstCharacter) {
    return null;
  }

  return value.substring(1, value.length - 1);
}

@Directive({
  selector: '[downloadFile]'
})
export class DownloadFileDirective {

  private config!: FileDownloadConfig;
  private method = 'GET';

  constructor(private readonly httpClient: HttpClient) {
  }

  @Input('downloadFile')
  public set url(downloadConfig: FileDownloadConfig) {
    this.config = downloadConfig;
    this.method = downloadConfig.method ?? this.method;
  }

  @HostListener('click')
  public async onClick(): Promise<void> {
    this.sendReq('token');
  }

  private sendReq(token: string) {
    let header: any = {
      'Authorization': 'Bearer ' + token,
      'Accept-Language': 'HU'
    };
    if (token == 'token') {
      header = {
        'Accept-Language': 'HU'
      };
    }
    this.httpClient.request(this.method,
      this.config.url,
      {
        responseType: 'blob',
        observe: 'response',
        headers: header
      }
    ).subscribe(response => {
      fileDownloadProcess(response, this.config.fileName);
    });
  }

}
