<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">

  <div class="nx-margin-top-2m">
    <ng-container *ngIf="warehouses.length>1 && !editMode">
      <div class="row justify-content-center">
        <div class="col-md-5 ">
          <div class="row g-0">
            <div class="col-12">
              <nx-formfield nxFloatLabel="always" appearance="auto"
                            nxLabel="{{'product.warehouseSelect' | translate}}">
                <nx-dropdown (nxValueChange)="changeSourceWarehouse($event)" [ngModel]="formModel.sourceWarehouse">
                  <nx-dropdown-item
                    *ngFor="let option of warehouses"
                    [nxValue]="option"
                  >
                    {{ option.name }}
                  </nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="sourceTemplate">

          </ng-container>

        </div>
        <div class="col-md-1 d-flex justify-content-center align-items-center">
          <div style="border-left: 1px solid #000; height: 97%; width:0px"></div>
        </div>
        <div class="col-md-5">
          <div class="row g-0">
            <div class="col-12">
              <nx-formfield nxFloatLabel="always" appearance="auto"
                            nxLabel="{{'product.warehouseSelect' | translate}}">
                <nx-dropdown (nxValueChange)="changeTargetWarehouse($event)" [ngModel]="formModel.targetWarehouse"
                             [disabled]="!formModel.sourceWarehouse">
                  <ng-container *ngFor="let option of warehouses">
                    <nx-dropdown-item *ngIf="formModel.sourceWarehouse && option.id != formModel.sourceWarehouse.id"
                                      [nxValue]="option"
                    >
                      {{ option.name }}
                    </nx-dropdown-item>
                  </ng-container>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="targetTemplate">

          </ng-container>

        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="warehouses.length<2 && !editMode">
      <nx-message nxContext="warning">
        <span class="nx-font-weight-bold">{{ 'stockTransfer.notEnoughWarehouse' |translate }}:</span><br/>
        {{ 'stockTransfer.notEnoughWarehouseDesc' |translate }}
      </nx-message>
    </ng-container>

    <ng-container *ngIf="editMode">
      <div class="row justify-content-center">
        <div class="col-md-5 ">
          <div class="row g-0">
            <ng-container *ngTemplateOutlet="sourceTemplate"></ng-container>
          </div>
        </div>
        <div class="col-md-1 d-flex justify-content-center align-items-center">
          <div style="border-left: 1px solid #000; height: 97%; width:0px"></div>
        </div>
        <div class="col-md-5">
          <div class="row g-0">
            <ng-container *ngTemplateOutlet="targetTemplate"></ng-container>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
</hellp-form-container>




<ng-template #sourceTemplate>
  <div class="row g-2   align-content-start">
    <ng-container *ngIf="formModel.sourceWarehouse">
      <div class="col-12">
        <div class="row g-0">
          <div class="col-4">{{ 'stockTransfer.productName' | translate }}</div>
          <div class="col-2">{{ 'stockTransfer.actualCount' | translate }}</div>
          <div class="col-4">{{ 'stockTransfer.transferCount' | translate }}</div>
          <div class="col-2"></div>
        </div>
      </div>
      <hr class="hr-dark"/>
      <div class="col-12">
        <div class="row g-0">
          <div class="col-4">
            <nx-formfield appearance="outline" nxFloatLabel="always">
              <input nxInput [(ngModel)]="filter['productName']" type="text"
                     placeholder="{{'supplierOrder.productNameFilter' | translate}}"
                     (ngModelChange)="onFilter()"/>
            </nx-formfield>
          </div>
          <div class="col-2"></div>
          <div class="col-4"></div>
          <div class="col-2"></div>
        </div>
      </div>
      <hr class="hr-dark"/>
      <ng-container *ngFor="let sourceStock of availableElements">
        <div class="col-12">
          <div class="row g-0 align-items-center text-middle">
            <div class="col-4">{{ sourceStock.product.product.name }}</div>
            <div class="col-2">{{ sourceStock.count }}</div>
            <div class="col-4">
              <nx-formfield>
                <input nxInput [(ngModel)]="sourceStock.orderCount" type="number"
                       [disabled]="sourceStock.count<1 || sourceStock.addToOrder"
                       placeholder="{{ 'stockTransfer.transferCount' | translate }}" [max]="sourceStock.count"
                       (keyup)="validateMax(sourceStock)"/>
              </nx-formfield>
            </div>
            <div class="col-2 text-end">
              <button nxButton="primary small" (click)="transfer(sourceStock)"
                      [disabled]="!formModel.targetWarehouse || sourceStock.orderCount<1 || sourceStock.addToOrder">
                <nx-icon name="arrow-right"></nx-icon>
              </button>
            </div>
          </div>
        </div>
        <hr class="hr-dark"/>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #targetTemplate>
  <ng-container *ngIf="formModel.elements.length>0">
    <div class="row g-2  vh-75 overflow-y-auto  align-content-start">
      <div class="col-12">
        <div class="row g-0">
          <div class="col-2"></div>
          <div class="col-4">{{ 'stockTransfer.productName' | translate }}</div>
          <div class="col-2">{{ 'stockTransfer.actualCountInTarget' | translate }}</div>
          <div class="col-2">{{ 'stockTransfer.transferCountToTarget' | translate }}</div>
          <div class="col-2" *ngIf="!editMode">{{ 'stockTransfer.newStock' | translate }}</div>
          <div class="col-2" *ngIf="editMode"></div>
        </div>
      </div>
      <hr class="hr-dark">
      <ng-container *ngFor="let stockTransferElement of formModel.elements">
        <div class="col-12">
          <div class="row g-0">
            <div class="col-2">
              <button nxButton="primary small" (click)="remove(stockTransferElement)"
                      [disabled]="stockTransferElement.status != SupplierOrderStatus.DRAFT">
                <nx-icon name="arrow-left"></nx-icon>
              </button>
            </div>
            <div class="col-4">{{ stockTransferElement.target.product.product.name }}</div>
            <div class="col-2">{{ stockTransferElement.target.count }}</div>
            <div class="col-2">{{ stockTransferElement.count }}</div>
            <div class="col-2" *ngIf="!editMode">{{ stockTransferElement.target.count + stockTransferElement.count }}
            </div>
            <div class="col-2" *ngIf="editMode">
              <ng-container *ngIf="stockTransferElement.status == SupplierOrderStatus.DRAFT">
                <button nxIconButton="primary small" (click)="approve(stockTransferElement)">
                  <nx-icon name="thumbs-up"></nx-icon>
                </button>
              </ng-container>

              <ng-container *ngIf="stockTransferElement.status != SupplierOrderStatus.DRAFT">
                {{ 'inventory.' + stockTransferElement.status | translate }}
              </ng-container>
            </div>
          </div>
        </div>
        <hr class="hr-dark">
      </ng-container>
    </div>
  </ng-container>
</ng-template>


