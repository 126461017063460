import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {SupplierModel} from "../../../services/supplier/supplier.model";
import {SupplierDtoTypes, SupplierService} from "../../../services/supplier/supplier.service";
import {FormPage} from "../../../utilities/abstract/form-page";
import {FormlyFormComponent} from "../../../components/formly-form/formly-form.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.css']
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.SUPPLIER,
})
export class SupplierFormComponent extends FormPage<SupplierModel, SupplierService> implements OnInit, OnDestroy {

  @ViewChild('formly') override formlyComponent!: FormlyFormComponent;

  constructor(service: SupplierService, route: ActivatedRoute) {
    super(route, service, SupplierModel, service.formlyService.supplierFormConfig);
  }

  ngOnInit(): void {
    this.onInit(SupplierDtoTypes.SupplierDTO);
  }

  protected override afterModelIsSetProcess() {
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

}
