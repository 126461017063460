<ng-container *ngIf="model">
  <div class="d-flex align-items-top">
    <div>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'category.name' | translate }}</nx-data-display-label>
        {{ model.name }}
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'category.shortName' | translate }}</nx-data-display-label>
        {{ model.shortName }}
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'category.slug' | translate }}</nx-data-display-label>
        <nx-link>
          <a href="{{publicUrl}}/biotermek/{{model.slug}}" target="_blank">{{ model.slug }}</a>
        </nx-link>
      </nx-data-display>
      <nx-data-display class="nx-margin-bottom-2xs">
        <nx-data-display-label>{{ 'category.description' | translate }}</nx-data-display-label>
        {{ model.description }}
      </nx-data-display>
    </div>
    <img loading="lazy" *ngIf="model.attachment" [src]="model.attachment.src" alt="{{model.name}}"
         style="height: 80px; object-fit: contain"
         class="img-thumbnail rounded" (error)="model.attachment.src = 'assets/img/default.jpg'">
  </div>
</ng-container>
