import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CrudService } from '../../../utilities/service/crud.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hellp-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('600ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('600ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class FormContainerComponent implements OnInit {
  private readonly _destroyed = new Subject<void>();
  @Input() editMode = false;
  @Input() service!: CrudService<any, any>;
  @Input() componentIsReady = false;
  @Input() showControlButtons: boolean = true;
  @Input() withoutCancel: boolean = false;
  @Output() componentIsReadyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    if (this.service) {
      this.service.$validationError
        .pipe(
          takeUntil(this._destroyed),
          filter((result) => result.length > 0),
        )
        .subscribe((result) => {
          this.service.notificationService.showErrors(
            'general.error',
            result.map((res) => res.message),
          );
          setTimeout(() => this.clearErrors(), 8000);
        });
    }
  }

  clearErrors() {
    this.service.$validationError.next([]);
  }
}
