export const COOKIE_KEYS = {
  PRODUCT_STEP_KEY: "PRODUCT_STEP"
}

export enum ProductStepNumber {
  Base = 0,
  Supplier = 1,
  TagAndConfig = 2,
  MainImage = 3,
  Gallery = 4,
  RelatedProducts = 5,
  Summary = 6,


}