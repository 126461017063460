<div nxModalContent>
  <nx-card class="w-100">
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'general.addNoteToModel' | translate}}
      </h3>
    <forms-dynamic-attribute-form [(dynamicAttribute)]="dynamicAttribute"></forms-dynamic-attribute-form>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | translate}}
  </button>
  <button
    [nxModalClose]="dynamicAttribute"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.add' | translate}}
  </button>
</div>
