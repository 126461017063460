import {Component, Inject} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {PlantCultureIssueType} from "../../../services/technologies/plant-culture-issue-type.model";
import {PartOfMonthByIndex} from "../../../utilities/types";
import {MonthByIndex} from "../../../services/warehouse/warehouse.model";
import {PlantCultureType} from "../../../services/technologies/plant-culture.model";

@Component({
  selector: 'app-add-plant-culture-issue-dialog',
  templateUrl: './add-plant-culture-issue-type-dialog.component.html',
  styleUrls: ['./add-plant-culture-issue-type-dialog.component.css']
})
export class AddPlantCultureIssueTypeDialogComponent {
  constructor(@Inject(NX_MODAL_DATA) public plantCultureIssueType: PlantCultureIssueType) {
  }

  protected readonly PartOfMonthByIndex = PartOfMonthByIndex;
  protected readonly MonthByIndex = MonthByIndex;
  protected readonly PlantCultureType = PlantCultureType;
}
