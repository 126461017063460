<hellp-card-container>
  <ng-container *ngIf="embeddedMode">
    <nx-switcher class="nx-margin-bottom-m" [(ngModel)]="showUpload" labelSize="small"
    >{{ 'tag.showUpload' | translate }}
    </nx-switcher
    >
  </ng-container>
  <div nxRow="" nxRowJustify="center" nxRowAlignContent="center" nxRowAlignItems="center">

    <ng-container *ngIf="(embeddedMode && showUpload) || !embeddedMode">
      <div nxCol="12,12,6">
        <nx-formfield nxLabel="{{'product.tagInput' | translate}}" appearance="auto">
          <input nxInput #addTagInput appValidatedInput required type="text" name="tagName" [(ngModel)]="tagName"
                 (keyup)="changeWorkName(addTagInput, tagNameInput)"/>
          <span nxFormfieldHint
                nxTooltip="{{'product.tagInputHint' | translate}}">{{ 'product.tagInputHint' | translate }}</span>

          <nx-error class="nx-margin-bottom-m" nxFormfieldError
                    *ngIf="validationError.name"> {{ validationError.name | translate }}
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield nxLabel="{{'product.tagName' | translate}}" appearance="auto">
          <input nxInput #tagNameInput (focusin)="focusWorkName()"/>
          <span nxFormfieldHint
                nxTooltip="{{'product.tagNameHint' | translate}}">{{ 'product.tagNameHint' | translate }}</span>
        </nx-formfield>
      </div>
      <div nxCol="12,12,8" class="nx-margin-y-m">
        <nx-radio-group name="tagType" [(ngModel)]="tagType">
          <nx-radio nxValue="primary" class="float-start nx-margin-right-m"
                    labelSize="small">{{ 'tag.primary' | translate }}
          </nx-radio>
          <nx-radio nxValue="secondary" class="float-start nx-margin-right-m"
                    labelSize="small">{{ 'tag.secondary' | translate }}
          </nx-radio>
          <nx-radio nxValue="base" class="float-start nx-margin-right-m"
                    labelSize="small">{{ 'tag.base' | translate }}
          </nx-radio>
        </nx-radio-group>
      </div>
      <div nxCol="12,12,4">
        <nx-switcher [(ngModel)]="isKeyword" labelSize="small">
          {{ 'tag.isKeyword' | translate }}
        </nx-switcher>
      </div>
      <div nxCol="12">
        <button
          nxButton="primary small"
          (click)="addTag(addTagInput, tagNameInput)"
          class="nx-margin-right-2xs nx-margin-bottom-s"
          type="button"
        >
          {{ 'product.addTag' | translate }}
        </button>
      </div>
      <hr>
    </ng-container>
    <div nxCol="12" *ngIf="embeddedMode && existingTags && addedTags">
      <nx-formfield nxLabel="{{'product.existingTags' | translate}}">
        <nx-multi-select
          filter
          filterPlaceholder="{{'general.filter' | translate}}"
          [options]="existingTags"
          selectLabel="name"
          selectValue="id"
          [(ngModel)]="addedTags"
          (selectionChange)="tagSelectChange()"
        ></nx-multi-select>
      </nx-formfield>
    </div>

  </div>
  <div nxRow="" nxRowJustify="start" nxRowAlignContent="center" nxRowAlignItems="start">
    <div nxCol="12" class="">
      <ng-container *ngFor="let tag of tags; let i = index">
        <div class="d-inline-flex justify-content-center align-items-center m-1 tag {{'tag-'+tag.html.type}} me-3">
          <nx-icon name="hashtag" *ngIf="tag.isKeyword"></nx-icon>
          {{ embeddedMode ? tag.html.nxTaglistLabel : tag.name }}
          <a href="javascript:void(0)" (click)="removeTag(tag.id)">
            <nx-icon name="x"></nx-icon>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</hellp-card-container>
