<nx-card class="viewer">
  <page-list-view-header
    iconName="newspaper"
    [newConsumer]="newConsumer()"
    [title]="'article.headline'"
  ></page-list-view-header>
  <div class="nx-margin-top-2m">
    <ng-container *ngIf="componentIsReady">
      <hellp-table id="magazine-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                   [showAsList]="mobile"></hellp-table>
    </ng-container>

    <ng-container *ngIf="!componentIsReady">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </div>
</nx-card>

<ng-template #statusTpl let-value="value">
  <span
    class="badge-sm {{value == MagazineStatus.DRAFT?'badge-draft':value == MagazineStatus.PUBLISHED?'badge-published':value == MagazineStatus.INACTIVE?'badge-inactive':'badge-in-progress'}}">
  {{'magazine.' + value | translate}}
    </span>
</ng-template>

