<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady" [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div nxRow="">
    <div nxCol="12" class="nx-margin-y-2m">
      <hellp-stepper-container [steps]="steps"></hellp-stepper-container>
    </div>
    <div nxCol="12" class="text-end">
      <button
        nxButton="primary small"
        (click)="onSave()"
        class="nx-margin-bottom-s"
        type="button"
      >
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</hellp-form-container>

<ng-template #baseData>
  <div nxRow="">
    <div nxCol="12,12,12,6">
      <div class="card p-3 mb-4 box-shadow">
        <nx-formfield nxLabel="{{'article.title' | translate}}" appearance="auto">
          <input nxInput appValidatedInput name="title" required [(ngModel)]="formModel.title"/>
          <nx-error class="nx-margin-bottom-m" nxFormfieldError
                    *ngIf="validationError.title"> {{ validationError.title | translate }}
          </nx-error>
          <span nxFormfieldHint>255/{{ formModel.title.length }}</span>
        </nx-formfield>

        <nx-formfield nxLabel="{{'article.shortDescription' | translate}}" appearance="auto">
                <textarea required nxInput appValidatedInput name="title" [(ngModel)]="formModel.shortDescription"
                          style="min-height: 200px;"
                          #exampleErrorNgModelHint="nxInput"></textarea>

          <nx-error nxFormfieldError
                    *ngIf="validationError.shortDescription"> {{ validationError.shortDescription | translate }}
          </nx-error>
          <span nxFormfieldHint>255/{{ formModel.shortDescription.length }}</span>
        </nx-formfield>
      </div>
    </div>
    <div nxCol="12,12,12,6">
      <forms-tag-form [tags]="formModel.tagList" (tagsChange)="tagsChange($event)"></forms-tag-form>
      <nx-error nxFormfieldError *ngIf="validationError.taglist"> {{ validationError.taglist | translate }}</nx-error>
    </div>
  </div>
  <div nxRow="" class="nx-margin-top-2m">
    <div nxCol="12,12,12,6">
      <div class="card p-3 mb-4 box-shadow">
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-m">
          {{ 'article.coverPhoto' | translate }}
        </h3>
        <app-image-upload [(selectedAttachment)]="formModel.coverPhoto.attachment"
                          [secondPanelExpanded]="true"></app-image-upload>
        <nx-error class="nx-margin-top-m" nxFormfieldError
                  *ngIf="validationError.coverPhoto"> {{ validationError.coverPhoto | translate }}
        </nx-error>
      </div>
    </div>
    <div nxCol="12,12,12,6">

      <div class="card p-3 mb-4 box-shadow">
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-m">
          {{ 'article.author' | translate }}
        </h3>
        <nx-formfield nxLabel="{{'article.existingAuthors' | translate}}" appearance="auto">
          <nx-dropdown appValidatedInput required [(ngModel)]="formModel.authorId" (selectionChange)="changeAuthor()">
            <nx-dropdown-item
              *ngFor="let option of authors"
              [nxValue]="option.id"
            >{{ option.name }}
            </nx-dropdown-item>
          </nx-dropdown>
          {{ formModel.authorId }}
          <nx-error nxFormfieldError *ngIf="validationError.authorId"> {{ validationError.authorId | translate }}
          </nx-error>
        </nx-formfield>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #contentData>
  <div class="card p-3 mb-4 box-shadow">
    <div nxRow="">
      <div nxCol="12">
        <quill-editor [required]="true" appValidatedInput [(ngModel)]="formModel.content"></quill-editor>
        <nx-error nxFormfieldError *ngIf="validationError.content"> {{ validationError.content | translate }}</nx-error>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #summaryData>
  <div class="card p-3 mb-4 box-shadow">
    <display-magazine [magazine]="formModel"></display-magazine>
  </div>
</ng-template>
