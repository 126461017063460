import {Injectable} from '@angular/core';
import {Patch, PathVariable, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {ScrappingElement, ScrappingModel} from "./scrapping.model";
import {Inventory, InventoryElement} from "../inventory/inventory.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/scrappings')
export class ScrappingRepository extends CrudRepository<ScrappingModel> {
  constructor() {
    super();
  }

  @Patch('/{id}/elements/{elementId}/approve')
  approveElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/process')
  process(@PathVariable() id: number) {
    return this.createRequest().send();
  }


  @Patch('/{id}/elements/add/')
  addElement(@PathVariable() id: number, @RequestBody() scrapping: ScrappingModel) {
    return this.createRequest().send();
  }

  @Patch('/{id}/approve')
  approve(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/update')
  updateElements(@PathVariable() id: number, @RequestBody() elements: ScrappingModel) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/{elementId}/remove')
  removeElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }

}
