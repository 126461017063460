import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ListPage} from "../../../utilities/abstract/list-page";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {lastValueFrom} from "rxjs";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {AdminLayout} from "../../../layouts/admin-layout/admin.layout";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {Utilities} from "../../../utilities/utilities";
import {
  PlantCultureIssueTypeDtoType,
  PlantCultureIssueTypeService
} from "../../../services/technologies/plant-culture-issue-type.service";
import {PlantCultureIssueType} from "../../../services/technologies/plant-culture-issue-type.model";
import {
  AddPlantCultureIssueTypeDialogComponent
} from "../../../components/dialogs/add-plant-culture-issue-type-dialog/add-plant-culture-issue-type-dialog.component";
import {PlantCultureType} from "../../../services/technologies/plant-culture.model";

@Component({
  selector: 'app-plant-culture-issue-type-list',
  templateUrl: './plant-culture-issue-type-list.component.html',
  styleUrls: ['./plant-culture-issue-type-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.PLANT_CULTURE_ISSUE_TYPE,
  layout: AdminLayout
})
export class PlantCultureIssueTypeListComponent extends ListPage<PlantCultureIssueType, PlantCultureIssueTypeService> implements OnInit {
  @ViewChild('monthTpl') monthTpl!: TemplateRef<any>;
  @ViewChild('translateTpl') translateTpl!: TemplateRef<any>;

  constructor(service: PlantCultureIssueTypeService, private readonly dialogService: NxDialogService) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit')),
      new TableAction('trash', this.deleteConsumer(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('name', await this.getTranslate('general.name')),
      TableCol.newString('type', await this.getTranslate('plantCulture.type')).template(() => this.translateTpl)
        .filterable(TableFilter.newSelect(Object.keys(PlantCultureType).map(t => ({
          label: this.translateService.instant('plantCulture.' + t),
          value: t
        })))),
      TableCol.newString('startOfMonth', await this.getTranslate('technologies.monthStart')).template(() => this.monthTpl),
      TableCol.newString('endOfMonth', await this.getTranslate('technologies.monthEnd')).template(() => this.monthTpl),
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(PlantCultureIssueTypeDtoType.SlimPlantCultureIssueTypeDTO));
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }


  protected override editProcess(model: PlantCultureIssueType) {
    this.dialogService.open(
      AddPlantCultureIssueTypeDialogComponent,
      Utilities.getDefaultModalConfig(model),
    ).afterClosed().subscribe(result => {
      if (result instanceof PlantCultureIssueType) {
        try {
          this.service.validate(result);

          this.service.update(result).subscribe(res => {
            this.service.notificationService.showSuccess('general.success', 'general.successUpdate');
            this.loadData();
          });
        } catch (error: any) {
          this.service.validationErrorHandler(error);
          this.editProcess(result);
        }
      }
    });
  }

  protected override newProcess(initialValue: PlantCultureIssueType = new PlantCultureIssueType()) {
    this.dialogService.open(
      AddPlantCultureIssueTypeDialogComponent,
      Utilities.getDefaultModalConfig(initialValue),
    ).afterClosed().subscribe(result => {
      if (result instanceof PlantCultureIssueType) {
        try {
          this.service.validate(result);
          this.service.save(result).subscribe(res => {
            this.loadData();
            this.service.notificationService.confirm('plantCulture.nextNew', 'plantCulture.addNextIssueType', 'general.yes', 'general.no').then(r => {
              if (r.isConfirmed) {
                this.newProcess();
              }
            })
          });
        } catch (error: any) {
          this.service.validationErrorHandler(error);
          this.newProcess(result);
        }
      }
    });
  }
}
