<ng-container *ngIf="componentIsReady">
  <nx-card class="viewer">

    <ng-container *ngIf="!isEdit ">
      <page-list-view-header
        [newConsumer]="edit()"
        newIcon="pencil"
        title="{{'orderInfo.headline'}}"
        iconName="info"
      ></page-list-view-header>
      <ng-container *ngIf=" formModel.id>0">
        <div class="row align-items-top">
          <div class="col-md-12 nx-margin-top-2m">
            <h5 nxHeadline="subsection-small">{{ 'orderInfo.icePackPrice' | translate }}</h5>
            <p class="mt-2">{{ formModel.icePackPrice.price | grossPrice }} </p>
          </div>
          <div class="col-md-4 mx-auto nx-margin-top-m">
            <h3 nxHeadline="subsection-large" class="mt-3">{{ 'cart.cashLimitDesc' | translate }}</h3>
            <table class="table thead-dark table-striped table-bordered mt-3">
              <thead>
              <tr>
                <th>{{ 'cart.sumPriceLimit' | translate }}</th>
                <th>{{ 'cart.sumPriceValue' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let cashPrice of formModel.cashPrices  ">
                <td>{{ 'cart.cartSumPrice'| translate }} < {{ cashPrice.key }}</td>
                <td>{{ cashPrice.extraPrice.price | grossPrice }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4 mx-auto nx-margin-top-m">
            <h3 nxHeadline="subsection-large" class="mt-3">{{ 'cart.deliveryPricesDesc' | translate }}</h3>
            <table class="table thead-dark table-striped table-bordered mt-3">
              <thead>
              <tr>
                <th>{{ 'cart.deliveryPriceWeight' | translate }}</th>
                <th>{{ 'cart.deliveryPriceValue' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let deliveryPrice of formModel.deliveryPrices  ">
                <td>{{ 'cart.cartSumWeight'| translate }} < {{ deliveryPrice.key }}</td>
                <td>{{ deliveryPrice.extraPrice.price | grossPrice }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-4 mx-auto nx-margin-top-m">
            <h3 nxHeadline="subsection-large" class="mt-3">{{ 'orderInfo.deliveryPartners' | translate }}</h3>
            <div class="row align-items-center mt-3">
              <ng-container *ngFor="let deliveryPartner of formModel.deliveryPartners">
                <div class="col-3 ms-auto text-end my-2">
                  <img loading="lazy" class="partner-logo border-radius-lg" [src]="deliveryPartner.logo.src"
                       alt="{{deliveryPartner.name}}"
                       style="width: 100px;">
                </div>
                <div class="col-9 me-auto">
                  <p>
                    {{ deliveryPartner.shortDescription }}
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="formModel.id<1">
        <p>{{ 'orderInfo.noData' | translate }}</p>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isEdit">
      <page-form-header #formHeader iconName="info" [model]="formModel"
                        [cancelSupplier]="cancel()" [saveSupplier]="save()"
                        [modalOptions]="toolbarAddOptions"
      ></page-form-header>
      <div nxRow>

        <div nxCol="12,12,6" class="nx-margin-top-2m">
          <div nxRow="" nxRowJustify="center" nxRowAlignItems="center">
            <div nxCol="12">
              <nx-formfield nxLabel="{{'orderInfo.addNewCashMap' | translate}}" appearance="auto" nxFloatLabel="auto">
                <input nxInput type="number" [(ngModel)]="newKeyValue"/>
              </nx-formfield>
            </div>
            <div nxCol="10">
              <nx-formfield nxLabel="{{'orderInfo.addNewCashMapPrice' | translate}}" appearance="auto"
                            nxFloatLabel="auto">
                <input nxInput type="number" [(ngModel)]="cashMapPrice"/>
              </nx-formfield>
            </div>
            <div nxCol="2" nxAlignSelf="center" class="text-end">
              <button nxIconButton="primary small" (click)="addCashPrice()">
                <nx-icon name="plus"></nx-icon>
              </button>
            </div>
            <div nxCol="12">
              <table nxTable class="nx-table--scrollable">
                <thead>
                <tr nxTableRow>
                  <th nxHeaderCell>{{ 'orderInfo.cashPriceKey' | translate }}</th>
                  <th nxHeaderCell>{{ 'orderInfo.cashPriceValue' | translate }}</th>
                  <th nxHeaderCell></th>
                </tr>
                </thead>
                <tbody>
                <tr nxTableRow *ngFor="let cashPrice of formModel.cashPrices">
                  <td nxTableCell>{{ cashPrice.key }}</td>
                  <td
                    nxTableCell>{{ cashPrice.extraPrice.price.net }} {{ cashPrice.extraPrice.price.currency }}
                  </td>
                  <td nxTableCell>
                    <button nxIconButton="cta small" (click)="removeCashPrice(cashPrice.key)">
                      <nx-icon name="trash"></nx-icon>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div nxCol="12,12,6" class="nx-margin-top-2m">
          <forms-price-form title="{{'orderInfo.icePackPrice' }}"
                            [(priceModel)]="formModel.icePackPrice.price"></forms-price-form>
        </div>

        <div nxCol="12,12,6" class="nx-margin-top-2m">
          <div nxRow="" nxRowJustify="center" nxRowAlignItems="center">
            <div nxCol="12">
              <nx-formfield nxLabel="{{'orderInfo.addNewDeliveryWeight' | translate}}" appearance="auto"
                            nxFloatLabel="auto">
                <input nxInput type="number" [(ngModel)]="newDeliveryKey"/>
              </nx-formfield>
            </div>
            <div nxCol="10">
              <nx-formfield nxLabel="{{'orderInfo.addNewDeliveryWeightPrice' | translate}}" appearance="auto"
                            nxFloatLabel="auto">
                <input nxInput type="number" [(ngModel)]="newDeliveryValue"/>
              </nx-formfield>
            </div>
            <div nxCol="2" nxAlignSelf="center" class="text-end">
              <button nxIconButton="primary small" (click)="addDeliveryPrice()">
                <nx-icon name="plus"></nx-icon>
              </button>
            </div>
            <div nxCol="12">
              <table nxTable class="nx-table--scrollable">
                <thead>
                <tr nxTableRow>
                <tr>
                  <th nxHeaderCell>{{ 'cart.deliveryPriceWeight' | translate }}</th>
                  <th nxHeaderCell>{{ 'cart.deliveryPriceValue' | translate }}</th>
                  <th nxHeaderCell></th>
                </tr>
                </thead>
                <tbody>
                <tr nxTableRow *ngFor="let deliveryPrice of formModel.deliveryPrices">
                  <td nxTableCell>{{ deliveryPrice.key }}</td>
                  <td
                    nxTableCell>{{ deliveryPrice.extraPrice.price.net }} {{ deliveryPrice.extraPrice.price.currency }}
                  </td>
                  <td nxTableCell>
                    <button nxIconButton="cta small" (click)="removeDeliveryPrice(deliveryPrice.key)">
                      <nx-icon name="trash"></nx-icon>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div nxCol="12,12,6" class="nx-margin-top-2m">
          <h3 nxHeadline="subsection-small" class="d-flex justify-content-between mx-auto nx-margin-bottom-2m">
            <span>{{ 'orderInfo.deliveryPartners' | translate }}</span>
<!--            <button nxIconButton="primary small" (click)="addNewDeliveryPartner()">-->
<!--              <nx-icon name="plus"></nx-icon>-->
<!--            </button>-->
          </h3>
          <hellp-table id="purchase-delivery-partner" [showFilter]="false" [rows]="deliveryPartners"
                       [colDefinitions]="colDef"
                       [actionConfig]="actionDef"></hellp-table>
        </div>

      </div>

    </ng-container>
  </nx-card>
</ng-container>
<ng-container *ngIf="!componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>
<ng-template #logoTpl let-value="value">
  <img loading="lazy" src="{{value.src}}" alt="logo" style="width: 100px;">
</ng-template>
