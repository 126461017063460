import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {ScrappingRepository} from "./scrapping.repository";
import {ScrappingElement, ScrappingModel} from "./scrapping.model";
import {Inventory} from "../inventory/inventory.model";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum ScrappingDtoType {
  ScrappingDTO = 'ScrappingDTO',
  SlimScrappingDTO = 'SlimScrappingDTO',
}

@Injectable({
  providedIn: 'root'
})
export class ScrappingService extends CrudService<ScrappingModel, ScrappingRepository> {

  constructor(repo: ScrappingRepository) {
    super(ROUTING.SCRAPPING, repo, ScrappingModel, PageMetaDataConf.SCRAPPING);
  }

  override validate(model: ScrappingModel) {

  }

  addElement(scrappingId: number, scrappingModel: ScrappingModel) {
    return this.repo.addElement(scrappingId, scrappingModel);
  }

  approveElement(scrapping: ScrappingModel, scrappingElement: ScrappingElement) {
    return this.repo.approveElement(scrapping.id, scrappingElement.id);
  }

  process(row: Inventory) {
    return this.repo.process(row.id);
  }

  approve(row: Inventory) {
    return this.repo.approve(row.id);
  }

  updateElements(inventory: ScrappingModel) {
    return this.repo.updateElements(inventory.id, inventory);
  }

  removeElement(scrappingId: number, element: ScrappingElement) {
    return this.repo.removeElement(scrappingId, element.id);
  }
}

