<ng-container *ngIf="model && componentIsReady">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12">
        <nx-card class="viewer">
          <page-show-tool-bar
            iconName="blog"
            [title]="model.title"
            [backSupplier]="back()"
            [customToolbarActions]="toolbar"
          ></page-show-tool-bar>
          <div nxRow="" class="nx-margin-top-2xs">
            <div nxCol="12">
              <display-magazine [magazine]="model"></display-magazine>
            </div>
          </div>
        </nx-card>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>
