<hellp-list-container [service]="service" [(componentIsReady)]="componentIsReady" [hasNewConsumer]="false">
  <hellp-table id="banner-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
               [showAsList]="mobile"></hellp-table>
</hellp-list-container>

<ng-template #statusTpl let-value="value">
  <span>
  {{ 'goodsLanding.listStatus.' + value | translate }}
    </span>
</ng-template>
