import {ConvertFrom} from "@hellp/parser";

export class PostPayload<MODEL> {
  type?: string;
  token?: string;
  dtoType: string;

  @ConvertFrom
  attribute: MODEL;

  constructor(attribute: MODEL, dtoType?: string, token?: string) {
    this.dtoType = dtoType ? dtoType : 'BaseDTO';
    this.attribute = attribute;
    this.token = token;
  }
}
