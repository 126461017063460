import {Component, Inject} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {ProductHistoryModel, ProductModel} from "../../../services/product/product.model";
import {HellparserService} from "@hellp/parser";

@Component({
  selector: 'modal-product-details',
  templateUrl: './product-details-modal.component.html',
  styleUrls: ['./product-details-modal.component.css']
})
export class ProductDetailsModalComponent {
  model?: ProductModel

  constructor(@Inject(NX_MODAL_DATA) readonly productHistory: ProductHistoryModel, private parser: HellparserService) {

    this.model = this.parser.parseApiDataToModel(productHistory.published, ProductModel)

    if (this.model) {
      this.model.history = ProductHistoryModel.createNewInstanceFromSelf(productHistory, new ProductModel());
    }
  }
}
