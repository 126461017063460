import {CrudService} from "../service/crud.service";
import {PermissionCheck} from "./permission-check";
import {Subject, Subscription, takeUntil} from "rxjs";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {ActivatedRoute} from "@angular/router";
import {BaseModel} from "./base.model";
import {FormlyFormComponent} from "../../components/formly-form/formly-form.component";
import {QueryList} from "@angular/core";
import {ValidatedInputDirective} from "../../directives/validated-input.directive";

export abstract class FormPage<MODEL extends BaseModel, SERVICE extends CrudService<MODEL, any>> extends PermissionCheck {
  protected readonly _destroyed = new Subject<void>();
  inputs!: QueryList<ValidatedInputDirective>;
  componentIsReady = false;
  protected sub!: Subscription;
  formModel: MODEL
  formlyComponent?: FormlyFormComponent;
  mode: 'edit' | 'new' = "new";
  id?: string;

  validationError: any = {};

  protected constructor(protected route: ActivatedRoute,
                        protected service: SERVICE,
                        protected formClazz: { new(): MODEL },
                        protected fields: FormlyFieldConfig[]) {
    super();
    this.formModel = new formClazz();
  }

  onInit(dto: string): void {
    this.route.params.pipe(takeUntil(this._destroyed)).subscribe(async params => {
      this.id = params['id'];
      this.loadModel(dto);
    });
  }

  public loadModel(dto: string) {
    if (this.id) {
      this.service.getById(parseInt(this.id), dto)
        .pipe(takeUntil(this._destroyed))
        .subscribe(result => {
          if (result == undefined) {
            this.service.notificationService.confirm('error.notFound', 'error.editNotPossible', 'general.goNew', 'general.goListview').then(result => {
              if (result.isConfirmed) {
                this.service.navigateToNew();
              } else {
                this.service.navigateToBase();
              }
            });
          } else {
            this.formModel = result;
            this.mode = "edit";
          }

          this.afterModelIsSetProcess();
        });
    } else {
      this.afterModelIsSetProcess();
    }
  }

  protected afterModelIsSetProcess() {
  }

  saveProcess(): void {
    this.service.save(this.formModel).subscribe((result: any) => {
      if (result) {
        this.service.notificationService.showSuccess('general.success', 'general.successSave');
        this.savePostProcess();
      }
    });
  }

  protected savePostProcess() {
    this.service.navigateToBase();
  }

  updateProcess(): void {
    this.service.update(this.formModel).subscribe((result: any) => {
      if (result) {
        this.service.notificationService.showSuccess('general.success', 'general.successSave');
        this.savePostProcess();
      } else {
        this.service.notificationService.showError('general.error', 'error.duringUpdate');
      }
    });
  }

  save(): () => void {
    return () => {
      if ((this.formlyComponent && this.formlyComponent.form.valid) || (!this.formlyComponent)) {
        try {
          this.savePreProcess();
          this.service.validate(this.formModel);
          if (this.formModel.id && this.formModel.id > 0) {
            this.updateProcess();
          } else {
            this.saveProcess();
          }
        } catch (error: any) {
          this.service.validationErrorHandler(error);
        }
      } else {
        this.service.notificationService.showWarning('general.warning', 'error.missingDataFromForm');
      }
    };
  }

  cancel(): () => void {
    return () => {

    };
  }

  onDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this._destroyed.next();
    this._destroyed.complete();
    this._destroyed.unsubscribe();
  }

  protected savePreProcess() {

  }

//TODO 2024-06-6 (hans): Ide jönnek a jó dolgok
  onSave() {
    if ((this.formlyComponent && this.formlyComponent.form.valid) || (!this.formlyComponent)) {
      try {
        this.savePreProcess();
        this.service.validate(this.formModel);
        if (this.formModel.id && this.formModel.id > 0) {
          this.updateProcess();
        } else {
          this.saveProcess();
        }
      } catch (error: any) {
        this.service.validationErrorHandler(error);
      }
    } else {
      this.service.notificationService.showWarning('general.warning', 'error.missingDataFromForm');
    }
  }

  onCancel() {
    this.service.navigateToBase();
  }

  initErrorHandlingOnInput() {
    this.service.$validationError.pipe(takeUntil(this._destroyed)).subscribe(result => {
      if (this.inputs) {
        this.inputs.forEach(item => {
          item.ngControl?.control?.markAsTouched();
        });
      }
      this.validationError = {};
      result.forEach(error => {
        this.validationError[error.attribute] = error.message;
      });
    });
  }

}
