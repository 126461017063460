import {EnvironmentInterface} from "./EnvironmentInterface"

export const environment: EnvironmentInterface = {
  production: false,
  cookiesValidityHours: 10,
  profile: 'DEV',
  keycloak: {
    issuer: 'https://auth.biokiskert.hu/auth/',
    realm: 'biokiskert-uat',
    clientId: 'frontend',
  },

  serviceUrl: 'https://uapi.biokiskert.hu/api',
  attachmentServiceUrl: 'https://uapi.biokiskert.hu/api',
  wsServiceUrl: 'https://uapi.biokiskert.hu/ws',
  publicUrl: 'https://uat.biokiskert.hu',
  adminUrl: 'https://uadmin.biokiskert.hu',

  defaultResizeHeight: 200,
  defaultResizeWidth: 200,


  keycloakUserUrl: 'admin/master/console/#/realms/biokiskert-uat/users/'
};
