import {Directive, Optional, Self} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[appValidatedInput]'
})
export class ValidatedInputDirective {

  constructor(@Optional() @Self() public ngControl: NgControl) {
  }

}
