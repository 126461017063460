import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ListPage } from '../../../utilities/abstract/list-page';
import { TableAction, TableCol } from '@hellp/table';
import { takeUntil } from 'rxjs';
import { CategoryModel } from '../../../services/category/category.model';
import { CategoryService } from '../../../services/category/category.service';
import { Page } from '@hellp/routing';
import { AdminGuard } from '../../../layouts/admin-layout/admin.guard';
import { ROUTING } from '../../../utilities/routing';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
@Page({
  path: '/',
  pathPrefix: ROUTING.CATEGORY,
  data: { title: 'title.categories' },
  authGuard: AdminGuard,
})
export class CategoryListComponent extends ListPage<CategoryModel, CategoryService> implements OnInit, OnDestroy {
  @ViewChild('image') imageTpl!: TemplateRef<any>;
  showFilter = false;
  nameFilter?: string;
  filteredModels: CategoryModel[] = [];
  tplId: string = 'templateFile';

  constructor(service: CategoryService) {
    super(service);
  }

  ngOnInit(): void {
    this.initTable();
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  loadData(): void {
    this.service
      .getAllParent()
      .pipe(takeUntil(this._destroyed))
      .subscribe((result) => {
        this.models = result;
        this.filteredModels = result;
        this.componentIsReady = true;
      });
  }

  override async initTable() {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show')),
      new TableAction(
        'pencil',
        this.editConsumer(),
        await this.getTranslate('table.action.edit'),
        this.rowPermission(),
      ),
      new TableAction(
        'trash',
        this.deleteConsumer(),
        await this.getTranslate('table.action.delete'),
        this.rowPermission(),
      ),
    ];

    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('name', await this.getTranslate('category.name')),
      TableCol.newString('shortName', await this.getTranslate('category.shortName')),
      TableCol.newString('subcategories.length', await this.getTranslate('category.subCategoryCount')),
    ];
  }

  filter() {
    if (this.nameFilter && this.models) {
      this.filteredModels = this.models.filter((model) => model.filterByName(this.nameFilter));
    }
  }
}
