import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShowPage} from "../../../utilities/abstract/show-page";
import {ActivatedRoute} from "@angular/router";
import {MagazineModel} from "../../../services/article/magazine.model";
import {MagazineDtoTypes, MagazineService} from "../../../services/article/magazine.service";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";

@Component({
  selector: 'app-article-show',
  templateUrl: './magazine-show.component.html',
  styleUrls: ['./magazine-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.MAGAZINE,
})
export class MagazineShowComponent extends ShowPage<MagazineModel, MagazineService> implements OnInit, OnDestroy {


  constructor(service: MagazineService, route: ActivatedRoute) {
    super(service, route);
  }

  ngOnInit() {
    this.onInit(MagazineDtoTypes.MagazineDTO);
  }


  ngOnDestroy(): void {
    this.onDestroy();
  }
}
