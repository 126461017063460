<div class="card p-3 mb-4 box-shadow">
  <div nxRow="">
    <div nxCol="12">
      <nx-formfield nxLabel="{{'product.supplier' |translate}}" appearance="auto">
        <nx-dropdown appValidatedInput required [(ngModel)]="supplierId" (selectionChange)="refreshSupplier()" [nxShowFilter]="true"
                     nxFilterPlaceholder="{{'general.typeToSearch' | translate}}">
          <nx-dropdown-item
            *ngFor="let supplier of suppliers"
            [nxValue]="supplier.id"
          > {{ supplier.companyName }}
          </nx-dropdown-item>
        </nx-dropdown>
      </nx-formfield>
    </div>
    <ng-container *ngIf="model">
      <div nxCol="12,12,12,4">
        <nx-formfield nxLabel="{{'product.supplierItemNr' |translate}}" appearance="auto">
          <input type="text" nxInput name="itemNr"
                 [(ngModel)]="model.itemNr"
          />

          <span nxFormfieldHint
                nxTooltip="{{'product.supplierItemNrInfo' | translate}}">{{ 'product.supplierItemNrInfo' | translate }}</span>
        </nx-formfield>
      </div>
      <div nxCol="12,12,12,4">
        <nx-formfield nxLabel="{{'product.supplierProductName' |translate}}" appearance="auto">
          <input type="text" nxInput name="itemNr"
                 [(ngModel)]="model.name"
          />
        </nx-formfield>
      </div>
      <div nxCol="12,12,12,4">
        <nx-formfield nxLabel="{{'product.supplierProductSku' |translate}}" appearance="auto">
          <input type="text" nxInput name="sku"
                 [(ngModel)]="model.sku"
          />
        </nx-formfield>
      </div>
      <div nxCol="12" class="nx-margin-top-2m">
        <forms-price-form [defaultVat]="27" [(priceModels)]="model.prices" [currencySelect]="true"></forms-price-form>
      </div>
    </ng-container>
  </div>
</div>
