import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Page } from '@hellp/routing';
import { ROUTING } from '../../../utilities/routing';
import { AdminGuard } from '../../../layouts/admin-layout/admin.guard';
import { ListPage } from '../../../utilities/abstract/list-page';
import { SupplierOrder, SupplierOrderStatus } from '../../../services/supplier-order/supplier-order.model';
import { SupplierOrderDtoType, SupplierOrderService } from '../../../services/supplier-order/supplier-order.service';
import { TableAction, TableCol, TableFilter } from '@hellp/table';
import { switchMap, takeUntil } from 'rxjs';
import { HistoryStatus } from '../../../utilities/types';
import { GoodsLandingService } from '../../../services/goods-landing/goods-landing.service';

@Component({
  selector: 'app-supplier-order-list',
  templateUrl: './supplier-order-list.component.html',
  styleUrls: ['./supplier-order-list.component.css'],
})
@Page({
  path: '',
  pathPrefix: ROUTING.SUPPLIER_ORDER,
  authGuard: AdminGuard,
})
export class SupplierOrderListComponent
  extends ListPage<SupplierOrder, SupplierOrderService>
  implements OnInit, OnDestroy
{
  protected readonly HistoryStatus = HistoryStatus;
  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>;

  constructor(
    service: SupplierOrderService,
    private goodsLandingService: GoodsLandingService,
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.initTable();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  loadData(): void {
    this.service
      .getAllByDeleted(SupplierOrderDtoType.SlimSupplierOrderDTO)
      .pipe(takeUntil(this._destroyed))
      .subscribe((result) => {
        this.models = result;
        console.log(result);
        this.componentIsReady = true;
      });
  }

  override async initTable() {
    this.actionDef = [
      new TableAction('eye', this.showConsumer(), await this.getTranslate('table.action.show'), this.isOrdered()),
      new TableAction(
        'cart-shopping',
        this.setOrderedConsumer(),
        await this.getTranslate('table.action.ordered'),
        this.isNotOrdered(),
      ),
      new TableAction('pencil', this.editConsumer(), await this.getTranslate('table.action.edit'), this.isNotOrdered()),
      new TableAction(
        'trash',
        this.deleteConsumer(),
        await this.getTranslate('table.action.delete'),
        this.isNotOrdered(),
      ),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')),
      TableCol.newString('warehouse.name', await this.getTranslate('supplierOrder.warehouse')),
      TableCol.newString('supplierName', await this.getTranslate('supplierOrder.supplier')),
      TableCol.newString('status', await this.getTranslate('supplierOrder.status'))
        .filterable(TableFilter.newSelect(Object.keys(SupplierOrderStatus).map(this.generateStatusFilterOption())))
        .template(() => this.statusTpl),
      TableCol.newDate('estimatedArrival', await this.getTranslate('supplierOrder.estimatedArrival')),
      TableCol.newString('sumOrderable', await this.getTranslate('supplierOrder.sumCount')),
      TableCol.newNumber('sumOrderablePrice', await this.getTranslate('supplierOrder.sumPrice')),
      TableCol.newString('currency', await this.getTranslate('supplierOrder.currency')),
    ];
  }

  private isOrdered() {
    return (row: SupplierOrder) => {
      return row.status != 'DRAFT';
    };
  }

  private isNotOrdered() {
    return (row: SupplierOrder) => {
      return row.status == 'DRAFT';
    };
  }

  private setOrderedConsumer() {
    return (supplierOrder: SupplierOrder) => {
      const approve$ = this.service.approve(supplierOrder);
      const process$ = this.service.process(supplierOrder);

      approve$
        .pipe(
          takeUntil(this._destroyed),
          switchMap(() => process$),
        )
        .subscribe((result) => {
          this.service.notificationService.showSuccess('general.success', 'supplierOrder.successOrdered');
          this.loadData();
        });
    };
  }

  // private setOrderedConsumer() {
  //   return (p1: SupplierOrder) => {
  //     this.service.approve(p1).subscribe(res => {
  //       this.service.process(p1).subscribe(result => {
  //         const goodsLanding: GoodsLanding = GoodsLanding.newBySupplierOrder(p1);
  //         this.goodsLandingService.save(goodsLanding).subscribe(r => {
  //           this.service.notificationService.showSuccess('general.success', 'supplierOrder.successOrdered');
  //           this.loadData();
  //         })
  //       })
  //     })
  //   };
  // }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('supplierOrder.statusesOrder.' + status);
      return { label: label, value: status };
    };
  }
}
