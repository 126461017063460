import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tag, TagType} from "../../../services/product/product.model";
import {TagDtoTypes, TagService} from "../../../services/tag/tag.service";
import {lastValueFrom, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../../utilities/abstract/form-component";

@Component({
  selector: 'forms-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.css']
})
export class TagFormComponent extends FormComponent implements OnInit {
  @Input() tags: Tag[] = [];
  @Output() tagsChange: EventEmitter<Tag[]> = new EventEmitter();

  @Input() embeddedMode = true;
  tagType: TagType = 'primary';
  existingTags!: Tag[];
  addedTags: number[] = [];
  private focusedWorkName = false;
  showUpload = !this.embeddedMode;
  isKeyword = false;
  tagName: any;


  constructor(private tagService: TagService, private translateService: TranslateService) {
    super(tagService);
  }

  ngOnInit(): void {
    if (this.embeddedMode) {
      this.tagService.getAllByDeleted(TagDtoTypes.TagDTO).pipe(takeUntil(this._destroyed)).subscribe(result => {
        this.existingTags = result;
        this.addedTags = this.existingTags.filter(t => this.tags.map(tt => tt.id).includes(t.id)).map(t => t.id);
      });
    }
    this.initErrorHandlingOnInput();
  }

  listChange() {
    this.tagsChange.emit(this.tags);
  }

  removeTag(id: number) {
    if (this.embeddedMode) {
      this.tags = this.tags.filter(t => t.id != id);
      this.addedTags = this.addedTags.filter(tid => tid != id);
      this.listChange();
    } else {
      this.tagService.notificationService.confirm(
        this.translateService.instant('general.warning'),
        this.translateService.instant('general.areUSure'),
        'general.ok',
        'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.tagService.delete(id));
          this.tagService.getAllByDeleted(TagDtoTypes.TagDTO).subscribe(result => {
            this.tags = result;
          });
        }
      });
    }
  }

  focusWorkName() {
    this.focusedWorkName = true;
  }

  changeWorkName(addTagInput: any, tagNameInput: any) {
    if (!this.focusedWorkName) {
      tagNameInput.value = addTagInput.value;
    }
  }

  addTag(addTagInput: any, tagNameInput: any) {
    try {
      const tag = new Tag({
        nxTaglistLabel: addTagInput.value,
        type: (this.tagType)
      }, tagNameInput.value, this.isKeyword);
      this.tagService.validate(tag);
      this.tagService.save(tag, TagDtoTypes.TagDTO).subscribe((result: Tag) => {
        if (this.embeddedMode) {
          this.existingTags.unshift(result);
          this.existingTags = [...this.existingTags];
          this.addedTags.push(result.id);
        }
        this.tags.push(result);

        addTagInput.value = '';
        tagNameInput.value = '';
        this.isKeyword = false;
        this.listChange();
      })
    } catch (error: any) {
      this.tagService.notificationService.showError('general.error', error.message);
    }
  }

  tagSelectChange() {
    this.tags = this.existingTags.filter(t => this.addedTags.includes(t.id))
    this.listChange();
  }
}
