import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {StockTransferRepository} from "./stock-transfer.repository";
import {StockTransferElement, StockTransferModel} from "./stock-transfer.model";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum StockTransferDtoType {
  StockTransferDTO = 'StockTransferDTO',
  SlimStockTransferDTO = 'SlimStockTransferDTO',
}

@Injectable({
  providedIn: 'root'
})
export class StockTransferService extends CrudService<StockTransferModel, StockTransferRepository> {

  constructor(repo: StockTransferRepository) {
    super(ROUTING.STOCK_TRANSFER, repo, StockTransferModel, PageMetaDataConf.STOCK_TRANSFER);
  }

  override validate(model: StockTransferModel) {

  }

  approveElement(stockTransferModel: StockTransferModel, stockTransferElement: StockTransferElement) {
    return this.repo.approveElement(stockTransferModel.id, stockTransferElement.id);
  }

  process(row: StockTransferModel) {
    return this.repo.process(row.id);
  }

  approve(row: StockTransferModel) {
    return this.repo.approve(row.id);
  }

  addElement(stockTransferModel: StockTransferModel) {
    return this.repo.addElement(stockTransferModel.id, stockTransferModel);
  }


  removeElement(stockTransferModelId: number, tempElement: StockTransferElement) {
    return this.repo.removeElement(stockTransferModelId, tempElement.id);
  }
}

