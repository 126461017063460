import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {StockTransferElement, StockTransferModel} from "../../../services/stock-transfer/stock-transfer.model";
import {StockTransferDtoType, StockTransferService} from "../../../services/stock-transfer/stock-transfer.service";
import {ActivatedRoute} from "@angular/router";
import {StockProductDtoTypes, WarehouseDtoTypes, WarehouseService} from "../../../services/warehouse/warehouse.service";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {SupplierOrderStatus} from "../../../services/supplier-order/supplier-order.model";
import {StockProduct, WarehouseModel} from "../../../services/warehouse/warehouse.model";
import {catchError, lastValueFrom, takeUntil} from "rxjs";

@Component({
  selector: 'app-stock-transfer-form',
  templateUrl: './stock-transfer-form.component.html',
  styleUrls: ['./stock-transfer-form.component.css']
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.STOCK_TRANSFER,
})
export class StockTransferFormComponent extends FormPage<StockTransferModel, StockTransferService> implements OnInit, OnDestroy {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);
  editMode = false;
  warehouses: WarehouseModel[] = [];
  availableElements: StockProduct[] = [];

  constructor(service: StockTransferService, route: ActivatedRoute, private warehouseService: WarehouseService) {
    super(route, service, StockTransferModel, []);
  }

  ngOnInit(): void {
    this.onInit(StockTransferDtoType.StockTransferDTO);
  }

  protected override afterModelIsSetProcess() {
    if (this.formModel.id && this.formModel.id > 0) {
      this.editMode = true;
      this.formModel.elements.forEach(sp => {
        sp.source.product = this.parser.parseApiDataToModel(sp.source.product, ProductHistoryModel);
        sp.target.product = this.parser.parseApiDataToModel(sp.target.product, ProductHistoryModel);
      })
      this.initWarehouse();

    } else {
      this.warehouseService.getAllByDeleted(WarehouseDtoTypes.StockTransferWarehouseDTO)
        .pipe(takeUntil(this._destroyed))
        .subscribe(result => {
          this.warehouses = result;
          this.warehouses.forEach(wh => {
            wh.products.forEach(product => {
              product.product = this.parser.parseApiDataToModel(product.product, ProductHistoryModel);
            })
            wh.products = wh.products.sort((a, b) => {
              if (a.product.product.name.toLowerCase() > b.product.product.name.toLowerCase()) {
                return -1;
              }
              return 1;
            });
          })
        })
    }

    this.componentIsReady = true
  }

  private initWarehouse() {
    this.formModel.sourceWarehouse.products.forEach(product => {
      product.product = this.parser.parseApiDataToModel(product.product, ProductHistoryModel);
      const element = this.formModel.elements.find(ste => ste.source.id == product.id);

      if (element) {
        product.addToOrder = true;
        product.orderCount = element.count;
        product.count = product.count - product.orderCount;
      } else {
        product.orderCount = 0;
        product.addToOrder = false;
      }
    })
    this.formModel.sourceWarehouse.products = this.formModel.sourceWarehouse.products.sort((a, b) => {
      if (a.product.product.name.toLowerCase() > b.product.product.name.toLowerCase()) {
        return -1;
      }
      return 1;
    });
    this.formModel.targetWarehouse.products.forEach(product => {
      product.product = this.parser.parseApiDataToModel(product.product, ProductHistoryModel);
    })
    this.formModel.elements = this.formModel.elements.sort((a, b) => {
      if (a.target.product.product.name.toLowerCase() > b.target.product.product.name.toLowerCase()) {
        return -1;
      }
      return 1;
    });
    this.availableElements = this.formModel.sourceWarehouse.products;
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected readonly SupplierOrderStatus = SupplierOrderStatus;

  changeTargetWarehouse(targetWarehouse: WarehouseModel) {
    this.formModel.targetWarehouse = targetWarehouse;
    this.formModel.targetWarehouseId = targetWarehouse.id;
  }

  changeSourceWarehouse(sourceWarehouse: WarehouseModel) {
    this.formModel.sourceWarehouse = sourceWarehouse;
    this.availableElements = this.formModel.sourceWarehouse.products;
    this.formModel.sourceWarehouseId = sourceWarehouse.id;
  }

  async transfer(sourceStock: StockProduct) {
    sourceStock.addToOrder = true
    const stockTransferElement = new StockTransferElement();
    stockTransferElement.count = sourceStock.orderCount;
    sourceStock.count = sourceStock.count - sourceStock.orderCount;
    stockTransferElement.source = sourceStock;
    const target = this.formModel.targetWarehouse.products.find(sp => sp.product.id == sourceStock.product.id);
    if (target) {
      stockTransferElement.target = target;
    } else {
      const sp: StockProduct = StockProduct.newStockProductByStockProduct(sourceStock);
      sp.warehouse = this.formModel.targetWarehouse;
      const result = await lastValueFrom(this.warehouseService.saveStockProduct(sp, StockProductDtoTypes.StockProductWithProductDTO))
      this.formModel.targetWarehouse.products.push(result);
      stockTransferElement.target = result;
      stockTransferElement.target.product = this.parser.parseApiDataToModel(stockTransferElement.target.product, ProductHistoryModel);

    }
    this.formModel.elements.push(stockTransferElement);
    if (this.editMode) {
      const tmpModel = new StockTransferModel();
      tmpModel.id = this.formModel.id;
      tmpModel.elements = [stockTransferElement];
      this.service.addElement(tmpModel).subscribe(result => {
        this.loadModel(StockTransferDtoType.StockTransferDTO);
      })
    }
  }

  remove(stockTransferElement: StockTransferElement) {
    if (!this.editMode) {
      stockTransferElement.source.count = stockTransferElement.source.count + stockTransferElement.source.orderCount;
      stockTransferElement.source.orderCount = 0;
      stockTransferElement.source.addToOrder = false;
      const index = this.formModel.elements.indexOf(stockTransferElement);
      if (index > -1) {
        this.formModel.elements.splice(index, 1);
      }
    }
    if (this.editMode) {
      const stockProduct = this.formModel.sourceWarehouse.products.find(sp => sp.id == stockTransferElement.source.id);
      if (stockProduct) {
        this.service.removeElement(this.formModel.id, stockTransferElement).subscribe(result => {
          this.loadModel(StockTransferDtoType.StockTransferDTO);
        });
      }
    }
  }

  validateMax(sourceStock: StockProduct) {
    if (sourceStock.orderCount > sourceStock.count) {
      sourceStock.orderCount = sourceStock.count;
    }
  }

  approve(stockTransferElement: StockTransferElement) {
    this.service.approveElement(this.formModel, stockTransferElement).pipe(
      takeUntil(this._destroyed),
      catchError(err => stockTransferElement.status = SupplierOrderStatus.DRAFT)).subscribe(result => {
      this.loadModel(StockTransferDtoType.StockTransferDTO);
    });

    stockTransferElement.status = SupplierOrderStatus.APPROVED
  }

  protected filter: any = {productName: ''};

  onFilter() {
    if (this.formModel.sourceWarehouse) {
      if (this.filter.productName.length < 1) {
        this.availableElements = this.formModel.sourceWarehouse.products;
      }
      this.availableElements = this.formModel.sourceWarehouse.products.filter(sp => {
        return sp.product.product.name.toLowerCase().includes(this.filter.productName.toLowerCase())
      });

    }

  }
}
