import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ShowPage} from "../../../utilities/abstract/show-page";
import {ProductModel} from "../../../services/product/product.model";
import {ProductDtoTypes, ProductService} from "../../../services/product/product.service";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ActivatedRoute} from "@angular/router";
import {GalleryPanelComponent} from "../../../components/panel/gallery-panel/gallery-panel.component";

@Component({
  selector: 'app-product-show',
  templateUrl: './product-show.component.html',
  styleUrls: ['./product-show.component.css']
})
@Page({
  path: [
    `${ROUTING.MARKETING_PRODUCT}/show/:id`,
    `${ROUTING.SALES_PRODUCT}/show/:id`
  ]
})
export class ProductShowComponent extends ShowPage<ProductModel, ProductService> implements OnInit, OnDestroy {
  @ViewChild('gallery') galleryComponent!: GalleryPanelComponent;

  constructor(service: ProductService, route: ActivatedRoute) {
    super(service, route);
  }

  async ngOnInit(): Promise<void> {
    this.onInit(ProductDtoTypes.ProductDTO);
  }


  protected override initProcess() {
    super.initProcess();
    // this.galleryComponent.refresh(this.model.gallery);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }
}
