import {Injectable} from "@angular/core";
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {BioCertModel} from "./bio-cert.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/bio-certificates')
export class BioCertRepository extends CrudRepository<BioCertModel> {
  constructor() {
    super();
  }

}