import {Injectable, Type} from "@angular/core";
import {Delete, Get, Patch, PathVariable, Post, Put, QueryParam, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {BillingData, Cart, EmailData, OrderModel} from "./order";
import {OrderStatus} from "../../utilities/types";
import {Observable} from "rxjs";
import {PostPayload} from "../../models/post-payload.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('carts')
export class CartRepository extends CrudRepository<Cart> {

}