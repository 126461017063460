<nx-card class="viewer">
  <page-list-view-header
    [newConsumer]="newConsumer()"
    [title]="'inventory.headline'"
    iconName="truck-ramp-box"
  ></page-list-view-header>
  <div class="nx-margin-top-2m">
    <ng-container *ngIf="componentIsReady">
      <hellp-table id="inventory-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                   [showAsList]="mobile"></hellp-table>
    </ng-container>

    <ng-container *ngIf="!componentIsReady">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </div>
</nx-card>

<ng-template #statusTpl let-value="value">
  <span>
  {{ 'inventory.' + value | translate }}
    </span>
</ng-template>
