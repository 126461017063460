import {Component, EventEmitter, Input} from '@angular/core';
import {AttachmentHandlerView} from '../attachment-handler/attachment-handler.model';
import {AttachmentModel} from "../../../services/attachment/attachment.model";

@Component({
  selector: 'app-image-attachment-element',
  templateUrl: './image-attachment-element.component.html',
  styleUrls: ['./image-attachment-element.component.scss']
})
export class ImageAttachmentElementComponent implements AttachmentHandlerView {

  @Input() isOptional!: boolean;
  @Input() data!: AttachmentModel;
  refreshEvent!: EventEmitter<any>;

}
