import {Patch, PathVariable, RepoPath, RequestBody} from "@hellp/repository";
import {Injectable} from "@angular/core";
import {CrudRepository} from "../../../utilities/service/crud.repository";
import {ProductHistoryModel} from "../product.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/product-histories')
export class ProductHistoryRepository extends CrudRepository<ProductHistoryModel> {


  @Patch('/{id}/supplier')
  public patchSupplier(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/order-config')
  public patchOrderConfig(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/tags')
  public patchTag(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/related-products')
  public patchRelatedProducts(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/dynamic-attributes')
  public patchDynamicAttribute(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/main-image')
  public patchMainImage(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/images')
  public patchGallery(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/draft')
  public patchDraft(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/category')
  public patchCategory(@PathVariable() id: number, @RequestBody() payload: ProductHistoryModel): Observable<ProductHistoryModel> {
    return this.createRequest().withModel(ProductHistoryModel).send();
  }

  @Patch('/{id}/publish')
  publish(@PathVariable() id: number) {
    return this.createRequest().send();
  }
}
