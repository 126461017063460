import {Injectable} from "@angular/core";
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {TermsAndConditionModel} from "./terms-and-condition.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/terms-and-conditions')
export class TermsAndConditionRepository extends CrudRepository<TermsAndConditionModel> {
  constructor() {
    super();
  }

}