import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProductService} from '../../../services/product/product.service';
import {ProductHistoryModel, ProductModel,} from '../../../services/product/product.model';
import {EntityRelatedProduct} from '../../../models/entity-relation-product';
import {map, Subject, takeUntil} from 'rxjs';
import {HellparserService} from '@hellp/parser';

@Component({
  selector: 'form-related-product',
  templateUrl: './related-product-form.component.html',
  styleUrls: ['./related-product-form.component.css'],
})
export class RelatedProductFormComponent implements OnInit, OnDestroy {
  protected readonly _destroyed = new Subject<void>();
  @Input() actualProduct!: ProductHistoryModel;
  @Input() relatedProducts!: EntityRelatedProduct[];
  @Output() relatedProductsChange: EventEmitter<EntityRelatedProduct[]> = new EventEmitter();

  selectedIds: number[] = [];
  dynamicBackendOptions: (term: string) => any;
  selectedName?: string = '';

  constructor(
    private productService: ProductService,
    private parser: HellparserService,
  ) {
    this.dynamicBackendOptions = (term: string) =>
      this.productService.getHistoriesByProductNameFilterWithoutStockFilter(term).pipe(
        takeUntil(this._destroyed),
        map(this.productService.getAutocompleteLabelProcess()),
      );
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private init() {
    this.relatedProducts.forEach(rp => rp.relatedProductId = rp.relatedProduct.id);
  }

  onAdd() {
    this.init();
    if (this.selectedName && this.selectedName.length > 0) {
      this.productService
        .getHistoryByAutocompleteField(this.selectedName)
        .pipe(takeUntil(this._destroyed))
        .subscribe((result) => {
          if (result) {
            result.published = this.parser.parseApiDataToModel(
              result.published,
              ProductModel,
            );
            this.add(result);
          } else {
            this.productService.notificationService.showWarning(
              'general.warning',
              'general.unknownProductName',
            );
          }
        });
    }
  }

  private add(result: ProductHistoryModel) {
    const tmp = new EntityRelatedProduct();
    tmp.relatedProduct = result;
    this.relatedProducts.push(tmp);
    this.relatedProductsChange.emit(this.relatedProducts);
    this.selectedName = undefined;
  }

  onRemove(relatedProduct: EntityRelatedProduct) {
    this.init();
    const index = this.relatedProducts
      .map((rp) => rp.relatedProduct.id)
      .indexOf(relatedProduct.relatedProduct.id);
    if (index > -1) {
      this.relatedProducts.splice(index, 1);
      this.relatedProductsChange.emit(this.relatedProducts);
    }
  }
}
