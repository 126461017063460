<nx-card class="viewer">
  <page-form-header #formHeader iconName="{{service.pageMetadata.icon}}"
                    title="{{editMode?service.pageMetadata.formHeadlineEdit:service.pageMetadata.formHeadlineNew}}"
                    [withActions]="showControlButtons"
                    [disabledCancel]="withoutCancel"
                    (cancel)="cancel.emit()" (save)="save.emit()"></page-form-header>

  <ng-container *ngIf="componentIsReady">
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf="!componentIsReady">
    <div class="d-flex justify-content-center nx-margin-y-2m">
      <app-spinner></app-spinner>
    </div>
  </ng-container>
</nx-card>
