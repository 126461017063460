import {Component, Input} from '@angular/core';
import {CategoryModel} from "../../../services/category/category.model";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-category-base-panel',
  templateUrl: './category-base-panel.component.html',
  styleUrls: ['./category-base-panel.component.css']
})
export class CategoryBasePanelComponent {
  @Input() model!: CategoryModel;
  publicUrl: string = environment.publicUrl;
}
