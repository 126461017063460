<app-attachment-handler
  [attachedItems]="itemAttachments"
  [availableItems]="availableAttachments"
  [attachedTitle]="'general.attachedPdf'"
  [availableTitle]="'general.availablePdf'"
  [attachedViewComponent]="imgViewComponent"
  [availableViewComponent]="imgViewComponent"
  [acceptString]="'application/pdf'"
  [isMultiple]="false"
  [maxElement]="1"
  [secondPanelExpanded]="secondPanelExpanded"
  [firstPanelExpanded]="firstPanelExpanded"
  (refreshEvent)="attachmentRefresh()"
  (attachmentSaveEvent)="attachmentSave($event)"
>
</app-attachment-handler>
