<hellp-list-container [service]="service" [(componentIsReady)]="componentIsReady">
  <hellp-table id="banner-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
               [showAsList]="mobile"></hellp-table>
</hellp-list-container>

<ng-template #download let-value="value">
  <button
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
    [downloadFile]="{url:value.downloadLink, fileName:value.fileName}"
  >
    {{ 'general.download' | translate }}
  </button>
</ng-template>
