<nx-card class="viewer">
  <page-list-view-header
    [newConsumer]="hasNewConsumer?newConsumer():undefined"
    title="{{service.pageMetadata.listHeadline}}"
    iconName="{{service.pageMetadata.icon}}"
  ></page-list-view-header>
  <div nxRow class="nx-margin-top-2m">
    <div nxCol="12">

      <ng-container *ngIf="componentIsReady">
        <ng-content></ng-content>
      </ng-container>

      <ng-container *ngIf="!componentIsReady">
        <div class="d-flex justify-content-center nx-margin-y-2m">
          <app-spinner></app-spinner>
        </div>
      </ng-container>

    </div>
  </div>
</nx-card>
