import {Injectable} from "@angular/core";
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {PrivacyPolicyModel} from "./privacy-policy.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/privacy-policies')
export class PrivacyPolicyRepository extends CrudRepository<PrivacyPolicyModel> {
  constructor() {
    super();
  }

}