import {Injectable} from '@angular/core';
import {Patch, PathVariable, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {StockTransferModel} from "./stock-transfer.model";
import {SupplierOrder, SupplierOrderElement} from "../supplier-order/supplier-order.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/stock-transfers')
export class StockTransferRepository extends CrudRepository<StockTransferModel> {
  constructor() {
    super();
  }

  @Patch('/{id}/elements/{elementId}/approve')
  approveElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/process')
  process(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/approve')
  approve(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/update')
  updateElements(@PathVariable() id: number, @RequestBody() elements: StockTransferModel) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/add/')
  addElement(@PathVariable() id: number, @RequestBody() supplierOrder: StockTransferModel) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/{elementId}/remove')
  removeElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }
}
