<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">
  <div nxRow="">
    <div nxCol="12" class="nx-margin-y-2m">
      <hellp-stepper-container [steps]="steps" (changeStepEvent)="changeStep($event)"></hellp-stepper-container>
    </div>
    <div nxCol="12" class="text-end">
      <button
        nxButton="primary small"
        (click)="onSave()"
        class="nx-margin-bottom-s"
        type="button"
      >
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</hellp-form-container>

<ng-template #baseData>
  <div nxRow="">
    <div nxCol="12,12,12,6">
      <div class="card p-3 mb-4 box-shadow">
        <div nxRow="">
          <div nxCol="12,12,6">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                          nxLabel="{{'technologies.cultureName' | translate}}">
              <nx-dropdown appValidatedInput required [nxShowFilter]="true"
                           nxFilterPlaceholder="{{'general.search' | translate}}"
                           [(ngModel)]="formModel.plantCultureId"
                           [disabled]="editMode"
                           (selectionChange)="changeCulture($event)">
                <nx-dropdown-item
                  *ngFor="let culture of plantCultures"
                  [nxValue]="culture.id"
                >{{ culture.name }}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error class="nx-margin-bottom-m" nxFormfieldError
                        *ngIf="validationError.plantCulture"> {{ validationError.plantCulture | translate }}
              </nx-error>
            </nx-formfield>

          </div>
          <div nxCol="12,12,6">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                          nxLabel="{{'technologies.issueName' | translate}}">
              <nx-dropdown appValidatedInput required [nxShowFilter]="true"
                           nxFilterPlaceholder="{{'general.search' | translate}}"
                           [disabled]="editMode"
                           [(ngModel)]="formModel.plantCultureIssueId" (selectionChange)="changeIssue($event)">
                <nx-dropdown-item
                  *ngFor="let issue of plantIssue"
                  [nxValue]="issue.id"
                >{{ issue.name }}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error class="nx-margin-bottom-m" nxFormfieldError
                        *ngIf="validationError.plantCultureIssue"> {{ validationError.plantCultureIssue | translate }}
              </nx-error>
            </nx-formfield>
          </div>

          <div nxCol="12" class="nx-margin-top-m">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                          nxLabel="{{'plantCulture.issueTypeName' | translate}}">
              <nx-dropdown appValidatedInput required [nxShowFilter]="true"
                           nxFilterPlaceholder="{{'general.search' | translate}}"
                           [(ngModel)]="formModel.plantCultureIssueTypeId" (selectionChange)="changeIssueType($event)">
                <nx-dropdown-item
                  *ngFor="let issueType of plantIssueTypes"
                  [nxValue]="issueType.id"
                >{{ issueType.name }} ({{ 'plantCulture.' + issueType.type | translate }})
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error class="nx-margin-bottom-m" nxFormfieldError
                        *ngIf="validationError.plantCultureIssueType"> {{ validationError.plantCultureIssueType | translate }}
              </nx-error>
            </nx-formfield>
          </div>

          <div nxCol="12,12,8,6" class="nx-margin-top-m">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                          nxLabel="{{'technologies.monthStart' | translate}}">
              <nx-dropdown [(ngModel)]="formModel.monthStart">
                <nx-dropdown-item
                  *ngFor="let month of MonthByIndex | keyvalue | arraySort:'key':true;"
                  [nxValue]="month.value"
                >{{ ('general.month.' + (month.key) | translate) }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,4,6" class="nx-margin-top-m">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                          nxLabel="{{'technologies.partOfMonthStart' | translate}}">
              <nx-dropdown [(ngModel)]="formModel.partOfMonthStart">
                <nx-dropdown-item
                  *ngFor="let month of PartOfMonthByIndex | keyvalue | arraySort:'key':true;"
                  [nxValue]="month.value"
                >{{ ('general.partOfMonth.' + (month.key) | translate) }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>

          <div nxCol="12,12,8,6" class="nx-margin-top-m">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                          nxLabel="{{'technologies.monthEnd' | translate}}">
              <nx-dropdown [(ngModel)]="formModel.monthEnd">
                <nx-dropdown-item
                  *ngFor="let month of MonthByIndex | keyvalue | arraySort:'key':true;"
                  [nxValue]="month.value"
                >{{ ('general.month.' + (month.key) | translate) }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,4,6" class="nx-margin-top-m">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                          nxLabel="{{'technologies.partOfMonthEnd' | translate}}">
              <nx-dropdown [(ngModel)]="formModel.partOfMonthEnd">
                <nx-dropdown-item
                  *ngFor="let month of PartOfMonthByIndex | keyvalue | arraySort:'key':true;"
                  [nxValue]="month.value"
                >{{ ('general.partOfMonth.' + (month.key) | translate) }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
        </div>
      </div>
    </div>
    <div nxCol="12,12,12,6">
      <forms-tag-form [tags]="formModel.tagList" (tagsChange)="tagsChange($event)"></forms-tag-form>
      <div nxRow="">
        <div nxCol="12">
          <div class="card p-3 mb-4 box-shadow">
            <h6>{{ 'technologies.youtubeEmbed' | translate }}
              <a
                [nxPopoverTriggerFor]="popoverAppendixExample"
                nxPopoverDirection="top"
                nxPopoverTrigger="hover"
                aria-label="More information"
              >
                <nx-icon name="info-circle-o" aria-hidden="true"></nx-icon>
              </a>
            </h6>
            <div nxRow nxRowAlignItems="center">
              <ng-container *ngIf="formModel.youtubeLinks.length<1">
                <div nxCol="11">
                  <nx-formfield nxFloatLabel="always">
                    <textarea nxInput [(ngModel)]="youtubeLink"></textarea>
                    <span nxFormfieldHint
                          nxTooltip="{{ 'technologies.youtubeLinkHint' | translate }}">{{ 'technologies.youtubeLinkHint' | translate }} </span>
                  </nx-formfield>
                </div>
                <div nxCol="1">
                  <button nxFormfieldAppendix nxIconButton="primary small" (click)="addYoutubeLink()">
                    <nx-icon name="plus">
                    </nx-icon>
                  </button>
                </div>
              </ng-container>

              <ng-container *ngFor="let link of formModel.youtubeLinks">
                <div nxCol="11" class="nx-margin-top-m">
                  <panel-youtube-video [videoUrl]="link"></panel-youtube-video>
                </div>
                <div nxCol="1" class="nx-margin-top-m">
                  <button nxFormfieldAppendix nxIconButton="danger small" (click)="removeYoutubeLink()">
                    <nx-icon name="minus">
                    </nx-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div nxCol="12">
      <div class="card p-3 mb-4 box-shadow">
        <div nxRow="">
          <div nxCol="12">
            <h6>{{ 'technologies.description' | translate }}</h6>
            <quill-editor [(ngModel)]="formModel.description" class="w-100"></quill-editor>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
<ng-template #suggestionData>
  <div class="card p-3 mb-4 box-shadow">
    <div nxRow="">
      <div nxCol="12">
        <h6>{{ 'technologies.suggestedProduct' | translate }}</h6>
        <div nxRow nxRowAlignItems="center">
          <div nxCol="11,11,6,4" class="nx-margin-bottom-m">
            <nx-formfield nxLabel="{{'order.availableProducts' | translate}}"
                          appearance="outline"
                          nxFloatLabel="always">
              <input
                nxInput
                type="text"
                [nxAutocomplete]="auto2"
                [nxAutocompleteItems]="dynamicBackendOptions"
                [(ngModel)]="selectedName"
              />
              <nx-autocomplete #auto2="nxAutocomplete"></nx-autocomplete>
            </nx-formfield>
          </div>
          <div nxCol="1,1,1,1" class="nx-margin-bottom-m">
            <button nxIconButton="primary small" (click)="addSuggestionProduct()">
              <nx-icon name="plus"></nx-icon>
            </button>
          </div>
          <div nxCol="12,12,5,3">
          </div>
          <div nxCol="12" cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let sp of formModel.suggestedProducts">
              <div nxRow cdkDrag class="drag-container cdk-drag-handle">
                <div nxCol="12,12,6" class="nx-margin-bottom-m">
                  <h5>{{ sp.productHistory.product.name }}</h5>
                  <p>{{ sp.productHistory.category.name }}</p>
                </div>
                <div nxCol="12,12,6" class="nx-margin-bottom-m">
                  <nx-formfield nxFloatLabel="always" nxLabel="{{'technologies.dosage' | translate}}">
                    <textarea nxInput [(ngModel)]="sp.dosage"></textarea>
                    <span nxFormfieldHint class="text-end"
                          nxTooltip="{{ 'technologies.dosageHint' | translate }}"> {{ 'technologies.dosageHint' | translate }} </span>
                  </nx-formfield>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #summaryData>
  <div class="card p-3 mb-4 box-shadow">
    <ng-container *ngIf="saveIsEnable">
      <display-plant-culture-issue-pairing [model]="formModel"></display-plant-culture-issue-pairing>
    </ng-container>
  </div>
</ng-template>

<nx-popover #popoverAppendixExample>
  <div style="max-width: 300px">
    {{ 'technologies.youtubeInfo' | translate }}
  </div>
</nx-popover>
