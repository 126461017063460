<div nxModalContent style="height: calc(100% - 72px)">
  <nx-card class="w-100 h-100">
    <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
      {{ 'general.addImageToModel' | translate }}
    </h3>

  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    [nxModalClose]="selectedAttachment"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{ 'general.add' | translate }}
  </button>
</div>
