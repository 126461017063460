<div nxModalContent class="nx-margin-top-m">
  <div nxLayout="grid">
    <div nxRow nxRowJustify="around">
      <div nxCol="12">
        <nx-card style="margin: auto" class="w-100">
          <nx-card-header>
            <h4 nxHeadline="subsection-small" class="nx-margin-bottom-2m">
              {{data.title}}
            </h4>
          </nx-card-header>
          <ng-container *ngIf="data.isImage">
            <div class="image-overview">
              <div nxLayout="grid">
                <div nxRow="">
                  <div nxCol="12">
                    <figure nxFigure="">
                      <img loading="lazy"
                        [src]="data.src"
                        alt="{{data.title}}"
                        (error)="data.src = 'assets/img/default.png'"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!data.isImage">
            <div class="image-overview">
              <div nxLayout="grid">
                <div nxRow="">
                  <div nxCol="12">
                    <figure nxFigure="">
                      <img loading="lazy"
                        [src]="'assets/img/file.png'"
                        alt="{{data.title}}"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </nx-card>
      </div>
    </div>
  </div>
</div>
<div nxModalActions>
  <button
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
    [downloadFile]="{url:data.downloadLink, fileName:data.fileName}"
  >
    {{'general.download' | translate}}
  </button>
</div>
