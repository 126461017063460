import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ListPage} from "../../../utilities/abstract/list-page";
import {TableCol} from "@hellp/table";
import {takeUntil} from "rxjs";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {BioCertModel} from "../../../services/bio-cert/bio-cert.model";
import {BioCertDtoTypes, BioCertService} from "../../../services/bio-cert/bio-cert.service";

@Component({
  selector: 'app-bio-cert-list',
  templateUrl: './bio-cert-list.component.html',
  styleUrls: ['./bio-cert-list.component.css']
})
@Page({
  path: '',
  pathPrefix: ROUTING.BIO_CERT
})
export class BioCertListComponent extends ListPage<BioCertModel, BioCertService> implements OnInit, OnDestroy {
  @ViewChild('download') downloadTpl!: TemplateRef<any>;

  constructor(service: BioCertService) {
    super(service);
  }

  ngOnInit(): void {
    this.initTable();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  loadData(): void {
    this.service.getAllByDeleted(BioCertDtoTypes.BioCertificateDTO).pipe(
      takeUntil(this._destroyed)
    ).subscribe(result => {
      this.models = result;
      this.componentIsReady = true;
    });
  }


  override async initTable(): Promise<void> {
    this.actionDef = [];
    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
      TableCol.newString('status', await this.getTranslate('article.status')),
      TableCol.newDate('createdAt', await this.getTranslate('general.createdAt')),
      TableCol.newString('attachment.name', await this.getTranslate('general.attachmentName')),
      TableCol.newString('attachment', await this.getTranslate('general.download')).filterable(false).template(() => this.downloadTpl),
    ];
  }
}
