import {ChangeDetectorRef, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {ArticleType, Author, MagazineModel} from "../../../services/article/magazine.model";
import {ActivatedRoute} from "@angular/router";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {EntityTag, Tag} from "../../../services/product/product.model";
import {Utilities} from "../../../utilities/utilities";
import {ArticleDtoType, ArticleService} from "../../../services/article/article.service";
import {takeUntil} from "rxjs";
import {StepElement} from "../../../models/step-element.model";
import {ValidatedInputDirective} from "../../../directives/validated-input.directive";
import {SKIP_VALIDATION} from "../../../utilities/types";

@Component({
  selector: 'app-article-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.css']
})

@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.ARTICLE,
})
export class ArticleFormComponent extends FormPage<MagazineModel, ArticleService> implements OnInit {
  @ViewChild('baseData') baseData!: TemplateRef<any>;
  @ViewChild('contentData') contentData!: TemplateRef<any>;
  @ViewChild('summaryData') summaryData!: TemplateRef<any>;
  @ViewChildren(ValidatedInputDirective) override inputs!: QueryList<ValidatedInputDirective>;

  hours: string[] = Utilities.getHours(0, 23);
  authors!: Author[];
  steps!: StepElement[];

  constructor(service: ArticleService, route: ActivatedRoute,
              public dialogService: NxDialogService, private cdr: ChangeDetectorRef) {
    super(route, service, MagazineModel, []);
  }

  ngOnInit(): void {
    this.onInit(ArticleDtoType.ArticleDTO);
    this.initSteps();
    this.cdr.detectChanges();
  }

  protected override afterModelIsSetProcess() {
    this.service.getAuthors().pipe(takeUntil(this._destroyed)).subscribe(result => {
      if (result.length > 0) {
        this.authors = result;
        if (this.formModel.author.id < 1) {
          this.formModel.author = this.authors[0];
        }
        this.formModel.authorId = this.formModel.author.id;
        this.componentIsReady = true
      } else {
        this.service.notificationService.showError('general.error', 'error.noAuthor');
      }
    });
    this.initErrorHandlingOnInput();
  }

  tagsChange(tags: Tag[]) {
    this.formModel.tags = tags.map(t => new EntityTag(t));
  }

  initSteps() {
    this.steps = [
      new StepElement(1, 'article.baseInformation', () => this.baseData, this.validateStep(1)),
      new StepElement(2, 'article.content', () => this.contentData, this.validateStep(2)),
      new StepElement(3, 'article.summary', () => this.summaryData, SKIP_VALIDATION),
    ]
  }

  validateStep(stepCount: number) {
    return () => {
      try {
        this.service.validateByStep(this.formModel, stepCount);
        return true;
      } catch (e) {
        console.warn(e);
        return false
      }
    }
  }

  changeAuthor() {
    const author = this.authors.find(a => a.id == this.formModel.authorId);
    if (author) {
      this.formModel.author = author;
    }
  }

  override savePreProcess() {
    this.formModel.type = ArticleType.BLOG;
    delete (this.formModel as any).publishedAt;
  }
}
