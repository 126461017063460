import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'arraySort'
})
export class ArraySortPipe implements PipeTransform {

  transform(array: any[], field: string, asNumber = false): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    array.sort((a, b) => {
      const ta = asNumber ? parseInt(a[field]) : a[field];
      const tb = asNumber ? parseInt(b[field]) : b[field];
      return (ta > tb) ? 1 : -1;
    });
    return array;
  }

}
