import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShowPage} from "../../../utilities/abstract/show-page";
import {StockTransferElement, StockTransferModel} from "../../../services/stock-transfer/stock-transfer.model";
import {StockTransferDtoType, StockTransferService} from "../../../services/stock-transfer/stock-transfer.service";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ActivatedRoute} from "@angular/router";
import {HellparserService} from "@hellp/parser";
import {ServiceLocator} from "../../../utilities/service/service.locator";
import {ProductHistoryModel} from "../../../services/product/product.model";
import {SupplierOrderStatus} from "../../../services/supplier-order/supplier-order.model";

@Component({
  selector: 'app-stock-transfer-show',
  templateUrl: './stock-transfer-show.component.html',
  styleUrls: ['./stock-transfer-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.STOCK_TRANSFER,
})
export class StockTransferShowComponent extends ShowPage<StockTransferModel, StockTransferService> implements OnInit, OnDestroy {
  private parser: HellparserService = ServiceLocator.injector.get(HellparserService);

  constructor(service: StockTransferService, route: ActivatedRoute) {
    super(service, route);
  }

  ngOnInit(): void {
    this.onInit(StockTransferDtoType.StockTransferDTO);
  }


  protected override initProcess() {
    super.initProcess();
    this.model.elements.forEach((element) => {
      element.source.product = this.parser.parseApiDataToModel(element.source.product, ProductHistoryModel);
      element.target.product = this.parser.parseApiDataToModel(element.target.product, ProductHistoryModel);
    });
    this.model.elements = this.model.elements.sort((a, b) => {
      if (a.target.product.product.name.toLowerCase() > b.target.product.product.name.toLowerCase()) {
        return -1;
      }
      return 1;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  approveElement(element: StockTransferElement) {
    this.service.approveElement(this.model, element).subscribe(result => {
      this.onInit(StockTransferDtoType.StockTransferDTO);
    });
  }

  protected readonly SupplierOrderStatus = SupplierOrderStatus;

  approve() {
    this.service.approve(this.model).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'stockTransfer.successApprove');
      this.onInit(StockTransferDtoType.StockTransferDTO);
    });
  }

  processModel() {
    this.service.process(this.model).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'stockTransfer.successTransfer');
      this.onInit(StockTransferDtoType.StockTransferDTO);
    })
  }
}
