import {ConvertProcess, ParseTo} from "@hellp/parser";
import {SupplierOrder, SupplierOrderElement} from "./supplier-order.model";
import {ProcessorService} from "../processor.service";
import {Currencies, PaymentType} from "../../utilities/types";
import {BaseModel} from "../../utilities/abstract/base.model";

export class SupplierOrderInvoiceElement {
  count = 0;
  countArrived = 0;
}

export class SupplierOrderInvoice extends BaseModel {
  @ParseTo(SupplierOrder)
  supplierOrder!: SupplierOrder;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getSupplierOrderId'}, "model")
  supplierOrderId = 0;
  partnerName = '';
  invoiceNumber = '';
  currency: Currencies = Currencies.huf;
  paymentMethod: PaymentType = PaymentType.CASH;
  elements: SupplierOrderElement[] = [];
  @ParseTo(Date)
  invoiceDate!: Date;
  @ParseTo(Date)
  completedAt!: Date;
  @ParseTo(Date)
  deadline!: Date;
  completed = false;
}
