import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {ShowPage} from "../../../utilities/abstract/show-page";
import {UserModel} from "../../../services/user/user.model";
import {UserDtoTypes, UserService} from "../../../services/user/user.service";
import {ActivatedRoute} from "@angular/router";
import {TableAction, TableCol, TableFilter} from "@hellp/table";
import {OrderModel} from "../../../services/order/order";
import {OrderService} from "../../../services/order/order.service";
import {lastValueFrom} from "rxjs";
import {DeliveryType, OrderStatus, PaymentType} from "../../../utilities/types";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-show',
  templateUrl: './user-show.component.html',
  styleUrls: ['./user-show.component.css']
})
@Page({
  path: '/show/:id',
  pathPrefix: ROUTING.USER,
})
export class UserShowComponent extends ShowPage<UserModel, UserService> implements OnInit {

  @ViewChild('statusTpl') statusTpl!: TemplateRef<any>;
  @ViewChild('paymentTpl') paymentTpl!: TemplateRef<any>;
  @ViewChild('deliveryTpl') deliveryTpl!: TemplateRef<any>;
  @ViewChild('priceTpl') priceTpl!: TemplateRef<any>;
  colDef!: TableCol[];
  actionDef!: TableAction[];
  orders: OrderModel[] = [];

  constructor(service: UserService, route: ActivatedRoute, private orderService: OrderService) {
    super(service, route);
  }

  async ngOnInit(): Promise<void> {
    this.onInit(UserDtoTypes.UserDTO);

  }

  protected override initProcess() {
    this.initTable();
  }

  async initTable(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.showOrderConsumer(), await this.getTranslate('table.action.show')),
    ];

    this.colDef = [
      TableCol.newId('id', await this.getTranslate('general.id')).sortable(false).filterable(false),
      TableCol.newDateTime('createdAt', await this.getTranslate('general.createdAt')),
      TableCol.newString('orderNumber', await this.getTranslate('order.orderNumber')),
      TableCol.newString('status', await this.getTranslate('order.statusTitle')).filterable(
        TableFilter.newSelect(Object.keys(OrderStatus).map(this.generateStatusFilterOption()))).template(
        () => this.statusTpl),
      TableCol.newString('paymentType', await this.getTranslate('order.paymentType')).filterable(
        TableFilter.newSelect(Object.keys(PaymentType).map(this.generatePaymentFilterOption()))).template(
        () => this.paymentTpl),
      TableCol.newString('deliveryType', await this.getTranslate('order.deliveryType')).filterable(
        TableFilter.newSelect(Object.keys(DeliveryType).map(this.generateDeliveryFilterOption()))).template(
        () => this.deliveryTpl),
      TableCol.newString('cart.sumPrice', await this.getTranslate('order.sumPrice')).template(
        () => this.priceTpl),
    ];

    this.orders = await lastValueFrom(this.orderService.getAllByUser(this.model.id));
  }

  private generateStatusFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  private generatePaymentFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }

  private generateDeliveryFilterOption() {
    return (status: string) => {
      const label = this.translateService.instant('enum.' + status);
      return {label: label, value: status};
    }
  }


  public showOrderConsumer(): (row: OrderModel) => void {
    return (model: OrderModel) => this.orderService.navigateToShow(model.id);
  }

  protected readonly PaymentType = PaymentType;
  protected readonly DeliveryType = DeliveryType;
  protected readonly environment = environment;
}
