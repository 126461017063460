import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {CategoryModel, DeliveryConfig, FinancialConfig, PackagingConfig} from "./category.model";
import {CategoryRepository} from "./category.repository";
import {map, Observable} from "rxjs";
import {ValidatorService} from "../../utilities/service/validator.service";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";
import {ValidationError} from "../../models/validation.error.model";

export enum CategoryDtoTypes {
  ProductCategoryDTO = 'ProductCategoryDTO',
  SlimProductCategoryDTO = 'SlimProductCategoryDTO',
  ParentOnlyProductCategoryDTO = 'ParentOnlyProductCategoryDTO'
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends CrudService<CategoryModel, CategoryRepository<CategoryModel>> {


  constructor(repo: CategoryRepository<CategoryModel>) {
    super(ROUTING.CATEGORY, repo, CategoryModel, PageMetaDataConf.CATEGORY);
  }


  getAllParent(): Observable<CategoryModel[]> {
    const filter = this.filterService.filter(CategoryDtoTypes.ProductCategoryDTO)
      .isNull('parent')
      .create();
    return this.repo.getAllByFilter(filter.stringify(), CategoryModel);
  }

  getAllMayHaveChildren() {
    return this.getAllByDeleted(CategoryDtoTypes.ParentOnlyProductCategoryDTO).pipe(map(categories => {
      return categories.filter(category => category.mayHaveChildren());
    }));
  }

  override validate(model: CategoryModel) {
    const errors: ValidationError[] = this.validationBaseData(model).concat(
      this.validationAttachment(model),
      this.validationConfig(model)
    );

    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

  validateByStep(model: CategoryModel, stepCount: number) {
    let errors: ValidationError[] = [];
    if (stepCount == 1) {
      errors = this.validationBaseData(model);
    }
    if (stepCount == 2) {
      errors = this.validationAttachment(model);
    }

    if (stepCount == 3) {
      errors = this.validationConfig(model);
    }

    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

  private validationConfig(model: CategoryModel) {
    let errors: ValidationError[] = [];
    if (model.orderConfig && model.orderConfig.deliveryConfig) {
      errors = errors.concat(ValidatorService.allIsRequired(model.orderConfig.deliveryConfig, ['availableModes'], DeliveryConfig.name, 'deliveryConfig'));
    }
    if (model.orderConfig && model.orderConfig.financialConfig) {
      errors = errors.concat(ValidatorService.allIsRequired(model.orderConfig.financialConfig, ['availableModes'], FinancialConfig.name, 'financialConfig'));
    }
    if (model.orderConfig && model.orderConfig.packagingConfig) {
      errors = errors.concat(ValidatorService.allIsRequired(model.orderConfig.packagingConfig, ['icePackRanges'], PackagingConfig.name));
    }
    return errors;
  }

  private validationAttachment(model: CategoryModel) {
    return ValidatorService.allIsRequired(model.image, ['attachment'], CategoryModel.name);
  }

  private validationBaseData(model: CategoryModel) {
    let errors: ValidationError[] = [];
    errors = ValidatorService.allIsRequired(model, ['name', 'description', 'shortName'], CategoryModel.name)
    if (model.description.length > 500) {
      errors.push(new ValidationError("error.CategoryModel.descriptionToLong", 'description'));
    }
    return errors;
  }
}
