<ng-template #myDetailTpl let-row="row" let-expanded="expanded" xmlns="http://www.w3.org/1999/html">
  <div style="padding-left:35px;" clsss="w-100">
    <a class="mr-3" href="javascript:void(0);"
       [downloadFile]="{url:row.downloadLink, fileName:row.fileName}">{{ 'general.download' | translate }}</a>
  </div>
</ng-template>

<nx-card class="viewer">
  <page-list-view-header
    iconName="file"
    [newConsumer]="newConsumer()"
    [title]="'attachment.headline'"
  ></page-list-view-header>
  <nx-sidepanel-outer-container style="height: calc(100vh - 200px)" class="nx-margin-top-2m">
    <div nxLayout="grid" class="pt-5">
      <div nxRow="">
        <div nxCol="12,12,6,4,3" *ngFor="let model of models" style="cursor: pointer" (click)="onOpenSide(model)">
          <app-attachment-element [data]="model"></app-attachment-element>
        </div>
      </div>
    </div>
    <nx-sidepanel class="my-sidepanel nx-margin-right-2m top-border" position="floating" [(opened)]="opened"
                  *ngIf="selectedModel">
      <nx-sidepanel-header>
        <div class="my-sidepanel-header">
          <nx-icon [name]="selectedModel.icon" class="nx-margin-right-2xs"></nx-icon>
          {{ selectedModel.name | shortify:30 }}
          <button
            nxSidepanelCloseButton
            aria-label="Close Sidepanel"
          ></button>
        </div>
      </nx-sidepanel-header>

      <nx-sidepanel-content>
        <div class="my-sidepanel-content">

          <nx-data-display>
            <nx-data-display-label>
                <span class="text-light">
                {{ 'general.id' | translate }}
                  </span>
            </nx-data-display-label>
            <span class="text-white-50">
              {{ selectedModel.id }}
              </span>
          </nx-data-display>
          <nx-data-display>
            <nx-data-display-label>
                <span class="text-light">
                {{ 'general.createdAt' | translate }}
                  </span>
            </nx-data-display-label>
            <span class="text-white-50">
              {{ selectedModel.createdAt|date:'yyyy.MM.dd HH:mm' }}
              </span>
          </nx-data-display>

          <nx-data-display>
            <nx-data-display-label>
                <span class="text-light">
                {{ 'general.updatedAt' | translate }}
                  </span>
            </nx-data-display-label>
            <span class="text-white-50">
              {{ selectedModel.updatedAt | date:'yyyy.MM.dd HH:mm' }}
              </span>
          </nx-data-display>
          <nx-data-display>
            <nx-data-display-label>
                <span class="text-light">
                {{ 'general.name' | translate }}
                  </span>
            </nx-data-display-label>
            <span class="text-white-50">
              {{ selectedModel.name | shortify:40 }}
              </span>
          </nx-data-display>
          <nx-data-display>
            <nx-data-display-label>
                <span class="text-light">
                {{ 'attachment.fileName' | translate }}
                  </span>
            </nx-data-display-label>
            <span class="text-white-50">
              {{ selectedModel.fileName | shortify:40 }}
              </span>
          </nx-data-display>
          <nx-data-display>
            <nx-data-display-label>
                <span class="text-light">
                {{ 'attachment.filePath' | translate }}
                  </span>
            </nx-data-display-label>
            <span class="text-white-50">
              {{ selectedModel.filePath | onlyPath }}
              </span>
          </nx-data-display>
          <nx-data-display>
            <nx-data-display-label>
                <span class="text-light">
                {{ 'attachment.mimeType' | translate }}
                  </span>
            </nx-data-display-label>
            <span class="text-white-50">
              {{ selectedModel.mimeType }}
              </span>
          </nx-data-display>

          <div class="d-flex">
            <button nxButton="primary small" class="nx-margin-top-2m" type="button"
                    [downloadFile]="{fileName:selectedModel.fileName, url:selectedModel.downloadLink}">
              {{ 'attachment.download' | translate }}
            </button>
            <button nxButton="primary small" class="nx-margin-top-2m ms-auto" type="button"
                    [cdkCopyToClipboard]="selectedModel.url" (click)="onCopyLink()">
              {{ 'attachment.copyLink' | translate }}
            </button>
          </div>
          <div class="d-flex">
            <a nxButton="secondary small" class="nx-margin-top-xs" type="button" (click)="editProcess(selectedModel)">
              {{ 'general.edit' | translate }}
            </a>
            <a nxButton="primary danger small" class="nx-margin-top-xs ms-auto" type="button"
               (click)="deleteProcess(selectedModel)">
              {{ 'general.delete' | translate }}
            </a>
          </div>
        </div>
      </nx-sidepanel-content>
    </nx-sidepanel>
  </nx-sidepanel-outer-container>
</nx-card>
