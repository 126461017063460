import {Component, Inject} from '@angular/core';
import {EmailData} from "../../../services/order/order";
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {Editor, Toolbar} from "ngx-editor";
import {NGX_TOOLBAR} from "../../../utilities/types";

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.css']
})
export class SendEmailDialogComponent {
  model!: EmailData;
  editor: Editor;

  toolbar: Toolbar = NGX_TOOLBAR;

  constructor(@Inject(NX_MODAL_DATA) public data: any) {
    this.model = data;

    this.editor = new Editor();
  }


}
