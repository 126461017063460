<div nxLayout="grid">
  <div nxRow="">
    <div nxCol="12">
      <nx-accordion [multi]="false" class="text-end">
        <nx-expansion-panel [(expanded)]="firstPanelExpanded">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              <nx-icon name="upload"></nx-icon>
              {{ 'general.attachmentInnerUpload' | translate }}
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <app-single-attachment-upload
            [acceptString]="acceptString"
            [(file)]="file"
            [(attachment)]="attachment"
          ></app-single-attachment-upload>
          <button nxPlainButton="primary" type="button" (click)="save()"
                  [disabled]="file === undefined">{{ 'general.save' | translate }}
          </button>
        </nx-expansion-panel>
        <nx-expansion-panel class="p-0" [(expanded)]="secondPanelExpanded">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              <nx-icon name="arrows-up-down-left-right"></nx-icon>
              {{ 'general.attachmentChoose' | translate }}
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxRow="">

            <div nxCol="6">
              <div class="attachment-card"
                   [id]="AVAILABLE"
                   cdkDropList
                   #available="cdkDropList"
                   [cdkDropListConnectedTo]="[attached]"
                   [cdkDropListData]="filteredOptionalElements"
                   (cdkDropListDropped)="drop($event)">
                <div class="attachment-card-header">
                  <p> {{ availableTitle | translate }}</p>
                  <nx-formfield nxLabel="{{'general.filter' | translate}}" appearance="auto">
                    <input nxInput type="text" name="filter"
                           [(ngModel)]="filterValue"
                           (ngModelChange)="filter()"
                           [nxAutocomplete]="autocomplete"
                           [nxAutocompleteItems]="autoCompleteDynamic"
                    />
                    <nx-autocomplete #autocomplete="nxAutocomplete"></nx-autocomplete>
                  </nx-formfield>
                </div>
                <div class="attachment-card-body">
                  <div *ngFor="let attachment of filteredOptionalElements" cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <ng-template viewAnchor [data]="attachment" [isOptional]="true"
                                 (refreshEvent)="refreshFromChild($event)"></ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div nxCol="6">
              <div class="attachment-card"
                   [id]="ATTACHED"
                   cdkDropList
                   #attached="cdkDropList"
                   [cdkDropListConnectedTo]="[available]"
                   [cdkDropListData]="attachedItems"
                   (cdkDropListDropped)="drop($event)">
                <div class="attachment-card-header">
                  <p> {{ attachedTitle | translate }}</p>
                </div>
                <div class="attachment-card-body">
                  <div *ngFor="let attachment of attachedItems" cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <ng-template viewAnchor [data]="attachment"
                                 (refreshEvent)="refreshFromChild($event)"></ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>


    </div>
  </div>
</div>

