<hellp-list-container [service]="service" [(componentIsReady)]="componentIsReady">
  <div class="row mt-3">
    <div class="col-12">
      <nx-switcher [(ngModel)]="showFilter" labelSize="small">{{ 'general.showFilter' | translate }}</nx-switcher>
    </div>
    <div class="dimension-transition col-12 pt-3 {{showFilter?'d-block':' d-none'}}">
      <nx-formfield nxLabel="{{'category.nameFilter' | translate}}" appearance="auto">
        <input nxInput name="nameFilter" [(ngModel)]="nameFilter" (ngModelChange)="filter()"/>
      </nx-formfield>
    </div>
  </div>
  <div class="row nx-margin-top-2m ms-2">
    <ng-container *ngFor="let model of filteredModels">
      <div class=" col-12 nx-margin-bottom-2m ">
        <app-category-element [actionDef]="actionDef" [category]="model" [parent]="null"></app-category-element>
      </div>
    </ng-container>
  </div>
</hellp-list-container>

<ng-template #subCategoryTable let-row="row">
  <div class="row">
    <div class="col-12 mx-auto">
      <hellp-table *ngIf="row.subcategories && row.subcategories.length>0"
                   [rows]="row.subcategories"
                   [colDefinitions]="colDef"
                   [actionConfig]="actionDef"
                   [expandableTemplate]="(row.subcategories && row.subcategories.length>0)?subCategoryTable:undefined"
      ></hellp-table>
    </div>
  </div>
</ng-template>
<ng-template #image let-value="value">
  <img loading="lazy"
       [src]="value.attachment.src"
       alt="{{value.attachment.fileName}}"
       style="height: 80px; object-fit: contain"
       class="img-thumbnail rounded"
       (error)="value.attachment.src = 'assets/img/default.jpg'">
</ng-template>
