<ng-container *ngIf="model && componentIsReady">
  <nx-card class="viewer">
    <page-show-tool-bar
      iconName="arrows-to-eye"
      title="{{'technologies.detailsHeadline' | translate}}"
      [backSupplier]="back()"
      [editSupplier]="edit()"
    ></page-show-tool-bar>
    <div nxRow="" class="nx-margin-top-2xs">
      <div nxCol="12">
        <display-plant-culture-issue-pairing [model]="model"></display-plant-culture-issue-pairing>
      </div>
    </div>
  </nx-card>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>