export class NotificationModel {
  iconName = 'inbox';
  title = 'Beszállítói megrendelés';
  createdAt: Date = new Date();
  description = '#IN9983 beszállítói rendelésen változás történt';
  read = false;

  constructor(iconName: string, title: string, createdAt: Date, description: string, read: boolean) {
    this.iconName = iconName;
    this.title = title;
    this.createdAt = createdAt;
    this.description = description;
    this.read = read;
  }
}

export const TypeIcon: { [key: string]: string } = {
  MAGAZINE_PUBLISHED: 'newspaper',
}