import {BaseModel} from "../../utilities/abstract/base.model";
import {Note, SupplierOrderStatus} from "../supplier-order/supplier-order.model";
import {ConvertFrom, ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {StockProduct, WarehouseModel} from "../warehouse/warehouse.model";
import {ProcessorService} from "../processor.service";

export class StockTransferElement extends BaseModel {
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT
  count = 0;

  note: Note = new Note()

  message = '';


  @ParseTo(StockProduct)
  source!: StockProduct;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getSourceId'}, "model")
  sourceId = 0;

  @ParseTo(StockProduct)
  target!: StockProduct;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getTargetId'}, "model")
  targetId = 0;
}

export class StockTransferModel extends BaseModel {
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  createdAt: Date = new Date();
  @ParseProcess({processorClass: ProcessorService, processorFunctionName: 'parseDate'})
  processedAt?: Date;

  note?: Note;
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT

  @ParseTo(StockTransferElement)
  @ConvertFrom
  elements: StockTransferElement[] = [];

  targetWarehouse!: WarehouseModel;
  targetWarehouseId!: number;
  sourceWarehouse!: WarehouseModel;
  sourceWarehouseId!: number;

}
