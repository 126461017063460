import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {TagRepository} from "./tag.repository";
import {ROUTING} from "../../utilities/routing";
import {ValidatorService} from "../../utilities/service/validator.service";
import {Tag} from "../product/product.model";
import {ValidationError} from "../../models/validation.error.model";

export enum TagDtoTypes {
  TagDTO = 'TagDTO'
}

@Injectable({
  providedIn: 'root'
})
export class TagService extends CrudService<Tag, TagRepository> {

  constructor(repo: TagRepository) {
    super(ROUTING.TAG, repo, Tag);
  }

  override validate(model: Tag) {
    const errors: ValidationError[] = ValidatorService.allIsRequired(model, ['name', 'slug', 'html'], Tag.name)
    if (errors.length > 0) {
      this.$validationError.next(errors);
      throw new Error("general.error");
    }
  }

}

