import {Component, Inject} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {PlantCulture, PlantCultureType} from "../../../services/technologies/plant-culture.model";

@Component({
  selector: 'app-add-plant-culture-dialog',
  templateUrl: './add-plant-culture-dialog.component.html',
  styleUrls: ['./add-plant-culture-dialog.component.css']
})
export class AddPlantCultureDialogComponent {

  constructor(@Inject(NX_MODAL_DATA) public plantCulture: PlantCulture) {
  }

  protected readonly PlantCultureType = PlantCultureType;
}
