import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {Inventory, InventoryElement} from "./inventory.model";
import {InventoryRepository} from "./inventory.repository";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum InventoryDtoType {
  InventoryDTO = 'InventoryDTO',
  SlimInventoryDTO = 'SlimInventoryDTO',
}

@Injectable({
  providedIn: 'root'
})
export class InventoryService extends CrudService<Inventory, InventoryRepository> {

  constructor(repo: InventoryRepository) {
    super(ROUTING.INVENTORY, repo, Inventory, PageMetaDataConf.INVENTORY);
  }

  override validate(model: Inventory) {


  }

  addElement(inventoryId: number, inventory: Inventory) {
    return this.repo.addElement(inventoryId, inventory);
  }

  approveElement(inventory: Inventory, inventoryElement: InventoryElement) {
    return this.repo.approveElement(inventory.id, inventoryElement.id);
  }

  process(row: Inventory) {
    return this.repo.process(row.id);
  }

  approve(row: Inventory) {
    return this.repo.approve(row.id);
  }

  updateElements(inventory: Inventory) {
    return this.repo.updateElements(inventory.id, inventory);
  }

  removeElement(inventoryId: number, element: InventoryElement) {
    return this.repo.removeElement(inventoryId, element.id);
  }
}

