import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {PermissionCheck} from "../../utilities/abstract/permission-check";
import {CustomToolbarAction} from "../show-tool-bar/show-tool-bar.component";
import {ActivatedRoute} from "@angular/router";

export class ViewChanger {
  static VIEW_NAMES = {LIST: 'LIST', TABLE: 'TABLE', MAP: '', GANT: ''}
  viewName: string;
  show = false;
  icon: string;

  constructor(viewName: string, show: boolean, icon: string) {
    this.viewName = viewName;
    this.show = show;
    this.icon = icon;
  }
}

export type VIEW_CHANGER_CONTAINER = { [key: string]: ViewChanger }

@Component({
  selector: 'page-list-view-header',
  templateUrl: './list-view-header.component.html',
  styleUrls: ['./list-view-header.component.scss']
})
export class ListViewHeaderComponent extends PermissionCheck implements OnInit {
  @Input() newConsumer?: () => void;
  @Input() customActions?: CustomToolbarAction[];
  @Input() newIcon = 'plus';
  @Input() title = '';
  @Input() iconName = 'eye';
  @Input() viewChangers?: VIEW_CHANGER_CONTAINER;
  @Output() viewChangersChange: EventEmitter<VIEW_CHANGER_CONTAINER> = new EventEmitter<VIEW_CHANGER_CONTAINER>();

  modelType!: string;
  mobile = false;

  private readonly _destroyed = new Subject<void>();

  constructor(public viewportService: NxViewportService, private route: ActivatedRoute,
              private _cdr: ChangeDetectorRef,) {
    super();
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
    this.initPermission();
    this.modelType = this.route.snapshot.url[0].path;
  }

  changeView(setKey: string) {
    if (this.viewChangers) {
      const localVc = this.viewChangers;
      Object.keys(this.viewChangers).forEach(key => {
        localVc[key].show = key == setKey;
      });
      this.viewChangersChange.emit(this.viewChangers);
    } else {
      console.warn("No viewchanger in list header!");
    }
  }

}
