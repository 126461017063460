<div nxRow="" nxRowJustify="between">
  <div nxCol="12" class="nx-margin-bottom-2m">
    <ng-container *ngFor="let tag of model.tagList">
      <div class="tag {{'tag-'+tag.html.type}} me-3">
        {{ tag.html.nxTaglistLabel }}
      </div>
    </ng-container>
  </div>
  <div nxCol="12,12,6">
    <nx-data-display label="{{'technologies.type' | translate}}" orientation="horizontal-columns"
                     class="nx-margin-top-m">
      {{ 'plantCulture.' + model.plantCultureIssueType.type | translate }}
    </nx-data-display>
    <nx-data-display label="{{'technologies.cultureName' | translate}}" orientation="horizontal-columns"
                     class="nx-margin-top-m">
      {{ model.plantCulture.name }}
    </nx-data-display>
    <nx-data-display label="{{'plantCulture.issueTypeName' | translate}}" orientation="horizontal-columns"
                     class="nx-margin-top-m">
      {{ model.plantCultureIssueType.name }}
    </nx-data-display>
    <nx-data-display label="{{'technologies.issueName' | translate}}" orientation="horizontal-columns"
                     class="nx-margin-top-m">
      {{ model.plantCultureIssue.name }}
    </nx-data-display>
    <nx-data-display label="{{'technologies.monthStart' |translate}}" orientation="horizontal-columns"
                     class="nx-margin-top-m">
      {{ ('general.monthName.' + (model.monthStart) | translate) }} {{ ('general.partOfMonthName.' + (model.partOfMonthStart) | translate) }}
    </nx-data-display>
    <nx-data-display label="{{'technologies.monthEnd' |translate}}" orientation="horizontal-columns"
                     class="nx-margin-top-m">
      {{ ('general.monthName.' + (model.monthEnd) | translate) }} {{ ('general.partOfMonthName.' + (model.partOfMonthEnd) | translate) }}
    </nx-data-display>
    <nx-data-display label="{{'technologies.description' |translate}}" orientation="horizontal-columns"
                     class="nx-margin-top-m">
      <p [innerHTML]="model.description | safeHtml "></p>
    </nx-data-display>
  </div>
  <div nxCol="12,12,6" class="text-end">
    <panel-youtube-video [videoUrl]="model.youtubeLinks[0]"></panel-youtube-video>
  </div>
  <hr class="hr-dark">
  <ng-container *ngFor="let suggestedProduct of model.sortedSuggestedProducts | arraySort:'id'">
    <div nxCol="12">
      <nx-small-stage class="nx-margin-bottom-m" appearance="expert" narrow>
        <nx-small-stage-image
          nxSmallStageImageEnd
          [src]="suggestedProduct.productHistory.mainImage.attachment.srcWidth"
        ></nx-small-stage-image>
        <p nxCopytext class="nx-margin-bottom-2xs">{{ suggestedProduct.productHistory.category.name }}</p>
        <h2 nxHeadline="subsection-medium"
            class="nx-font-weight-regular">{{ suggestedProduct.productHistory.product.name }}</h2>
        <h4 nxHeadline="subsection-small">
          <b>{{ 'technologies.dosage' | translate }}: </b>
          {{ suggestedProduct.dosage }}
        </h4>
      </nx-small-stage>
    </div>
  </ng-container>
</div>
