import {Component, OnInit} from '@angular/core';
import {FormPage} from "../../../utilities/abstract/form-page";
import {ActivatedRoute} from "@angular/router";
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {filter, takeUntil} from "rxjs";
import {TermsAndConditionModel} from "../../../services/terms-and-condition/terms-and-condition.model";
import {BioCertDtoTypes, BioCertService} from "../../../services/bio-cert/bio-cert.service";
import {BioCertModel} from "../../../services/bio-cert/bio-cert.model";

@Component({
  selector: 'app-bio-cert-form',
  templateUrl: './bio-cert-form.component.html',
  styleUrls: ['./bio-cert-form.component.css']
})

@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.BIO_CERT,
})
export class BioCertFormComponent extends FormPage<BioCertModel, BioCertService> implements OnInit {

  constructor(route: ActivatedRoute, service: BioCertService,) {
    super(route, service, TermsAndConditionModel, []);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.service.getAllByDeleted(BioCertDtoTypes.BioCertificateDTO).pipe(
      takeUntil(this._destroyed),
      filter(result => !!result && result.length > 0)
    ).subscribe(result => {
      this.formModel = result[0];
      this.componentIsReady = true;
    });
  }
}
