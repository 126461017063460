import {Patch, PathVariable, RepoPath, RequestBody} from "@hellp/repository";
import {Injectable} from "@angular/core";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {StockConfig, StockProduct} from "./warehouse.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/stock-products')
export class StockProductRepository<MODEL> extends CrudRepository<MODEL> {

  @Patch('/{id}/stock-config')
  updateStockConfig(@PathVariable() id: number, @RequestBody() stockProduct: StockProduct) {
    return this.createRequest().withModel(StockProduct).send();
  }
}
