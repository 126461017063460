<ng-container *ngIf="model && componentIsReady">
  <nx-card class="viewer">
    <page-show-tool-bar
      iconName="right-left"
      title="{{'stockTransfer.details' | translate}}"
      [backSupplier]="back()"
    ></page-show-tool-bar>
    <div class="row my-4 align-items-center justify-content-center">

      <div class="col-1"></div>
      <div class="col-5  mb-3">
        <p>{{ 'stockTransfer.status' | translate }}: {{ 'stockTransfer.detailStatus.' + model.status | translate }}</p>
      </div>
      <div class="col-5  mb-3 text-end">
        <ng-container *ngIf="model.status == SupplierOrderStatus.DRAFT">
          <button nxButton="primary small" (click)="approve()">
            <nx-icon name="thumbs-up" class="me-2"></nx-icon>
            {{ 'stockTransfer.approve' |translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="model.status == SupplierOrderStatus.APPROVED">
          <button nxButton="primary small" (click)="processModel()">
            <nx-icon name="arrows-turn-to-dots" class="me-2"></nx-icon>
            {{ 'stockTransfer.process' |translate }}
          </button>
        </ng-container>
      </div>
      <div class="col-1"></div>

      <div class="col-5 text-end"><h4 nxHeadline="section">{{ model.sourceWarehouse.name }}</h4></div>
      <div class="col-1 text-center">
        <h4 nxHeadline="section">
          <nx-icon name="arrow-right"></nx-icon>
        </h4>
      </div>
      <div class="col-5"><h4 nxHeadline="section">{{ model.targetWarehouse.name }}</h4></div>
    </div>
    <hr class="hr-dark">
    <div class="row my-2 align-items-center justify-content-center">
      <ng-container *ngFor="let element of model.elements">
        <div class="col-5 text-end">
          <nx-card class="w-100 mb-2">
            <h4 nxHeadline="subsection-small">{{ element.source.product.product.name }}</h4>
            <ng-container *ngIf="element.status == SupplierOrderStatus.PROCESSED">
              <p class="m-0 pt-2"><b>{{ 'stockTransfer.sourceTransferCount' | translate }}: </b> {{ element.count }}</p>
            </ng-container>
            <ng-container *ngIf="element.status != SupplierOrderStatus.PROCESSED">
              <p class="m-0 pt-2"><b>{{ 'stockTransfer.afterStock' | translate }}: </b> {{ element.source.count - element.count }}</p>
            </ng-container>

          </nx-card>
        </div>
        <div class="col-1 text-center">
          <h4 nxHeadline="subsection-small">
            <nx-icon name="arrow-right"></nx-icon>
          </h4>
        </div>
        <div class="col-5">
          <nx-card class="w-100 mb-2 ">
            <div class="row justify-content-between align-items-center">
              <div class="col-8">
                <h4 nxHeadline="subsection-small">{{ element.target.product.product.name }}</h4>
                <p class="m-0 pt-2">
                  <ng-container *ngIf="element.status == SupplierOrderStatus.PROCESSED">
                    <b>{{ 'stockTransfer.targetTransferCount' | translate }}:</b> {{ element.count }}<br>
                  </ng-container>
                  <ng-container *ngIf="element.status != SupplierOrderStatus.PROCESSED">
                    <b>{{ 'stockTransfer.afterStock' | translate }}:</b> {{ element.target.count + element.count }}<br>
                  </ng-container>
                  <b>{{ 'stockTransfer.status' | translate }}: </b>{{ 'stockTransfer.detailStatus.' + element.status | translate }}<br>
                </p>
              </div>
              <div class="col-4 text-end">
                <ng-container *ngIf="element.status == SupplierOrderStatus.DRAFT">
                  <button nxIconButton="primary small" (click)="approveElement(element)">
                    <nx-icon name="thumbs-up"></nx-icon>
                  </button>
                </ng-container>
              </div>
            </div>
          </nx-card>
        </div>
      </ng-container>
    </div>
  </nx-card>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>
