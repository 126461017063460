import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from "@hellp/routing";
import {ROUTING} from "../../../utilities/routing";
import {FormPage} from "../../../utilities/abstract/form-page";
import {FormToolBarOption} from "../../../components/form-header/form-tool-bar-option.model";
import {ActivatedRoute} from "@angular/router";
import {AttachmentModel} from "../../../services/attachment/attachment.model";
import {AttachmentService} from "../../../services/attachment/attachment.service";
import {ATTACHMENT_DTOS} from "../../../services/processor.service";


@Component({
  selector: 'app-edit-attachment-list',
  templateUrl: './attachment-form.component.html',
  styleUrls: ['./attachment-form.component.scss']
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.ATTACHMENT,
})
export class AttachmentFormComponent extends FormPage<AttachmentModel, AttachmentService> implements OnInit, OnDestroy {

  toolbarAddOptions: FormToolBarOption[];
  file!: File;

  constructor(service: AttachmentService, route: ActivatedRoute,) {
    super(route, service, AttachmentModel, []);
    this.toolbarAddOptions = [];
  }

  ngOnInit(): void {
    this.onInit(ATTACHMENT_DTOS.AttachmentDTO);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  protected override afterModelIsSetProcess() {
    setTimeout(() => {
      this.componentIsReady = true
    }, 600);
  }

  override saveProcess() {
    if (this.file != undefined && this.formModel.name && this.formModel.name.length > 0) {
      this.formModel.slug = this.formModel.name;
      this.service.saveFile(this.file, this.formModel).subscribe(resp => {
        if (resp != undefined) {
          this.service.notificationService.showSuccess('general.success', 'general.successSave');
          this.service.navigateToBase();
        }
      });
    } else {
      this.service.notificationService.showWarning('general.warning', 'general.invalidData');
    }
  }
}
