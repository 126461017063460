import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from '@hellp/routing';
import {ROUTING} from '../../../utilities/routing';
import {FormPage} from '../../../utilities/abstract/form-page';
import {BillingData, Cart, OrderModel,} from '../../../services/order/order';
import {OrderDtoTypes, OrderService,} from '../../../services/order/order.service';
import {ActivatedRoute} from '@angular/router';
import {CartService} from '../../../services/order/cart.service';
import {HellparserService} from '@hellp/parser';
import {ProductService} from '../../../services/product/product.service';
import {DeliveryType, PaymentType} from '../../../utilities/types';
import {PurchaseInfoDtoTypes, PurchaseInfoService,} from '../../../services/purchase-info/purchase-info.service';
import {PurchaseInfoModel} from '../../../services/purchase-info/purchase-info.model';
import {map, Observable, of, take, takeUntil} from 'rxjs';
import {ProductModel,} from '../../../services/product/product.model';
import {UserDtoTypes, UserService} from '../../../services/user/user.service';
import {UserModel} from '../../../services/user/user.model';
import {BillingDataService} from '../../../services/order/billing-data.service';
import {BillingDataFormComponent} from "../../../components/form/billing-data-form/billing-data-form.component";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {
  BillingDataComponentMode,
  BillingDataDialogComponent
} from "../../../components/dialogs/billing-data-dialog/billing-data-dialog.component";
import {Utilities} from "../../../utilities/utilities";
import {
  BILLING_DATA_MODAL_ID
} from "../../../components/dialogs/edit-billing-data-dialog/edit-billing-data-dialog.component";

@Component({
  selector: 'app-user-order-form',
  templateUrl: './user-order-form.component.html',
  styleUrls: ['./user-order-form.component.css'],
})
@Page({
  path: ['new', 'edit/:id'],
  pathPrefix: ROUTING.ORDER,
})
export class UserOrderFormComponent extends FormPage<OrderModel, OrderService> implements OnInit, OnDestroy {
  @ViewChild('billingDataForm') billingDataPanel!: BillingDataFormComponent;
  @ViewChild('deliveryDataForm') deliveryPanel!: BillingDataFormComponent;

  private purchaseInfo!: PurchaseInfoModel;
  protected readonly BillingDataComponentMode = BillingDataComponentMode;
  protected readonly PaymentType = PaymentType;
  protected readonly DeliveryType = DeliveryType;
  cartService!: CartService;
  selectedName?: string;
  dynamicBackendOptions: (term: string) => any;
  cartModel: Cart;
  withExistUser = true;
  registeredUsers: UserModel[] = [];
  selectedUser?: UserModel;
  newBillingData = false;
  private $deliveryDataProcess: Observable<any> = of();

  constructor(service: OrderService, route: ActivatedRoute,
              private readonly dialogService: NxDialogService,
              private billingDataService: BillingDataService,
              private parser: HellparserService,
              private userService: UserService,
              private productService: ProductService,
              private purchaseInfoService: PurchaseInfoService,
  ) {
    super(route, service, OrderModel, []);
    this.cartModel = new Cart();
    this.purchaseInfoService
      .getAllByDeleted(PurchaseInfoDtoTypes.PurchaseInfoDTO)
      .pipe(takeUntil(this._destroyed))
      .subscribe((result) => {
        this.purchaseInfo = result[0];
        this.cartService = new CartService(
          this.cartModel,
          this.formModel.paymentType,
          this.formModel.deliveryType,
          this.purchaseInfo,
        );
      });
    this.dynamicBackendOptions = (term: string) =>
      productService.getHistoriesByProductNameFilter(term).pipe(
        takeUntil(this._destroyed),
        map(this.productService.getAutocompleteLabelProcess()),
      );
  }

  protected override afterModelIsSetProcess() {
    this.componentIsReady = true;
  }

  ngOnInit(): void {
    this.onInit(OrderDtoTypes.OrderDTO);
    this.userService
      .getAllRegistered(UserDtoTypes.UserDTO)
      .pipe(takeUntil(this._destroyed))
      .subscribe((result) => {
        this.registeredUsers = result;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  add() {
    if (this.selectedName && this.selectedName.length > 0) {
      this.productService.getHistoryByAutocompleteField(this.selectedName)
        .pipe(takeUntil(this._destroyed))
        .subscribe((result) => {
          if (result) {
            result.published = this.parser.parseApiDataToModel(
              result.published,
              ProductModel,
            );
            this.cartService.add(result, 1);
            this.selectedName = undefined;
          } else {
            this.productService.notificationService.showWarning('general.warning', 'general.unknownProductName',);
          }
        });
    }
  }

  onDeliveryAddressAdd() {
    if (this.formModel.deliveryDataSameAsBillingData) {
      this.formModel.deliveryData = undefined;
    }
  }

  protected override savePreProcess() {

    this.formModel.cart = this.cartModel;
    if (this.selectedUser) {
      this.formModel.user = this.selectedUser;
      this.formModel.userId = this.selectedUser.id;
    }
    this.$deliveryDataProcess = this.saveDeliveryDataIfNotExist();
    this.formModel.message = 'Ez egy adminisztrátor által létrehozott rendelés!';
    this.formModel.acceptedTerms = true;
    this.formModel.adminOrder = true;
  }

  private saveDeliveryDataIfNotExist(): Observable<any> {
    if (this.selectedUser) {
      if (this.formModel.deliveryDataSameAsBillingData) {
        this.selectedUser.deliveryData.forEach(dd => {
          if (dd.isSame(this.formModel.billingData)) {
            this.formModel.deliveryData = dd;
          }
        });
        if (!this.formModel.deliveryData || this.formModel.deliveryData.id < 1) {
          return this.billingDataService.createDeliveryDataForUser(this.formModel.billingData);
        }
        return of(this.formModel.deliveryData);
      }
      return of(this.formModel.deliveryData)
    } else {
      if (this.formModel.deliveryDataSameAsBillingData) {
        if (this.formModel.billingData) {
          return this.billingDataService.createDeliveryDataForUser(this.formModel.billingData);
        }
        return of(this.formModel.deliveryData);
      }
      return of(this.formModel.deliveryData);
    }
  }

  override saveProcess() {
    this.$deliveryDataProcess.pipe(take(1)).subscribe(deliveryData => {
      this.formModel.deliveryData = deliveryData;
      this.service.save(this.formModel, OrderDtoTypes.RedirectOrderDTO)
        .pipe(take(1)).subscribe((result) => {
        if (this.formModel.paymentType == PaymentType.BARION) {
          this.service.sendPaymentLinkViaEmail(result.orderNumber)
            .pipe(take(1))
            .subscribe((result) => {
              this.service.notificationService.showSuccess('general.success', 'order.createSuccess');
              this.service.navigateToBase();
            });
        } else {
          this.service.notificationService.showSuccess('general.success', 'order.createSuccess');
          this.service.navigateToBase();
        }
      });
    });
  }

  onUserSelect() {
    if (this.selectedUser) {
      this.formModel.user = this.selectedUser;
      this.formModel.userId = this.selectedUser.id;
      if (this.selectedUser.billingData.length > 0) {
        this.formModel.billingData = this.selectedUser.billingData[0];
        this.formModel.billingData.user = UserModel.getSlimUserFrom(
          this.selectedUser,
        );
      }
      if (this.formModel.deliveryType == DeliveryType.DELIVERY) {
        if (this.selectedUser.deliveryData.length > 0) {
          this.formModel.deliveryData = this.selectedUser.deliveryData[0];
          this.formModel.deliveryData.user = UserModel.getSlimUserFrom(
            this.selectedUser,
          );
        }
      }
    }
  }

  onChangeWithUser() {
    if (!this.withExistUser) {
      this.selectedUser = undefined;
      this.formModel.user = undefined;
      this.formModel.userId = undefined
    }
  }

  onAddBillingData(billingDataMode: BillingDataComponentMode) {
    this.dialogService.open(
      BillingDataDialogComponent,
      Utilities.getDefaultModalConfig({user: this.selectedUser, mode: billingDataMode}, BILLING_DATA_MODAL_ID)
    ).afterClosed().subscribe((result: BillingData) => {
      if (result.id) {
        if (billingDataMode == BillingDataComponentMode.BillingData) {
          this.addBillingDataToOrder(result);
        } else {
          this.addDeliveryDataToOrder(result);
        }
      }
    });
  }

  private addDeliveryDataToOrder(result: BillingData) {
    if (this.selectedUser) {
      this.selectedUser.deliveryData.push(result);
      this.formModel.deliveryData = result;
    } else {
      this.formModel.deliveryData = result;
      this.formModel.user = result.user;
      this.formModel.userId = result.user.id;
    }
  }

  private addBillingDataToOrder(result: BillingData) {
    if (this.selectedUser) {
      this.selectedUser.billingData.push(result);
      this.formModel.billingData = result;
    } else {
      this.formModel.billingData = result;
      this.formModel.user = result.user;
      this.formModel.userId = result.user.id;
    }
  }
}
