import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {ValidatorService} from "../../utilities/service/validator.service";
import {TermsAndConditionModel} from "./terms-and-condition.model";
import {TermsAndConditionRepository} from "./terms-and-condition.repository";

export enum TermsAndConditionDtoTypes {
  TermsAndConditionsDTO = 'TermsAndConditionsDTO'
}

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionService extends CrudService<TermsAndConditionModel, TermsAndConditionRepository> {

  constructor(repo: TermsAndConditionRepository) {
    super(ROUTING.TERMS_AND_CONDITION, repo, TermsAndConditionModel);
  }

  override validate(model: TermsAndConditionModel) {
    ValidatorService.required(model.attachment, "error.terms.attachmentRequired");
  }

}
