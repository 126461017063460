import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent {

  @Input() acceptString = 'image/*';
  @Input() isMultiple = false;
  @Output() addFileEvent:EventEmitter<File> = new EventEmitter<File>();

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target.files);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.addFileEvent.emit(item)
    }
  }


}
