<nx-card class="viewer">
  <page-list-view-header
    iconName="newspaper"
    [title]="'productMatrix.headline'"
  ></page-list-view-header>
  <div class="nx-margin-top-2m">
    <ng-container *ngIf="componentIsReady">

      <div nxRow="">
        <div nxCol="12" class="mx-auto">
          <div class="overflow-x-scroll">
            <table nxTable sticky="first">
              <thead>
              <tr nxTableRow>
                <td nxHeaderCell class="first-col-width bg-dark text-light"></td>
                <td nxHeaderCell *ngFor="let column of productList; let j = index">{{ column.product.name }}</td>
              </tr>
              </thead>
              <tbody>
              <tr nxTableRow *ngFor="let row of productList; let i = index">
                <td nxTableCell class="first-col-width bg-darker text-light">{{ row.product.name }}</td>
                <td nxTableCell *ngFor="let cell of productMatrix[i]; let j = index"
                    [ngClass]="{'diagonal':isDiagonal(i, j)}"
                    class="{{cell?cell.type+'-toggle':''}}">
                  <ng-container *ngIf="cell == null">

                  </ng-container>
                  <ng-container *ngIf="cell === undefined">
                    <nx-formfield nxLabel="{{'productMatrix.typeLabel' | translate}}" appearance="auto"
                                  nxFloatLabel="auto"
                                  class="nx-margin-top-s">
                      <nx-dropdown [ngModel]="null"
                                   (ngModelChange)="saveProductRelation($event, i, j)">
                        <nx-dropdown-item [nxValue]="null" disabled>{{ 'productMatrix.type.empty' | translate }}
                        </nx-dropdown-item>
                        <ng-container *ngFor="let type of Object.keys(ProductMatrixRelationType)">
                          <nx-dropdown-item [nxValue]="type">{{ 'productMatrix.type.' + type | translate }}
                          </nx-dropdown-item>
                        </ng-container>
                      </nx-dropdown>
                    </nx-formfield>
                  </ng-container>
                  <ng-container *ngIf="cell !== null && cell !== undefined">
                    <div>
                      <nx-formfield nxLabel="{{'productMatrix.typeLabel' | translate}}" appearance="auto"
                                    nxFloatLabel="auto"
                                    class=" nx-margin-top-s">
                        <nx-dropdown [(ngModel)]="cell.type"
                                     (ngModelChange)="updateTypeOfRelation(cell)">
                          <nx-dropdown-item [nxValue]="null">{{ 'productMatrix.type.reset' | translate }}
                          </nx-dropdown-item>
                          <ng-container *ngFor="let type of Object.keys(ProductMatrixRelationType)">
                            <nx-dropdown-item [nxValue]="type">{{ 'productMatrix.type.' + type | translate }}
                            </nx-dropdown-item>
                          </ng-container>
                        </nx-dropdown>
                      </nx-formfield>
                    </div>
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!componentIsReady">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </div>
</nx-card>