<div class="card  hover mb-2 ">
  <div class="card-header p-3 pt-2">
    <div
      class="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-4 right-position"
      [nxContextMenuTriggerFor]="menu"
    >
      <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
    </div>
    <div class="row pt-2">
      <div class="text-start col-md-6">
        <p class="text-sm ms-2 mb-0 text-capitalize">{{ descriptionWithoutHtml | shortify:74}}</p>
        <h4 class="ms-2 mb-0 d-flex align-items-center">{{category.name}}<span class="ms-2" nxHeadline="subsection-xsmall">({{category.shortName}})</span></h4>

      </div>
      <div class="col-md-6 text-start text-md-end">
        <img loading="lazy" [src]="category.attachment.src" alt="{{category.name}}" style="height: 80px; object-fit: contain"
             class="img-thumbnail rounded" (error)="category.attachment.src = 'assets/img/default.jpg'">
      </div>
    </div>
  </div>
  <ng-container *ngIf="category.subcategories && category.subcategories.length>0 && recursive">
    <div class="card-footer p-2 pb-3 text-start">
      <nx-link nxStyle="block">
        <a (click)="showSubTrans = !showSubTrans">
          <nx-icon *ngIf="!showSubTrans" [nxTooltip]="'category.openSubs' | translate" aria-hidden="true"
                   name="chevron-down"></nx-icon>

          <nx-icon *ngIf="showSubTrans" [nxTooltip]="'category.closeSubs' | translate" aria-hidden="true"
                   name="chevron-up"></nx-icon>

          {{'category.openSubs' | translate}}
        </a>
      </nx-link>
      <ng-container *ngIf="category.subcategories && category.subcategories.length>0 && showSubTrans && recursive">
        <div class="row">
          <ng-container *ngFor="let subCategory of category.subcategories">
            <div class="col-12 nx-margin-bottom-2m ps-5 w-auto">
              <app-category-element [actionDef]="actionDef" [category]="subCategory"
                                    [parent]="category"></app-category-element>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

  </ng-container>
</div>

<nx-context-menu #menu="nxContextMenu">
  <ng-container *ngFor="let action of actionDef">
    <button *ngIf="isHidden(category, action)"
            nxContextMenuItem
            aria-label="a useful label"
            type="button"
            class="nx-margin-right-2xs nx-margin-bottom-0"
            (click)="action.clickPredicate(category)"
    >
      <nx-icon name="{{action.iconName}}"></nx-icon>
      {{action.title | translate}}
    </button>
  </ng-container>

</nx-context-menu>
<style>

  .right-position {
    position: absolute;
    left: -16px;
  }

  .icon-shape {
    width: 28px;
    height: 28px;
    border-radius: var(--card-border-radius);
    cursor: pointer;
  }

  .bg-gradient-dark {
    background-image: linear-gradient(195deg, var(--dark-green-box-primary-background), var(--dark-green-box-secondary-background));
    color: #fefefe;
  }

  .shadow-dark {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(64, 64, 64, .4) !important;
  }


  .shadow {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06) !important;
  }

  .icon-lg nx-icon {
    top: 11%;
    font-size: 1rem;
  }

  .icon-shape nx-icon {
    color: #fff;
    opacity: .8;
    top: 6px;
    position: relative;
  }
</style>
