import {ConvertProcess, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../services/processor.service";
import {ProductHistoryModel} from "../services/product/product.model";

export class EntityRelatedProduct {
  @ParseTo(ProductHistoryModel)
  relatedProduct!: ProductHistoryModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getRelatedProductId'}, "model")
  relatedProductId = 0;
}
