import {BaseModel} from "../../utilities/abstract/base.model";
import {AttachmentModel} from "../attachment/attachment.model";
import {ConvertProcess, ParseProcess, ParseTo} from "@hellp/parser";
import {ProcessorService} from "../processor.service";

export class TermsAndConditionModel extends BaseModel {
  @ParseProcess({processorClass:ProcessorService, processorFunctionName:'parseDate'})
  createdAt:Date = new Date();
  status: TermsAndConditionStatus = TermsAndConditionStatus.ACTIVE;
  @ParseTo(AttachmentModel)
  attachment!: AttachmentModel;
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getAttachmentId'}, "model")
  attachmentId = 0;
}

export enum TermsAndConditionStatus {
  ACTIVE = 'ACTIVE',
  DEPRECATED = 'DEPRECATED',
}