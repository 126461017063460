import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormToolBarOption } from './form-tool-bar-option.model';
import { Subject, takeUntil } from 'rxjs';
import { NxBreakpoints, NxViewportService } from '@aposin/ng-aquila/utils';
import { PermissionCheck } from '../../utilities/abstract/permission-check';

@Component({
  selector: 'page-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
})
export class FormHeaderComponent extends PermissionCheck implements OnInit {
  @Input() withActions = true;
  @Input() model!: any;
  @Input() titleProperty = 'name';
  @Input() iconName = 'eye';

  @Input() disableSave!: boolean;
  @Input() disabledCancel!: boolean;
  @Input() saveSupplier!: () => void;
  @Input() cancelSupplier!: () => void;
  @Input() modalOptions: FormToolBarOption[] = [];

  @Input() title = '';
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<void> = new EventEmitter();

  modelType!: string;

  mobile = false;

  private readonly _destroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    public viewportService: NxViewportService,
    private _cdr: ChangeDetectorRef,
  ) {
    super();
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe((isGreaterThanXLarge) => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
    this.modelType = this.route.snapshot.url[0].path;
  }
}
