<ng-container *ngIf="model && componentIsReady">
  <nx-card class="viewer">
    <page-show-tool-bar
      iconName="leaf"
      title="{{'product.details' | translate}}"
      [backSupplier]="back()"
      [editSupplier]="edit()"
    ></page-show-tool-bar>
    <display-product-show [model]="model"></display-product-show>
  </nx-card>
</ng-container>
<ng-container *ngIf="!model || !componentIsReady">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>