export class AddressModel {
  zipCode = 1202;
  country = 'Magyarország';
  city = 'Budapest';
  street = 'Lázár utca';
  streetNumber = '48';
  other = '';

  public toString(): string {
    return `${this.country}, ${this.zipCode} ${this.city}, ${this.street} ${this.streetNumber} ${this.other}`;
  }
}
