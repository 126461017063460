import {Injectable} from '@angular/core';
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {PlantCulture} from "./plant-culture.model";
import {PlantCultureIssue} from "./plant-culture-issue.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/plant-culture-issues')
export class PlantCultureIssueRepository extends CrudRepository<PlantCultureIssue> {
  constructor() {
    super();
  }

}
