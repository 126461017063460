<div class="card nx-margin-bottom-m box-shadow" data-animation="true">
  <div class="product-card card-header p-0 position-relative  mx-3 z-index-2  border-radius-lg border-none">
    <div class="card-img d-block blur-shadow-image ">
      <ng-container *ngIf="product.mainImage.attachment">
        <img [src]="product.mainImage.attachment.src" height="260" loading="lazy"
             alt="{{product.mainImage.attachment.name}}"
             class="img-fluid rounded"
             (error)="product.mainImage.attachment.src = 'assets/img/default.jpg'">

      </ng-container>

    </div>
  </div>
  <div class="card-body ">
    <div class="fix-height-2">
      <h3 class="mt-1" nxHeadline="subsection">
        {{ product.product.name }}
      </h3>
      <p>
        {{ product.product.short }}
      </p>
    </div>
  </div>
</div>
