<nx-card class="viewer">

  <ng-container *ngIf="!isEdit">
    <page-list-view-header
      [newConsumer]="edit()"
      newIcon="pencil"
      title="{{'menu.privacyPolicy' | translate}}"
      iconName="paragraph"
    ></page-list-view-header>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-2m">
        <div class="px-2 wysiwyg-description" [innerHTML]="formModel.content | safeHtml"></div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isEdit">
    <page-form-header #formHeader iconName="icons" [model]="formModel"
                      [cancelSupplier]="cancel()" [saveSupplier]="save()"
                      [modalOptions]="toolbarAddOptions"
    ></page-form-header>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-2m">

        <div class="NgxEditor__Wrapper" style="min-height: 500px">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
          <ngx-editor style="height: 500px" [editor]="editor" name="description" [(ngModel)]="formModel.content"
                      [disabled]="false"
                      placeholder="{{'aboutUs.aboutUs' | translate}}"></ngx-editor>
        </div>
      </div>
    </div>

  </ng-container>
</nx-card>
