import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-datetimepicker',
  template: `
    <nx-formfield nxLabel="Birthday">
      <input
        nxDatefield
        nxInput
        [nxDatepicker]="myDatepicker"
        formControlName="date"
      />
      <span nxFormfieldHint>MM/DD/YYYY</span>

      <nx-datepicker-toggle
        [for]="myDatepicker"
        nxFormfieldSuffix
      ></nx-datepicker-toggle>
      <nx-datepicker #myDatepicker></nx-datepicker>
    </nx-formfield>
  `,
})
export class DatepickerTypeComponent extends FieldType {}
