<ng-container *ngIf="model">
  <div nxLayout="grid" class="nx-margin-top-2m">
    <div nxRow="">
      <div nxCol="12,12,8,6,7">
        <app-gallery-panel [attachments]="model.gallery"></app-gallery-panel>

        <h6 nxHeadline="subsection-large" class="nx-margin-y-m ">{{ 'product.tags' | translate }}</h6>
        <ng-container *ngFor="let tag of model.history.tagList">
          <div class="tag {{'tag-'+tag.html.type}} me-3">
            {{ tag.html.nxTaglistLabel }}
          </div>
        </ng-container>
      </div>
      <div nxCol="12,12,8,6,5">
        <h4 nxHeadline="subsection-small">{{ model.history.category.name }}</h4>
        <h3 nxHeadline="section">{{ model.name }}</h3>
        <h6>
          <span nxHeadline="subsection-xsmall">{{ 'product.itemNr' | translate }}: </span><span
          nxHeadline="subsection-small">{{ model.itemNr }}</span>,
          <span nxHeadline="subsection-xsmall">{{ 'product.SKU' | translate }}: </span><span
          nxHeadline="subsection-small">{{ model.sku }}</span>
        </h6>
        <br>
        <h6 nxHeadline="subsection-small">{{ 'product.price' | translate }}</h6>
        <h5 nxHeadline="subsection-large" *ngIf="model.basePrice">{{ model.basePrice | grossPrice }} <span
          style="font-size: 14px">({{ model.basePrice | price }})</span></h5>
        <br>
        <h6 nxHeadline="subsection-small">{{ 'product.description' | translate }}</h6>
        <p nxHeadline="subsection-large">{{ model.short }}</p>
        <br>
        <app-dimension-panel [dimensions]="model.dimensions" [weightKg]="model.weightKg"></app-dimension-panel>
        <br>
        <app-category-order-config-panel [model]="model.history.orderConfig"
                                         [notForCategory]="true"></app-category-order-config-panel>

        <h6 nxHeadline="subsection-small">{{ 'product.productInfo' | translate }}</h6>
        <table nxTable zebra class="nx-table--scrollable">
          <tbody>
          <tr nxTableRow>
            <td nxTableCell>{{ 'product.elementOfProductMatrix' |translate }}</td>
            <td
              nxTableCell>{{ model.history.elementOfProductMatrix ? ('general.yes' |translate) : ('general.no' |translate) }}
            </td>
          </tr>
          <tr nxTableRow *ngFor="let dynamicAttribute of model.history.dynamicAttributes | keyvalue">
            <td nxTableCell>{{ dynamicAttribute.key }}</td>
            <td nxTableCell>{{ dynamicAttribute.value }}</td>
          </tr>
          </tbody>
        </table>
        <br>
        <h6 nxHeadline="subsection-large" class="nx-margin-bottom-m">{{ 'product.stockInfo' | translate }}</h6>
        <app-product-stock-panel></app-product-stock-panel>
      </div>
      <div nxCol="12" class="nx-margin-top-2m">
        <h6 nxHeadline="subsection-large" class="nx-margin-bottom-m">{{ 'product.description' | translate }}</h6>
        <div [innerHTML]="model.description | safeHtml"></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!model">
  <div class="d-flex justify-content-center nx-margin-y-2m">
    <app-spinner></app-spinner>
  </div>
</ng-container>
