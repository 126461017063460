import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MagazineModel} from "../../../services/article/magazine.model";

@Component({
  selector: 'display-magazine',
  templateUrl: './magazine-display.component.html',
  styleUrls: ['./magazine-display.component.scss']
})
export class MagazineDisplayComponent implements OnInit {
  @Input() magazine!: MagazineModel

  @ViewChild('goUp', {static: true}) contentPage!: ElementRef;
  count = 210;
  page = 1;
  perPage = 1;

  ngOnInit(): void {
    this.count = this.magazine.pagedContent.length - 1;
    this.page = 1;
  }


  prevPage() {
    this.page--;

    this.contentPage.nativeElement.scrollIntoView();
  }

  nextPage() {
    this.page++;

    this.contentPage.nativeElement.scrollIntoView();
  }

  goToPage(n: number) {
    this.page = n;

    this.contentPage.nativeElement.scrollIntoView();
  }
}
