import {BaseModel} from "../../utilities/abstract/base.model";
import {Note, SupplierOrder, SupplierOrderElement, SupplierOrderStatus} from "../supplier-order/supplier-order.model";
import {ConvertFrom, ConvertProcess, ParseTo} from "@hellp/parser";
import {StockProduct, WarehouseModel} from "../warehouse/warehouse.model";
import {ProcessorService} from "../processor.service";

export class GoodsLandingElement extends BaseModel {
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT
  count = 0;

  @ParseTo(SupplierOrderElement)
  supplierOrderElement!: SupplierOrderElement;

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getSupplierOrderElementId'}, "model")
  supplierOrderElementId = 0;

  note: Note = new Note();
  message = '';

  @ParseTo(StockProduct)
  stockProduct!: StockProduct;

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getStockProductId'}, "model")
  stockProductId = 0;
}

export class GoodsLanding extends BaseModel {
  status: SupplierOrderStatus = SupplierOrderStatus.DRAFT
  @ParseTo(GoodsLandingElement)
  @ConvertFrom
  elements: GoodsLandingElement[] = [];
  note: Note = new Note();
  message = '';
  @ParseTo(WarehouseModel)
  warehouse!: WarehouseModel

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getWarehouseIdIfExist'}, "model")
  warehouseId = 0;

  get supplierName(): string {
    if (this.elements) {
      return this.elements[0].supplierOrderElement.supplierProduct.companyName;
    }
    return '';
  }

  static newBySupplierOrder(p1: SupplierOrder) {
    const tmp = new GoodsLanding();
    const elements: GoodsLandingElement[] = [];
    p1.elements.forEach(e => {
      const goodsLandingElement = new GoodsLandingElement();
      goodsLandingElement.supplierOrderElement = e;
      const a = p1.warehouse.products.find(sp => sp.product.supplierProduct.id = e.supplierProduct.id);
      if (a) {
        goodsLandingElement.stockProduct = a;
      }
      goodsLandingElement.count = e.count;
      elements.push(goodsLandingElement);
    });
    tmp.elements = elements;
    tmp.warehouse = p1.warehouse;
    tmp.message = p1.message;

    return tmp;
  }
}
