import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LayoutHeaderComponent} from './layout-header/layout-header.component';
import {LayoutSidebarComponent} from './layout-sidebar/layout-sidebar.component';
import {NxHeaderModule} from '@aposin/ng-aquila/header';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {NxContextMenuModule} from '@aposin/ng-aquila/context-menu';
import {NxLinkModule} from '@aposin/ng-aquila/link';
import {NxSidebarModule} from '@aposin/ng-aquila/sidebar';
import {NxActionModule} from '@aposin/ng-aquila/action';
import {NxTreeModule} from '@aposin/ng-aquila/tree';
import {NxTooltipModule} from '@aposin/ng-aquila/tooltip';
import {ListViewHeaderComponent} from './list-view-header/list-view-header.component';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {ShowToolBarComponent} from './show-tool-bar/show-tool-bar.component';
import {NxToolbarModule} from '@aposin/ng-aquila/toolbar';
import {FormHeaderComponent} from './form-header/form-header.component';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {FormlyFormComponent} from './formly-form/formly-form.component';
import {PanelWrapperComponent} from './formly/panel-type/panel-type.component';
import {DatepickerTypeComponent} from './formly/datepicker-type/datepicker-type.component';
import {ObjectTypeComponent} from './formly/object-type/object-type.component';
import {ArrayTypeComponent} from './formly/array-type/array-type.component';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {NxDatefieldModule} from '@aposin/ng-aquila/datefield';
import {CategoryElementComponent} from './category-element/category-element.component';
import {NxDataDisplayModule} from '@aposin/ng-aquila/data-display';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxBadgeModule} from '@aposin/ng-aquila/badge';
import {ProductElementComponent} from './product-element/product-element.component';
import {AddDynamicAttributeComponent} from './dialogs/add-dynamic-attribute/add-dynamic-attribute.component';
import {NxModalModule} from '@aposin/ng-aquila/modal';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MapTypeComponent} from './formly/map-type/map-type.component';
import {DynamicAttributePanelComponent} from './panel/dynamic-attribute-panel/dynamic-attribute-panel.component';
import {AttachmentShowComponent} from './dialogs/attachment-show/attachment-show.component';
import {DirectivesModule} from '../directives/directives.module';
import {NxNotificationPanelModule} from '@aposin/ng-aquila/notification-panel';
import {NxCopytextModule} from '@aposin/ng-aquila/copytext';
import {NxIndicatorModule} from '@aposin/ng-aquila/indicator';
import {NxImageModule} from '@aposin/ng-aquila/image';
import {LineChartComponent} from './line-chart/line-chart.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {PipeModule} from '../pipe/pipe.module';
import {AttachmentHandlerComponent} from './attachment/attachment-handler/attachment-handler.component';
import {DragAndDropComponent} from './attachment/drag-and-drop/drag-and-drop.component';
import {
  ImageAttachmentElementComponent
} from './attachment/image-attachment-element/image-attachment-element.component';
import {NxAccordionModule} from '@aposin/ng-aquila/accordion';
import {
  SingleAttachmentUploadComponent
} from './attachment/single-attachment-upload/single-attachment-upload.component';
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {AddImageToModelComponent} from './dialogs/add-image-to-model/add-image-to-model.component';
import {AttachmentElementComponent} from './attachment/attachment-element/attachment-element.component';
import {NxMenuModule} from '@aposin/ng-aquila/menu';
import {ImageUploadComponent} from './attachment/image-upload/image-upload.component';
import {DynamicAttributeFormComponent} from './form/dynamic-attribute-form/dynamic-attribute-form.component';
import {OrderConfigFormComponent} from './form/order-config-form/order-config-form.component';
import {NxDropdownModule} from '@aposin/ng-aquila/dropdown';
import {NxSwitcherModule} from '@aposin/ng-aquila/switcher';
import {SpinnerComponent} from './spinner/spinner.component';
import {NxSliderModule} from '@aposin/ng-aquila/slider';
import {NxNumberStepperModule} from '@aposin/ng-aquila/number-stepper';
import {CategoryBasePanelComponent} from './panel/category-base-panel/category-base-panel.component';
import {
  CategoryOrderConfigPanelComponent
} from './panel/category-order-config-panel/category-order-config-panel.component';
import {NxListModule} from '@aposin/ng-aquila/list';
import {CategoryBaseFormComponent} from './form/category-base-form/category-base-form.component';
import {PdfsUploadComponent} from './attachment/pdfs-upload/pdfs-upload.component';
import {ProductBaseFormComponent} from './form/product-base-form/product-base-form.component';
import {NgxEditorModule} from 'ngx-editor';
import {PriceFormComponent} from './form/price-form/price-form.component';
import {ProductPackFormComponent} from './form/product-pack-form/product-pack-form.component';
import {TagFormComponent} from './form/tag-form/tag-form.component';
import {NxTaglistModule} from '@aposin/ng-aquila/taglist';
import {NxRadioToggleModule} from '@aposin/ng-aquila/radio-toggle';
import {NxRadioModule} from '@aposin/ng-aquila/radio-button';
import {ProductBasePanelComponent} from './panel/product-base-panel/product-base-panel.component';
import {GalleryPanelComponent} from './panel/gallery-panel/gallery-panel.component';
import {GalleryModule} from 'ng-gallery';
import {DimensionPanelComponent} from './panel/dimension-panel/dimension-panel.component';
import {NxPopoverModule} from '@aposin/ng-aquila/popover';
import {ProductStockPanelComponent} from './panel/product-stock-panel/product-stock-panel.component';
import {SupplierProductFormComponent} from './form/supplier-product-form/supplier-product-form.component';
import {ProductShowDisplayComponent} from './dispaly/product-show-dispaly/product-show-display.component';
import {RelatedProductFormComponent} from './form/related-product-form/related-product-form.component';
import {ProductCardDisplayComponent} from './dispaly/product-card-display/product-card-display.component';
import {ProductDetailsModalComponent} from './dialogs/product-details-modal/product-details-modal.component';
import {NxTableModule} from '@aposin/ng-aquila/table';
import {MagazineDisplayComponent} from './dispaly/magazine-display/magazine-display.component';
import {NxPaginationModule} from '@aposin/ng-aquila/pagination';
import {CartTableDisplayComponent} from './dispaly/cart-table-display/cart-table-display.component';
import {
  OrderBillingDataDisplayComponent
} from './dispaly/order-billing-data-display/order-billing-data-display.component';
import {OrderDetailsDisplayComponent} from './dispaly/order-details-display/order-details-display.component';
import {PdfUploadComponent} from './attachment/pdf-upload/pdf-upload.component';
import {DateRangeDialogComponent} from './dialogs/date-range-dialog/date-range-dialog.component';
import {AddDeliveryPartnerComponent} from './dialogs/add-delivery-partner/add-delivery-partner.component';
import {DeliveryPartnerFormComponent} from './form/delivery-parnert-form/delivery-partner-form.component';
import {EditOrderDialogComponent} from './dialogs/edit-order-dialog/edit-order-dialog.component';
import {EditBillingDataDialogComponent} from './dialogs/edit-billing-data-dialog/edit-billing-data-dialog.component';
import {BillingDataFormComponent} from './form/billing-data-form/billing-data-form.component';
import {SendEmailDialogComponent} from './dialogs/send-email-dialog/send-email-dialog.component';
import {NxAutocompleteModule} from '@aposin/ng-aquila/autocomplete';
import {StartDeliveryDialogComponent} from './dialogs/start-delivery-dialog/start-delivery-dialog.component';
import {ProductCartCardDisplayComponent} from './dispaly/product-cart-card-display/product-cart-card-display.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {StockConfigDialogComponent} from './dialogs/stock-config-dialog/stock-config-dialog.component';
import {NxTabsModule} from '@aposin/ng-aquila/tabs';
import {ImagesUploadComponent} from './attachment/images-upload/images-upload.component';
import {AddPlantCultureDialogComponent} from './dialogs/add-plant-culture-dialog/add-plant-culture-dialog.component';
import {
  AddPlantCultureIssueDialogComponent
} from './dialogs/add-plant-culture-issue-dialog/add-plant-culture-issue-dialog.component';
import {YoutubeVideoPanelComponent} from './panel/youtube-video-panel/youtube-video-panel.component';
import {
  PlantCultureIssuePairingDisplayComponent
} from './dispaly/plant-culture-issue-pairing-display/plant-culture-issue-pairing-display.component';
import {NxSmallStageModule} from '@aposin/ng-aquila/small-stage';
import {
  AddPlantCultureIssueTypeDialogComponent
} from './dialogs/add-plant-culture-issue-type-dialog/add-plant-culture-issue-type-dialog.component';
import {ListContainerComponent} from './container/list-container/list-container.component';
import {FormContainerComponent} from './container/form-container/form-container.component';
import {StepperContainerComponent} from './container/stepper-container/stepper-container.component';
import {NxProgressStepperModule} from "@aposin/ng-aquila/progress-stepper";
import {CardContainerComponent} from './container/card-container/card-container.component';
import {BillingDataDialogComponent} from "./dialogs/billing-data-dialog/billing-data-dialog.component";
import { SupplierOrderInvoiceDialogComponent } from './dialogs/supplier-order-invoice-dialog/supplier-order-invoice-dialog.component';
import { AddPdfToModelComponent } from './dialogs/add-pdf-to-model/add-pdf-to-model.component';

@NgModule({
  declarations: [
    BillingDataDialogComponent,
    ImagesUploadComponent,
    OrderBillingDataDisplayComponent,
    CartTableDisplayComponent,
    LayoutHeaderComponent,
    LayoutSidebarComponent,
    ListViewHeaderComponent,
    ShowToolBarComponent,
    FormHeaderComponent,
    FormlyFormComponent,
    ArrayTypeComponent,
    DatepickerTypeComponent,
    ObjectTypeComponent,
    PanelWrapperComponent,
    CategoryElementComponent,
    ProductElementComponent,
    AddDynamicAttributeComponent,
    MapTypeComponent,
    DynamicAttributePanelComponent,
    AttachmentShowComponent,
    LineChartComponent,
    AttachmentHandlerComponent,
    DragAndDropComponent,
    ImageAttachmentElementComponent,
    SingleAttachmentUploadComponent,
    AddImageToModelComponent,
    AttachmentElementComponent,
    ImageUploadComponent,
    PdfsUploadComponent,
    DynamicAttributeFormComponent,
    OrderConfigFormComponent,
    SpinnerComponent,
    CategoryBasePanelComponent,
    CategoryOrderConfigPanelComponent,
    CategoryBaseFormComponent,
    ProductBaseFormComponent,
    PriceFormComponent,
    ProductPackFormComponent,
    TagFormComponent,
    ProductBasePanelComponent,
    GalleryPanelComponent,
    DimensionPanelComponent,
    ProductStockPanelComponent,
    SupplierProductFormComponent,
    ProductShowDisplayComponent,
    RelatedProductFormComponent,
    ProductCardDisplayComponent,
    ProductDetailsModalComponent,
    MagazineDisplayComponent,
    OrderDetailsDisplayComponent,
    PdfUploadComponent,
    DateRangeDialogComponent,
    AddDeliveryPartnerComponent,
    DeliveryPartnerFormComponent,
    EditOrderDialogComponent,
    EditBillingDataDialogComponent,
    BillingDataFormComponent,
    SendEmailDialogComponent,
    StartDeliveryDialogComponent,
    ProductCartCardDisplayComponent,
    StockConfigDialogComponent,
    PdfsUploadComponent,
    AddPlantCultureDialogComponent,
    AddPlantCultureIssueDialogComponent,
    YoutubeVideoPanelComponent,
    PlantCultureIssuePairingDisplayComponent,
    AddPlantCultureIssueTypeDialogComponent,
    ListContainerComponent,
    FormContainerComponent,
    StepperContainerComponent,
    CardContainerComponent,
    SupplierOrderInvoiceDialogComponent,
    AddPdfToModelComponent,
  ],
  exports: [
    BillingDataDialogComponent,
    ImagesUploadComponent,
    PdfUploadComponent,
    PdfsUploadComponent,
    OrderBillingDataDisplayComponent,
    CartTableDisplayComponent,
    LayoutHeaderComponent,
    LayoutSidebarComponent,
    ListViewHeaderComponent,
    ShowToolBarComponent,
    FormHeaderComponent,
    FormlyFormComponent,
    CategoryElementComponent,
    MapTypeComponent,
    DynamicAttributePanelComponent,
    AttachmentShowComponent,
    LineChartComponent,
    AttachmentHandlerComponent,
    DragAndDropComponent,
    ImageAttachmentElementComponent,
    SingleAttachmentUploadComponent,
    AddImageToModelComponent,
    AttachmentElementComponent,
    ImageUploadComponent,
    PdfsUploadComponent,
    DynamicAttributeFormComponent,
    OrderConfigFormComponent,
    SpinnerComponent,
    CategoryBasePanelComponent,
    CategoryOrderConfigPanelComponent,
    CategoryBaseFormComponent,
    ProductBaseFormComponent,
    PriceFormComponent,
    ProductPackFormComponent,
    TagFormComponent,
    ProductBasePanelComponent,
    GalleryPanelComponent,
    DimensionPanelComponent,
    ProductStockPanelComponent,
    SupplierProductFormComponent,
    ProductShowDisplayComponent,
    RelatedProductFormComponent,
    NgxEditorModule,
    MagazineDisplayComponent,
    OrderDetailsDisplayComponent,
    AddPlantCultureIssueTypeDialogComponent,
    BillingDataFormComponent,
    ProductCartCardDisplayComponent,
    YoutubeVideoPanelComponent,
    PlantCultureIssuePairingDisplayComponent,
    ListContainerComponent,
    FormContainerComponent,
    StepperContainerComponent,
    CardContainerComponent,
  ],
  imports: [
    OverlayModule,
    CommonModule,
    NxHeaderModule,
    RouterLinkActive,
    RouterLink,
    TranslateModule,
    NxIconModule,
    NxContextMenuModule,
    NxLinkModule,
    NxSidebarModule,
    NxActionModule,
    NxTreeModule,
    NxTooltipModule,
    NxButtonModule,
    NxToolbarModule,
    NxHeadlineModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      validationMessages: [{name: 'required', message: 'Kötelező mező!'}],
      wrappers: [{name: 'panel', component: PanelWrapperComponent}],
      types: [
        {name: 'datepicker', component: DatepickerTypeComponent},
        {name: 'object', component: ObjectTypeComponent},
        {name: 'map', component: MapTypeComponent},
        {name: 'array', component: ArrayTypeComponent},
      ],
    }),
    FormlyBootstrapModule,
    NxGridModule,
    NxInputModule,
    NxDatefieldModule,
    NxDataDisplayModule,
    NxCardModule,
    NxBadgeModule,
    NxModalModule,
    TextFieldModule,
    FormsModule,
    DirectivesModule,
    NxNotificationPanelModule,
    NxCopytextModule,
    NxIndicatorModule,
    NxImageModule,
    NgApexchartsModule,
    PipeModule,
    NxAccordionModule,
    CdkDropList,
    CdkDrag,
    NxMenuModule,
    NxDropdownModule,
    NxSwitcherModule,
    NxSliderModule,
    NxNumberStepperModule,
    NxListModule,
    NgOptimizedImage,
    NgxEditorModule,
    NxTaglistModule,
    NxRadioToggleModule,
    NxRadioModule,
    GalleryModule,
    NxPopoverModule,
    NxTableModule,
    NxPaginationModule,
    NxAutocompleteModule,
    NxTabsModule,
    NxSmallStageModule,
    NxProgressStepperModule,
  ],
})
export class ComponentsModule {
}
