import {BaseModel, Slugable} from "../../utilities/abstract/base.model";
import {ConvertFrom, ConvertProcess, IgnoreProperty, ParseTo} from "@hellp/parser";
import {Currencies, DeliveryType, PaymentType, StringStringMap} from "../../utilities/types";
import {PriceModel} from "../../models/price.model";
import {EntityAttachment} from "../attachment/attachment.model";
import {ProcessorService} from "../processor.service";


export class CategoryModel extends BaseModel implements Slugable {
  name = '';
  description = ''
  shortName = '';
  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'slugGenerator'}, "model")
  slug = '';
  cartNote: string | null = '';
  dynamicAttributes: StringStringMap = {}
  @IgnoreProperty
  @ParseTo(CategoryModel)
  parent: CategoryModel | null = null;
  @IgnoreProperty
  @ParseTo(CategoryModel)
  subcategories: CategoryModel[] = [];

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'ifEmptySetNull'})
  orderConfig: OrderConfigModel = new OrderConfigModel();

  @ConvertProcess({processorClass: ProcessorService, processorFunctionName: 'getParentId'}, "model")
  parentId = 0;

  @ParseTo(EntityAttachment)
  @ConvertFrom
  image: EntityAttachment = new EntityAttachment();

  get sku(): string {
    let sku = '';
    if (this.parent) {
      sku = `${this.parent.sku}`;
    }
    sku += `${this.shortName}/`;
    return sku;
  }

  get attachment() {
    return this.image.attachment;
  }

  get slugField(): string {
    return this.name;
  }


  public mayHaveChildren(): boolean {
    return (this.parent == null) || (this.parent && this.parent.parent == null);
  }

  filterByName(name: string | undefined) {
    if (name) {
      if (this.name.toLowerCase().includes(name.toLowerCase())) {
        return true;
      }
      if (this.subcategories && this.subcategories.length > 0) {
        const found: CategoryModel[] = this.subcategories.filter(cat => cat.filterByName(name));
        return found.length > 0;
      }
    }
    return false;

  }
}

export class OrderConfigModel {
  deliveryConfig?: DeliveryConfig;
  financialConfig?: FinancialConfig;
  packagingConfig?: PackagingConfig;
  extraPrices?: ExtraPriceModel[];
  cartExclusive = false;

  hasData(): boolean {
    return (this.deliveryConfig != undefined || this.financialConfig != undefined || this.packagingConfig != undefined || (this.extraPrices != undefined && this.extraPrices.length > 0));
  }
}

export class ExtraPriceModel {
  key = '';
  @ParseTo(PriceModel)
  price: PriceModel;

  constructor(key?: string, price?: PriceModel) {
    this.key = key ?? '';
    this.price = price ?? new PriceModel(Currencies.huf, 0, 0);
  }
}

export class PackagingConfig {
  icePackRanges?: IcePackRange[]

}

export class FinancialConfig {
  availableModes: PaymentType[] = [PaymentType.BARION, PaymentType.CASH];
  tax = 0
}

export class DeliveryConfig {
  availableModes: DeliveryType[] = [DeliveryType.DELIVERY, DeliveryType.ONSITE];
}

export class IcePackRange {
  start = 1;
  end = 2;
  count = 1;

  constructor(start?: number, end?: number, count?: number) {
    this.start = start ?? 1;
    this.end = end ?? 2;
    this.count = count ?? 1;
  }
}
