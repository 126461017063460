import {Injectable} from '@angular/core';
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {UserRepository} from "./user.repository";
import {UserModel} from "./user.model";
import {map, Observable} from "rxjs";
import {PostPayload} from "../../models/post-payload.model";

export enum UserDtoTypes {
  UserDTO = 'UserDTO',
  SlimUserDTO = 'SlimUserDTO'
}


@Injectable({
  providedIn: 'root'
})
export class UserService extends CrudService<UserModel, UserRepository<UserModel>> {

  constructor(repo: UserRepository<UserModel>) {
    super(ROUTING.USER, repo, UserModel);
  }

  findByEmail(email: string, dto: string = UserDtoTypes.UserDTO): Observable<UserModel | null> {
    const filter = this.filterService.filter(dto)
      .equal("email", email).create();

    return this.repo.getAllByFilter(filter.stringify(), UserModel).pipe(
      map((result: UserModel[]) => {
        if (result.length > 0) {
          return result[0]
        }
        return null;
      }));
  }

  registration(userModel: UserModel) {
    return this.repo.registration(new PostPayload<UserModel>(userModel, UserDtoTypes.SlimUserDTO));
  }

  getAllRegistered(dto: string = UserDtoTypes.SlimUserDTO) {
    const filter = this.filterService.filter(dto)
      .isNotNull('keycloakId')
      .create();
    return this.repo.getAllByFilter(filter.stringify(), UserModel);
  }

}

