import {Injectable} from "@angular/core";
import {CrudService} from "../../utilities/service/crud.service";
import {ROUTING} from "../../utilities/routing";
import {OrderRepository} from "./order.repository";
import {BillingData, Cart, EmailData, ExtraCartElement, OrderModel} from "./order";
import {DeliveryType, OrderStatus, PaymentType} from "../../utilities/types";
import {map, Observable, Subject} from "rxjs";
import {Utilities} from "../../utilities/utilities";
import {EmailRepository} from "./email.repository";
import {CartRepository} from "./cart.repository";
import {PutPayload} from "../../models/put-payload.model";
import {DeliveryPartnerType} from "../purchase-info/purchase-info.model";
import {CASH_PRICE_KEY, DELIVERY_PRICE_KEY} from "./cart.service";
import {ValidatorService} from "../../utilities/service/validator.service";
import {PageMetaDataConf} from "../../utilities/page-meta-data.conf";

export enum OrderDtoTypes {
  OrderDTO = 'OrderDTO',
  RedirectOrderDTO = 'RedirectOrderDTO'
}

export enum CartDtoTypes {
  CartDTO = 'CartDTO'
}


@Injectable({
  providedIn: 'root'
})
export class OrderService extends CrudService<OrderModel, OrderRepository> {
  $cartRefresh: Subject<boolean> = new Subject<boolean>();

  constructor(repo: OrderRepository, private emailRepo: EmailRepository, private cartRepo: CartRepository) {
    super(ROUTING.ORDER, repo, OrderModel, PageMetaDataConf.USER_ORDER);
  }

  override save(model: OrderModel, responseDTO?: string): Observable<any> {
    return this.repo.adminOrderSave(model, OrderModel);
  }

  sendEmailToUsers(emailData: EmailData) {
    return this.emailRepo.sendOrderEmails(emailData);
  }

  sendPaymentLinkViaEmail(orderNumber: string) {
    return this.repo.sendPaymentLink(orderNumber);
  }

  generatePdf(orderNumbers: string[]) {
    return this.repo.generatePdf(orderNumbers);
  }

  payByCash(order: OrderModel) {
    return this.repo.payByCash(order.id);
  }

  getAllByDeletedAndDateRange(from: Date, to: Date, deleted = false) {
    const filter = this.filterService.filter(OrderDtoTypes.OrderDTO)
      .equal('deleted', deleted)
      .and()
      .greaterThanEqual('createdAt', Utilities.dateToBackendString(from))
      .and()
      .lessThanEqual('createdAt', Utilities.dateToBackendString(to))
      .create();
    return this.repo.getAllByFilter(filter.stringify(), OrderModel);
  }

  statusActionIsAvailable(order: OrderModel, status: OrderStatus): boolean {
    switch (status) {
      case OrderStatus.BEING_PREPARED:
        return order.status == OrderStatus.ORDERED;
      case OrderStatus.PREPARED:
        return order.status == OrderStatus.BEING_PREPARED;
      case OrderStatus.PACKAGE_BEING_CHECKED:
        return order.status == OrderStatus.PREPARED;
      case OrderStatus.PACKAGE_CHECKED:
        return order.status == OrderStatus.PACKAGE_BEING_CHECKED;
      case OrderStatus.AWAITING_COURIER_PICKUP:
        return order.status == OrderStatus.PACKAGE_CHECKED && order.deliveryType == DeliveryType.DELIVERY;
      case OrderStatus.AWAITING_ONSITE_PICKUP:
        return order.status == OrderStatus.PACKAGE_CHECKED && order.deliveryType == DeliveryType.ONSITE;
      case OrderStatus.COMPLETED:
        return (order.status == OrderStatus.IN_TRANSIT && order.deliveryType == DeliveryType.DELIVERY)
          || (order.status == OrderStatus.AWAITING_ONSITE_PICKUP && order.deliveryType == DeliveryType.ONSITE);
    }
    return false
  }

  resendEmail(id: number): Observable<any> {
    return this.repo.resendEmail(id);
  }

  reCreateCart(id: number, cart: Cart): Observable<any> {
    return this.repo.reCreateCart(id, cart);
  }

  changeStatus(id: number, status: OrderStatus) {
    return this.repo.changeStatus(id, status);
  }

  createInvoice(id: number) {
    return this.repo.createInvoice(id);
  }

  addDeliveryData(id: number, deliveryData: BillingData) {
    return this.repo.addDeliveryData(id, {id: id, deliveryDataId: deliveryData.id});
  }

  refreshDeliveryStatus(id: number) {
    return this.repo.refreshDeliveryStatus(id);
  }

  updateCart(cart: Cart) {
    return this.cartRepo.update(cart.id, new PutPayload<Cart>(cart), Cart);
  }

  getCartById(id: number): Observable<Cart | undefined> {
    const filter = this.filterService.filter(CartDtoTypes.CartDTO)
      .equal('id', id)
      .create();
    return this.cartRepo.getAllByFilter(filter.stringify(), Cart).pipe(map(result => result.length > 0 ? result[0] : undefined));
  }

  startDeliveryProcess(id: number, partnerType: DeliveryPartnerType) {
    return this.repo.startDeliveryProcess(id, partnerType);
  }


  getAllByUser(userId: number) {
    const filter = this.filterService.filter(OrderDtoTypes.OrderDTO)
      .equal('user_id', userId)
      .create();
    return this.repo.getAllByFilter(filter.stringify(), OrderModel);
  }

  getShipmentLabel(id: number, partnerType: DeliveryPartnerType, shipmentId: number) {
    return this.repo.getShipmentLabel(id, partnerType, shipmentId);
  }

  cancelOrderByAdmin(model: OrderModel) {
    return this.repo.cancelOrderByAdmin(model.id);
  }

  override validate(order: OrderModel) {
    ValidatorService.allRequired(order, ['billingData', 'user', 'userId'], OrderModel.name);
    ValidatorService.allRequired(order.billingData, ['email', 'userId', 'id'], BillingData.name);
    if (order.cart.cartElements.length < 1) {
      throw new Error('error.order.cartIsEmpty');
    }
    if (!order.billingData || order.billingData.id < 1) {
      throw new Error('error.order.noBillingData');
    }
    if (order.paymentType == PaymentType.CASH && !order.cart.hasExtraTo(CASH_PRICE_KEY)) {
      throw new Error('error.order.cantSendOrderByCashExtra');
    }
    if (order.deliveryType == DeliveryType.DELIVERY && !order.cart.hasExtraTo(DELIVERY_PRICE_KEY)) {
      throw new Error('error.order.cantSendOrderByCashExtra');
    }
    if (order.deliveryType == DeliveryType.DELIVERY && (!order.deliveryData || order.deliveryData.id < 1) && !order.deliveryDataSameAsBillingData) {
      throw new Error('error.order.unknownDeliveryData')
    }
  }

  changePaymentFromCashToBarion(id: number, extraCartElementId: number) {
    return this.repo.changePaymentFromCashToBarion(id, extraCartElementId);
  }

  changePaymentFromBarionToCash(id: number, extraCartElement: ExtraCartElement) {
    return this.repo.changePaymentFromBarionToCash(id, extraCartElement);
  }

  changeShipmentFromOnsiteToDelivery(id: number, extraCartElement: ExtraCartElement) {
    return this.repo.changeShipmentFromOnsiteToDelivery(id, extraCartElement);
  }

  changeShipmentFromDeliveryToOnsite(id: number, extraCartElementId: number) {
    return this.repo.changeShipmentFromDeliveryToOnsite(id, extraCartElementId);
  }

  findAllByProductName(productNameFilter: string) {
    const filter = this.filterService.filter(OrderDtoTypes.OrderDTO)
      .equal('deleted', false)
      .and()
      .match('cart_cartElements_product_name', productNameFilter)
      .create();
    return this.repo.getAllByFilter(filter.stringify(), OrderModel);
  }
}
