import {Injectable} from "@angular/core";
import {RepoPath} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {AboutUsModel} from "./about-us.model";

@Injectable({
  providedIn: 'root'
})
@RepoPath('/about-us')
export class AboutUsRepository extends CrudRepository<AboutUsModel> {
  constructor() {
    super();
  }

}