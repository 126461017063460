import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({selector: '[copy-clipboard]'})
export class CopyClipboardDirective {

  @Input("copy-clipboard")
  public copyText: string = '';

  constructor() { }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.copyText) {
      console.error('No text provided to copy');
      return;
    }

    navigator.clipboard.writeText(this.copyText).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }
}
