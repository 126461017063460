import {Injectable} from '@angular/core';
import {Patch, PathVariable, RepoPath, RequestBody} from "@hellp/repository";
import {CrudRepository} from "../../utilities/service/crud.repository";
import {SupplierOrder} from "./supplier-order.model";
import {Inventory} from "../inventory/inventory.model";


@Injectable({
  providedIn: 'root'
})
@RepoPath('/supplier-orders')
export class SupplierOrderRepository extends CrudRepository<SupplierOrder> {
  constructor() {
    super();
  }
  @Patch('/{id}/ordered')
  setOrdered(@PathVariable() id: number,) {
    return this.createRequest().send();
  }

  @Patch('/{id}/process')
  process(@PathVariable() id: number, @RequestBody() supplierOrder: SupplierOrder) {
    return this.createRequest().send();
  }

  @Patch('/{id}/approve')
  approve(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/cancel')
  cancel(@PathVariable() id: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/add/')
  addElement(@PathVariable() id: number, @RequestBody() supplierOrder: SupplierOrder) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/update/')
  updateElement(@PathVariable() id: number, @RequestBody() supplierOrder: SupplierOrder) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/{elementId}/approve')
  approveElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }
  @Patch('/{id}/elements/{elementId}/approve/{count}')
  approveElementOld(@PathVariable() id: number, @PathVariable() elementId: number, @PathVariable() count: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/{elementId}/process')
  processElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/elements/{elementId}/remove')
  removeElement(@PathVariable() id: number, @PathVariable() elementId: number) {
    return this.createRequest().send();
  }

  @Patch('/{id}/attachments')
  uploadPdfs(@PathVariable() id: number, @RequestBody() model: SupplierOrder) {
    return this.createRequest().send();
  }

}
