<hellp-form-container [service]="service"
                      [(componentIsReady)]="componentIsReady"
                      [editMode]="mode==='edit'"
                      (save)="onSave()"
                      (cancel)="onCancel()">

  <div class="nx-margin-top-2m">
    <ng-container *ngIf="componentIsReady">
      <div class="row align-items-end">
        <ng-container *ngIf="!editMode">
          <div class="col-md-4">
            <nx-formfield nxFloatLabel="always" appearance="auto"
                          nxLabel="{{'product.warehouseSelect' | translate}}">
              <nx-dropdown (nxValueChange)="changeWarehouse($event)" [ngModel]="selectedWarehouse">
                <nx-dropdown-item
                  *ngFor="let option of warehouses"
                  [nxValue]="option"
                >
                  {{ option.name }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedWarehouse">
          <div class="col-md-4">
            <nx-formfield nxLabel="{{'scrapping.selectedWarehouse' | translate}}" appearance="auto"
                          nxFloatLabel="always">
              <input
                [disabled]="true"
                nxInput
                [ngModel]="selectedWarehouse.name"
              />
            </nx-formfield>
          </div>
          <div class="col-md-4">
            <nx-formfield nxLabel="{{'scrapping.note' | translate}}" appearance="auto" nxFloatLabel="always">
              <textarea nxInput [(ngModel)]="note" [disabled]="editMode"></textarea>
            </nx-formfield>
          </div>
          <div class="col-12 overflow-x-auto">
            <table nxTable>
              <thead>
              <tr nxTableRow>
                <th nxHeaderCell>{{ 'scrapping.productName' | translate }}</th>
                <th nxHeaderCell>{{ 'scrapping.supplierProductName' | translate }}</th>
                <ng-container *ngIf="editMode">
                  <th nxHeaderCell>{{ 'inventory.status' | translate }}</th>
                </ng-container>
                <th nxHeaderCell>{{ 'scrapping.actualCount' | translate }}</th>
                <th nxHeaderCell>{{ 'scrapping.count' | translate }}</th>
                <th nxHeaderCell>{{ 'scrapping.note' | translate }}</th>
                <th nxHeaderCell>
                </th>
              </tr>
              <tr nxTableRow style="border-bottom: 1px solid #000">
                <td nxTableCell>
                  <nx-formfield appearance="outline" nxFloatLabel="always">
                    <input nxInput [(ngModel)]="filter['productName']" type="text"
                           placeholder="{{'scrapping.productNameFilter' | translate}}"
                           (ngModelChange)="onFilter()"/>
                  </nx-formfield>
                </td>
                <td nxTableCell>
                  <nx-formfield appearance="outline" nxFloatLabel="always">
                    <input nxInput [(ngModel)]="filter['supplierProductName']" type="text"
                           placeholder="{{'scrapping.supplierProductNameFilter' | translate}}"
                           (ngModelChange)="onFilter()"/>
                  </nx-formfield>
                </td>
                <td nxTableCell></td>
                <td nxTableCell></td>
                <ng-container *ngIf="editMode">
                  <td nxTableCell></td>
                </ng-container>
                <td nxTableCell></td>
                <td nxTableCell>
                  <button nxPlainButton (click)="clearFilter()" nxTooltip="{{'general.clearFilter' | translate}}">
                    <nx-icon name="xmark"></nx-icon>
                  </button>
                </td>
              </tr>

              </thead>
              <tbody>
              <ng-container *ngFor="let stockProduct of availableElements| arraySort:'id':true">

                <tr nxTableRow>
                  <td nxTableCell>
                    {{ stockProduct.product.product.name }} <br>
                    ({{ stockProduct.product.product.itemNr }})
                  </td>
                  <td nxTableCell>
                    {{ stockProduct.product.supplierProduct.name }}<br>
                    ({{ stockProduct.product.supplierProduct.itemNr }})
                  </td>
                  <ng-container *ngIf="editMode">
                    <td nxTableCell>
                      <ng-container *ngIf="stockProduct.tempElement">
                        {{ 'inventory.' + stockProduct.tempElement.status  | translate }}
                      </ng-container>
                    </td>
                  </ng-container>

                  <td nxTableCell>
                    {{ stockProduct.count }} db
                  </td>
                  <td nxTableCell>
                    <ng-container *ngIf="!stockProduct.tempElement">
                      <nx-formfield appearance="outline">
                        <input nxInput [(ngModel)]="stockProduct.orderCount" type="number"
                               [disabled]="stockProduct.tempElement && stockProduct.tempElement.status != SupplierOrderStatus.DRAFT"
                               name="{{'order_'+stockProduct.id}}"/>
                      </nx-formfield>
                    </ng-container>
                    <ng-container *ngIf="stockProduct.tempElement">
                      <nx-formfield appearance="outline">
                        <input nxInput [(ngModel)]="stockProduct.tempElement.count" type="number"
                               [disabled]="stockProduct.tempElement && stockProduct.tempElement.status != SupplierOrderStatus.DRAFT"
                               name="{{'order_'+stockProduct.id}}"/>
                      </nx-formfield>
                    </ng-container>
                  </td>
                  <td nxTableCell>
                    <ng-container *ngIf="stockProduct.tempElement">
                      <nx-formfield appearance="outline">
                        <textarea nxInput [(ngModel)]="stockProduct.tempElement.message"
                                  [disabled]="stockProduct.tempElement && stockProduct.tempElement.status != SupplierOrderStatus.DRAFT"
                                  name="{{'note_'+stockProduct.id}}"></textarea>
                      </nx-formfield>
                    </ng-container>
                  </td>
                  <td nxTableCell>
                    <ng-container
                      *ngIf="editMode && stockProduct.tempElement && stockProduct.tempElement.id && stockProduct.tempElement.status == SupplierOrderStatus.DRAFT">
                      <button nxButton="primary small" class=" m-1"
                              (click)="updateElements()">
                        <nx-icon name="save"></nx-icon>
                      </button>
                      <button nxButton="danger small" class=" m-1"
                              (click)="removeElement(stockProduct.tempElement)">
                        <nx-icon name="trash"></nx-icon>
                      </button>
                      <button nxButton="primary small" class="bg-darker m-1"
                              (click)="approveElements(stockProduct.tempElement)">
                        <nx-icon name="thumbs-up"></nx-icon>
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="(!stockProduct.tempElement || !stockProduct.tempElement.id) && formModel.status == SupplierOrderStatus.DRAFT">
                      <button nxButton="primary small" class=" m-1"
                              *ngIf="!stockProduct.addToOrder"
                              [disabled]="stockProduct.orderCount<1"
                              (click)="add(stockProduct)">
                        <nx-icon name="plus"></nx-icon>
                      </button>
                      <button nxButton="danger small" class=" m-1" *ngIf="stockProduct.addToOrder"
                              (click)="remove(stockProduct)">
                        <nx-icon name="minus"></nx-icon>
                      </button>
                    </ng-container>
                  </td>
                </tr>

              </ng-container>
              </tbody>
            </table>
          </div>

        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!componentIsReady">
      <div class="d-flex justify-content-center nx-margin-y-2m">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </div>
</hellp-form-container>


