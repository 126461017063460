<hellp-list-container [service]="service" [(componentIsReady)]="componentIsReady" [hasNewConsumer]="false">
  <hellp-table id="product-table" [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
               [showAsList]="mobile"></hellp-table>
</hellp-list-container>

<ng-template #inlineTable let-row="row">
  {{ row  | json }}
</ng-template>

<ng-template #priceTpl let-value="value">
  <ng-container *ngIf="value.length>0">
    {{ value[0].net }} + {{ value[0].vat }}%
  </ng-container>
</ng-template>

<ng-template #hasPublishedTpl let-value="value">
  <ng-container *ngIf="value">
    <a class="link-primary" [href]="environment.publicUrl+'/bio/'+value.slug"
       target="_blank">{{ 'product.publishedAccess' | translate }}</a>
  </ng-container>
</ng-template>

<ng-template #statusTpl let-value="value">
  <span
    class="badge-sm {{value == HistoryStatus.DRAFT?'badge-draft':value == HistoryStatus.PUBLISHED?'badge-published':'badge-in-progress'}}">
  {{ 'enum.' + value | translate }}
    </span>
</ng-template>
