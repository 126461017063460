<ng-container *ngIf="priceModels">
  <ng-container *ngFor="let currency of currencies ; let i = index">
    <ng-container *ngTemplateOutlet="priceTpl; context:{price:priceModels[i], currency:currency}"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="priceModel">
  <ng-container *ngTemplateOutlet="priceTpl; context:{price:priceModel, currency:Currencies.huf}"></ng-container>
</ng-container>

<ng-template #priceTpl let-price="price" let-currency="currency">
  <div class="card p-3 mb-4 box-shadow">
    <div nxRow>
      <div *ngIf="title" nxCol="12">
        <h4 nxHeadline="subsection-small" class="nx-margin-bottom-m">{{ title | translate }}</h4>
      </div>
      <div nxCol="12,12,{{currencySelect?7:9}}">
        <nx-formfield nxLabel="{{'product.netPrice' | translate}}"
                      appearance="auto"
        >
          <input appValidatedInput required nxInput min="1" name="prices" type="number" [(ngModel)]="price.net"/>
          <span nxFormfieldSuffix>{{ price.currency }}</span>

          <nx-error nxFormfieldError
                    *ngIf="validationError.prices"> {{ validationError.prices | translate }}
          </nx-error>
        </nx-formfield>
      </div>
      <ng-container *ngIf="currencySelect">
        <div nxCol="12,12,2">
          <nx-formfield nxLabel="{{'product.currency'| translate}}"
                        appearance="auto">
            <nx-dropdown [(ngModel)]="price.currency">
              <nx-dropdown-item
                *ngFor="let option of ['HUF','EUR']"
                [nxValue]="option"
              ></nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
      </ng-container>
      <ng-container *ngIf="!withoutVat">
        <div nxCol="12,12,3">
          <nx-formfield
            nxLabel="{{'product.vat' | translate}}"
            appearance="auto"
          >
            <input appValidatedInput nxInput type="number" name="vat" [(ngModel)]="price.vat"/>
            <span nxFormfieldSuffix>%</span>
          </nx-formfield>
        </div>
      </ng-container>
    </div>

  </div>
</ng-template>
