import {Component, Input} from '@angular/core';
import {Dimensions} from "../../../services/product/product.model";

@Component({
  selector: 'app-dimension-panel',
  templateUrl: './dimension-panel.component.html',
  styleUrls: ['./dimension-panel.component.css']
})
export class DimensionPanelComponent {
  @Input() dimensions!:Dimensions;
  @Input() weightKg?:number;

}
